import { useState, ReactNode, useEffect } from "react"
import { toast } from "react-toastify"
import { SuccessSvg } from "~/ui-rtk/components/ui/svg/essentials"
import useConnect from "./connect"
import { Button } from "~/ui-rtk/components/ui/controls"
import { serviceConnectorMap } from "~/ui-rtk/components/features/connectors/forms/utils"

type TConnectionDetailsProps = {
  token: string
  service: string
  company: string
  application: string
}

const ConnectionDetails: React.FC<TConnectionDetailsProps> = ({
  token,
  company,
  service,
  application,
}) => {
  const [Dialog, setDialog] = useState<ReactNode | null>(null)
  const [onboardUrl, setOnboardUrl] = useState<string | null>(null)
  const { createConnection, isLoading } = useConnect()

  const closeDialog = () => {
    setDialog(null)
  }

  const handleConnect = async () => {
    try {
      const { companyConnectorId, uri } = await createConnection(token)
      const connectorService = serviceConnectorMap[service]
      if (connectorService) {
        const { component: ConnectorDialog } = connectorService
        setDialog(() => (
          <ConnectorDialog
            isOpen
            isGuest
            token={token}
            uri={uri}
            service={service}
            companyConnectorId={companyConnectorId}
            onClose={closeDialog}
          />
        ))
        return
      }

      setOnboardUrl(uri)
    } catch {
      toast.error("Failed to connect to the service. Try again later", {
        toastId: `fail-connect-to-service-${service}`,
      })
    }
  }

  useEffect(() => {
    if (onboardUrl) {
      location.href = onboardUrl
    }
  }, [onboardUrl])

  return (
    <div className="space-y-6">
      <p className="text-white font-[600] text-left">
        To successfully connect {application} to Marathon Data, please take the
        following steps:
      </p>
      <ul className="flex flex-col gap-4 text-left">
        <li className="flex items-center gap-4">
          <SuccessSvg fill="green" size={24} />
          <p>
            Ensure you have access to the {company} official {application}{" "}
            account, and that you use that username and login for the
            connection.
          </p>
        </li>
        <li className="flex items-center gap-4">
          <SuccessSvg fill="green" size={24} />
          <p>
            If you’ve previously logged into a {application} account via OAuth,
            you may need to use the “Switch Accounts” link you’ll find beneath
            your {application} username & profile picture.
          </p>
        </li>
      </ul>
      <Button
        variant={{ variant: "solid", color: "white" }}
        onClick={handleConnect}
        className="px-8 mx-auto"
        isLoading={isLoading}
      >
        Log in to {application}
      </Button>

      {Dialog}
    </div>
  )
}

export default ConnectionDetails
