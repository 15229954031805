import { AggregationType, BrandMediaAggregationType } from "../constants/charts"
import { usePeriod } from "./period"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

type TReturnType = {
  dateRange: [Date, Date]
  compareRange: [Date, Date] | null
}

const defaultMaxDate = dayjs().subtract(3, "day").toDate()

export const useMinMaxDateRange = (
  minDate?: Date,
  maxDate?: Date,
  defaultGranularity?: BrandMediaAggregationType | AggregationType,
  resetPeriodOnGranularityChange = false,
): TReturnType => {
  const { lastDate } = useLastUpdateDate()
  const { period: dateRange, comparePeriod: compareRange } = usePeriod(
    defaultGranularity,
    resetPeriodOnGranularityChange,
  )
  const maxDateWithDefault =
    maxDate ??
    (lastDate ? new Date(lastDate as string) : undefined) ??
    defaultMaxDate
  let adjustedStartDate: Date = dayjs(dateRange[0]).toDate()
  let adjustedEndDate: Date = dayjs(dateRange[1]).toDate()
  let compareRangeAdjusted: [Date, Date] | null = null

  if (minDate && adjustedStartDate < minDate) {
    adjustedStartDate = minDate
  }

  if (maxDateWithDefault && adjustedEndDate > maxDateWithDefault) {
    adjustedEndDate = maxDateWithDefault
  }

  if (compareRange && compareRange[0] && compareRange[1]) {
    compareRangeAdjusted = [
      dayjs(compareRange[0]).toDate(),
      dayjs(compareRange[1]).toDate(),
    ]
  }

  return {
    dateRange: [adjustedStartDate, adjustedEndDate],
    compareRange: compareRangeAdjusted,
  }
}
