import React, { useEffect, useState } from "react"
import { CompanyDto } from "~/ui-rtk/api/types"

import { Logo } from "~/ui-rtk/components/ui/common"
import useDate from "~/ui-rtk/hooks/date.ts"
import { Title, ProfileMenu } from "./components"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { DEFAULT_LOGGED_ROUTE, guestRoutes } from "~/ui-rtk/constants/routes"

import { WarnSvg, PlusSvg } from "../../ui/svg/essentials"
import P from "~/ui-rtk/components/ui/typography/P"
import { useAppSelector, useAppDispatch } from "~/ui-rtk/app/hooks"
import { updateDemo } from "~/ui-rtk/app/slices/demo.slice"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { SessionRole } from "~/api/dto/auth/session-role"
import { CreateCompanyDialog } from "./components/AddCompanyDialog"
import { Button } from "../../ui/controls"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"

type TTopbarProps = {
  onCompanyChange: (companyId: string) => void
  currentCompany: CompanyDto
}

const dayjs = useDate()

const Topbar: React.FC<TTopbarProps> = ({
  onCompanyChange,
  currentCompany,
}) => {
  const navigate = useStableNavigate()
  const dispatch = useAppDispatch()
  const {
    lastDate,
    isLoading: isLastDateLoading,
    refetch: refetchLastDate,
  } = useLastUpdateDate()

  const role = useAppSelector(selectUserSessionRole)
  const demo = useAppSelector(selectIsEnabled)

  const enableDemoMode = () => {
    dispatch(updateDemo(!demo.isEnabled))
  }

  const [isOpen, setIsOpen] = useState(false)

  const redirectToHomePage = () => {
    void navigate(DEFAULT_LOGGED_ROUTE)
  }

  const redirectToSubscribePage = () => {
    void navigate(guestRoutes.SUBSCRIBE)
  }

  const openDialog = () => {
    if (role !== SessionRole.SUPER_ADMIN) {
      return
    }

    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const today = dayjs()

  useEffect(() => {
    refetchLastDate().catch(() => null)
  }, [currentCompany])

  let LastSyncedTag = null
  if (lastDate && !isLastDateLoading) {
    LastSyncedTag = (
      <div className="gap-2 justify-center p-3 rounded-full bg-basic-dark-blue border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5">
        <P className="font-bold text-3">
          Last Updated on: {dayjs(lastDate as string).format("MMM D, YYYY")}
        </P>
      </div>
    )
  }

  let FreeAccessTag = null
  if (currentCompany.metadata?.freeAccessEndsAt) {
    const freeAccess = dayjs(currentCompany.metadata?.freeAccessEndsAt)

    if (freeAccess.isBefore(today)) {
      FreeAccessTag = (
        <div
          className="gap-2 justify-center p-3 cursor-pointer rounded-full bg-brand-dark border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5"
          onClick={redirectToSubscribePage}
        >
          <WarnSvg size={24} fill="background.light" />
          <P className="font-bold text-3">
            Free Access ended {dayjs().to(freeAccess)}
          </P>
        </div>
      )
    } else {
      FreeAccessTag = (
        <div className="gap-2 justify-center p-3 rounded-full bg-basic-dark-blue border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5">
          <WarnSvg size={24} fill="background.light" />
          <P className="font-bold text-3">
            Free Access ends {dayjs().to(freeAccess)}
          </P>
        </div>
      )
    }
  }

  let TrialTag = null
  if (
    currentCompany.subscription?.trialEndsAt &&
    dayjs().isBefore(currentCompany.subscription?.trialEndsAt)
  ) {
    TrialTag = (
      <div className="gap-2 justify-center p-3 rounded-full bg-basic-dark-blue border border-basic-blue flex items-center text-4 line-height-4 font-semibold leading-5">
        <WarnSvg size={24} fill="background.light" />
        <P className="font-bold text-3">
          Trial ends {dayjs().to(currentCompany.subscription?.trialEndsAt)}
        </P>
      </div>
    )
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-[1001] flex items-center justify-between px-5 py-2 border-b border-b-basic-blue bg-basic-black">
      <div className="flex items-center gap-16">
        <Logo onClick={redirectToHomePage} cursor="pointer" />
        <div className="flex gap-2">
          <Title
            onCompanyChange={onCompanyChange}
            currentCompany={currentCompany}
          />

          {role === SessionRole.SUPER_ADMIN && (
            <React.Fragment>
              <Button
                onClick={openDialog}
                size="sm"
                variant={{ variant: "solid", color: "blue" }}
              >
                <PlusSvg />
              </Button>

              <CreateCompanyDialog isOpen={isOpen} onClose={closeDialog} />
            </React.Fragment>
          )}
          {role === SessionRole.SUPER_ADMIN && (
            <React.Fragment>
              <Button
                onClick={enableDemoMode}
                size="sm"
                variant={{
                  variant: "solid",
                  color: demo.isEnabled ? "red" : "blue",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                  />
                </svg>
                Demo Mode
              </Button>

              <CreateCompanyDialog isOpen={isOpen} onClose={closeDialog} />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3">
        {LastSyncedTag}
        {TrialTag ? TrialTag : FreeAccessTag}
        <ProfileMenu />
      </div>
    </div>
  )
}

export default Topbar
