import { LocalStorageKeys, useLocalStorage } from "~/ui-rtk/utils/storage"
import { PaginationSkeleton } from "./PaginationSkeleton"
import "./skeleton.scss"
import { ITEMS_PER_PAGE } from "../TableVisualization/constants"
import useRefetchDialog from "~/ui-rtk/hooks/refetch-dialog"

export function TableSkeleton() {
  const [itemsPerPage] = useLocalStorage<number>(
    LocalStorageKeys.TABLE_VISUALIZATION_ITEMS_PER_PAGE,
    ITEMS_PER_PAGE,
  )

  const refetchContent = useRefetchDialog()

  const rows = new Array(itemsPerPage).fill("&nbsp;")
  const cols = new Array(6).fill("&nbsp;")
  return (
    <div className="h-full px-4 pb-2 space-y-3 bg-basic-black relative">
      {refetchContent}
      <table className="table border-b border-b-basic-blue">
        <thead>
          <tr>
            {cols.map((_, idx) => (
              <th key={`skeleton-head-${idx}`} className="px-4 py-5">
                <div className="skeleton bg-basic-dark-blue">&nbsp;</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((_, idx) => (
            <tr
              key={`skeleton-row-${idx}`}
              className={idx % 2 !== 0 ? "bg-basic-dark-blue/50" : ""}
            >
              {cols.map((__, idy) => (
                <td key={`skeleton-cell-${idx}-${idy}`} className="px-5 py-4">
                  <div className="skeleton bg-basic-dark-blue">&nbsp;</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationSkeleton />
    </div>
  )
}
