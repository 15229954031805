import * as yup from "yup"
import { useCallback } from "react"
import {
  useCompanyConnectorControllerAuthenticateCompanyConnectorMutation as useCompanyConnectorAuthMutation,
  useGuestCompanyConnectorControllerAuthenticateMutation as useGuestCompanyConnectorAuthMutation,
} from "~/ui-rtk/api/companyConnectorApi"
import { GuestTokenParam } from "../types"

export const validationSchema = yup.object().shape({
  user: yup.string().required("SFTP User is required!"),
  password: yup.string().required("SFTP Password is required!"),
})

export type FormValues = yup.InferType<typeof validationSchema>

const initialValues: FormValues = {
  user: "",
  password: "",
}

type Params = {
  authCode: string
  companyConnectorId: string
} & GuestTokenParam

export const useConnect = () => {
  const [authAsync, { isLoading: isAuthLoading }] =
    useCompanyConnectorAuthMutation()
  const [guestAuthAsync, { isLoading: isGuestAuthLoading }] =
    useGuestCompanyConnectorAuthMutation()

  const authenticate = useCallback(async (params: Params) => {
    const { isGuest, companyConnectorId, authCode } = params
    if (isGuest) {
      await guestAuthAsync({
        id: companyConnectorId,
        token: params.token,
        authenticateCompanyConnectorDto: { authCode },
      }).unwrap()
    } else {
      await authAsync({
        id: companyConnectorId,
        authenticateCompanyConnectorDto: { authCode },
      }).unwrap()
    }
  }, [])

  const isLoading = isAuthLoading || isGuestAuthLoading

  return {
    authenticate,
    initialValues,
    validationSchema,
    isLoading,
  }
}
