import { type ColumnOrderState } from "@tanstack/react-table"
import { useEffect, useState } from "react"

const IN_MEMORY_ORDER_STATE: Record<string, ColumnOrderState> = {}

export function useColumnOrder(widgetId: string, columnIds: string[]) {
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    IN_MEMORY_ORDER_STATE[widgetId] ?? columnIds,
  )
  const [rendered, rerender] = useState(0)

  useEffect(() => {
    IN_MEMORY_ORDER_STATE[widgetId] = columnOrder
    rerender(rendered - 1)
  }, [columnOrder, widgetId])

  return {
    columnOrder: IN_MEMORY_ORDER_STATE[widgetId] ?? columnOrder,
    setColumnOrder,
  }
}
