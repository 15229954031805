import { useMemo } from "react"
import { useCompanyConnectorControllerGetCompanyConnectorStatusQuery } from "~/ui-rtk/api/companyConnectorApi"
import { isSyncOutdated } from "./utils"
import { ConnectionStatus } from "../../utils"

export const useConnect = (id: string) => {
  const { data, isLoading } =
    useCompanyConnectorControllerGetCompanyConnectorStatusQuery({
      id,
    })

  const connectorInfo = useMemo(() => {
    if (!data) {
      return undefined
    }

    let status: ConnectionStatus = "healthy"

    const isOutdated = isSyncOutdated(data.lastSuccessfulSyncDate)

    if (isOutdated) {
      status = "unhealthy"
    }

    return {
      status,
      isLastSyncOutdated: isOutdated,
      ...data,
    }
  }, [data])

  return { connectorInfo, isLoading }
}
