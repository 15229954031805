const connectorsRequiringWhiteTheme = [
  "amazon_ads",
  "tiktok",
  "tiktok_ads",
  "shopify",
]

export const adjustConnectorIconForWhiteTheme = (
  connectorId: string,
  iconPath: string,
) => {
  const requiresWhiteTheme = connectorsRequiringWhiteTheme.includes(connectorId)
  if (!requiresWhiteTheme) {
    return iconPath
  }

  return iconPath.replace(
    "/assets/img/connectors/",
    "/assets/img/connectors/white-theme/",
  )
}
