import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { useCallback } from "react"
import { AggregationType } from "../constants/charts"
import { useAppSelector } from "../app/hooks"
import { TCubeFilterOptions } from "../shared/types/charts"

import useDate from "~/ui-rtk/hooks/date"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"
const dayjs = useDate()

const USE_SESSION_STORAGE = false

let IN_MEMORY_CACHE: Record<string, any> = {}

type TCubeChacheKeyOptions = {
  companyId: string
  chartId: string
  dateRange: [Date, Date]
  page: number
  itemsPerPage: number
  granularity?: AggregationType | BrandMediaAggregationType
  cubeFilters?: TCubeFilterOptions
  sorting?: any[]
}

export const getCubeCacheKey = ({
  companyId,
  chartId,
  dateRange,
  page,
  itemsPerPage,
  granularity,
  cubeFilters,
  sorting,
}: TCubeChacheKeyOptions) =>
  `${companyId}-${chartId}-${dayjs(dateRange[0]).format("YYYY-MM-DD")}-${dayjs(dateRange[1]).format("YYYY-MM-DD")}${granularity ? `-${granularity}` : ""}-p${page}-ipp${itemsPerPage}-f${JSON.stringify(cubeFilters)}-s${JSON.stringify(sorting)}`

// ToDo: could be `base64` encoded
const METRICS_CACHE_KEY = `MARATHON_DATA_CACHE_KEY_${import.meta.env.VITE_ENV}`

const getParsedStorageItem = (): Record<string, any> => {
  const cache = window.sessionStorage.getItem(METRICS_CACHE_KEY)
  if (!cache) {
    return {}
  }
  let parsedCache = {}
  try {
    parsedCache = JSON.parse(cache)
  } catch (e) {
    console.warn("could not parse cache", e)
  }
  return parsedCache ?? {}
}

const getSessionStorageItem = (key: string) => {
  const parsedCache = USE_SESSION_STORAGE
    ? getParsedStorageItem()
    : IN_MEMORY_CACHE
  if (!parsedCache) {
    return null
  }
  return parsedCache[key] ?? null
}

const setSessionStorageItem = (key: string, val: any) => {
  if (USE_SESSION_STORAGE) {
    let parsedCache = getParsedStorageItem()
    if (!parsedCache) {
      parsedCache = <Record<string, any>>{}
    }
    parsedCache[key] = val
    const cache = JSON.stringify(parsedCache)
    window.sessionStorage.setItem(METRICS_CACHE_KEY, cache)
  } else {
    IN_MEMORY_CACHE[key] = val
  }
}

const clearSessionStorageItem = () => {
  window.sessionStorage.removeItem(METRICS_CACHE_KEY)
}

export function clearMetricsCache() {
  IN_MEMORY_CACHE = {}
}

export default function useMetricsCache(
  chartId: string,
  granularity: AggregationType | BrandMediaAggregationType,
  dateRange: [Date, Date],
  onCacheSet: (metrics: any) => void,
  page: number,
  itemsPerPage: number,
  cubeFilters?: TCubeFilterOptions,
  sorting?: any[],
) {
  const currentCompany = useAppSelector(selectCurrentCompany)
  if (!currentCompany) {
    throw new Error("No current company")
  }

  const { id: companyId } = currentCompany

  const clearCache = clearSessionStorageItem
  const setCacheItem = useCallback(
    (
      chartId: string,
      dateRange: [Date, Date],
      granularity: AggregationType | BrandMediaAggregationType,
      metrics: any,
      page: number,
      itemsPerPage: number,
      cubeFilters?: TCubeFilterOptions,
      sorting?: any[],
    ) => {
      if (!companyId) {
        console.warn("Company id is missing")
        return
      }

      if (!dateRange) {
        return
      }

      const key = getCubeCacheKey({
        companyId,
        chartId,
        dateRange,
        granularity,
        page,
        itemsPerPage,
        cubeFilters,
        sorting,
      })
      setSessionStorageItem(key, metrics)
      onCacheSet(metrics)
    },
    [
      chartId,
      dateRange,
      granularity,
      companyId,
      page,
      itemsPerPage,
      cubeFilters,
      sorting,
    ],
  )

  if (!dateRange) {
    return {
      isInProgress: () => false,
      data: null,
      clearCache,
      setCacheItem: () => null,
    }
  }

  const key = getCubeCacheKey({
    companyId,
    chartId,
    dateRange,
    granularity,
    page,
    itemsPerPage,
    cubeFilters,
    sorting,
  })

  return {
    isInProgress: () => !getSessionStorageItem(key),
    data: getSessionStorageItem(key),
    clearCache,
    setCacheItem,
    page,
  }
}
