import React from "react"
import { Form, Formik } from "formik"
import { toast } from "react-toastify"

import {
  ConnectorDialog,
  ConnectFormField,
} from "~/ui-rtk/components/features/connectors"
import { FormValues, useConnect } from "./connect"

import {
  isInternalServerError,
  isUnauthorizedError,
} from "~/ui-rtk/utils/http-utils"

import { EMAIL_SUBJECT, EMAIL_BODY } from "./constants"
import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"

import { Button } from "~/ui-rtk/components/ui/controls"

import type { CustomerConnectorDialogFormPropsWithoutUri } from "../types"

type AttentiveFormProps = CustomerConnectorDialogFormPropsWithoutUri

const AttentiveForm: React.FC<AttentiveFormProps> = ({
  companyConnectorId,
  isOpen,
  onClose,
  ...props
}) => {
  const { isLoading, initialValues, validationSchema, authenticate } =
    useConnect()

  const handleConnect = async (values: FormValues) => {
    if (!companyConnectorId) {
      throw new Error("Missing company connector id")
    }

    const authCode = btoa(`${values.user}:${values.password}`)

    try {
      await authenticate({
        companyConnectorId,
        authCode,
        ...props,
      })
    } catch (err) {
      if (isUnauthorizedError(err)) {
        toast.error("Invalid credentials", {
          toastId: "attentive-form-connect-unauthorized-error",
        })
      } else if (isInternalServerError(err)) {
        toast.error("Uh oh! Something didn't go as expected!", {
          toastId: "attentive-form-connect-interval-error",
        })
      } else {
        toast.error("Uh oh! The server returned a bad response!", {
          toastId: "attentive-form-connect-bad-response-error",
        })
      }
      return
    }

    onClose()
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.attentive}
      alt="Attentive Logo"
      title="Connection to Attentive"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className="leading-5 text-left text-base text-platinum">
        Before you begin this process, you must have an Attentive SFTP account
        set up for you. If you haven't yet requested an SFTP account, please
        contact your designated Attentive Client Strategy team member or
        Attentive White Glove team{" "}
        <a
          href={`mailto:whiteglove@attentivemobile.com?subject=${encodeURIComponent(EMAIL_SUBJECT)}&body=${encodeURIComponent(EMAIL_BODY)}`}
          className="text-blue"
        >
          whiteglove@attentivemobile.com
        </a>
        . You will be required to provide an email address that will be used to
        manage the SFTP account and reset the password.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConnect}
      >
        {({ touched, errors }) => (
          <Form className="flex flex-col w-full gap-4 mt-8">
            <div className="space-y-3">
              <ConnectFormField
                name="user"
                label="SFTP User*"
                placeholder="Enter your SFTP user here"
                isError={Boolean(touched.user && errors.user)}
              />
              <ConnectFormField
                name="password"
                label="SFTP Password*"
                placeholder="Enter your SFTP password here"
                isError={Boolean(touched.password && errors.password)}
              />
            </div>

            <Button
              variant={{ variant: "solid", color: "white" }}
              type="submit"
              isLoading={isLoading}
            >
              Connect
            </Button>
          </Form>
        )}
      </Formik>
    </ConnectorDialog>
  )
}

export default AttentiveForm
