import React, { PropsWithChildren } from "react"

import { H3 } from "~/ui-rtk/components/ui/typography"
import { Dialog } from "~/ui-rtk/components/ui/common"
import { CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type ConnectorDialogProps = {
  imgSrc: string
  alt: string
  title: string
  isOpen: boolean
  onClose: () => void
  classes?: Partial<{
    container: string
  }>
} & PropsWithChildren

const ConnectorDialog: React.FC<ConnectorDialogProps> = ({
  imgSrc: img,
  alt,
  title,
  isOpen,
  children,
  classes,
  onClose,
}) => (
  <Dialog isOpen={isOpen} handleClose={onClose}>
    <div className="max-w-160 w-full absolute rounded-md py-8 px-10 center-absolute bg-basic-dark-blue border border-basic-blue shadow-deep">
      <div className="flex flex-col items-center gap-6 mb-8">
        <img src={img} alt={alt} className="w-9 h-9" />
        <H3 className="font-medium text-white">{title}</H3>
      </div>
      <div className={cn("space-y-4", classes?.container)}>{children}</div>
      <button
        className="absolute right-4 top-4 max-w-7 max-h-7 p-2 flex items-center justify-center hover:bg-basic-blue hover:text-white transition-colors rounded-1"
        onClick={onClose}
      >
        <CloseSvg size={20} fill="platinum" />
      </button>
    </div>
  </Dialog>
)

export default ConnectorDialog
