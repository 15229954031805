import React from "react"
import { ChevronDownSvg } from "~/ui-rtk/components/ui/svg/arrow"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TToggleButtonProps = {
  onToggle: () => void
  initial?: boolean
}

const ToggleButton: React.FC<TToggleButtonProps> = ({
  onToggle,
  initial = false,
}) => (
  <button onClick={onToggle}>
    <ChevronDownSvg
      fill="white"
      size={16}
      className={cn("transition-all", initial && "rotate-180")}
    />
  </button>
)

export default ToggleButton
