import React from "react"
import { Formik, Form } from "formik"

import {
  ConnectorDialog,
  ConnectFormField,
} from "~/ui-rtk/components/features/connectors"
import { FormValues, useConnect } from "./connect"
import { toast } from "react-toastify"
import { isInternalServerError } from "~/ui-rtk/utils/http-utils"
import type { CustomerConnectorDialogFormPropsWithoutUri } from "../types"
import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"
import { Button } from "~/ui-rtk/components/ui/controls"

type GoogleKeywordSearchFormProps = CustomerConnectorDialogFormPropsWithoutUri

const GoogleKeywordSearchForm: React.FC<GoogleKeywordSearchFormProps> = ({
  companyConnectorId,
  isOpen,
  onClose,
  ...props
}) => {
  const { initialValues, validationSchema, isLoading, authenticate } =
    useConnect()

  const handleConnect = async (values: FormValues) => {
    if (isLoading) {
      return
    }

    if (!companyConnectorId) {
      throw new Error("Missing comapny connector id")
    }

    const authCode = "google-keywords"
    const extra = JSON.stringify(values)

    try {
      await authenticate({ companyConnectorId, authCode, extra, ...props })
      onClose()
    } catch (err) {
      if (isInternalServerError(err)) {
        toast.error("Invalid Google Keywords", {
          toastId: "google-keywords-form-connect-keywords-error",
        })
        return
      }

      toast.error("Unexpected error. Please, try again later.", {
        toastId: "google-keywords-form-connect-unexcepted-error",
      })
    }
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.googleKeywordSearch}
      alt="Google Keyword Search Logo"
      title="Connection to Google Keyword Search"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className="leading-5 text-left text-base text-platinum">
        In order to proceed with this connection, you must provide a list of
        keywords you want to search. After typing a keyword make sure to hit
        "Enter" so that the word is saved. You can insert up to 9 keywords in
        each field.
      </p>
      <Formik
        onSubmit={handleConnect}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ touched, errors }) => (
          <Form className="flex flex-col w-full gap-4 mt-8">
            <div className="space-y-3">
              <ConnectFormField
                name="brands"
                label="Brand Keywords*"
                placeholder="Insert your brand keywords here"
                isError={Boolean(touched.brands && errors.brands)}
              />
              <ConnectFormField
                name="competitors"
                label="Competitor Keywords*"
                placeholder="Insert your competitor keywords here"
                isError={Boolean(touched.competitors && errors.competitors)}
              />
              <ConnectFormField
                name="categories"
                label="Category Keywords*"
                placeholder="Insert your category keywords here"
                isError={Boolean(touched.categories && errors.categories)}
              />
            </div>

            <Button
              variant={{ variant: "solid", color: "white" }}
              type="submit"
              isLoading={false}
            >
              Connect
            </Button>
          </Form>
        )}
      </Formik>
    </ConnectorDialog>
  )
}

export default GoogleKeywordSearchForm
