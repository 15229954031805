import cube, { type CubeApi } from "@cubejs-client/core"
import { useCallback, useMemo } from "react"
import { useAuthControllerCurrentUserQuery } from "~/ui-rtk/api/authApi"
import { SessionDto } from "~/ui-rtk/api/types"

let cubeApi: CubeApi

export const useAppSession = () => {
  const {
    currentData: session,
    isLoading,
    refetch,
    fulfilledTimeStamp,
  } = useAuthControllerCurrentUserQuery()

  const getNewToken = useCallback(async () => {
    const newSession = await refetch()
    return newSession.data?.cube.token
  }, [refetch])

  const getApiToken = async (
    session: SessionDto,
    timestamp: number,
    fulfilledTimeStamp: number,
  ) => {
    if (
      session.cube.token &&
      timestamp - fulfilledTimeStamp < session.cube.expiresIn * 1000
    ) {
      return session.cube.token
    }
    return await getNewToken()
  }

  cubeApi = useMemo(() => {
    if (!session || !fulfilledTimeStamp) {
      return cube(() => Promise.resolve(""), {
        apiUrl: `${import.meta.env.VITE_CUBE_API_URL}/cubejs-api/v1`,
      })
    }

    const timestamp = new Date().getTime()

    return cube(
      async () => {
        const apiToken = await getApiToken(
          session,
          timestamp,
          fulfilledTimeStamp,
        )
        return apiToken ?? "no-token"
      },
      {
        apiUrl: `${import.meta.env.VITE_CUBE_API_URL}/cubejs-api/v1`,
      },
    )
  }, [session, fulfilledTimeStamp])

  const invalidateTokenIfNeeded = useCallback(() => {
    const timestamp = new Date().getTime()
    if (
      !fulfilledTimeStamp ||
      !session ||
      timestamp - fulfilledTimeStamp >= (session?.cube?.expiresIn ?? 0) * 1000
    ) {
      return getNewToken()
    }
  }, [refetch, session, fulfilledTimeStamp])

  return { session, cubeApi, isLoading, invalidateTokenIfNeeded }
}
