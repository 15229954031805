import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ArrowTopSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99813 1.83C7.87146 1.83 7.7448 1.87667 7.6448 1.97667L3.59812 6.02333C3.40479 6.21667 3.40479 6.53667 3.59812 6.73C3.79146 6.92333 4.11146 6.92333 4.30479 6.73L7.99813 3.03667L11.6915 6.73C11.8848 6.92333 12.2048 6.92333 12.3981 6.73C12.5915 6.53667 12.5915 6.21667 12.3981 6.02333L8.35146 1.97667C8.25146 1.87667 8.1248 1.83 7.99813 1.83Z"
      fill={fill}
    />
    <path
      d="M8 1.94406C7.72667 1.94406 7.5 2.17073 7.5 2.44406V13.6641C7.5 13.9374 7.72667 14.1641 8 14.1641C8.27333 14.1641 8.5 13.9374 8.5 13.6641V2.44406C8.5 2.17073 8.27333 1.94406 8 1.94406Z"
      fill={fill}
    />
  </svg>
))
