import React from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { Button } from "~/ui-rtk/components/ui/controls"

import { brandRoutes } from "~/ui-rtk/constants/routes"

type TExpiryDetailsProps = {
  inviter: string
}

const ExpiryDetails: React.FC<TExpiryDetailsProps> = ({ inviter }) => {
  const navigate = useStableNavigate()

  const handleNavigate = () => {
    navigate(brandRoutes.SETUP.CONNECTORS)
  }

  return (
    <div className="space-y-4">
      <p className="text-red text-[600] text-4.5 leading-7">
        Unfortunately, it looks like this link has expired! Please request a new
        link from {inviter} in order to complete the connection!
      </p>
      <Button
        variant={{ variant: "solid", color: "white" }}
        onClick={handleNavigate}
        className="px-8"
      >
        Back to connectors
      </Button>
    </div>
  )
}

export default ExpiryDetails
