import { useEffect } from "react"
import { Formik, Form, ErrorMessage, Field } from "formik"

import { H3, P } from "~/ui-rtk/components/ui/typography"
import { Divider } from "~/ui-rtk/components/ui/common"
import { Input, Button } from "~/ui-rtk/components/ui/controls"

import useConnect, { ChangePasswordFormValues } from "./connect"
import useLoadingState from "~/ui-rtk/hooks/loading-state"

export default function SettingsPasswordPage() {
  const { changePassword, initialValues, validationSchema } = useConnect()
  const { isLoading, setIsLoading } = useLoadingState()

  useEffect(() => {
    document.title = "Marathon Data: Settings > Password"
  }, [])

  const handleChangePassword = async (values: ChangePasswordFormValues) => {
    setIsLoading(true)
    await changePassword(values).catch(() => null)
    setIsLoading(false)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleChangePassword}
        validationSchema={validationSchema}
      >
        <Form className="py-6">
          <H3>Password</H3>
          <P className="my-3">
            Manage your password here. Please enter your current password to
            change your password
          </P>
          <div className="py-5">
            <div className="inline-block w-48">Old Password</div>
            <div className="inline-block">
              <Field
                type="password"
                id="oldPassword"
                name="oldPassword"
                autoComplete="current-password"
                as={Input}
              />
              <ErrorMessage
                component="a"
                name="oldPassword"
                className="absolute input-error-message"
              />
            </div>
          </div>
          <Divider className="my-3" />
          <div className="py-5">
            <div className="inline-block w-48">New Password</div>
            <div className="inline-block">
              <Field
                type="password"
                id="newPassword"
                name="newPassword"
                autoComplete="new-password"
                as={Input}
              />
              <ErrorMessage
                component="a"
                name="newPassword"
                className="absolute input-error-message"
              />
            </div>
          </div>
          <div className="py-5 mt-3">
            <div className="inline-block w-48">Confirm Password</div>
            <div className="inline-block">
              <Field
                type="password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                autoComplete="new-password"
                as={Input}
              />
              <ErrorMessage
                component="a"
                name="confirmNewPassword"
                className="absolute input-error-message"
              />
            </div>
          </div>
          <Divider className="mt-3 mb-5" />
          <div>
            <Button
              variant={{ variant: "solid", color: "pink" }}
              size="sm"
              type="submit"
              isLoading={isLoading}
            >
              Update Password
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  )
}
