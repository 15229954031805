import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectIsOpen } from "../app/selectors/drawer.selector"
import { setContent, setIsOpen } from "../app/slices/drawer.slice"
import { TDrawerConfig } from "../constants/drawer"

export default function useDrawer() {
  const dispatch = useAppDispatch()
  const { isOpen } = useAppSelector(selectIsOpen)

  const openDrawer = useCallback(
    (content: TDrawerConfig) => {
      dispatch(setContent(content))
      dispatch(setIsOpen(true))
    },
    [dispatch, setContent, setIsOpen],
  )

  const closeDrawer = useCallback(() => {
    dispatch(setIsOpen(false))
    dispatch(setContent(null))
  }, [dispatch, setContent, setIsOpen])

  return {
    isOpen,
    openDrawer,
    closeDrawer,
  }
}
