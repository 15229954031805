import React, { ReactNode } from "react"
import { ErrorMessage, Field } from "formik"

import { Input } from "~/ui-rtk/components/ui/controls"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TFormFieldClasses = Partial<{
  label: string
  input: string
}>

type TFormFieldProps = {
  id: string
  type: string
  isError?: boolean
  className?: string
  placeholder?: string
  label?: string
  icon?: ReactNode
  appendNode?: ReactNode
  classes?: TFormFieldClasses
}

const FormField: React.FC<TFormFieldProps> = ({
  id,
  type,
  label,
  isError = false,
  placeholder = "",
  className,
  classes,
  ...props
}) => (
  <div className="relative flex flex-col items-start w-full">
    {label ? (
      <label
        className={cn(
          "text-white px-0.5 font-medium mb-1 text-4 leading-4 tracking-[-1%]",
          classes?.label,
        )}
      >
        {label}
      </label>
    ) : null}
    <Field
      id={id}
      name={id}
      type={type}
      as={Input}
      placeholder={placeholder}
      fullWidth
      isError={isError}
      className={cn("bg-white w-full text-black", className)}
      classes={{
        input: cn(
          "bg-white text-black font-normal outline-0 bg-inherit",
          classes?.input,
        ),
      }}
      {...props}
    />
    <ErrorMessage
      name={id}
      component="a"
      className="absolute left-0 input-error-message top-full"
    />
  </div>
)

export default FormField
