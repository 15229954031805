import { useState } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"
import * as yup from "yup"
import {
  useAuthControllerCurrentUserQuery,
  useAuthControllerLogoutMutation,
} from "~/ui-rtk/api/authApi"
import {
  UpdateCompanyDto,
  useCompanyControllerUpdateCompanyMutation,
} from "~/ui-rtk/api/companyApi"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { useLocalStorage, LocalStorageKeys } from "~/ui-rtk/utils/storage"

export type TKeywordsPageFormValues = {
  googleSearchAlias: string
  googleBrands: string[]
  googleCompetitors: string[]
  googleCategories: string[]
  instagramCompetitors: string[]
}

export const validationSchema = yup.object().shape({
  googleSearchAlias: yup.string().optional(),
  googleBrands: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required")
    .required("Please fill keywords"),
  googleCompetitors: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required")
    .required("Please fill keywords"),
  googleCategories: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required")
    .required("Please fill keywords"),
  instagramCompetitors: yup
    .array()
    .of(yup.string())
    .min(1, "At least one keyword is required")
    .required("Please fill keywords"),
})

export default function useConnect() {
  const [dontShowDialogAgain, setDontShowDialogAgain] =
    useLocalStorage<boolean>(
      LocalStorageKeys.LOCAL_STORAGE_DONT_SHOW_AGAIN_SAVE_KEYWORD_DIALOG_NOTIFICATON_KEY,
      false,
    )
  const [reloaded, triggerReload] = useState(false)
  const currentCompany = useAppSelector(selectCurrentCompany)
  const { refetch } = useAuthControllerCurrentUserQuery()
  const [updateCompany] = useCompanyControllerUpdateCompanyMutation()
  const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
  const [logout] = useAuthControllerLogoutMutation()
  const navigate = useStableNavigate()

  const initialValues: TKeywordsPageFormValues = {
    googleSearchAlias: keywords?.google?.searchAlias,
    googleBrands: keywords?.google?.brands ?? [],
    googleCompetitors: keywords?.google?.competitors ?? [],
    googleCategories: keywords?.google?.categories ?? [],
    instagramCompetitors: keywords?.instagram?.competitors ?? [],
  }

  const setDontShowDialogAgainHandler = (val: boolean) => {
    setDontShowDialogAgain(val)
    triggerReload(!reloaded)
  }

  const updateKeywords = async ({
    googleSearchAlias,
    googleBrands,
    googleCompetitors,
    googleCategories,
    instagramCompetitors,
  }: TKeywordsPageFormValues) => {
    if (!currentCompany) {
      return
    }
    const company: UpdateCompanyDto = {
      metadata: {
        ...(currentCompany?.metadata ?? {}),
        keywords: {
          ...(currentCompany?.metadata?.keywords ?? {}),
          google: {
            ...((currentCompany?.metadata as any)?.keywords?.google ?? {}),
            searchAlias: googleSearchAlias,
            brands: googleBrands,
            competitors: googleCompetitors,
            categories: googleCategories,
          },
          instagram: {
            ...((currentCompany?.metadata as any)?.keywords?.instagram ?? {}),
            competitors: instagramCompetitors,
          },
        },
      },
    }

    try {
      await updateCompany({
        id: currentCompany.id,
        updateCompanyDto: company,
      }).unwrap()
      if (dontShowDialogAgain) {
        toast.success("Keywords were updated")
      }
      await refetch()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error)
      if (error.status === 401) {
        await logout()
        navigate("/")
        return
      }
      toast.error("Could not update keywords")
    }
  }

  return {
    companyName: currentCompany?.name,
    initialValues,
    validationSchema,
    updateKeywords,
    dontShowDialogAgain,
    setDontShowDialogAgain: setDontShowDialogAgainHandler,
  }
}
