import BrandsHealthMindShareDashboard from "~/ui-rtk/components/features/dashboards/BrandHealthMindShareDashboard/BrandHealthMindShareDashboard"
import useConnect from "./connect"
import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"

export default function BrandsHealthMindShare() {
  const { content, isLoading } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <BrandsHealthMindShareDashboard
        slug={content.slug}
        charts={content.content.charts}
        title={content.content.title}
        parent={content.parent}
        periodPicker={content.content.periodPicker}
      />
    </WithLoader>
  )
}
