import { ProductFeature } from "~/api/services/product/enums"
import { CompanyDto, SubscriptionDataDto, SubscriptionDto } from "../api/types"
import { isFuture, parseISO } from "date-fns"

export abstract class ACL {
  public static isSubscriptionValid(
    subscription: SubscriptionDto | null,
    requiredFeatures?: ProductFeature[],
  ) {
    if (!subscription) {
      return false
    }

    const { metadata, trialEndsAt, expiresAt } = subscription

    const hasRequiredFeatures =
      (requiredFeatures ?? []).length > 0
        ? (requiredFeatures ?? []).every(feature =>
            metadata?.features?.includes(feature),
          )
        : true

    if (!hasRequiredFeatures) {
      return false
    }

    const isTrialExpired = this.isExpired(trialEndsAt)
    const isSubscriptionExpired = this.isExpired(expiresAt)
    return !isTrialExpired || !isSubscriptionExpired
  }

  public static isSubscriptionDataValid(
    subscriptionData?: SubscriptionDataDto,
    requiredFeatures?: ProductFeature[],
  ) {
    if (!subscriptionData) {
      return false
    }

    const { features, trialEndsAt, currentPeriodEndsAt } = subscriptionData

    const hasRequiredFeatures = requiredFeatures
      ? requiredFeatures.every(feature => features?.includes(feature))
      : true

    if (!hasRequiredFeatures) {
      return false
    }

    const isTrialExpired = this.isExpired(trialEndsAt)
    const isSubscriptionExpired = this.isExpired(currentPeriodEndsAt)
    return !isTrialExpired || !isSubscriptionExpired
  }

  public static isAccessGranted(
    company: CompanyDto | null,
    subscriptionData?: SubscriptionDataDto,
  ) {
    if (!company) {
      return false
    }

    const { subscription, metadata } = company

    const accessEndsAt = metadata?.freeAccessEndsAt
    const isFreeAccessGranted = accessEndsAt && !this.isExpired(accessEndsAt)
    const isSubscriptionValid =
      this.isSubscriptionValid(subscription) ||
      this.isSubscriptionDataValid(subscriptionData)

    return isFreeAccessGranted || isSubscriptionValid
  }

  private static isExpired(date: string) {
    return !isFuture(parseISO(date))
  }
}
