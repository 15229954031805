const CURRENCY = "USD"

interface Options {
  currency?: boolean
  decimals?: number
  compact?: boolean
}

export const formatNumber = (number: number, options?: Options) =>
  (number || 0).toLocaleString(
    "en-US",
    options
      ? {
          notation: options.compact ? "compact" : undefined,
          currency: options.currency ? CURRENCY : undefined,
          currencyDisplay: "narrowSymbol",
          maximumFractionDigits:
            options.decimals === undefined && options.currency
              ? 2
              : options.decimals,
          style: options.currency ? "currency" : "decimal",
        }
      : undefined,
  )

export function capitalize(text: string) {
  if (text.length > 1) {
    return text[0].toUpperCase() + text.slice(1)
  }

  return text.toUpperCase()
}

export const isNumeric = (val: any): val is number => {
  if (typeof val === "number") {
    return true
  }
  return !isNaN(val)
}

export function startCase(text: string) {
  return text.replace(/\w+/g, capitalize)
}
