import { useMemo } from "react"
import {
  CompanyConnectorDto,
  ConnectionStatus,
  ConnectorDto,
} from "~/ui-rtk/api/types"
import { useDeepCompareMemo } from "~/ui-rtk/hooks/deep-compare-memo"
import {
  isConnectorExcluded,
  isVisibleToCompany,
} from "~/ui-rtk/utils/connector-utils"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { checkForMissingKeywords } from "./utils"

export interface TConnectorSetupDetails {
  totalConnectors: number
  totalConnected: number
  hasMissingKeywords: boolean
  requiresReconnect: CompanyConnectorDto[]
  essentialUnconnectedConnectors: ConnectorDto[]
}

export const useSetupDetails = (
  connectors: ConnectorDto[] | undefined,
  companyConnectors: CompanyConnectorDto[] | undefined,
): TConnectorSetupDetails => {
  const currentCompany = useAppSelector(selectCurrentCompany)

  const memoizedConnectors = useDeepCompareMemo(connectors)
  const memoizedCompanyConnectors = useDeepCompareMemo(companyConnectors)

  const connectorsSetupDetails = useMemo<
    Omit<TConnectorSetupDetails, "hasMissingKeywords">
  >(() => {
    if (!memoizedConnectors || !memoizedCompanyConnectors) {
      return {
        totalConnectors: 0,
        totalConnected: 0,
        requiresReconnect: [],
        essentialUnconnectedConnectors: [],
      }
    }

    const connectedServices: CompanyConnectorDto[] = []
    const requiresReconnect: CompanyConnectorDto[] = []

    memoizedCompanyConnectors.forEach(service => {
      if (isConnectorExcluded(service.serviceName)) {
        return
      }

      if (service.status === ConnectionStatus.CONNECTED) {
        connectedServices.push(service)
      } else if (service.status === ConnectionStatus.PENDING_RECONNECT) {
        requiresReconnect.push(service)
      }
    })

    const visibleConnectors = memoizedConnectors.filter(connector => {
      if (isConnectorExcluded(connector.id)) {
        return false
      }

      return isVisibleToCompany(connector, currentCompany?.id ?? "")
    })

    const essentialUnconnectedConnectors = memoizedConnectors.filter(c => {
      const isEssential = c.category.includes("Essential")
      if (!isEssential) {
        return false
      }

      const isAlreadyConnected = memoizedCompanyConnectors.find(
        service => service.serviceName === c.id,
      )
      return !isAlreadyConnected
    })

    return {
      essentialUnconnectedConnectors,
      requiresReconnect,
      totalConnected: connectedServices.length,
      totalConnectors: visibleConnectors.length,
    }
  }, [memoizedConnectors, memoizedCompanyConnectors])

  const hasMissingKeywords = useMemo(() => {
    const keywords = currentCompany?.metadata?.keywords
    if (!keywords) {
      return true
    }

    return checkForMissingKeywords(keywords)
  }, [currentCompany])

  return {
    ...connectorsSetupDetails,
    hasMissingKeywords,
  }
}
