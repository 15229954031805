import { Formik, Form } from "formik"
import { FormField, Button } from "~/ui-rtk/components/ui/controls"
import useConnect from "./connect"

const CreateAccountForm = () => {
  const { isSubmitting, initialValues, validationSchema, handleRegister } =
    useConnect()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleRegister}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="w-full" autoComplete="off">
          <div className="flex flex-col items-center gap-5.5 mb-5">
            <FormField
              id="fullName"
              type="text"
              placeholder="Full name"
              isError={Boolean(touched.fullName && errors.fullName)}
            />
            <FormField
              id="workEmail"
              type="email"
              placeholder="Work email"
              isError={Boolean(touched.workEmail && errors.workEmail)}
            />
            <FormField
              id="password"
              type="password"
              placeholder="Password"
              isError={Boolean(touched.password && errors.password)}
            />
          </div>
          <Button
            type="submit"
            className="mx-auto mt-8 sm:max-w-48 w-full"
            isLoading={isSubmitting}
          >
            Register
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default CreateAccountForm
