import numeral from "numeral"
import { Card } from "~/ui-rtk/components/ui/common"

export default function ValueOverTimeInfoBlock({
  info,
  label,
  dataKey,
  dataLabel,
}: {
  info: {
    total: number
    brandValue: number
    spend: number
  }
  label: string
  dataKey: "total" | "brandValue"
  dataLabel: string
}) {
  const roas = info.spend > 0 ? info[dataKey] / info.spend : 0
  return (
    <Card rounded className="border bg-basic-blue/20 border-basic-blue">
      <table className="text-sm">
        <tbody>
          <tr>
            <td className="max-w-7.5" rowSpan={2}>
              {label}
            </td>
            <td className="px-1">${numeral(info[dataKey]).format("0.00a")}</td>
            <td>{dataLabel}</td>
          </tr>
          <tr>
            <td className="px-1">${numeral(info.spend).format("0.00a")}</td>
            <td>Spend</td>
          </tr>
          <tr>
            <td></td>
            <td className="px-1">{roas.toFixed(1)}x</td>
            <td>ROAS</td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}
