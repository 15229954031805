import React from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  checked?: boolean
}

export default function Checkbox({
  className,
  checked = false,
  onChange,
}: CheckboxProps) {
  const defaultClassNames =
    "cursor-pointer checked:outline-none focus:outline-none focus:ring-offset-0 focus:ring-0 appearance-none"
  const cx = cn(defaultClassNames, className)

  return (
    <input
      type="checkbox"
      className={cx}
      checked={checked}
      onChange={onChange}
    />
  )
}
