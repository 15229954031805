import React from "react"
import { Dialog } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"
import { CloseSvg, TrashSvg } from "~/ui-rtk/components/ui/svg/essentials"

type TDeleteUserDialog = {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}

const DeleteUserDialog: React.FC<TDeleteUserDialog> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => (
  <Dialog isOpen={isOpen} handleClose={onCancel}>
    <div className="max-w-160 w-full absolute rounded-md py-8 px-10 center-absolute bg-basic-dark-blue border border-basic-blue shadow-deep">
      <div>
        <TrashSvg size={32} />
        <h3 className="text-basic-white text-6 font-[500] leading-7 mt-6 mb-4">
          Delete This User?
        </h3>
      </div>
      <p className="leading-6 text-left">
        Deleting this user is irreversible please make sure this is an action
        you want to proceed with.
      </p>
      <div className="flex items-center w-full gap-6 mt-7">
        <Button
          className="w-full"
          variant={{ variant: "solid", color: "white" }}
          size="md"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="w-full"
          variant={{ variant: "solid", color: "red" }}
          size="md"
          onClick={onConfirm}
        >
          Delete
        </Button>
      </div>
      <button
        className="absolute right-4 top-4 max-w-7 max-h-7 p-2 flex items-center justify-center hover:bg-basic-blue hover:text-white transition-colors rounded-1"
        onClick={onCancel}
      >
        <CloseSvg size={20} fill="platinum" />
      </button>
    </div>
  </Dialog>
)

export default DeleteUserDialog
