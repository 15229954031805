import React, { useState } from "react"
import ReactDatePicker, {
  CalendarContainer,
  type CalendarContainerProps,
} from "react-datepicker"

import { Input, Button } from "~/ui-rtk/components/ui/controls"
import { Popover } from "~/ui-rtk/components/ui/common"
import { ChevronDownSvg } from "~/ui-rtk/components/ui/svg/arrow"

import { getDateLabel } from "./utils"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import type { DateOrNull } from "~/ui-rtk/shared/types/date"

type DateFieldProps = {
  value?: DateOrNull
  placeholder?: string
  onChange?: (date: DateOrNull) => void
}

const DateField: React.FC<DateFieldProps> = ({
  onChange,
  value,
  placeholder = "",
}) => {
  const [internalValue, setInternalValue] = useState<DateOrNull>(value || null)

  const handleClearInput = (callback: CallableFunction) => () => {
    onChange?.(null)
    setInternalValue(null)
    callback()
  }

  const handleChange = (callback: CallableFunction) => (date: DateOrNull) => {
    setInternalValue(date)
    onChange?.(date)
    callback()
  }

  return (
    <Popover
      relative
      handler={() => (
        <Input
          readOnly
          value={internalValue ? getDateLabel(internalValue) : placeholder}
          appendNode={<ChevronDownSvg className="cursor-pointer" />}
          className={cn("min-w-64 max-h-13 rounded-md")}
        />
      )}
    >
      {closePopup => (
        <div className="bg-basic-dark-blue mt-2 rounded-md overflow-hidden">
          <ReactDatePicker
            inline
            selected={internalValue}
            calendarClassName="ui-rtk-date-picker"
            calendarContainer={(passProps: CalendarContainerProps) => (
              <CalendarContainer {...passProps} />
            )}
            onChange={handleChange(closePopup)}
          />
          <div className="flex items-center gap-2 justify-center">
            <Button
              variant={{ variant: "text" }}
              size="sm"
              className="text-3.5"
              onClick={handleClearInput(closePopup)}
            >
              Clear
            </Button>
          </div>
        </div>
      )}
    </Popover>
  )
}

export default DateField
