import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { PeriodPicker } from "~/ui-rtk/components/ui/controls"
import { H2 } from "~/ui-rtk/components/ui/typography"
import {
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import { TSourceMap } from "~/ui-rtk/hooks/sources"
import { WIDGET_TYPE } from "~/ui-rtk/constants/dashboard"
import { createRef, ElementType, useCallback, useMemo } from "react"
import ChartLoader from "~/ui-rtk/components/ui/charts/ChartLoader"
import { ComposedChart } from "~/ui-rtk/components/ui/charts"
import { Card } from "~/ui-rtk/components/ui/common"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { ExclamationSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { THomeDashboardItem } from "./types"
import { DATE_FORMAT, usePeriod } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export interface THomeDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  sources: TSourceMap
  dateRangeRestrictions?: Partial<{ minDate: Date; maxDate: Date }>
  visualizations: Record<string, any>
  items: THomeDashboardItem[]
  cubeQueryParams?: Record<string, any>
}

const VISUALIZATIONS: Partial<Record<VISUALIZATION_TYPE, ElementType>> = {
  ComposedChart,
}

export default function HomeDashboard({
  title,
  periodPicker,
  slug,
  dateRangeRestrictions,
  sources,
  visualizations,
  items,
  cubeQueryParams,
}: THomeDashboardProps) {
  const { dateRange, compareRange } = useMinMaxDateRange(
    dateRangeRestrictions?.minDate,
    dateRangeRestrictions?.maxDate,
  )
  const { granularity, setPeriod, setComparePeriod } = usePeriod()

  const visibleWidgets: (VISUALIZATION_TYPE | WIDGET_TYPE)[] = useMemo(
    () =>
      items
        .filter(
          ({ widget }) =>
            !visualizations[widget]?.requiredSources?.length ||
            visualizations[widget]?.requiredSources?.some((source: string) =>
              sources?.has(source),
            ),
        )
        .map(({ widget }) => widget),
    [items],
  )
  const refs = useMemo(
    () => Object.fromEntries(visibleWidgets.map(id => [id, createRef<any>()])),
    [visibleWidgets],
  )

  const handleChangePeriods = (
    dateRange: [Date, Date],
    compareDateRange: [Date, Date] | null,
  ) => {
    setPeriod(
      dayjs(dateRange[0]).format(DATE_FORMAT),
      dayjs(dateRange[1]).format(DATE_FORMAT),
    )

    if (compareDateRange && compareDateRange.length > 0) {
      setComparePeriod(
        dayjs(compareDateRange[0]).format(DATE_FORMAT),
        dayjs(compareDateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setComparePeriod()
    }
  }

  const isSourceConnected = useCallback(
    (widget: string) =>
      !visualizations[widget] ||
      (visualizations[widget]?.requiredSources?.length ?? 0) === 0 ||
      visualizations[widget]?.requiredSources?.some((source: string) =>
        sources.has(source),
      ),
    [visualizations, sources],
  )

  const renderChartLoader = useCallback(
    (item: THomeDashboardItem) => {
      const widgetType = visualizations[item.widget].type as VISUALIZATION_TYPE
      const tableTypes = [
        VISUALIZATION_TYPE.TableVisualization,
        VISUALIZATION_TYPE.ComposedTable,
      ]

      const chartLoader = (
        <ChartLoader
          chartId={item.widget}
          className={cn("border-0 rounded-lg", item.className)}
          widgetType={widgetType}
          component={VISUALIZATIONS[widgetType]}
          dateRange={dateRange}
          compareRange={compareRange ?? undefined}
          key={item.widget}
          granularity={granularity}
          sources={sources}
          requiredSources={visualizations[item.widget]?.requiredSources}
          cubeQueryParams={cubeQueryParams}
          description={visualizations[item.widget]?.description}
          compareMode={
            visualizations[item.widget].props.comparisonEnabled &&
            (compareRange?.length ?? 0) > 0
          }
          {...visualizations[item.widget].props}
        />
      )

      if (tableTypes.includes(widgetType)) {
        return chartLoader
      }

      return (
        <Card rounded className="bg-basic-dark-blue">
          {chartLoader}
        </Card>
      )
    },
    [
      visualizations,
      cubeQueryParams,
      sources,
      granularity,
      dateRange,
      compareRange,
    ],
  )

  return (
    <div className="home-dashboard">
      <div className="flex justify-between">
        <div className="text-start">
          <H2>
            <Breadcrumbs
              items={[
                {
                  slug,
                  name: title,
                },
              ]}
            />
          </H2>
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <div>
          <PeriodPicker
            comparisonEnabled={
              VISUALIZATIONS_MAP.brand_value_line_chart.props
                .comparisonEnabled ?? false
            }
            dateRange={dateRange}
            compareRange={compareRange}
            opacity={periodPicker?.visible ? 1 : 0.5}
            onChange={handleChangePeriods}
            restrictions={dateRangeRestrictions}
          />
        </div>
      </div>
      <div className="grid-stack">
        {items.length > 0 ? (
          items.map((item: THomeDashboardItem, idx: number) => {
            if (!visibleWidgets.includes(item.widget)) {
              return null
            }

            return (
              <div
                className={`my-4`}
                key={`${item.widget}-${idx}`}
                ref={refs[item.widget]}
              >
                <div className="relative">
                  {!isSourceConnected(item.widget) ? (
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center gap-1 align-center z-1">
                      <ExclamationSvg size={20} />
                    </div>
                  ) : null}
                  {visualizations[item.widget] &&
                  VISUALIZATIONS[
                    visualizations[item.widget].type as VISUALIZATION_TYPE
                  ]
                    ? renderChartLoader(item)
                    : null}
                  {!visualizations[item.widget] ? (
                    <div className="flex items-center justify-center h-full bg-background-dark w-100">
                      <ExclamationSvg size={32} fill="error" />
                      <span className="font-bold text-error">
                        Invalid visualization "{item.widget}"
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            )
          })
        ) : (
          <div>This page is under construction. Please come again later</div>
        )}
      </div>
    </div>
  )
}
