import React from "react"
import { toast } from "react-toastify"

import AccountInfo from "./components/AccountInfo"
import ConnectorStatus from "./components/ConnectorStatus"
import ConnectorDialog from "../ConnectorDialog"
import { Button } from "~/ui-rtk/components/ui/controls"
import { useConnect } from "./connect"

import type { TDialogProps } from "~/ui-rtk/components/ui/common/Dialog"

type ConnectorStatusDialogProps = {
  imgSrc: string
  label: string
  confirmedAt?: string
  companyConnectorId: string
  onReconnect: () => void
  onClose: () => void
} & Pick<TDialogProps, "isOpen">

const ConnectionStatusDialog: React.FC<ConnectorStatusDialogProps> = ({
  isOpen,
  label,
  imgSrc,
  confirmedAt,
  companyConnectorId,
  onClose,
  onReconnect,
}) => {
  const { disconnect } = useConnect()

  const handleReconnect = () => {
    void onReconnect()
    void onClose()
  }

  const handleDisconnect = async () => {
    try {
      await disconnect(companyConnectorId)
      void onClose()
    } catch {
      toast.error(`Failed to delete connection with ${label}`)
    }
  }

  return (
    <ConnectorDialog
      isOpen={isOpen}
      onClose={onClose}
      imgSrc={imgSrc}
      alt={`${label} Logo`}
      title={`Connection to ${label}`}
    >
      <div className="flex flex-col items-center">
        <ConnectorStatus companyConnectorId={companyConnectorId} />
        <AccountInfo
          confirmedAt={confirmedAt}
          companyConnectorId={companyConnectorId}
        />

        <div className="flex justify-center w-full gap-5">
          <Button
            className="max-w-50 w-full"
            onClick={handleReconnect}
            variant={{ variant: "solid", color: "white" }}
          >
            Reconnect
          </Button>
          <Button
            className="max-w-50 w-full py-4"
            onClick={handleDisconnect}
            variant={{ variant: "solid", color: "red" }}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </ConnectorDialog>
  )
}

export default ConnectionStatusDialog
