import { RootState } from "../store"
import { UserState } from "../slices/user.slice"
import { createSelector } from "@reduxjs/toolkit"

export const selectUserState = (state: RootState): UserState => state.userSlice
export const selectUser = createSelector(
  [selectUserState],
  ({ user, currentCompany }) => ({
    ...user,
    currentCompany,
  }),
)
export const selectUserSessionRole = createSelector(
  [selectUserState],
  userSlice => userSlice.sessionRole,
)
export const selectSubscriptionData = createSelector(
  [selectUserState],
  userSlice => userSlice.subscriptionData,
)

// Return types
export type SelectUserType = UserState["user"] & {
  currentCompany: UserState["currentCompany"]
}

export type SelectCurrentCompany = UserState["currentCompany"]
