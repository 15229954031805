import { Dialog as HDialog } from "@headlessui/react"
import { PropsWithChildren, useMemo } from "react"

type TDialogClasses = {
  container: string
}

export type TDialogProps = {
  isOpen: boolean
  handleClose: () => void
  width?: number | string
  height?: number | string
  classes?: Partial<TDialogClasses>
  dialogRef?: React.Ref<HTMLDivElement>
} & React.HTMLProps<HTMLDivElement>

export default function Dialog({
  isOpen,
  handleClose,
  children,
  dialogRef,
  width = 300,
  height = "auto",
  classes,
}: PropsWithChildren<TDialogProps>) {
  const divStyle = useMemo(
    () => ({
      width,
      ...(height !== "auto" ? { height } : {}),
    }),
    [width, height],
  )
  return (
    <HDialog
      className="relative z-[999999999]"
      open={isOpen}
      onClose={handleClose}
    >
      <div
        onClick={handleClose}
        className="fixed inset-0 bg-black/50"
        aria-hidden="true"
      />
      <div className="fixed inset-0 overflow-y-auto">
        <HDialog.Panel className="flex items-center justify-center h-full min-h-full p-6 text-center">
          <div ref={dialogRef} style={divStyle} className={classes?.container}>
            {children}
          </div>
        </HDialog.Panel>
      </div>
    </HDialog>
  )
}
