import { useCallback } from "react"
import { useCompanyConnectorControllerDeleteCompanyConnectorMutation } from "~/ui-rtk/api/companyConnectorApi"

export const useConnect = () => {
  const [deleteCompanyConnectorAsync] =
    useCompanyConnectorControllerDeleteCompanyConnectorMutation()

  const disconnect = useCallback(async (companyConnectorId: string) => {
    await deleteCompanyConnectorAsync({ id: companyConnectorId }).unwrap()
  }, [])

  return { disconnect }
}
