import React from "react"
import { Form, Formik } from "formik"

import {
  ConnectorDialog,
  ConnectFormField,
} from "~/ui-rtk/components/features/connectors"
import { FormValues, useConnect } from "./connect"
import { toast } from "react-toastify"
import { isInternalServerError } from "~/ui-rtk/utils/http-utils"

import type { CustomerConnectorDialogFormPropsWithoutUri } from "../types"
import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"
import { Button } from "~/ui-rtk/components/ui/controls"

type SpsFormProps = CustomerConnectorDialogFormPropsWithoutUri

const SpsForm: React.FC<SpsFormProps> = ({
  companyConnectorId,
  isOpen,
  onClose,
  ...props
}) => {
  const { initialValues, validationSchema, isLoading, authenticate } =
    useConnect()

  const handleConnect = async (values: FormValues) => {
    if (isLoading) {
      return
    }

    if (!companyConnectorId) {
      throw new Error("Missing company connector id")
    }

    const username = values.user
    const password = values.password
    const authCode = btoa(`${username}:${password}`)

    try {
      await authenticate({ companyConnectorId, authCode, ...props })
    } catch (err) {
      if (isInternalServerError(err)) {
        toast.error("Invalid SPS Credentials", {
          toastId: "sps-form-connect-credentials-error",
        })
        return
      }

      toast.error("Unexpected error. Please, try again later.", {
        toastId: "sps-form-connect-unexcepted-error",
      })
    }
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.sps}
      alt="SPS Logo"
      title="Connection to SPS"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className="leading-5 text-left text-base text-platinum">
        Before you begin this process, you must have an SPS SFTP account set up
        for you. If you haven't yet requested an SFTP account, please contact
        SPS team.
      </p>
      <Formik
        onSubmit={handleConnect}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ touched, errors }) => (
          <Form className="flex flex-col w-full gap-4 mt-8">
            <div className="space-y-3">
              <ConnectFormField
                name="user"
                label="SFTP User*"
                placeholder="Enter your SFTP user here"
                isError={Boolean(touched.user && errors.user)}
              />
              <ConnectFormField
                name="password"
                label="SFTP Password*"
                placeholder="Enter your SFTP password here"
                isError={Boolean(touched.password && errors.password)}
              />
            </div>

            <Button
              variant={{ variant: "solid", color: "white" }}
              type="submit"
              isLoading={isLoading}
            >
              Connect
            </Button>
          </Form>
        )}
      </Formik>
    </ConnectorDialog>
  )
}

export default SpsForm
