import React, { useRef, useState } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type ToggleSize = "sm" | "md" | "lg" | "xl"

type ToggleProps = {
  id?: string
  size?: ToggleSize
  value?: boolean
  onChange?: (value: boolean) => void
}

const mapSize: { [key in ToggleSize]: { button: string; circle: string } } = {
  sm: {
    button: "w-15 h-8 p-1",
    circle: "w-5 h-5",
  },
  md: {
    button: "w-18 h-9.5 p-1",
    circle: "w-6 h-6",
  },
  lg: {
    button: "w-20 h-10.5 p-1",
    circle: "w-7.5 h-7.5",
  },
  xl: {
    button: "w-25 h-11 p-1",
    circle: "w-8.5 h-8.5",
  },
}

const Toggle: React.FC<ToggleProps> = ({ size = "xl", value, onChange }) => {
  const spanRef = useRef<HTMLSpanElement>(null)
  const [internalValue, setInternalValue] = useState(!!value)

  const classes = mapSize[size]

  const toggle = () => {
    setInternalValue(prev => !prev)
    onChange?.(!internalValue)
  }

  return (
    <button
      type="button"
      onClick={toggle}
      className={cn(
        "rounded-full relative transition-colors bg-silver-sand",
        classes.button,
        internalValue && "bg-american-green",
      )}
    >
      <span
        ref={spanRef}
        className={cn(
          "rounded-[50%] bg-white absolute transition-all left-1 top-1/2 -translate-y-1/2",
          classes.circle,
          internalValue && `left-[calc(100%-40px)]`,
        )}
      />
    </button>
  )
}

export default Toggle
