import {
  type Query as TCubeQuery,
  TimeDimension,
  TimeDimensionGranularity,
  ResultSet,
  TQueryOrderObject,
} from "@cubejs-client/core"
import { TMetric } from "~/ui-rtk/constants/metrics-mapping"
import { SortingState } from "@tanstack/react-table"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export function polyfillCubeQueryWithTimeDimension(
  cubeQuery: TMetric,
  {
    granularity,
    dateRange,
  }: {
    dateRange: [Date, Date]
    granularity?: TimeDimensionGranularity
  },
): TCubeQuery {
  return {
    ...cubeQuery,
    timeDimensions: (cubeQuery?.timeDimensions ?? []).map(
      (timeDimension: TimeDimension) => {
        const $timeDimension = {
          ...timeDimension,
          dateRange: [
            dayjs(dateRange[0]).format("YYYY-MM-DD"),
            dayjs(dateRange[1]).format("YYYY-MM-DD"),
          ],
        }

        if (granularity) {
          return {
            ...$timeDimension,
            granularity,
          }
        }

        return $timeDimension
      },
    ) as unknown as TimeDimension[],
  }
}

export function addPagination(
  cubeQuery: TCubeQuery,
  itemsPerPage?: number,
  offset?: number,
) {
  cubeQuery.limit = itemsPerPage
  cubeQuery.offset = offset
  cubeQuery.total = true

  return cubeQuery
}

export function addFilters(
  cubeQuery: TCubeQuery,
  CubeFilters?: TCubeFilterOptions,
  cubePrefix?: string,
) {
  const filters = cubeQuery.filters || []

  const addPrefix = (filter: any, prefix: string) => {
    if (filter?.and) {
      filter.and = filter.and.map((f: any) => addPrefix(f, prefix))
    }

    if (filter?.or) {
      filter.or = filter.and.map((f: any) => addPrefix(f, prefix))
    }

    if (filter.member && !filter.member.includes(prefix)) {
      filter.member = `${prefix}.${filter.member}`
    }
    return filter
  }

  if (CubeFilters?.and) {
    if (cubePrefix) {
      filters.push({ and: addPrefix(CubeFilters.and, cubePrefix) })
    } else {
      filters.push({ and: CubeFilters.and })
    }
  }

  if (CubeFilters?.extraFilters) {
    if (cubePrefix) {
      for (const filter of CubeFilters.extraFilters) {
        filters.push(addPrefix(filter, cubePrefix))
      }
    } else {
      filters.push(...CubeFilters.extraFilters)
    }
  }

  cubeQuery.filters = filters

  return cubeQuery
}

export function addSorting(
  cubeQuery: TCubeQuery,
  sorting?: SortingState,
  cubePrefix?: string,
) {
  if (sorting && sorting.length > 0) {
    if (!cubeQuery.order) {
      cubeQuery.order = {} as TQueryOrderObject
    }
    sorting.forEach(({ id, desc }: { id: string; desc: boolean }) => {
      if (cubeQuery.order) {
        const orderKey = cubePrefix ? `${cubePrefix}.${id}` : id
        const cubeQueryOrder = cubeQuery.order as TQueryOrderObject
        cubeQueryOrder[orderKey] = desc ? "desc" : "asc"
      }
    })
  }
  return cubeQuery
}

export function getPivotData(
  resultSet: ResultSet<any>,
  query: TCubeQuery | TCubeQuery[],
) {
  if (Array.isArray(query)) {
    const decomposedPivotData = resultSet
      .decompose()
      .map(_resultSet => _resultSet.tablePivot())
    return decomposedPivotData.reduce((acc, pivot) => [...acc, ...pivot], [])
  }

  return resultSet.tablePivot()
}
