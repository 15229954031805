const Divider = ({ className }: { className?: string }) => {
  const cx = [
    "bg-background-dark border-t border-solid border-basic-blue",
    className,
  ]
    .join(" ")
    .trim()
  return <hr className={cx} />
}

export default Divider
