import { Loader } from "../../ui/common"
import useLoadingState from "~/ui-rtk/hooks/loading-state"

export default function StaticSpinner() {
  const { isLoading } = useLoadingState()
  return !isLoading ? null : (
    <div className="static-spinner fixed top-0 bottom-0 left-0 right-0 bg-background-dark/75 z-[2000]">
      <div className="flex w-full h-full items-center justify-center">
        <Loader size={150} />
      </div>
    </div>
  )
}
