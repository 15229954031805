import { emptySplitApi as api } from "./emptyApi"
import { ProductDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    productControllerGetAll: build.query<
      ProductControllerGetAllApiResponse,
      ProductControllerGetAllApiArg
    >({
      query: () => ({
        url: "/product",
      }),
    }),
  }),
})

export { injectedRtkApi as generatedApi }

export type ProductControllerGetAllApiResponse = ProductDto[]
export type ProductControllerGetAllApiArg = void

export const { useProductControllerGetAllQuery } = injectedRtkApi
