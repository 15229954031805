import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithAuth } from "~/ui-rtk/utils/base-query"

export const emptySplitApi = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    "Auth",
    "UserCompany",
    "Connectors",
    "CompanyConnectors",
    "ConnectionLinkCreated",
  ],
  endpoints: () => ({}),
})
