export const guestRoutes = {
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",

  CONFIRM_EMAIL: "/confirm-email",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",

  INVITE: "/invite",
  PRICING_DETAILS: "/pricing-details",
  SUBSCRIBE: "/subscribe",
  SUBSCRIBE_CONFIRM: "/subscribe/confirm",

  DOCS: "/docs",

  CREATE_ACCOUNT: "/onboarding/create-account",

  CREATE_CONNECTION: "/connectors/guest/oauth-connection",
  FIVETRAN_ONBOARDING: "/fivetran-onboarding",
  AUTHENTICATE_CONNECTOR: "/authenticate-connector",
}

export const DEFAULT_GUEST_ROUTE = guestRoutes.SIGN_IN
