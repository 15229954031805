import { useState, useCallback, useMemo } from "react"
import { useConnectorControllerGetAllConnectorsQuery as useGetAllConnectorsQuery } from "~/ui-rtk/api/connectorApi"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery as useGetCompanyConnectorsQuery } from "~/ui-rtk/api/companyConnectorApi"

import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { useSetupDetails } from "./hooks/setup-details"
import useAccess from "~/ui-rtk/hooks/access"

export const useConnect = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const sessionRole = useAppSelector(selectUserSessionRole)
  const { isSuperAdmin, hasAccess, isBillingEnabled } = useAccess()

  const {
    data: connectors,
    isLoading: isConnectorsLoading,
    isError: isConnectorsError,
  } = useGetAllConnectorsQuery()

  const {
    data: companyConnectors,
    isLoading: isCompanyConnectorsLoading,
    isError: isCompanyConnectorsError,
  } = useGetCompanyConnectorsQuery()

  const isSetupDetailsLoading =
    isConnectorsLoading || isCompanyConnectorsLoading
  const isSetupDetailsError = isConnectorsError || isCompanyConnectorsError

  const setupDetails = useSetupDetails(connectors, companyConnectors)

  const showSidebarProducts = useMemo(
    () => isSuperAdmin || isBillingEnabled,
    [isSuperAdmin, isBillingEnabled, hasAccess],
  )

  const handleBeforeNavigate = useCallback(() => {
    // continue navigating if already expanded
    if (isExpanded) return true

    setIsExpanded(true)

    // stop navigating if not expanded
    return false
  }, [isExpanded, setIsExpanded])

  const handleNavigate = useCallback(() => {
    setIsExpanded(false)
  }, [setIsExpanded])

  const handleToggleExpand = useCallback(() => {
    setIsExpanded(previous => !previous)
  }, [setIsExpanded])

  const collapse = () => {
    setIsExpanded(false)
  }

  const expand = () => {
    setIsExpanded(true)
  }

  return {
    showSidebarProducts,
    isExpanded,
    sessionRole,
    handleBeforeNavigate,
    handleNavigate,
    handleToggleExpand,
    expand,
    collapse,
    setupDetails,
    isSetupDetailsLoading,
    isSetupDetailsError,
  }
}
