import React from "react"
import { Formik, Form } from "formik"

import { useConnect } from "./connect"
import { Button, FormField } from "~/ui-rtk/components/ui/controls"

type TResetPasswordFormProps = {
  token: string
}

const ResetPasswordForm: React.FC<TResetPasswordFormProps> = ({ token }) => {
  const { validationSchema, initialValues, resetPassword } = useConnect(token)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={resetPassword}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="flex flex-col items-center w-full space-y-8">
          <div className="flex flex-col items-center w-full gap-5.5">
            <FormField
              id="newPassword"
              type="password"
              placeholder="Password"
              isError={Boolean(touched.newPassword && errors.newPassword)}
            />
            <FormField
              id="confirmNewPassword"
              type="password"
              placeholder="Confirm Password"
              isError={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword,
              )}
            />
          </div>
          <Button type="submit" size="lg" className="sm:max-w-48 w-full">
            Reset
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
