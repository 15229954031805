import { useMemo } from "react"
import {
  VISUALIZATIONS_MAP,
  VISUALIZATIONS_MAP_KEY,
} from "~/ui-rtk/constants/charts"

export const useVisualizationsMap = (widgetIds: string[]) => {
  const visualizations = useMemo(() => {
    const visualizations = <Record<VISUALIZATIONS_MAP_KEY, any>>{}
    Object.keys(VISUALIZATIONS_MAP)
      .filter(key => widgetIds.includes(key))
      .forEach(key => {
        visualizations[key] = VISUALIZATIONS_MAP[key]
      })
    return visualizations
  }, [widgetIds])

  return {
    visualizations,
  }
}
