import { useEffect, useState } from "react"
import { Select } from "../../controls"

type DropDownChartProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metrics: any[]
  onChange: (str: string) => void
  state?: string
  placeholder?: string
  isLoading?: boolean
  error?: boolean
}

export default function DropDownChart({
  metrics,
  onChange,
  state = "idle",
  placeholder: placeholderProp,
  isLoading = false,
  error = false,
}: DropDownChartProps) {
  const [ready, setReady] = useState(false)
  let placeHolder: string | undefined = undefined
  if ((isLoading && !error) || !ready) {
    placeHolder = "Loading..."
  } else if (error) {
    placeHolder = "Unable to load data from server"
  } else {
    placeHolder = placeholderProp || undefined
  }

  const updateFilterValue = (value: string) => {
    if (state !== "idle") {
      return
    }
    onChange(value)
  }

  useEffect(() => setReady(true), [])

  return (
    <div className="h-14">
      {ready ? (
        <Select
          options={metrics}
          placeholder={placeHolder}
          onChange={e => updateFilterValue(e.target.value)}
          disabled={state !== "idle"}
        />
      ) : (
        <Select options={[]} placeholder={placeHolder} disabled />
      )}
    </div>
  )
}
