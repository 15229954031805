type DataPendingInstruction = {
  id: string
  content: string
}

const EMAIL_ADDRESS = "no-reply@marathondataco.com"

export const DATA_PENDING_INSTRUCTIONS: DataPendingInstruction[] = [
  {
    id: "instruction-1",
    content: "Your data will take approximately 24 — 48 hours to fully upload.",
  },
  {
    id: "instruction-2",
    content: `No need to check back, we’ll send you an email when we’re done! The sender will be ${EMAIL_ADDRESS} so you might want to mark our welcome email as “important” so you don’t miss it!`,
  },
  {
    id: "instruction-3",
    content:
      "If you weren’t able to connect all data sources, no worries — the connectors page will be live from here on out and you can connect new data sources at any time. Note: the same 24-48 hour timeline applies to any new data connections!",
  },
  {
    id: "instruction-4",
    content:
      "In the meantime, please don’t hesitate to reach out to team Marathon with any questions, thoughts, or concerns!",
  },
]
