import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Column, Header } from "@tanstack/react-table"

import { KeywordFilter, RangeFilter } from "./filters"
import { Divider } from "~/ui-rtk/components/ui/common"
import { Button, Select } from "~/ui-rtk/components/ui/controls"

import { isFilteringAllowed, isRangeFilter, isTextFilter } from "./utils"

import type {
  TTextOperator,
  TableVisualizationColumnDef,
  TableVisualizationColumnFilter,
  TMetric,
} from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import { TSelectOption } from "~/ui-rtk/components/ui/controls/Select"
import { TManualFilter } from "../../constants"

type MenuProps = {
  onApply: (filter: TableVisualizationColumnFilter) => void
  onReset: () => void
  headers: Header<TMetric, unknown>[]
  filterToEdit?: {
    idx: number
    filter: TableVisualizationColumnFilter
  }
  additionalFilters?: TManualFilter[]
}

const Menu: React.FC<MenuProps> = ({
  headers,
  onApply,
  onReset,
  filterToEdit,
  additionalFilters = [],
}) => {
  const [tableFilter, setTableFilter] =
    useState<TableVisualizationColumnFilter | null>(
      filterToEdit?.filter ?? null,
    )
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null)

  const options = useMemo(
    () =>
      headers.reduce(
        (acc: TSelectOption[], header) => {
          const columnDef = header.column
            .columnDef as TableVisualizationColumnDef
          if (
            isFilteringAllowed(columnDef.type) &&
            !columnDef.disableFiltering
          ) {
            acc.push({
              value: columnDef.key,
              label: (columnDef?.header as CallableFunction)?.(),
            })
          }

          return acc
        },
        [
          {
            value: "",
            label: "Select",
            disabled: true,
          } as TSelectOption,
          ...additionalFilters,
        ],
      ),
    [headers],
  )

  const setInitialState = () => {
    setTableFilter(null)
    setSelectedColumn(null)
  }

  const handleResetFilters = () => {
    setInitialState()
    onReset()
  }

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedColumn(event?.target?.value)
  }

  const handleApplyFilters = () => {
    if (!tableFilter) {
      return
    }

    onApply({
      ...tableFilter,
      idx: filterToEdit?.idx,
    })
    setSelectedColumn(null)
  }

  const changeState =
    (variant: "text" | "range") =>
    (
      key: string,
      value: string | string[] | { min?: number; max?: number },
      operator?: TTextOperator,
    ) => {
      const arrayedValue = Array.isArray(value) ? value : [value]
      const filterValue = variant === "range" ? value : arrayedValue

      const filter: TableVisualizationColumnFilter = {
        id: key,
        variant,
        value: filterValue,
        operator,
      }

      setTableFilter(filter)
    }

  useEffect(() => {
    if (filterToEdit) {
      setSelectedColumn(filterToEdit?.filter?.id)
      setTableFilter(filterToEdit?.filter)
    }
  }, [filterToEdit])

  const renderFilters = useCallback(
    (columnKey: string) => {
      const header = headers.find(f => f.id === columnKey)
      if (!header) {
        const additionalFilter = additionalFilters.find(
          f => f.value === columnKey,
        )
        if (!additionalFilter) {
          return
        }
        return (
          <KeywordFilter
            defaultFilter={filterToEdit?.filter}
            column={
              {
                columnDef: {
                  key: columnKey,
                },
              } as unknown as Column<TMetric, unknown>
            }
            onChange={changeState("text")}
          />
        )
      }

      const columnDef = header.column.columnDef as TableVisualizationColumnDef
      if (isTextFilter(columnDef.type)) {
        return (
          <KeywordFilter
            defaultFilter={filterToEdit?.filter}
            column={header.column}
            onChange={changeState("text")}
          />
        )
      } else if (isRangeFilter(columnDef.type)) {
        return (
          <RangeFilter
            tableFilter={tableFilter}
            column={header.column}
            onChange={changeState("range")}
          />
        )
      }
    },
    [headers, tableFilter],
  )

  return (
    <div className="flex flex-col w-72 top-[120%] border right-[0%] border-basic-blue rounded-lg overflow-hidden absolute z-[50] bg-basic-dark-blue px-4 pb-2 gap-4">
      <div className="flex items-center justify-between">
        <p className="font-bold text-4">Filter column</p>
        <Button
          variant={{ variant: "text" }}
          onClick={handleResetFilters}
          className="text-basic-grey-inactive hover:text-white hover:opacity-100"
        >
          Reset
        </Button>
      </div>

      <section>
        <div className="space-y-3">
          <label className="ml-1 font-semibold">Column</label>
          <Select
            options={options}
            defaultValue={filterToEdit?.filter?.id ?? ""}
            onChange={handleSelect}
            className="bg-basic-blue text-white font-semibold"
          />
        </div>
        <Divider className="my-6 bg-basic-blue" />
        {selectedColumn && renderFilters(selectedColumn)}
        {selectedColumn && <Divider className="my-6 bg-basic-blue" />}
        <div className="text-center flex flex-col gap-2">
          <Button
            disabled={!selectedColumn}
            size="lg"
            className="font-bold"
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </div>
      </section>
    </div>
  )
}

export default Menu
