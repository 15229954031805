import numeral from "numeral"

export const safeDivide = (numerator: number, denominator: number): number => {
  const result = denominator !== 0 ? numerator / denominator : 0
  return !Number.isNaN(result) ? result : 0
}

export const isNumber = (value: string | number) =>
  typeof value === "number"
    ? !isNaN(value)
    : typeof value === "string" && !isNaN(Number(value))

const parseNumber = (value: string | number) => {
  if (isNumber(value)) {
    return typeof value === "number" ? value : Number(value)
  }

  return null
}

export const toCurrency = (
  value: string | number,
  fallback = "- -",
  full = false,
) => {
  const numericValue = parseNumber(value)
  if (numericValue === null) {
    return fallback
  }

  if (!full && numericValue < 0.01 && numericValue >= 0) {
    return `< $0.01`
  }

  return numericValue >= 1000 && !full
    ? `$${numeral(numericValue).format("0.00a")}`
    : `$${numericValue.toFixed(2)}`
}

export const toCoef = (
  value: string | number,
  fallback = "- -",
  full = false,
) => {
  const numericValue = parseNumber(value)
  if (numericValue === null) {
    return fallback
  }

  const roundedValue = Math.round(numericValue * 100) / 100
  if (!full && roundedValue < 0.01 && roundedValue >= 0) {
    return `< 0.01x`
  }

  if (full) {
    return `${numericValue}x`
  }

  const formatter = Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })

  const formattedValue = formatter.format(roundedValue)

  if (Number(formattedValue) === 0) {
    return "0"
  }

  return `${formattedValue}x`
}

export const toPercentage = (
  value: string | number,
  fallback = "- -",
  full = false,
) => {
  const numericValue = parseNumber(value)
  if (numericValue === null) {
    return fallback
  }

  if (!full && numericValue < 0.01 && numericValue >= 0) {
    return `< 0.01%`
  }

  if (full) {
    return `${numericValue}%`
  }

  return Number(value).toLocaleString("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  })
}

export const toNumber = (
  value: string | number,
  fallback = "- -",
  options?: {
    fractionDigits: number
  },
  full = false,
) => {
  const numericValue = parseNumber(value)
  if (numericValue === null) {
    return fallback
  }

  if (full) {
    return value
  }

  return numericValue >= 1000
    ? `${numeral(numericValue).format("0.00a")}`
    : `${numericValue.toFixed(options?.fractionDigits || 0)}`
}

export type ConvertNumberType = "coef" | "currency" | "percent" | "number"
export const ConvertNumberTypeArr: ConvertNumberType[] = [
  "coef",
  "currency",
  "percent",
  "number",
]

export const convertNumber = (
  type: ConvertNumberType,
  value: string | number,
  fallback = "- -",
  full = false,
) => {
  switch (type) {
    case "coef":
      return toCoef(value, fallback, full)
    case "currency":
      return toCurrency(value, fallback, full)
    case "percent":
      return toPercentage(value, fallback, full)
    case "number":
      return toNumber(value, fallback, undefined, full)
    default:
      return value
  }
}
