import { useState } from "react"
import { toast } from "react-toastify"
import { useAuthControllerRequestConfirmEmailMutation } from "~/ui-rtk/api/authApi"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUser } from "~/ui-rtk/app/selectors/user.selector"
import { ContentBox } from "~/ui-rtk/components/ui/common"

import { Button } from "~/ui-rtk/components/ui/controls"

export const CheckInbox = () => {
  const [requestConfirmEmail] = useAuthControllerRequestConfirmEmailMutation()
  const { email } = useAppSelector(selectUser)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const openGmail = () => {
    const gmailUrl = `https://mail.google.com/mail`
    window.open(gmailUrl, "_blank")
  }

  const resendLink = async () => {
    void setIsSubmitting(true)

    try {
      await requestConfirmEmail().unwrap()
      toast.success("The confirmation email has been sent successfully.", {
        toastId: "onboarding-resend-email-toast-success",
      })
    } catch (error) {
      toast.error("Failed to send confirmation email link.", {
        toastId: "onboarding-resend-email-toast-error",
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderSubTitle = () => (
    <>
      Almost there! Please check your inbox at{" "}
      <span className="font-bold text-brand">{email}</span> and click the link
      to complete your account setup
    </>
  )

  return (
    <ContentBox title="Check your inbox" subTitle={renderSubTitle()}>
      <div className="flex justify-between gap-6">
        <Button onClick={openGmail} size="lg" className="w-full">
          Open Gmail
        </Button>
        <Button
          isLoading={isSubmitting}
          variant={{ variant: "solid", color: "white" }}
          onClick={resendLink}
          className="w-full"
          size="lg"
        >
          Resend Link
        </Button>
      </div>
    </ContentBox>
  )
}
