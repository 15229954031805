import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export default function H6({
  children,
  className = "",
}: Props): ReturnType<FC<Props>> {
  const cs = ["text-sm", className].join(" ").trim()
  return <h6 className={cs}>{children}</h6>
}
