import { emptySplitApi as api } from "./emptyApi"
import { ConnectorDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    connectorControllerCreateConnector: build.mutation<
      ConnectorControllerCreateConnectorApiResponse,
      ConnectorControllerCreateConnectorApiArg
    >({
      query: queryArg => ({
        url: `/connector`,
        method: "POST",
        body: queryArg.createConnectorDto,
      }),
    }),
    connectorControllerGetAllConnectors: build.query<
      ConnectorControllerGetAllConnectorsApiResponse,
      ConnectorControllerGetAllConnectorsApiArg
    >({
      query: () => ({ url: `/connector` }),
      providesTags: ["UserCompany", "Connectors"],
    }),
    connectorControllerGetConnectorById: build.query<
      ConnectorControllerGetConnectorByIdApiResponse,
      ConnectorControllerGetConnectorByIdApiArg
    >({
      query: queryArg => ({ url: `/connector/${queryArg.id}` }),
    }),
    connectorControllerUpdateConnectorById: build.mutation<
      ConnectorControllerUpdateConnectorByIdApiResponse,
      ConnectorControllerUpdateConnectorByIdApiArg
    >({
      query: queryArg => ({
        url: `/connector/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateConnectorDto,
      }),
    }),
    connectorControllerDeleteConnector: build.mutation<
      ConnectorControllerDeleteConnectorApiResponse,
      ConnectorControllerDeleteConnectorApiArg
    >({
      query: queryArg => ({
        url: `/connector/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type ConnectorControllerCreateConnectorApiResponse =
  /** status 200  */ ConnectorDto
export type ConnectorControllerCreateConnectorApiArg = {
  createConnectorDto: CreateConnectorDto
}
export type ConnectorControllerGetAllConnectorsApiResponse =
  /** status 200  */ ConnectorDto[]
export type ConnectorControllerGetAllConnectorsApiArg = void
export type ConnectorControllerGetConnectorByIdApiResponse =
  /** status 200  */ ConnectorDto
export type ConnectorControllerGetConnectorByIdApiArg = {
  id: string
}
export type ConnectorControllerUpdateConnectorByIdApiResponse =
  /** status 200  */ ConnectorDto
export type ConnectorControllerUpdateConnectorByIdApiArg = {
  id: string
  updateConnectorDto: UpdateConnectorDto
}
export type ConnectorControllerDeleteConnectorApiResponse =
  /** status 200  */ ConnectorDto
export type ConnectorControllerDeleteConnectorApiArg = {
  id: string
}

export type CreateConnectorDto = {
  id: string
  name: string
  icon: string
  config: object
  metadata: object
}
export type UpdateConnectorDto = {
  name: string
  icon: string
  config: object
  schema: object
  metadata: object
}

export const {
  useConnectorControllerCreateConnectorMutation,
  useConnectorControllerGetAllConnectorsQuery,
  useLazyConnectorControllerGetAllConnectorsQuery,
  useConnectorControllerGetConnectorByIdQuery,
  useConnectorControllerUpdateConnectorByIdMutation,
  useConnectorControllerDeleteConnectorMutation,
} = injectedRtkApi
