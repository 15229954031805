import {
  FetchArgs,
  BaseQueryFn,
  fetchBaseQuery,
  FetchBaseQueryError,
  BaseQueryApi,
} from "@reduxjs/toolkit/query/react"
import { apiSlice } from "../app/slices/api.slice"
import { isUnauthorizedError } from "./http-utils"
import { RootState } from "../app/store"

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL || "/",
  credentials: "include",
})

const isLoggedIn = (api: BaseQueryApi) => {
  const state = api.getState() as RootState
  return !!state.userSlice.user?.email
}

const initiateLogout = async (api: BaseQueryApi) => {
  await api.dispatch(apiSlice.endpoints.logout.initiate())
}

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions = {}) => {
  const result = await baseQuery(args, api, extraOptions)
  if (isUnauthorizedError(result.error) && isLoggedIn(api)) {
    await initiateLogout(api)
  }
  return result
}
