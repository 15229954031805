import React from "react"
import { Link } from "react-router-dom"
import { Form, Formik, Field, ErrorMessage } from "formik"

import { useConnect } from "./connect"
import { Button, Input } from "~/ui-rtk/components/ui/controls"

import { brandRoutes } from "~/ui-rtk/constants/routes"

type TCreatePasswordFormProps = {
  token: string
}

const CreatePasswordForm: React.FC<TCreatePasswordFormProps> = ({ token }) => {
  const { initialValues, createPassword, validationSchema, isLoading } =
    useConnect(token)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createPassword}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="flex flex-col gap-2">
          <div className="mt-3 flex flex-col items-start justify-between min-h-18">
            <label
              htmlFor="email"
              className="flex-none w-24 mt-3 mb-1 text-left text-nowrap"
            >
              Password
            </label>
            <div className="relative flex-auto w-full">
              <Field
                id="password"
                name="password"
                type="password"
                as={Input}
                fullWidth
                className="w-full"
                isError={touched.password && errors.password}
              />
              <ErrorMessage
                component="a"
                name="password"
                className="absolute input-error-message"
              />
            </div>
          </div>

          <div className="mt-3 flex flex-col items-start justify-between min-h-18">
            <label
              htmlFor="email"
              className="flex-none w-24 mt-3 mb-1 text-left text-nowrap"
            >
              Confirm Password
            </label>
            <div className="relative flex-auto w-full">
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                as={Input}
                fullWidth
                className="w-full"
                isError={touched.confirmPassword && errors.confirmPassword}
              />
              <ErrorMessage
                component="a"
                name="confirmPassword"
                className="absolute input-error-message"
              />
            </div>
          </div>

          <Button
            type="submit"
            className="w-full mt-6 mb-4"
            isLoading={isLoading}
          >
            Create Password
          </Button>

          <Link to={brandRoutes.SIGN_IN} className="text-basic-white">
            Back to sign in
          </Link>
        </Form>
      )}
    </Formik>
  )
}

export default CreatePasswordForm
