import { PropsWithChildren } from "react"
import { Loader } from "../../ui/common"

type TWithLoaderProps = {
  isLoading: boolean
}

export default function WithLoader({
  isLoading,
  children,
}: PropsWithChildren<TWithLoaderProps>) {
  return (
    <>
      <div
        className={`${isLoading ? "h-full flex items-center justify-center" : ""}`}
      >
        {isLoading ? (
          <Loader size={100} className="opacity-50"></Loader>
        ) : (
          children
        )}
      </div>
    </>
  )
}
