import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

function getDaySuffix(day: number) {
  if (day > 3 && day < 21) return "th"
  switch (day % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

export function formatDate(date: Date | string) {
  if (!date) {
    return ""
  }

  const dayjsDate = dayjs(date)
  const day = dayjsDate.date()
  const suffix = getDaySuffix(day)
  const month = dayjsDate.format("MMMM")
  const year = dayjsDate.year()
  return `${month} ${day}${suffix} ${year}`
}
