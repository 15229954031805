export enum ConnectionStatus {
  NOT_CONNECTED = "NOT_CONNECTED",
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  PENDING_SCHEMA_UPDATE = "PENDING_SCHEMA_UPDATE",
  PENDING_RECONNECT = "PENDING_RECONNECT",
}

export type CompanyConnectorDto = {
  id: string
  serviceName: string
  companyId: string
  metadata: object
  lastSuccessfulSyncDate: string
  isCompleted: boolean
  status: ConnectionStatus
  createdAt: string
  updatedAt: string
  confirmedAt?: string
}

export type CompanyConnectCardDto = {
  uri: string
  token: string
  companyConnectorId: string
}

export type CompanyConnectorStatusDto = {
  connectorId: string
  connectorType: string
  setupState: string
  syncState: string
  updateState: string
  lastSuccessfulSyncDate: string
  nextAction: string
  warnings?: Array<{ message: string }>
}

export type AccountInfoDto = {
  metric: string
  avatarUrl: string
  accountName: string
}
