import { useCallback } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { Dialog } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"
import { CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { H4, P } from "~/ui-rtk/components/ui/typography"
import { guestRoutes } from "~/ui-rtk/constants/routes"

export default function SubscribeDialog({ onClose }: { onClose: () => void }) {
  const navigate = useStableNavigate()
  const redirectToSubscribe = useCallback(
    () => navigate(guestRoutes.SUBSCRIBE),
    [navigate],
  )
  return (
    <Dialog isOpen={true} handleClose={onClose}>
      <div className="max-w-100 w-full absolute rounded-md py-6 px-10 center-absolute bg-basic-dark-blue shadow-deep border border-basic-blue">
        <div className="flex flex-col items-center gap-6 mb-8">
          <H4 className="font-medium text-white text-2xl">
            Thanks for Completing Your Essential Connections!
          </H4>
        </div>
        <div className="space-y-4">
          <P className="leading-5 text-left text-xl text-platinum">
            Ready to explore your custom brand model and start to build baseline
            revenue?
          </P>
          <P className="leading-5 text-left text-xl text-platinum">
            Click the button below to start your free trial:
          </P>
        </div>
        <div className="mt-10 text-center flex justify-center">
          <Button className="w-100" onClick={() => redirectToSubscribe()}>
            Start my Trial
          </Button>
        </div>

        <button
          className="absolute right-4 top-4 max-w-7 max-h-7 p-2 flex items-center justify-center hover:bg-[#2a2c33] hover:text-white transition-colors rounded-1"
          onClick={onClose}
        >
          <CloseSvg size={20} fill="platinum" />
        </button>
      </div>
    </Dialog>
  )
}
