import { createSlice } from "@reduxjs/toolkit"
import { TDrawerConfig } from "~/ui-rtk/constants/drawer"
import { revertAll } from "../revertAll"

type TDrawerState = {
  isOpen: boolean
  content: TDrawerConfig | null
}

const initialState: TDrawerState = {
  isOpen: false,
  content: null,
}

export const drawerSlice = createSlice({
  name: "drawerSlice",
  initialState,
  extraReducers: builder => builder.addCase(revertAll, () => initialState),
  reducers: {
    setIsOpen(state, { payload }: { payload: boolean }) {
      state.isOpen = payload
    },
    setContent(state, { payload }: { payload: TDrawerConfig | null }) {
      state.content = payload
    },
  },
})

export const { setIsOpen, setContent } = drawerSlice.actions
