import { useState } from "react"

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  SubTitle,
} from "~/ui-rtk/components/ui/typography"
import { Popover, Card, SearchSelect } from "~/ui-rtk/components/ui/common"
import {
  Checkbox,
  DatePicker,
  FileUpload,
  Input,
  MultiSelect,
  PeriodPicker,
  Button,
} from "~/ui-rtk/components/ui/controls"

import { Option } from "~/ui-rtk/components/ui/common/SearchSelect"
import { type TMultiSelectOption } from "~/ui-rtk/components/ui/controls/MultiSelect"
import AreaChart from "~/ui-rtk/components/ui/charts/AreaChart"
import ChipInput from "~/ui-rtk/components/ui/controls/ChipsInput"
import { ComposedChart } from "~/ui-rtk/components/ui/charts"
import DropDownChart from "~/ui-rtk/components/ui/charts/DropDownChart"
import CohortVisualization, {
  CohortVisualizationColumn,
} from "../components/ui/charts/CohortVisualization"
import { AggregationType } from "../constants/charts"

import TableVisualization from "../components/ui/charts/TableVisualization"
import { TableVisualizationColumn } from "../components/ui/charts/TableVisualization/types"
import { DATE_FORMAT, usePeriod } from "../hooks/period"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const generateDateRange = (startDate?: string, endDate?: string): Date[] =>
  endDate && startDate && dayjs(endDate).isValid() && dayjs(startDate).isValid()
    ? Array(Math.abs(dayjs(endDate).add(1, "day").diff(startDate, "days")))
        .fill(null)
        .map((_, index) => dayjs(startDate).add(index, "day").toDate())
    : []

export default function Docs() {
  const [checked, setChecked] = useState(false)
  const {
    period: dateRange,
    comparePeriod: compareRange,
    granularity,
    setPeriod,
    setComparePeriod,
    setGranularity,
  } = usePeriod()

  const [dateInputFocused, setDateInputFocused] = useState(0)

  const [chipValues, setChipValues] = useState<string[]>([])

  const options: TMultiSelectOption[] = [
    {
      label: "My Awesome Option 1",
      id: "option-1",
    },
    {
      label: "My Awesome Option 2",
      id: "option-2",
    },
    {
      label: "Super Option 3",
      id: "option-3",
    },
    {
      label: "Option 4",
      id: "option-4",
    },
    {
      label: "Don't choose me",
      id: "option-5",
    },
  ]

  const getSearchSelectOptions = (): Option[] =>
    options.map(o => ({
      label: o.label,
      value: o.id,
    }))

  const [selectedOptions, setSelectedOptions] = useState<TMultiSelectOption[]>(
    [],
  )

  const setDate = (date: Date) => {
    if (dateInputFocused === 0) {
      setPeriod(
        dayjs(date).format(DATE_FORMAT),
        dayjs(dateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setPeriod(
        dayjs(dateRange[0]).format(DATE_FORMAT),
        dayjs(date).format(DATE_FORMAT),
      )
    }
  }
  const highlightWithRanges: Array<Record<string, Date[]>> = [
    {
      "react-datepicker__day--highlight-range": generateDateRange(
        dateRange[0],
        dateRange[1],
      ),
    },
  ]

  const dropDownMetricsOptions = [
    {
      label: "Value",
      value: "value",
    },
    {
      label: "Predicted Value",
      value: "predictedValue",
    },
  ]

  const chartMetrics = [
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 54,
      predictedValue: 32,
      date: "2023-10-21",
      label: "2023-10-21",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 39,
      predictedValue: 34,
      date: "2023-10-22",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 25,
      predictedValue: 44,
      date: "2023-10-24",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 25,
      predictedValue: 26,
      date: "2023-10-25",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 19,
      predictedValue: 18,
      date: "2023-10-26",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
    {
      "?column?": "companyId",
      metric: "tiktok followers gained",
      value: 20,
      predictedValue: 32,
      date: "2023-10-27",
      metadata: {
        source: "tiktok",
      },
    },
  ]
  const [dropDownMetrics, setDropDownMetrics] = useState<string>("")

  const areaChartMetrics = chartMetrics.map(metrics => ({
    value: dropDownMetrics
      ? metrics[dropDownMetrics as never]
      : metrics["value"],
    metadate: metrics.metadata,
  }))

  const cohortVisualizationColumns = [
    {
      key: "date",
      header: "Date",
      type: "date",
    },
    {
      key: "value",
      header: "Actual",
    },
    {
      key: "predictedValue",
      header: "Predicted",
    },
  ]

  const tableVisualizationHeaders = [
    {
      key: "metric",
      header: "Metric",
    },
    {
      key: "date",
      header: "Date",
      type: "date",
    },
    {
      key: "value",
      header: "Actual",
    },
    {
      key: "predictedValue",
      header: "Predicted",
    },
  ]

  const handleSetDataAggType = (granularity: AggregationType) => {
    setGranularity(granularity)
  }

  return (
    <div className="p-6">
      <H2> Cards: </H2>
      <Card className="inline-block mt-2 me-2">Card</Card>
      <Card rounded className="inline-block mt-2">
        Rounded Card
      </Card>
      <Card rounded className="mt-4">
        <H3 className="me-3">Typography Components</H3>
        <H1>H1 Header</H1>
        <H2>H2 Header</H2>
        <H3>H3 Header</H3>
        <H4>H4 Header</H4>
        <H5>H5 Header</H5>
        <H6>H6 Header</H6>
        <SubTitle>SubTitle component</SubTitle>
        <P>P Component</P>
        <div className="p-3">
          Checkbox:{" "}
          <Checkbox
            checked={checked}
            onChange={ev => setChecked((ev.target as HTMLInputElement).checked)}
          />
        </div>
        <div className="p-3">
          Input: <Input />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>Popover</H3>
        <Popover relative handler={() => <Button>Open Popover</Button>}>
          {() => <Card rounded>You will see mee</Card>}
        </Popover>
      </Card>
      <Card className="mt-4">
        <H3>Date Picker</H3>
        <DatePicker
          monthsShown={2}
          inline
          highlightDates={highlightWithRanges}
          onChange={(date: Date) => setDate(date)}
        />
        <br />
        <div className="inline-block">
          <Input
            className="inline-block"
            type="string"
            value={dayjs(dateRange[0]).toDate().toDateString()}
            onFocus={() => setDateInputFocused(0)}
            onChange={ev =>
              setPeriod(
                dayjs((ev.target as HTMLInputElement).value).format(
                  DATE_FORMAT,
                ),
                dayjs(dateRange[1]).format(DATE_FORMAT),
              )
            }
          />
        </div>{" "}
        |{" "}
        <div className="inline-block">
          <Input
            className="inline-block"
            type="string"
            value={dayjs(dateRange[0]).toDate().toDateString()}
            onFocus={() => setDateInputFocused(1)}
            onChange={ev =>
              setPeriod(
                dayjs(dateRange[0]).format(DATE_FORMAT),
                dayjs((ev.target as HTMLInputElement).value).format(
                  DATE_FORMAT,
                ),
              )
            }
          />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>Period Picker</H3>
        <P>
          Dates {dateRange[0]} - {dateRange[1]}
        </P>
        {compareRange && (
          <P>
            Compare to {compareRange[0]} - {compareRange[1]}
          </P>
        )}
        <PeriodPicker
          dateRange={[
            dayjs(dateRange[0]).toDate(),
            dayjs(dateRange[1]).toDate(),
          ]}
          compareRange={
            compareRange
              ? [
                  dayjs(compareRange[0]).toDate(),
                  dayjs(compareRange[1]).toDate(),
                ]
              : null
          }
          onChange={(dateRange, compareDateRange) => {
            setPeriod(
              dayjs(dateRange[0]).format(DATE_FORMAT),
              dayjs(dateRange[1]).format(DATE_FORMAT),
            )

            if (compareDateRange && compareDateRange.length > 0) {
              setComparePeriod(
                dayjs(compareDateRange[0]).format(DATE_FORMAT),
                dayjs(compareDateRange[1]).format(DATE_FORMAT),
              )
            } else {
              setComparePeriod()
            }
          }}
        />
      </Card>
      <Card className="mt-4">
        <H3>ChipInput</H3>
        <div>Values: {chipValues.join(", ")}</div>
        <div className="mt-2">
          <ChipInput
            value={chipValues}
            onChange={(value: string[]) => setChipValues(value)}
          />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>MultiSelect</H3>
        <div>
          Selected: {selectedOptions.map(option => option.label).join(", ")}
        </div>
        <div>
          <MultiSelect
            options={options}
            value={selectedOptions}
            onChange={setSelectedOptions}
          />
        </div>
      </Card>
      <Card className="mt-4 bg-transparent">
        <H3>TableVisualization</H3>
        <div>
          <TableVisualization
            chartId="docs-table"
            metrics={chartMetrics as never}
            columnConfig={
              tableVisualizationHeaders as TableVisualizationColumn[]
            }
            setPaginationState={() => null}
            paginationState={{
              pageIndex: 0,
              pageSize: 10,
            }}
            sorting={[]}
            setSorting={() => null}
          />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>CohortVisualization</H3>
        <div>
          <CohortVisualization
            metrics={chartMetrics as never}
            realizedLabelText="realized"
            forecastLabelText="forecast"
            realizedCellColor="#00ff00"
            forecastCellColor="#0000ff"
            columnConfig={
              cohortVisualizationColumns as CohortVisualizationColumn[]
            }
          />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>DropDown Chart</H3>
        <div>
          <DropDownChart
            metrics={dropDownMetricsOptions}
            isLoading={false}
            onChange={val => setDropDownMetrics(val)}
          />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>Area Chart</H3>
        <div className="h-120">
          <AreaChart
            dataAggType={granularity}
            setDataAggType={handleSetDataAggType}
            metrics={areaChartMetrics as never}
            title="Empty Chart"
            unit="subscriber"
          />
        </div>
      </Card>
      <Card className="mt-4">
        <H3>Composed Chart</H3>
        <div className="h-120">
          <ComposedChart
            dataSources={["TikTok", "FaceBook"]}
            totalLabelProps={{
              type: "line",
              dataKey: "value",
            }}
            graphs={[
              {
                type: "line",
                dataKey: "value",
              },
              {
                type: "dashed",
                dataKey: "predictedValue",
              },
            ]}
            compareMode={false}
            metrics={chartMetrics as never}
            title="Empty Chart"
          />
        </div>
      </Card>

      <Card className="mt-4 mb-50">
        <H3 className="mb-2">SearchSelect</H3>
        <SearchSelect
          value={getSearchSelectOptions()[0]}
          options={getSearchSelectOptions()}
          onSelect={option => {
            // eslint-disable-next-line no-console
            console.log(option)
          }}
        />
      </Card>

      <Card>
        <FileUpload
          label="Csv*"
          maxSize={50}
          isMultiple
          onUpload={console.info}
        />
      </Card>
    </div>
  )
}
