import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const LogoutSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0751 4.24738C10.2846 4.88873 9.79259 5.92629 9.64723 7.61433C9.6117 8.02701 9.24834 8.33275 8.83565 8.29721C8.42297 8.26168 8.11723 7.89832 8.15276 7.48563C8.31741 5.57367 8.90535 4.07623 10.1299 3.08259C11.3338 2.10573 13.0163 1.72998 15.11 1.72998H15.24C17.5492 1.72998 19.3609 2.18777 20.5766 3.4034C21.7922 4.61903 22.25 6.4308 22.25 8.73998V15.26C22.25 17.5692 21.7922 19.3809 20.5766 20.5966C19.3609 21.8122 17.5492 22.27 15.24 22.27H15.11C13.0315 22.27 11.3592 21.8993 10.1577 20.9381C8.93462 19.9597 8.33891 18.484 8.16324 16.5996C8.12479 16.1872 8.42796 15.8217 8.84039 15.7832C9.25281 15.7448 9.61832 16.0479 9.65676 16.4604C9.81109 18.1159 10.3054 19.1353 11.0948 19.7668C11.9058 20.4157 13.1685 20.77 15.11 20.77H15.24C17.4008 20.77 18.7191 20.3328 19.5159 19.5359C20.3128 18.739 20.75 17.4208 20.75 15.26V8.73998C20.75 6.57916 20.3128 5.26093 19.5159 4.46406C18.7191 3.66719 17.4008 3.22998 15.24 3.22998H15.11C13.1537 3.22998 11.8862 3.58923 11.0751 4.24738ZM6.38033 8.10965C6.67322 8.40254 6.67322 8.87742 6.38033 9.17031L4.31066 11.24H15C15.4142 11.24 15.75 11.5758 15.75 11.99C15.75 12.4042 15.4142 12.74 15 12.74H4.31066L6.38033 14.8097C6.67322 15.1025 6.67322 15.5774 6.38033 15.8703C6.08744 16.1632 5.61256 16.1632 5.31967 15.8703L1.96967 12.5203C1.67678 12.2274 1.67678 11.7525 1.96967 11.4597L5.31967 8.10965C5.61256 7.81676 6.08744 7.81676 6.38033 8.10965Z"
      fill={fill}
    />
  </svg>
))
