import React, { useMemo } from "react"
import {
  iconsSizeMap,
  IconSize,
} from "~/ui-rtk/shared/constants/icons-size.constant"

type LoaderProps = {
  /** set the color of the icon. */
  color?: string

  /** sets the size of the icon. */
  size?: IconSize | number | "inherit"
  className?: string
}

export const Loader: React.FC<LoaderProps> = ({
  color = "currentColor",
  size = "inherit",
  className = "",
  ...props
}) => {
  const sizeClass =
    size !== "inherit" && typeof size !== "number" && size in iconsSizeMap
      ? `${iconsSizeMap[size]}rem`
      : size

  const loaderPieceClass =
    "absolute w-[0.8em] h-[0.8em] m-[0.1em] border-[0.1em] border-solid border-current rounded-full animate-spin border-t-transparent border-r-transparent border-b-transparent"

  const wrapStyle = useMemo(
    () => ({ color, fontSize: sizeClass }),
    [color, sizeClass],
  )
  const animationStyles = useMemo(
    () => [
      { animationDelay: "-0.45s" },
      { animationDelay: "-0.3s" },
      { animationDelay: "-0.15s" },
    ],
    [],
  )

  return (
    <div
      className={`${className} inline-block relative w-[1em] h-[1em]`}
      style={wrapStyle}
      {...props}
    >
      <div className={loaderPieceClass} />
      <div className={loaderPieceClass} style={animationStyles[0]} />
      <div className={loaderPieceClass} style={animationStyles[1]} />
      <div className={loaderPieceClass} style={animationStyles[2]} />
    </div>
  )
}
