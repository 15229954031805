import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const SuccessSvg = SvgHOC(({ fill, ...props }) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.08579 6.08579 1.25 12 1.25C17.9142 1.25 22.75 6.08579 22.75 12C22.75 17.9142 17.9142 22.75 12 22.75C6.08579 22.75 1.25 17.9142 1.25 12ZM12 2.75C6.91421 2.75 2.75 6.91421 2.75 12C2.75 17.0858 6.91421 21.25 12 21.25C17.0858 21.25 21.25 17.0858 21.25 12C21.25 6.91421 17.0858 2.75 12 2.75ZM16.7808 8.64014C17.0734 8.93329 17.073 9.40816 16.7799 9.7008L11.1099 15.3608C10.8169 15.6532 10.3424 15.653 10.0497 15.3603L7.21967 12.5303C6.92678 12.2374 6.92678 11.7626 7.21967 11.4697C7.51256 11.1768 7.98744 11.1768 8.28033 11.4697L10.5805 13.7698L15.7201 8.6392C16.0133 8.34657 16.4882 8.34699 16.7808 8.64014Z"
      fill={fill}
    />
  </svg>
))
