import { ClipboardIcon } from "@heroicons/react/20/solid"
import React, { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { H3 } from "~/ui-rtk/components/ui/typography"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TDetailsHeaderClasses = {
  container: string
}

type TDetailsHeaderProps = {
  title: string
  classes?: Partial<TDetailsHeaderClasses>
  demoMode?: boolean
  id?: string
}

export const DetailsTitle: React.FC<TDetailsHeaderProps> = ({
  title,
  id,
  classes,
  demoMode,
}) => {
  const [copied, setCopied] = useState(false)

  const innerTitle = title.length > 50 ? `${title.slice(0, 50)}...` : title

  const handleCopyToClipboard = useCallback(() => {
    if (id != null) {
      void navigator.clipboard
        .writeText(id)
        .then(() => {
          setCopied(true)
          toast.success("Copied")
          setTimeout(() => {
            setCopied(false)
          }, 2000)
        })
        .catch(() =>
          toast.error("Failed to copy id to clipboard", {
            toastId: "clipboard-copy-post-id",
          }),
        )
    }
  }, [id])

  return (
    <div
      className={cn(
        "flex justify-between",
        classes?.container,
        demoMode && "blur-md",
      )}
    >
      <H3 className="text-6">{innerTitle}</H3>
      {id && (
        <button
          onClick={handleCopyToClipboard}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold pl-2 pr-8 px-4 rounded flex items-center focus:outline-none focus:shadow-outline"
        >
          <ClipboardIcon className="h-4 w-4 mr-2" /> {/* Icon */}
          {copied ? "Copied!" : "Copy ID"}
        </button>
      )}
    </div>
  )
}
