import useSources from "~/ui-rtk/hooks/sources"
import { useVisualizationsMap } from "~/ui-rtk/hooks/visualizations"

const content = {
  id: "home-page",
  slug: "/home",
  icon: null,
  title: "Home",
  content: {
    items: [
      {
        className: "h-120",
        widget: "brand_value_line_chart",
      },
    ],
    title: "Home",
    periodPicker: {
      visible: true,
    },
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const widgetIds = content.content.items.map(({ widget }) => widget)

  const { visualizations } = useVisualizationsMap(widgetIds)

  return {
    content,
    sources,
    visualizations,
    isLoading: isSourcesDataLoading,
  }
}
