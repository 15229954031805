import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const PinSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_7845_85630)">
      <path
        d="M14.0455 5.75445L10.8283 2.53727C9.94346 1.65243 8.45177 1.8945 7.89215 3.01375L6.26854 6.261C6.21008 6.37793 6.10815 6.46731 5.98459 6.50999L3.20707 7.4695C2.62528 7.67048 2.45467 8.41116 2.88992 8.84641L4.95957 10.9161L2.00006 13.8756L2 14.5827H2.70716L5.66668 11.6232L7.73636 13.6929C8.1716 14.1281 8.91229 13.9575 9.11327 13.3757L10.0728 10.5982C10.1155 10.4746 10.2048 10.3727 10.3218 10.3142L13.569 8.69059C14.6883 8.13097 14.9303 6.63929 14.0455 5.75445Z"
        stroke={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_7845_85630">
        <rect width="15" height="15" fill={fill} />
      </clipPath>
    </defs>
  </svg>
))
