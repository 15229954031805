import { capitalize } from "lodash"
import {
  WarnSvg,
  ErrorSvg,
  SuccessSvg,
} from "~/ui-rtk/components/ui/svg/essentials"

export type ConnectionStatus = "healthy" | "unhealthy" | "error"

export const getConnectionStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "healthy":
      return "#13CE66"
    case "error":
      return "#E2323E"
    default:
      return "#F4D148"
  }
}

export const renderStatusIcon = (status: ConnectionStatus) => {
  switch (status) {
    case "healthy":
      return <SuccessSvg size={20} fill="green" />
    case "error":
      return <ErrorSvg size={20} fill="error" />
    default:
      return <WarnSvg size={20} fill="yellow-info" />
  }
}

export const renderHealthIndicator = (status: string) => {
  const color = getConnectionStatusColor(status)

  return (
    <p
      className="text-4 leading-7 font-medium flex items-center gap-2"
      style={{
        color,
      }}
    >
      {capitalize(status)}
      <div
        className="w-4 h-4 rounded-[50%]"
        style={{ backgroundColor: color }}
      />
    </p>
  )
}
