import type { FC, ReactNode } from "react"

import { LogoSvg } from "~/ui-rtk/components/ui/svg/business/LogoSvg"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { ArrowBackSvg } from "~/ui-rtk/components/ui/svg/arrow"
import { guestRoutes } from "~/ui-rtk/constants/routes"

type Props = {
  children: ReactNode
  shadow?: Partial<{
    blueShadow: string
    yellowShadow: string
  }>
}

export default function GuestLayout({ children }: Props): ReturnType<FC> {
  const navigate = useStableNavigate()

  const handleBackToSignIn = () => {
    void navigate("/sign-in")
  }

  const renderBackButton = () => {
    const { pathname } = location
    if ([guestRoutes.SIGN_IN, guestRoutes.SIGN_UP].includes(pathname)) {
      return
    }

    return (
      <div className="flex items-center">
        <button
          onClick={handleBackToSignIn}
          className="flex items-center gap-2 px-3 py-2 cursor-pointer"
        >
          <span className="font-bold leading-5 text-white text-4">Back</span>
          <ArrowBackSvg size={20} />
        </button>
      </div>
    )
  }

  return (
    <div className="relative flex flex-col justify-center flex-1 min-h-full px-6 py-12 overflow-hidden lg:px-8 bg-basic-black text-gray-ligh">
      <div className="absolute z-50 flex flex-col justify-start gap-10 top-5 left-5">
        <header
          className="flex items-center cursor-pointer"
          onClick={handleBackToSignIn}
        >
          <LogoSvg width={177} height={26} />
        </header>
        {renderBackButton()}
      </div>
      {children}
    </div>
  )
}
