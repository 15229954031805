import { useEffect, useState } from "react"
import Card from "~/ui-rtk/components/ui/common/Card"
import Button from "~/ui-rtk/components/ui/controls/Button"

const DIALOG_TIMEOUT = 30_000

export default function useRefetchDialog() {
  const [showRefetch, setShowRefetch] = useState(false)
  const [timeoutRef, setTimeoutRef] = useState<ReturnType<
    typeof setTimeout
  > | null>(null)

  useEffect(() => {
    setTimeoutRef(
      setTimeout(() => {
        setShowRefetch(true)
      }, DIALOG_TIMEOUT),
    )
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef)
      }
    }
  }, [])

  const handleRefetch = () => {
    location.reload()
    setShowRefetch(false)
  }

  return showRefetch ? (
    <div className="absolute flex justify-center items-center top-0 bottom-0 left-0 right-0 z-30">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-background-dark-shade opacity-20"></div>
      <Card rounded className="pa-4 z-40 max-w-150">
        <div className="mb-4 text-center">
          Refresh your page to get back to the
          baseline-resilient-revenue-building goodness!
        </div>
        <Button size="lg" className="w-full" onClick={() => handleRefetch()}>
          Refresh
        </Button>
      </Card>
    </div>
  ) : null
}
