import SvgHOC from "../../common/SvgHOC/index"

export const ImageSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6581_33571)">
      <path
        d="M6.00016 14.6666H10.0002C13.3335 14.6666 14.6668 13.3333 14.6668 9.99998V5.99998C14.6668 2.66665 13.3335 1.33331 10.0002 1.33331H6.00016C2.66683 1.33331 1.3335 2.66665 1.3335 5.99998V9.99998C1.3335 13.3333 2.66683 14.6666 6.00016 14.6666Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99984 6.66667C6.73624 6.66667 7.33317 6.06971 7.33317 5.33333C7.33317 4.59695 6.73624 4 5.99984 4C5.26346 4 4.6665 4.59695 4.6665 5.33333C4.6665 6.06971 5.26346 6.66667 5.99984 6.66667Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.77979 12.6334L5.06645 10.4267C5.59312 10.0734 6.35312 10.1134 6.82642 10.52L7.04642 10.7134C7.56642 11.16 8.40642 11.16 8.92642 10.7134L11.6998 8.33335C12.2198 7.88669 13.0598 7.88669 13.5798 8.33335L14.6664 9.26669"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6581_33571">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
))
