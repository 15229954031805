import { ColumnPinningState } from "@tanstack/react-table"
import { useEffect, useState } from "react"

const IN_MEMORY_PINNING_STATE: Record<string, ColumnPinningState> = {}

export function usePinning(widgetId: string) {
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>(
    IN_MEMORY_PINNING_STATE[widgetId] ?? {
      left: [],
      right: [],
    },
  )
  const [rendered, rerender] = useState(0)

  useEffect(() => {
    IN_MEMORY_PINNING_STATE[widgetId] = columnPinning
    rerender(rendered + 1)
  }, [columnPinning, widgetId])

  return {
    columnPinning: IN_MEMORY_PINNING_STATE[widgetId] ?? columnPinning,
    setColumnPinning,
  }
}
