import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { SessionRole } from "~/api/dto/auth/session-role"
import {
  CompanyDto,
  SessionDto,
  SubscriptionDataDto,
  UserCompanyDto,
  CompanySetupStatus,
} from "~/ui-rtk/api/types"
import { revertAll } from "../revertAll"

export interface UserState {
  user: {
    id: string
    name: string
    email: string
    companies: UserCompanyDto[]
  }
  currentCompany: CompanyDto
  sessionRole: SessionRole
  subscriptionData?: SubscriptionDataDto
}

const initialState: UserState = {
  user: {
    id: "",
    name: "",
    email: "",
    companies: [],
  },
  currentCompany: {
    id: "",
    name: "",
    setupStatus: CompanySetupStatus.INITIAL_SETUP,
    subscription: null,
    metadata: {},
    createdAt: "",
    updatedAt: "",
  },
  sessionRole: SessionRole.USER,
}

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: builder => builder.addCase(revertAll, () => initialState),
  reducers: {
    setUserSession: (
      state: UserState,
      { payload }: PayloadAction<SessionDto>,
    ) => {
      const { currentCompany, user, sessionRole, subscriptionData } = payload

      state.sessionRole = sessionRole
      state.currentCompany = { ...currentCompany }
      state.user = user
      state.subscriptionData = subscriptionData
    },
  },
})

export const userActions = userSlice.actions
