import { ContentBox } from "~/ui-rtk/components/ui/common"

const SkippedDataConnection = () => (
  <ContentBox
    title="Thanks for completing your onboarding flow"
    subTitle="Please check back later to ensure your sent connections have been successfully completed!"
    classes={{ content: "max-w-200" }}
  />
)

export default SkippedDataConnection
