import { useCallback } from "react"
import { toast } from "react-toastify"
import * as yup from "yup"

import { hasErrorMessage } from "~/ui-rtk/utils/http-utils"
import { domainRegex } from "~/ui-rtk/constants/regex"
import { useCompanyControllerSignUpCompanyMutation } from "~/ui-rtk/api/companyApi"

const validationSchema = yup.object({
  companyName: yup.string().required("Company name is required"),
  domain: yup
    .string()
    .transform(value => value.trim())
    .matches(domainRegex, { message: "Invalid domain format" })
    .required("Domain is required"),
})

export type CreateCompanyFormValues = yup.InferType<typeof validationSchema>

const initialValues: CreateCompanyFormValues = {
  domain: "",
  companyName: "",
}

export const useConnect = () => {
  const [signUpCompanyAsync, { isLoading, isSuccess }] =
    useCompanyControllerSignUpCompanyMutation()

  const createCompany = useCallback(async (values: CreateCompanyFormValues) => {
    try {
      const { company } = await signUpCompanyAsync({
        signUpCompanyDto: { ...values },
      }).unwrap()

      if (isSuccess) {
        toast.success(`Company ${company.name} was successfully created`, {
          toastId: "onboarding-account-details-success",
        })
      }
    } catch (error) {
      if (hasErrorMessage(error)) {
        toast.error(error.data.message, {
          toastId: "create-company-error",
        })
      }
    }
  }, [])

  return {
    createCompany,
    isLoading,
    initialValues,
    validationSchema,
  }
}
