import React from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TCategoryItemProps = {
  id: string
  text: string
  isSelected: boolean
  onClick?: (id: string) => void
}

const CategoryItem: React.FC<TCategoryItemProps> = ({
  id,
  text,
  isSelected,
  onClick,
}) => {
  const handleSelect = () => {
    void onClick?.(id)
  }

  return (
    <li
      className={cn(
        "py-3 px-4 rounded-2xl whitespace-nowrap cursor-pointer font-medium",
        isSelected && "bg-basic-blue",
      )}
      onClick={handleSelect}
    >
      {text}
    </li>
  )
}

export default CategoryItem
