import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export default function H1({
  children,
  className = "",
}: Props): ReturnType<FC<Props>> {
  const cs = ["text-3xl font-bold sm:text-4xl", className].join(" ").trim()
  return <h1 className={cs}>{children}</h1>
}
