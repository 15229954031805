import { useCompanyConnectorControllerGetCompanyConnectorAccountInfoQuery } from "~/ui-rtk/api/companyConnectorApi"

export const useConnect = (id: string) => {
  const { data: accountInfo, isLoading } =
    useCompanyConnectorControllerGetCompanyConnectorAccountInfoQuery({ id })

  // const [isRefetching, setIsRefetching] = useState(false)

  // const refetchWithDelay = useCallback(
  //   async (attempt = 0) => {
  //     try {
  //       if (attempt < 2) {
  //         if (!isRefetching) {
  //           setIsRefetching(true)
  //         }

  //         const { data } = await refetch()
  //         if (isAccountInfo(data)) {
  //           setIsRefetching(false)
  //           return
  //         }

  //         delay(() => refetchWithDelay(attempt + 1), 1000)
  //       } else {
  //         setIsRefetching(false)
  //       }
  //     } catch {
  //       setIsRefetching(false)
  //     }
  //   },
  //   [accountInfo, refetch],
  // )

  // useEffect(() => {
  //   if (!isAccountInfo(accountInfo) && !isRefetching) {
  //     void refetchWithDelay()
  //   }
  // }, [refetchWithDelay])

  return {
    accountInfo,
    isLoading,
  }
}
