export const getTextColor = (percentage: number) => {
  if (percentage < 30) return "text-states-error"
  if (percentage > 70) return "text-verse-green"
  return "text-yellow-info"
}

export const calculateConnectedPercentage = (
  connectedSourcesCount: number,
  total: number,
) => {
  if (total === 0) {
    return 0
  }
  return Math.floor((connectedSourcesCount / total) * 100)
}
