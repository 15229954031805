import { PropsWithChildren } from "react"
import useSources from "~/ui-rtk/hooks/sources"

export default function WithSources({ children }: PropsWithChildren) {
  const { sources, isLoading } = useSources()

  if (isLoading) {
    return <></>
  }

  return (
    <>
      <div
        className={`${isLoading ? "h-full flex items-center justify-center" : ""}`}
      >
        {sources.size < 1 ? <>No Connected Sources found</> : children}
      </div>
    </>
  )
}
