import { emptySplitApi as api } from "./emptyApi"
import { BrandEventDto } from "./types"
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    brandEventsControllerGetCompanyConnectors: build.query<
      BrandEventsControllerGetCompanyConnectorsApiResponse,
      BrandEventsControllerGetCompanyConnectorsApiArg
    >({
      query: () => ({ url: `/brand-event` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type BrandEventsControllerGetCompanyConnectorsApiResponse =
  /** status 200  */ BrandEventDto[]
export type BrandEventsControllerGetCompanyConnectorsApiArg = void
export const { useBrandEventsControllerGetCompanyConnectorsQuery } =
  injectedRtkApi
