import React, { useMemo } from "react"
import { ColumnOrderState, Row } from "@tanstack/react-table"
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable"

import { BodyCell } from "./BodyCell"
import { TMetric } from "~/ui-rtk/components/ui/charts/TableVisualization/types"

type TTableBodyProps = {
  rows: Row<TMetric>[]
  colsLength: number
  countPerPage: number
  columnOrder: ColumnOrderState
  disableHover?: boolean
  onRowClick: (data: TMetric) => void
  id?: string
  demoMode?: boolean
}

export const TableBody: React.FC<TTableBodyProps> = ({
  rows,
  colsLength,
  countPerPage,
  columnOrder,
  disableHover,
  onRowClick,
  id,
  demoMode,
}) => {
  const emptyRows = useMemo(
    () => new Array(countPerPage - (rows.length || 0)).fill("&nbsp;"),
    [rows.length, countPerPage],
  )

  const hoverClass = disableHover ? "" : "hover:bg-gray-dark"

  return (
    <tbody className="w-full" id={id}>
      {rows.map((row, idx) => (
        <tr
          key={row.id}
          className={
            idx % 2 !== 0 ? `bg-basic-black ${hoverClass}` : hoverClass
          }
          onClick={() => onRowClick(row.original)}
        >
          {row.getVisibleCells().map(cell => (
            <SortableContext
              key={cell.id}
              items={columnOrder}
              strategy={horizontalListSortingStrategy}
            >
              <BodyCell
                demoMode={demoMode}
                key={cell.id}
                cell={cell}
                className={
                  idx % 2 !== 0
                    ? `bg-basic-black ${hoverClass}`
                    : `bg-basic-dark-blue ${hoverClass}`
                }
              />
            </SortableContext>
          ))}
        </tr>
      ))}
      {emptyRows.map((_, idx) => (
        <tr
          key={`empty-${idx}`}
          className={
            idx % 2 === (rows.length % 2 === 0 ? 1 : 0)
              ? "bg-basic-black"
              : "bg-basic-dark-blue"
          }
        >
          <td colSpan={colsLength} className="px-5 py-4">
            &nbsp;
          </td>
        </tr>
      ))}
    </tbody>
  )
}
