import type { JSXElementConstructor } from "react"

import {
  AttentiveForm,
  GoogleKeywordSearchForm,
  YotpoForm,
  TargetForm,
  SephoraForm,
  InstagramInsightsForm,
  SpsForm,
} from "../forms"

import type { CustomConnectorDialogFormProps } from "./types"

export const serviceConnectorMap: Record<
  string,
  {
    type: "connector" | "upload"
    component: JSXElementConstructor<CustomConnectorDialogFormProps>
  }
> = {
  attentive: { type: "connector", component: AttentiveForm },
  google_keyword_search: {
    type: "connector",
    component: GoogleKeywordSearchForm,
  },
  yotpo: { type: "connector", component: YotpoForm },
  target: { type: "upload", component: TargetForm },
  sephora: { type: "upload", component: SephoraForm },
  instagram_insights: { type: "connector", component: InstagramInsightsForm },
  sps: { type: "connector", component: SpsForm },
}
