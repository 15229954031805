import React, { useCallback, useState } from "react"
import { ImageSvg } from "../../svg/essentials"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TClasses = Partial<{
  image: string
  wrapper: string
}>

export type TImageProps = {
  src: string
  alt: string
  size?: number
  width?: number
  height?: number
  classes?: Partial<TClasses>
  fallback?: React.ReactNode | string
  loadingIndicator?: React.ReactNode
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, "src" | "alt" | "className">

export const Image: React.FC<TImageProps> = ({
  src,
  alt,
  size,
  width,
  height,
  classes,
  fallback,
  loadingIndicator,
  ...props
}) => {
  const [isBrokenImage, setIsBrokenImage] = useState(!src)
  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setIsLoading(false)
  }

  const handleError = () => {
    setIsLoading(false)
    setIsBrokenImage(true)
  }

  const imageProps: React.ImgHTMLAttributes<HTMLImageElement> = {
    src,
    alt,
    style: {
      width: size || width,
      height,
    },
    onLoad: handleLoad,
    onError: handleError,
    className: cn(`object-cover`, classes?.image),
    ...props,
  }

  const renderFallback = useCallback(() => {
    if (fallback) {
      if (typeof fallback === "string") {
        return <img src={fallback} {...imageProps} />
      }
      return fallback
    }
    return (
      <ImageSvg
        size={size || width || height || 42}
        fill="basic.grey.inactive"
      />
    )
  }, [size, width, height, fallback, imageProps])

  const renderContent = useCallback(() => {
    if (isLoading && loadingIndicator) {
      return loadingIndicator
    } else if (isBrokenImage) {
      return renderFallback()
    }

    return <img {...imageProps} />
  }, [isLoading, loadingIndicator, isBrokenImage, imageProps])

  return (
    <span className={cn("flex items-center justify-center", classes?.wrapper)}>
      {renderContent()}
    </span>
  )
}
