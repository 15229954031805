export type IconSize =
  | "micro"
  | "tiny"
  | "small"
  | "medium"
  | "big"
  | "large"
  | "huge"

export const iconsSizeMap: { [key in IconSize]: number } = {
  micro: 0.75,
  tiny: 1,
  small: 1.25,
  medium: 1.5,
  big: 2,
  large: 3,
  huge: 4,
} as const
