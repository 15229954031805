export const EMAIL_SUBJECT = "Customer SFTP Account Setup Request"
export const EMAIL_BODY = `Hi Attentive Client Strategy Team,

I hope this message finds you well.
I am writing to request the setup of an Attentive SFTP account for our company, [Your Company Name].
Please find the details required for setting up the SFTP account below:

* Primary Contact Email Address: [Your Email Address]
* Company Name: [Your Company Name]
* Desired Username for SFTP Account: [Preferred Username]

Best regards,

[Your Full Name]
[Your Position]
[Your Contact Information]
[Your Company Name]
[Company Address]
`
