import { useCallback, useMemo } from "react"
import { toast } from "react-toastify"
import { isFuture } from "date-fns"

import { useConnectorControllerGetAllConnectorsQuery } from "~/ui-rtk/api/connectorApi"
import {
  useCompanyConnectorControllerCreateCompanyConnectorMutation as useCreateCompanyConnectorMutation,
  useCompanyConnectorControllerGetCompanyConnectorsQuery,
} from "~/ui-rtk/api/companyConnectorApi"
import { mapConnectorStatus } from "~/ui-rtk/utils/connector-utils"
import {
  useLazyCompanyControllerGetCompanyByIdQuery,
  useCompanyControllerUpdateCompanyMutation as useUpdateCompanyMutation,
} from "~/ui-rtk/api/companyApi"
import { useConnectionLinkControllerGetConnectionLinksByCompanyQuery as useConnectionLinksByCompanyQuery } from "~/ui-rtk/api/connectionLinkApi"

import { CONNECTION_FLOW_STEPS } from "./constants"

import type { ConnectorStatus } from "~/ui-rtk/shared/types/connectors"
import { useAppDispatch, useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { companyActions } from "~/ui-rtk/app/slices/company.slice"
import { CONNECTORS_IDS } from "~/ui-rtk/constants/sources"
import { CompanySetupStatus, ConnectorMetadata } from "~/ui-rtk/api/types"

export const UNRESOLVED_CONNECTOR_STATUSES = [
  "RECONNECT",
  "FAILED",
  "NOT_CONNECTED",
]

type ConnectionLink = {
  recipientEmail: string
  createdAt: Date
  expiresAt: Date
}

export type Connector = {
  id: string
  name: string
  icon: string
  isSkipped: boolean
  connectionLink?: ConnectionLink
  status: ConnectorStatus
  metadata: ConnectorMetadata
}

export type ConnectionStep = {
  title: string
  order: number
  connectors: Connector[]
  completed: boolean
}

export const useConnect = () => {
  const [queryCompanyByIdAsync] = useLazyCompanyControllerGetCompanyByIdQuery()

  const dispatch = useAppDispatch()
  const currentCompany = useAppSelector(selectCurrentCompany)

  const { data: connectionLinks, isLoading: isConnectionLinksLoading } =
    useConnectionLinksByCompanyQuery()

  const [updateCompany, { isLoading: isCompanyUpdateLoading }] =
    useUpdateCompanyMutation()
  const [connectAsync] = useCreateCompanyConnectorMutation()

  const {
    data: dataConnectors = [],
    isError: isGetAllConnectorsError,
    isLoading: isGetAllConnectorsLoading,
  } = useConnectorControllerGetAllConnectorsQuery()
  const {
    data: connectedServices = [],
    isError: isGetCompanyConnectorsError,
    isLoading: isGetCompanyConnectorsLoading,
  } = useCompanyConnectorControllerGetCompanyConnectorsQuery()

  const isDataError = isGetAllConnectorsError || isGetCompanyConnectorsError
  const isDataLoading =
    isConnectionLinksLoading ||
    isGetAllConnectorsLoading ||
    isGetCompanyConnectorsLoading

  const getConnectorsForStep = useCallback(
    (requiredConnectors: string[]) =>
      dataConnectors.reduce<Connector[]>((acc, connector) => {
        if (requiredConnectors.includes(connector.id)) {
          const connectedService = connectedServices.find(
            service => service.serviceName === connector.id,
          )

          let connectionLink: ConnectionLink | undefined
          if (connectionLinks) {
            const link = connectionLinks.find(
              ({ expiresAt, connectorId }) =>
                isFuture(new Date(expiresAt)) && connectorId === connector.id,
            )

            if (link) {
              connectionLink = {
                recipientEmail: link.recipientEmail,
                createdAt: new Date(link.createdAt),
                expiresAt: new Date(link.expiresAt),
              }
            }
          }

          const isSkipped = Boolean(
            currentCompany?.metadata?.skippedServices?.includes(connector.id),
          )

          acc.push({
            id: connector.id,
            name: connector.name,
            icon: connector.icon,
            isSkipped,
            connectionLink,
            metadata: connectedService?.metadata as ConnectorMetadata,
            status: mapConnectorStatus(connectedService?.status),
          })
        }
        return acc
      }, []),
    [dataConnectors, connectedServices, connectionLinks, currentCompany],
  )

  const connectionFlowDetails = useMemo(() => {
    let totalConnectors = 0
    let totalCompleted = 0

    const steps: ConnectionStep[] = CONNECTION_FLOW_STEPS.map(
      ({ title, order, requiredConnectors }) => {
        const connectors = getConnectorsForStep(requiredConnectors)
        const isAllCompleted = connectors.every(
          c => !UNRESOLVED_CONNECTOR_STATUSES.includes(c.status),
        )

        totalConnectors += connectors.length
        totalCompleted += connectors.filter(
          c =>
            !UNRESOLVED_CONNECTOR_STATUSES.includes(c.status) ||
            Boolean(currentCompany?.metadata.skippedServices?.includes(c.id)),
        ).length

        return {
          title,
          order,
          connectors,
          completed: isAllCompleted,
        }
      },
    )

    return {
      totalConnectors,
      totalCompleted,
      steps,
      defaultStep: steps.find(step => !step.completed)?.order || 0,
    }
  }, [dataConnectors, currentCompany, connectedServices, getConnectorsForStep])

  const completeConnectionFlow = useCallback(async () => {
    if (!currentCompany) {
      return
    }

    try {
      await updateCompany({
        id: currentCompany?.id,
        updateCompanyDto: {
          setupStatus: CompanySetupStatus.COMPLETE,
        },
      })
    } catch (error) {
      toast.error("Unknown issue occured", {
        toastId: "complete-connection-flow-error",
      })
    }
  }, [currentCompany, updateCompany])

  const isGoogleAnalyticsCompleted = useMemo(() => {
    const googleAnalyticsService = connectedServices.find(
      c => c.serviceName === CONNECTORS_IDS.GOOGLE_ANALYTICS_4,
    )

    if (!googleAnalyticsService) {
      return false
    }

    return !UNRESOLVED_CONNECTOR_STATUSES.includes(
      googleAnalyticsService.status,
    )
  }, [connectedServices])

  const connect = async (id: string) => {
    try {
      const { uri, companyConnectorId } = await connectAsync({
        createCompanyConnectorDto: {
          service: id,
        },
      }).unwrap()

      return { uri, companyConnectorId }
    } catch (error) {
      throw new Error("Failed to connect to the service.")
    }
  }

  const getCurrentCompanyAsync = async () => {
    if (!currentCompany) {
      throw new Error()
    }

    try {
      const company = await queryCompanyByIdAsync({
        id: currentCompany.id,
      }).unwrap()

      if (!company) {
        throw new Error()
      }

      return company
    } catch (error) {
      throw new Error("Failed to get company")
    }
  }

  const skipServiceAsync = async (service: string) => {
    const company = await getCurrentCompanyAsync()
    const services = company?.metadata.skippedServices ?? []
    const skippedServices = [...services, service]

    const updatedCompany = await updateCompany({
      id: company.id,
      updateCompanyDto: {
        metadata: {
          skippedServices,
        },
      },
    }).unwrap()

    dispatch(
      companyActions.setCurrentCompany({
        id: updatedCompany.id,
        name: updatedCompany.name,
        metadata: updatedCompany.metadata,
        createdAt: updatedCompany.createdAt,
        updatedAt: updatedCompany.updatedAt,
        setupStatus: updatedCompany.setupStatus,
        subscription: null,
      }),
    )
  }

  const undoServiceSkipAsync = async (service: string) => {
    const company = await getCurrentCompanyAsync()
    const { skippedServices: services = [] } = company.metadata

    const skippedServices = services.filter(s => s !== service)

    const updatedCompany = await updateCompany({
      id: company.id,
      updateCompanyDto: {
        metadata: {
          skippedServices,
        },
      },
    }).unwrap()

    dispatch(
      companyActions.setCurrentCompany({
        id: updatedCompany.id,
        name: updatedCompany.name,
        metadata: updatedCompany.metadata,
        createdAt: updatedCompany.createdAt,
        updatedAt: updatedCompany.updatedAt,
        setupStatus: updatedCompany.setupStatus,
        subscription: null,
      }),
    )
  }

  return {
    skipServiceAsync,
    undoServiceSkipAsync,
    isConnectionFlowCompleting: isCompanyUpdateLoading,
    isGoogleAnalyticsCompleted,
    connectionFlowDetails,
    connect,
    completeConnectionFlow,
    isDataError,
    isDataLoading,
  }
}
