import { PropsWithChildren } from "react"

export type TProgressWithLabelProps = {
  className?: string
  max?: number
  value?: number
}

export default function ProgressWithLabel({
  className,
  value = 100,
  max = 100,
  children,
}: PropsWithChildren<TProgressWithLabelProps>) {
  return (
    <div className={`h-full flex gap-2`}>
      <div className="w-[calc(100%-100px)]">
        <progress
          className={`${className} progress h-full square-progress`}
          value={value}
          max={max}
        ></progress>
      </div>
      {children && (
        <div className="flex h-full items-center justify-center max-w-25">
          {children}
        </div>
      )}
    </div>
  )
}
