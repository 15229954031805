import {
  createElement,
  ElementType,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
} from "react"
import { useAppDispatch, useAppSelector } from "~/ui-rtk/app/hooks"
import {
  selectDrawerContent,
  selectIsOpen,
} from "~/ui-rtk/app/selectors/drawer.selector"
import { setIsOpen } from "~/ui-rtk/app/slices/drawer.slice"
import { DRAWER_TYPE } from "~/ui-rtk/constants/drawer"
import { useClickOutside } from "~/ui-rtk/shared/hooks"
import { CampaingAdSetAdDetails } from "~/ui-rtk/components/ui/drawer"
import { CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

import "./drawer.scss"

const DRAWER_COMPONENTS: Record<DRAWER_TYPE, ElementType> = {
  CampaingAdSetAdDetails,
}

export default function WithDrawer({ children }: PropsWithChildren) {
  const { isOpen } = useAppSelector(selectIsOpen)
  const { drawerContent } = useAppSelector(selectDrawerContent)
  const dispatch = useAppDispatch()

  const contentRef = useRef<HTMLDivElement>(null)

  const onClose = useCallback(() => {
    dispatch(setIsOpen(false))
  }, [dispatch, setIsOpen])

  useClickOutside(contentRef, (event: MouseEvent) => {
    if (!isOpen) {
      return
    }

    if (drawerContent?.clickSourceId) {
      const sourceElement = document.getElementById(
        drawerContent?.clickSourceId,
      )
      if (
        sourceElement &&
        sourceElement.contains(event.target as HTMLElement)
      ) {
        return
      }
    }
    onClose()
  })

  const drawerRenderedContent = useMemo(
    () =>
      !drawerContent ? (
        <></>
      ) : (
        createElement(
          DRAWER_COMPONENTS[DRAWER_TYPE[drawerContent.type] as DRAWER_TYPE],
          drawerContent.props,
        )
      ),
    [drawerContent],
  )

  return (
    <div className={`drawer drawer-end -top-21 ${isOpen ? "drawer-open" : ""}`}>
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div
        className={cn(
          "drawer-content mt-21 transition-all overflow-y-auto scrollbar",
        )}
      >
        {children}
      </div>
      <div
        ref={contentRef}
        className={cn(
          "drawer-side border-l border-l-basic-blue",
          isOpen && "bg-basic-black",
        )}
      >
        <div className="flex items-start p-5 mt-21 min-w-75 max-w-180">
          {drawerRenderedContent}
          <button onClick={onClose} className="-ml-5">
            <CloseSvg size={22} />
          </button>
        </div>
      </div>
    </div>
  )
}
