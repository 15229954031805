import React, { useState } from "react"
import { SetupMessage, TSetupMessageProps } from "./SetupMessage"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { CloseSvg, InfoSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { Button } from "~/ui-rtk/components/ui/controls"

type TInfoComposition = {
  Message: React.FC<TSetupMessageProps>
}

type TSetupInfoProps = {
  isExpanded: boolean
  collapse: () => void
} & React.PropsWithChildren

export const SetupInfo: React.FC<TSetupInfoProps> & TInfoComposition = ({
  isExpanded,
  children,
}) => {
  const navigate = useStableNavigate()
  const [isMessageClosed, setIsMessageClosed] = useState(false)

  const redirectToConnectors = () => {
    navigate(brandRoutes.SETUP.CONNECTORS)
  }

  const closeMessage = () => {
    void setIsMessageClosed(true)
  }

  const openMessage = () => {
    void setIsMessageClosed(false)
  }

  return (
    <div className={cn("relative px-5", isExpanded && "self-start")}>
      {isExpanded && !isMessageClosed ? (
        <div className="flex overflow-hidden flex-col border min-w-61 border-basic-blue bg-basic-dark-blue p-3 rounded-2.5">
          <div className="flex gap-2 mb-4">
            <InfoSvg fill="states.error" size={22} />
            {children}
            <CloseSvg
              cursor="pointer"
              size={22}
              fill="gray"
              onClick={closeMessage}
            />
          </div>
          <Button size="md" onClick={redirectToConnectors}>
            FIX NOW
          </Button>
        </div>
      ) : (
        <InfoSvg
          size={22}
          fill="gray"
          cursor="pointer"
          className="min-w-5.5"
          onClick={openMessage}
        />
      )}
    </div>
  )
}

SetupInfo.Message = SetupMessage
