import { MutableRefObject, useEffect, useMemo, useState } from "react"
import { TableVisualizationColumn } from "../types"

const scrollBarWidth = 15

export default function useDefaultColumnWidth(
  columnDefs: TableVisualizationColumn[],
  wrapperRef: MutableRefObject<HTMLTableElement | null>,
  defaultWidth: number,
) {
  const [wrapperWidth, setWrapperWidth] = useState<number | null>(null)

  const { fixedWidthColumnsTotal, defaultWidthColumnsAmount } = useMemo(() => {
    let fixedWidthColumnsTotal = 0
    let defaultWidthColumnsAmount = 0
    columnDefs.forEach(column => {
      if (column.width && !Number.isNaN(column.width)) {
        fixedWidthColumnsTotal += column.width
      } else {
        defaultWidthColumnsAmount += 1
      }
    })

    return {
      fixedWidthColumnsTotal,
      defaultWidthColumnsAmount,
    }
  }, [columnDefs])

  useEffect(() => {
    if (wrapperRef.current) {
      setWrapperWidth(wrapperRef.current.offsetWidth)
    }
  }, [wrapperRef.current])

  const totalColumnWidth = useMemo(
    () => fixedWidthColumnsTotal + defaultWidthColumnsAmount * defaultWidth,
    [fixedWidthColumnsTotal, defaultWidthColumnsAmount, defaultWidth],
  )

  if (
    !wrapperWidth ||
    wrapperWidth <= totalColumnWidth ||
    defaultWidthColumnsAmount === 0
  ) {
    return defaultWidth
  }

  return (
    (wrapperWidth - scrollBarWidth - fixedWidthColumnsTotal) /
    defaultWidthColumnsAmount
  )
}
