import { FC, ReactNode, useCallback } from "react"
import { SessionRole } from "~/api/dto/auth/session-role.ts"
import { BulbSvg } from "~/ui-rtk/components/ui/svg/business/BulbSvg.tsx"
import { MapSvg } from "~/ui-rtk/components/ui/svg/business/MapSvg"
import { RocketSvg } from "~/ui-rtk/components/ui/svg/business/RocketSvg.tsx"

import { SidebarItem } from "./SidebarItem"
import { Divider } from "~/ui-rtk/components/ui/common"
import { SetupInfo } from "./components/SetupInfo"
import {
  ConnectorsSetupLabel,
  KeywordsSetupLabel,
} from "./components/CustomLabels"

import { sidebarItems, setupSidebarItems, TSetupSidebarItem } from "./constants"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import useFeaturebase from "~/ui-rtk/hooks/featurebase"

import { useConnect } from "./connect"
import { brandRoutes } from "~/ui-rtk/constants/routes"

const Sidebar: FC = () => {
  const {
    showSidebarProducts,
    isExpanded,
    sessionRole,
    expand,
    collapse,
    handleBeforeNavigate,
    handleNavigate,
    setupDetails,
    isSetupDetailsLoading,
  } = useConnect()

  const { openFeedback, openChangeLog, openRoadMap } = useFeaturebase()

  const renderSetupItem = useCallback(
    (setupItem: TSetupSidebarItem) => {
      const subItems = setupItem?.items?.map(item => {
        const isSetupItem =
          brandRoutes.SETUP.KEYWORDS.includes(item.path) ||
          brandRoutes.SETUP.CONNECTORS.includes(item.path)

        if (!isSetupItem) {
          return item
        }

        let label: ReactNode | string = item.label
        if (item.path === brandRoutes.SETUP.CONNECTORS) {
          label = (
            <ConnectorsSetupLabel
              item={item}
              isLoading={isSetupDetailsLoading}
              setupDetails={setupDetails}
            />
          )
        } else if (item.path === brandRoutes.SETUP.KEYWORDS) {
          label = (
            <KeywordsSetupLabel
              item={item}
              isLoading={isSetupDetailsLoading}
              setupDetails={setupDetails}
            />
          )
        }

        return { ...item, label }
      })

      return (
        <SidebarItem
          key={setupItem.path}
          onBeforeNavigate={handleBeforeNavigate}
          onNavigate={handleNavigate}
          labelVisible={isExpanded}
          sessionRole={sessionRole}
          {...setupItem}
          items={subItems}
        />
      )
    },
    [isExpanded, sessionRole, setupDetails],
  )

  const renderInfoMessage = useCallback(() => {
    const { essentialUnconnectedConnectors, hasMissingKeywords } = setupDetails
    if (!essentialUnconnectedConnectors.length || !hasMissingKeywords) {
      return null
    }

    return (
      <SetupInfo isExpanded={isExpanded} collapse={collapse}>
        <SetupInfo.Message connectors={essentialUnconnectedConnectors} />
      </SetupInfo>
    )
  }, [setupDetails, isExpanded])

  return (
    <div
      onMouseEnter={expand}
      onMouseLeave={collapse}
      className={cn(
        "fixed flex flex-col justify-between w-16 pb-20 top-16 left-0 z-[1000] h-screen overflow-y-auto transition-all bg-basic-black border-r border-r-basic-blue",
        isExpanded && "w-70",
      )}
    >
      <ul className="flex flex-col w-full gap-3 px-5 mx-0 mt-4">
        {showSidebarProducts && (
          <>
            {sidebarItems.map(item => (
              <SidebarItem
                onBeforeNavigate={handleBeforeNavigate}
                onNavigate={handleNavigate}
                key={item.path}
                labelVisible={isExpanded}
                sessionRole={sessionRole}
                {...item}
              />
            ))}
            <Divider />
          </>
        )}
        {setupSidebarItems.map(renderSetupItem)}
      </ul>
      <div
        className={cn(
          "relative w-full px-5 space-y-5",
          isExpanded && "self-start",
        )}
      >
        <Divider />
        <SidebarItem
          label="Roadmap"
          sessionRole={sessionRole}
          Icon={MapSvg}
          labelVisible={isExpanded}
          onClick={openRoadMap}
          path=""
          availableTo={[
            SessionRole.USER,
            SessionRole.ADMIN,
            SessionRole.SUPER_ADMIN,
          ]}
        />
        <SidebarItem
          label="Feedback"
          sessionRole={sessionRole}
          Icon={BulbSvg}
          labelVisible={isExpanded}
          onClick={openFeedback}
          path=""
          availableTo={[
            SessionRole.USER,
            SessionRole.ADMIN,
            SessionRole.SUPER_ADMIN,
          ]}
        />
        <SidebarItem
          label="Changelog"
          sessionRole={sessionRole}
          Icon={RocketSvg}
          labelVisible={isExpanded}
          onClick={openChangeLog}
          path=""
          availableTo={[
            SessionRole.USER,
            SessionRole.ADMIN,
            SessionRole.SUPER_ADMIN,
          ]}
        />
      </div>
      {renderInfoMessage()}
    </div>
  )
}

export default Sidebar
