import React, { useMemo } from "react"
import { CamelSvg } from "~/ui-rtk/components/ui/svg/business"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type CamelProgressBarProps = {
  total: number
  completed: number
}

const CamelProgressBar: React.FC<CamelProgressBarProps> = ({
  total,
  completed,
}) => {
  const progress = useMemo(
    () => (total > 0 ? (completed / total) * 100 : 0),
    [total, completed],
  )

  const camelSvgClassNames = cn("relative transition-all", {
    "-translate-x-[70%]": progress > 0,
  })

  return (
    <div className="w-full flex items-center justify-center px-4 border border-white/[0.12] bg-white/5 backdrop-blur-3xl rounded-2xl h-25">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="max-w-89 w-full">
          <CamelSvg
            className={camelSvgClassNames}
            style={{ left: `${progress}%` }}
            size={32}
          />
          <div className="relative mt-1 mb-2 h-2 bg-black rounded-lg">
            <div
              className="absolute top-0 left-0 h-full bg-brand rounded-lg w-full transition-all"
              style={{ width: progress > 0 ? `${progress}%` : "8px" }}
            />
          </div>
        </div>
        <p className="italic font-[500] text-center text-white">
          {completed} out of {total} connections complete
        </p>
      </div>
    </div>
  )
}

export default CamelProgressBar
