import { PaginationState } from "@tanstack/react-table"
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Select } from "~/ui-rtk/components/ui/controls"
import {
  ChevronLeftSvg,
  ChevronRightSvg,
} from "~/ui-rtk/components/ui/svg/arrow"
import { PAGE_OPTIONS } from "~/ui-rtk/components/ui/charts/TableVisualization/constants"
import { debounce } from "lodash"

type TTablePaginationProps = {
  state: PaginationState
  total: number
  onNext: () => void
  onPrevious: () => void
  onChangeState: (state: Partial<PaginationState>) => void
}

export const Pagination: React.FC<TTablePaginationProps> = ({
  state,
  total,
  onNext,
  onPrevious,
  onChangeState,
}) => {
  const [currentPage, setCurrentPage] = useState(`${state.pageIndex + 1}`)
  const [inputWidth, setInputWidth] = useState(0)

  const spanRef = useRef<HTMLSpanElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChangeDebounced = useCallback(
    debounce((value: string) => {
      const numericValue = +value
      if (numericValue > total || value === "") {
        return
      }

      const pageIndex = numericValue === 0 ? 0 : numericValue - 1
      void onChangeState({ pageIndex })
    }, 500),
    [],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (/^\d*$/.test(value) === false && value !== "") {
        return
      }

      void setCurrentPage(value)
      void handleChangeDebounced(value)
    },
    [total],
  )

  const handleBlur = useCallback(() => {
    if (currentPage === "" || +currentPage <= 0 || +currentPage > total) {
      void setCurrentPage(`${state.pageIndex + 1}`)
      void onChangeState({ pageIndex: state.pageIndex })
    }
  }, [currentPage, state.pageIndex, total])

  const handleNext = useCallback(() => {
    if (+currentPage < total) {
      void setCurrentPage(prev => `${+prev + 1}`)
      void onNext()
    }
  }, [currentPage, total])

  const handlePrevious = useCallback(() => {
    if (+currentPage > 1) {
      void setCurrentPage(prev => `${+prev - 1}`)
      void onPrevious()
    }
  }, [currentPage])

  const handleCountPerPage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value
      if (/^\d*$/.test(value)) {
        const countPerPage = +value
        const newState: Partial<PaginationState> = { pageSize: countPerPage }

        if (countPerPage * +currentPage > total) {
          const newIndex = Math.floor(total / +countPerPage)
          newState.pageIndex = newIndex
          void setCurrentPage(`${newIndex + 1}`)
        }

        void onChangeState(newState)
      }
    },
    [currentPage, total],
  )

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current?.offsetWidth)
    }
  }, [currentPage])

  useEffect(() => {
    if (state.pageIndex !== +currentPage - 1) {
      void setCurrentPage(`${state.pageIndex + 1}`)
    }
  }, [state.pageIndex])

  const inputStyles = useMemo(() => ({ width: inputWidth }), [inputWidth])

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <ChevronLeftSvg
          size={18}
          className="-mb-0.5 cursor-pointer"
          onClick={handlePrevious}
        />
        <div className="flex items-center gap-2.5">
          <p>Page</p>
          <div
            className="relative cursor-text inline-block border rounded-md border-basic-blue px-2.5 py-1.5"
            onClick={() => inputRef.current?.focus()}
          >
            <input
              ref={inputRef}
              value={currentPage}
              onBlur={handleBlur}
              onChange={handleChange}
              style={inputStyles}
              className="ring-0 focus:ring-0"
            />
            <span
              ref={spanRef}
              className="absolute invisible whitespace-nowrap "
            >
              {currentPage}
            </span>
          </div>
          <p>of {total}</p>
        </div>
        <ChevronRightSvg
          size={18}
          className="cursor-pointer -mb-0.5"
          onClick={handleNext}
        />
      </div>
      <div className="flex items-center gap-2.5">
        <p>Show</p>
        <Select
          defaultValue={state.pageSize}
          options={PAGE_OPTIONS}
          onChange={handleCountPerPage}
        />
      </div>
    </div>
  )
}
