import { createSlice } from "@reduxjs/toolkit"
import { revertAll } from "../revertAll"

const initialState = {
  isLoading: false,
}

export const loadingSlice = createSlice({
  name: "loadingSlice",
  initialState,
  extraReducers: builder => builder.addCase(revertAll, () => initialState),
  reducers: {
    setIsLoading(state, { payload }: { payload: boolean }) {
      state.isLoading = payload
    },
  },
})

export const { setIsLoading } = loadingSlice.actions
