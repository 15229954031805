import { useEffect } from "react"
import { Formik, Form, ErrorMessage, Field } from "formik"

import { H3, P } from "~/ui-rtk/components/ui/typography/"
import { Divider } from "~/ui-rtk/components/ui/common"
import { Input, Button, Select } from "~/ui-rtk/components/ui/controls"

import useConnect, { type UpdateProfileFormValues } from "./connect"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { dataSourceOptions } from "./constants"
import { SendArrowSvg } from "~/ui-rtk/components/ui/svg/arrow"

export default function SettingsProfilePage() {
  const {
    isPortalSessionLoading,
    isSubscriptionValid,
    createCustomerPortal,
    updateProfile,
    initialValues,
    validationSchema,
    showDataSourceInfo,
  } = useConnect()
  const { isLoading, setIsLoading } = useLoadingState()

  useEffect(() => {
    document.title = "Marathon Data: Settings > Profile"
  }, [])

  const handleUpdateProfile = async (values: UpdateProfileFormValues) => {
    setIsLoading(true)
    await updateProfile(values).catch(() => null)
    setIsLoading(false)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdateProfile}
        validationSchema={validationSchema}
      >
        <Form className="py-6">
          <H3>General</H3>
          <P className="my-3 text-platinum">
            Basic info, like your name and adress that will be displayed in
            public
          </P>
          <div className="py-5">
            <div className="inline-block w-48 ">Name</div>
            <div className="inline-block">
              <Field type="text" id="name" name="name" as={Input} />
              <ErrorMessage
                component="a"
                name="name"
                className="absolute input-error-message"
              />
            </div>
          </div>
          <Divider className="my-3" />
          <div className="py-5">
            <div className="inline-block w-48 ">E-mail</div>
            <div className="inline-block">
              <Field type="email" id="email" name="email" as={Input} />
              <ErrorMessage
                component="a"
                name="email"
                className="absolute input-error-message"
              />
            </div>
          </div>
          <Divider className="mt-3 mb-5" />
          {showDataSourceInfo && (
            <>
              <div className="py-5">
                <div className="inline-block w-48 ">Data Source</div>
                <div className="inline-block w-50">
                  <Field
                    type="text"
                    id="dataSource"
                    name="dataSource"
                    as={Select}
                    options={dataSourceOptions}
                  />
                  <ErrorMessage
                    component="a"
                    name="email"
                    className="absolute input-error-message"
                  />
                </div>
              </div>
              <Divider className="mt-3 mb-5" />
            </>
          )}
          <div className="flex items-center gap-2">
            <Button size="sm" type="submit" isLoading={isLoading}>
              Update Profile
            </Button>
            {isSubscriptionValid && (
              <Button
                type="button"
                size="sm"
                onClick={createCustomerPortal}
                isLoading={isPortalSessionLoading}
              >
                Manage Subscription
                <SendArrowSvg fill="black" className="-scale-x-100" />
              </Button>
            )}
          </div>
        </Form>
      </Formik>
    </>
  )
}
