import { ForgotPasswordForm } from "~/ui-rtk/components/features/forms"
import { GuestLayout } from "~/ui-rtk/components/layout"
import { useForgotPasswordContext } from "./"
import CheckInbox from "./CheckInbox"
import { ContentBox } from "~/ui-rtk/components/ui/common"

export const ForgotPasswordComp = () => {
  const { email, isEmailSentView } = useForgotPasswordContext()

  const title = isEmailSentView ? "Check your inbox" : "Reset password"
  const renderSubTitle = () => {
    if (isEmailSentView) {
      return (
        <p className="text-center max-w-100">
          Please check your inbox at{" "}
          <span className="font-bold text-basic-pink">{email}</span> <br /> and
          click the link to complete your password reseting
        </p>
      )
    }

    return (
      <>
        Enter your email address to get a link to reset <br /> your password
      </>
    )
  }

  return (
    <GuestLayout>
      <ContentBox title={title} subTitle={renderSubTitle()}>
        {!isEmailSentView ? (
          <ForgotPasswordForm />
        ) : (
          <CheckInbox email={email} />
        )}
      </ContentBox>
    </GuestLayout>
  )
}
