import { useCallback, useMemo, useState } from "react"
import * as yup from "yup"
import type { FormikHelpers } from "formik"

import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import { isFetchBaseQueryError } from "~/ui-rtk/utils/guards"
import {
  LocalStorageKeys,
  getLocalStorageValue,
  setLocalStorageValue,
} from "~/ui-rtk/utils/storage"
import { useAuthControllerLoginMutation } from "~/ui-rtk/api/authApi"

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Oops! That doesn’t look like a valid email")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
  remember: yup.boolean(),
})

type FormValues = {
  email: string
  password: string
  remember: boolean
}

export default function useConnect() {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const rememberedEmail = useMemo(
    () => getLocalStorageValue<string | null>(LocalStorageKeys.REMEMBER_ME),
    [],
  )

  const [login, result] = useAuthControllerLoginMutation()
  const navigate = useStableNavigate()

  const initialValues = useMemo(
    () => ({
      email: rememberedEmail || "",
      password: "",
      remember: !!rememberedEmail,
    }),
    [rememberedEmail],
  )
  const handleLogin = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      void setIsSubmitting(true)
      try {
        await login({
          loginCredentialsDto: {
            email: values.email,
            password: values.password,
          },
        }).unwrap()
        setLocalStorageValue(
          LocalStorageKeys.REMEMBER_ME,
          values.remember ? values.email : null,
        )
        navigate("/")
      } catch (error) {
        if (isFetchBaseQueryError(error) && error.status === 401) {
          setErrors({
            email: "Invalid credentials",
            password: "Invalid credentials",
          })
        }
      } finally {
        void setIsSubmitting(false)
      }
    },
    [login, navigate],
  )

  return {
    defaultEmail: rememberedEmail,
    handleLogin,
    loginResult: result,
    initialValues,
    validationSchema,
    isSubmitting,
  }
}
