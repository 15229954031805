import React from "react"

import { ConnectorDialog } from "~/ui-rtk/components/features/connectors"

import type { CustomConnectorDialogFormProps } from "../types"
import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"
import { Button } from "~/ui-rtk/components/ui/controls"

type InstagramInsightsFormProps = CustomConnectorDialogFormProps

const InstagramInsightsForm: React.FC<InstagramInsightsFormProps> = ({
  uri,
  isOpen,
  onClose,
}) => {
  const handleConnect = () => {
    if (uri) {
      window.location.href = uri
    } else {
      throw new Error("URI is not provided")
    }
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.instagramInsights}
      alt="Instagram Insights Logo"
      title="Connection to Instagram Insights"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className="leading-5 text-left text-base text-platinum mb-2">
        In order to proceed with this connection, you need to fulfill the
        following requirements:
        <ul className="mt-2 ml-10 list-disc">
          <li>
            You must have a business or creator account on Instagram (
            <a
              href="https://business.instagram.com/getting-started"
              target="_blank"
              className="text-blue"
            >
              see more
            </a>
            )
          </li>
          <li>
            You must have a Facebook page (
            <a
              href="https://www.facebook.com/help/104002523024878/"
              target="_blank"
              className="text-blue"
            >
              see more
            </a>
            )
          </li>
          <li>
            You must link the Facebook page to the Instagram account (
            <a
              href="https://www.facebook.com/help/instagram/402748553849926/"
              target="_blank"
              className="text-blue"
            >
              see more
            </a>
            )
          </li>
        </ul>
      </p>

      <Button
        variant={{ variant: "solid", color: "white" }}
        onClick={handleConnect}
        className="w-full"
      >
        Connect
      </Button>
    </ConnectorDialog>
  )
}

export default InstagramInsightsForm
