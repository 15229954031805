import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { SessionDto, DefaultDto } from "~/ui-rtk/api/types"
import {
  SwitchCompanyDto,
  RequestResetPasswordDto,
  ResetPasswordDto,
  ResetPasswordLoggedDto,
  UpdateProfileDto,
  LoginCredentialsDto,
} from "~/ui-rtk/api/authApi"

import { userActions } from "./user.slice"
import { companyActions } from "./company.slice"
import { revertAll } from "../revertAll"
import { clearMetricsCache } from "~/ui-rtk/hooks/metrics-cache"

export const apiSlice = createApi({
  reducerPath: "apiToRemove",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL || "/",
    credentials: "include",
  }),
  tagTypes: ["Auth"],
  endpoints: build => ({
    login: build.mutation<SessionDto, LoginCredentialsDto>({
      query: body => ({
        url: "auth/login",
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error) => (error ? [] : ["Auth"]),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(apiSlice.util.resetApiState())
        } catch {
          // noop
        }
      },
    }),
    getCurrentUser: build.query<SessionDto, void>({
      query: () => ({
        url: "auth/current-user",
        method: "GET",
      }),
      providesTags: ["Auth"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          const session: SessionDto = response.data
          dispatch(revertAll())
          dispatch(userActions.setUserSession(session))
          dispatch(companyActions.setCurrentCompany(session.currentCompany))
          clearMetricsCache()
        } catch {
          //
        }
      },
    }),
    switchCompany: build.mutation<SessionDto, SwitchCompanyDto>({
      query: body => ({
        url: "auth/switch-company",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Auth"],
    }),
    requestResetPassword: build.mutation<DefaultDto, RequestResetPasswordDto>({
      query: body => ({
        url: "auth/request-reset-password",
        method: "POST",
        body,
      }),
    }),
    resetPassword: build.mutation<DefaultDto, ResetPasswordDto>({
      query: body => ({
        url: "auth/validate-token",
        method: "PUT",
        body,
      }),
    }),
    changePassword: build.mutation<DefaultDto, ResetPasswordLoggedDto>({
      query: body => ({
        url: "auth/change-password",
        method: "PUT",
        body,
      }),
    }),
    updateProfile: build.mutation<SessionDto, UpdateProfileDto>({
      query: body => ({
        url: "auth/profile",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetCurrentUserQuery,
  useSwitchCompanyMutation,
  useRequestResetPasswordMutation,
  useUpdateProfileMutation,
  useChangePasswordMutation,
} = apiSlice
