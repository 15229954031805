import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ChevronLeftSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0791 3.54975C16.372 3.84264 16.372 4.31752 16.0791 4.61041L9.55906 11.1304C9.08196 11.6075 9.08196 12.3926 9.55906 12.8697L16.0791 19.3897C16.372 19.6826 16.372 20.1575 16.0791 20.4504C15.7862 20.7433 15.3113 20.7433 15.0184 20.4504L8.4984 13.9304C7.43551 12.8675 7.43551 11.1326 8.4984 10.0697L15.0184 3.54975C15.3113 3.25685 15.7862 3.25685 16.0791 3.54975Z"
      fill={fill}
    />
  </svg>
))
