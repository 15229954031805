import React, { ReactNode } from "react"

import { Loader, Tooltip } from "~/ui-rtk/components/ui/common"
import { ExclamationSvg } from "~/ui-rtk/components/ui/svg/essentials"

import type { NavItem } from "~/ui-rtk/components/layout/Sidebar/types"
import type { TConnectorSetupDetails } from "~/ui-rtk/components/layout/Sidebar/hooks"

type TKeywordsSetupLabelProps = {
  item: NavItem
  isLoading: boolean
  setupDetails: TConnectorSetupDetails
}

const KeywordsSetupLabel: React.FC<TKeywordsSetupLabelProps> = ({
  item,
  setupDetails,
  isLoading = false,
}) => {
  let icon: ReactNode | null
  if (setupDetails.hasMissingKeywords) {
    icon = (
      <Tooltip
        text="All keywords needs to be filled!"
        type="error"
        classes={{ container: "fixed" }}
      >
        <button className="cursor-pointer">
          <ExclamationSvg
            size={18}
            fill="error"
            className="-mt-1 animate-fadeIn"
          />
        </button>
      </Tooltip>
    )
  }

  return (
    <>
      {item.label}
      {!isLoading ? icon : <Loader />}
    </>
  )
}

export default KeywordsSetupLabel
