import {
  AggregationType,
  BrandMediaAggregationType,
} from "~/ui-rtk/constants/charts"
import Card from "~/ui-rtk/components/ui/common/Card"
import { H3, P } from "../../typography"
import pluralize from "pluralize"
import { TooltipProps } from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import { formatNumber } from "~/ui-rtk/utils/format"
import { getLightColor } from "~/ui-rtk/utils/chart-utils"
import { useMemo } from "react"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

type TChartTooltipContentProps = TooltipProps<ValueType, NameType> & {
  title?: string
  unit?: string
  dataAggType?: AggregationType | BrandMediaAggregationType
  numberType?: "default" | "currency"
  pluralizeUnit: boolean
  compareMode?: boolean
  isCompareMetricMain?: boolean
}

const AGG_TYPE_DATE_FORMAT = {
  [AggregationType.DAILY]: "ddd, LL",
  [AggregationType.WEEKLY]: "LL ([Week] W)",
  [AggregationType.MONTHLY]: "MMM YYYY",
}

export default function ChartTooltipContent({
  title,
  unit,
  dataAggType = AggregationType.DAILY,
  numberType,
  pluralizeUnit,
  active,
  label,
  payload,
  compareMode = false,
  isCompareMetricMain = false,
}: TChartTooltipContentProps) {
  if (!active || !payload || !payload.length) {
    return null
  }

  const textColor = payload[0].color
    ? getLightColor(payload[0].color)
    : payload[0].color

  let percentChange
  const [value, compareValue] =
    compareMode && isCompareMetricMain
      ? [payload[0].payload.comparedValue, payload[0].value]
      : [payload[0].value, payload[0].payload.comparedValue]

  const [date, compareDate] =
    compareMode && isCompareMetricMain
      ? [payload[0].payload.compareDate, payload[0].payload.date]
      : [payload[0].payload.date, payload[0].payload.compareDate]

  const absDelta = (value ?? 0) - compareValue
  if ((!value && !compareValue) || !compareMode) {
    percentChange = null
  } else if (compareValue) {
    percentChange = (absDelta / Math.abs(compareValue)) * 100
  } else {
    percentChange = absDelta > 0 ? 100 : -100
  }
  const colorClassName =
    percentChange != null && percentChange < 0
      ? "text-error"
      : "text-green-bright"

  const pStyle = useMemo(
    () => ({
      color: textColor,
    }),
    [textColor],
  )

  const content = compareMode ? (
    <>
      <P>
        {dayjs(date).format(
          AGG_TYPE_DATE_FORMAT[dataAggType as AggregationType],
        )}
        &nbsp;&nbsp;&nbsp;
      </P>
      <P>
        Compare to:{" "}
        {dayjs(compareDate).format(
          AGG_TYPE_DATE_FORMAT[dataAggType as AggregationType],
        )}
        &nbsp;&nbsp;&nbsp;
      </P>
      <table className="table table-zebra">
        <thead>
          <tr>
            <th className="p-0.5">Actual</th>
            <th className="p-0.5">Compare</th>
            <th className="p-0.5">Delta</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-sm">
            <td className="p-0.5">
              {formatNumber(Number(value), {
                compact: numberType !== "currency",
                currency: numberType === "currency",
                decimals: 2,
              })}
            </td>
            <td className="p-0.5">
              {formatNumber(Number(compareValue), {
                compact: numberType !== "currency",
                currency: numberType === "currency",
                decimals: 2,
              })}
            </td>
            <td className={[colorClassName, "p-0.5"].filter(c => c).join(" ")}>
              {percentChange !== null
                ? `${percentChange > 0 ? "+" : ""}${formatNumber(Number(percentChange), { decimals: 1 })}%`
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <>
      <P style={pStyle}>
        {dayjs(label).format(
          AGG_TYPE_DATE_FORMAT[dataAggType as AggregationType],
        )}
        &nbsp;&nbsp;&nbsp;
      </P>
      <P style={pStyle}>
        {formatNumber(Number(payload[0].value), {
          compact: true,
          currency: numberType === "currency",
          decimals: 2,
        })}
        {unit
          ? ` ${pluralizeUnit ? pluralize(unit, Number(payload[0].value)) : unit}`
          : null}
      </P>
      {percentChange != null ? (
        <P className={`font-bold ${colorClassName}`}>
          ({formatNumber(Number(percentChange), { decimals: 1 })}%)
        </P>
      ) : null}
    </>
  )

  return (
    <Card rounded className="bg-basic-black border-2 border-basic-blue">
      <H3>{title}</H3>
      {content}
    </Card>
  )
}
