import { useCallback } from "react"
import * as yup from "yup"
import YupPassword from "yup-password"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"
import { useAuthControllerResetPasswordMutation } from "~/ui-rtk/api/authApi"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { isUnauthorizedError } from "~/ui-rtk/utils/http-utils"

YupPassword(yup)

export type InviteUserFormValues = {
  password: string
  confirmPassword: string
}

const initialValues: InviteUserFormValues = {
  password: "",
  confirmPassword: "",
}

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required!")
    .min(8, "Must be at least 8 characters long")
    .minLowercase(1, "Must contain a lowercase letter (a-z)")
    .minUppercase(1, "Must contain an uppercase letter (A-Z)")
    .minNumbers(1, "Must contain a number")
    .minSymbols(1, "Must contain a symbol (e.g. @, $, !, %, *, ?)"),
  confirmPassword: yup
    .string()
    .required("Must match")
    .oneOf([yup.ref("password"), ""], "Must match"),
})

export const useConnect = (token: string) => {
  const navigate = useStableNavigate()
  const [resetPasswordAsync, { isLoading }] =
    useAuthControllerResetPasswordMutation()

  const redirect = () => {
    setTimeout(() => {
      void navigate(brandRoutes.SIGN_IN)
    }, 1000)
  }

  const submit = useCallback(
    async (values: InviteUserFormValues) => {
      try {
        await resetPasswordAsync({
          resetPasswordDto: {
            token,
            newPassword: values.password,
          },
        }).unwrap()
        toast.success(
          "Your password has been successfully created! Redirecting...",
        )
        redirect()
      } catch (error: any) {
        console.error(error)
        if (isUnauthorizedError(error)) {
          navigate("/")
          return
        }
        toast.error("Could not create password", {
          toastId: "create-password-error",
        })
      }
    },
    [token],
  )

  return { initialValues, validationSchema, createPassword: submit, isLoading }
}
