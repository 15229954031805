const textFilterTypes = new Set(["title", "text"])
const rangeFilterTypes = new Set(["number", "currency", "percentage", "count"])
const allowedFilterTypes = new Set([
  ...Array.from(textFilterTypes),
  ...Array.from(rangeFilterTypes),
])

export const isFilteringAllowed = (type?: string) => {
  if (!type) {
    return false
  }

  return allowedFilterTypes.has(type)
}

export const isTextFilter = (type?: string) => {
  if (!type) {
    return false
  }

  return textFilterTypes.has(type)
}

export const isRangeFilter = (type?: string) => {
  if (!type) {
    return false
  }

  return rangeFilterTypes.has(type)
}
