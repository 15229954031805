import { Popover as HPopover } from "@headlessui/react"
import React, { ReactNode, useState, type FC } from "react"
import { usePopper, PopperChildrenProps } from "react-popper"

type TPopoverProps = {
  relative?: boolean
  align?: "start" | "center" | "end"
  handler: () => ReactNode
  children: (close: () => void) => React.ReactElement
}

export default function Popover({
  children,
  relative = false,
  handler,
  align = "start",
}: TPopoverProps): ReturnType<FC<TPopoverProps>> {
  const placement = `bottom-${align}` as PopperChildrenProps["placement"]
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: "fixed",
  })

  const cx = []
  if (relative) {
    cx.push("relative")
  } else {
    cx.push("absolute")
  }
  return (
    <HPopover className={cx.join(" ").trim()}>
      <HPopover.Button
        ref={ref => setReferenceElement(ref)}
        className="!outline-none"
        as="div"
      >
        {handler()}
      </HPopover.Button>

      <HPopover.Panel
        className="z-50 bg-transparent text-gray-light"
        ref={ref => setPopperElement(ref)}
        style={styles.popper}
        {...attributes.popper}
      >
        {({ close }) => children(close)}
      </HPopover.Panel>
    </HPopover>
  )
}
