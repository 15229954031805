export function safeJSONParse<T = unknown, K = null>(
  text: string,
  fallback: K = null as K,
) {
  try {
    return JSON.parse(text) as T
  } catch {
    return fallback
  }
}
