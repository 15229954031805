import { Form, Formik } from "formik"

import { useConnect } from "./connect"
import { Button, FormField } from "~/ui-rtk/components/ui/controls"

const AccountDetailsForm = () => {
  const {
    isSubmitting,
    initialValues,
    validationSchema,
    updateAccountDetails,
  } = useConnect()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateAccountDetails}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="flex flex-col items-center w-full space-y-8">
          <div className="flex flex-col items-center w-full gap-5.5">
            <FormField
              id="companyName"
              type="text"
              placeholder="Company name"
              isError={Boolean(touched.companyName && errors.companyName)}
            />
            <FormField
              id="domain"
              type="text"
              placeholder="Company Domain"
              isError={Boolean(touched.domain && errors.domain)}
            />
          </div>
          <Button
            type="submit"
            size="lg"
            className="sm:max-w-48 w-full"
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default AccountDetailsForm
