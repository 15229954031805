import { H3, SubTitle } from "../../typography"
import InfoDialog from "../InfoDialog"

type TMoneyWarnDialogProps = {
  onClose?: () => void
}

export default function MoneyWarnDailog({
  onClose = () => null,
}: TMoneyWarnDialogProps) {
  return (
    <InfoDialog width={600} isOpen hideTitle handleClose={onClose}>
      <div className="px-3">
        <H3>Note RE Statistical Models</H3>
        <SubTitle>Report a funky number!</SubTitle>
        <div className="py-3 text-start">
          <ul className="list-disc">
            <li className="pb-2">
              Statistical model are, at the end of the day, just another tool in
              a brand&apos;s toolkit to build the absolute best company they
              can. They are an incredibly powerful tool, but that doesn&apos;t
              mean they are infallible.
            </li>
            <li className="pb-2">
              If you see a number that you think is too high, or too low, for a
              given engagement type - please let us know! We&apos;ll get back to
              you with a verdict - if your model is truly emphatic that the
              figure is correct, we&apos;ll communicate that. However, if the
              model is more vague, a lot of the time your intuition is a
              valuable insight that can help us refine the model to where it
              needs to be. At the end of the day, your intuition is similarly
              trained on a massive dataset on how your company, and potentially
              even other companies, has grown in the past relative to these
              metrics.
            </li>
            <li>
              So, please - let us know if any of these values catch you
              off-guard. Your feedback will only make this tool more valuable
              for you and your team!
            </li>
          </ul>
        </div>
      </div>
    </InfoDialog>
  )
}
