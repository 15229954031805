import { SessionRole } from "~/api/dto/auth/session-role.ts"
import { DataSource } from "@db/maindb/prisma/client"

import * as yup from "yup"

export { SessionRole as Role }

export enum DataSourceEnum {
  SNAPSHOT = "SNAPSHOT",
  LATEST = "LATEST",
}

export type UserFormValues = {
  name: string
  email: string
  role: SessionRole
  id: string
  dataSource?: DataSource
}

export const validationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required!"),
  name: yup.string().required("Name is required!"),
  role: yup
    .mixed<SessionRole>()
    .oneOf(Object.values(SessionRole))
    .required("Role is required!"),
  dataSource: yup
    .mixed<DataSource>()
    .oneOf(Object.values(DataSourceEnum))
    .optional(),
})

export function useValidation() {
  return [validationSchema]
}
