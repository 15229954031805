import {
  Dispatch,
  ElementType,
  SetStateAction,
  createElement,
  useEffect,
  useState,
} from "react"
import { TAreaChartProps } from "../AreaChart"
import { TComposedChartProps } from "../ComposedChart/ComposedChart"
import {
  AggregationType,
  TOTAL_CALCULATED_DATA_KEY,
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
  BrandMediaAggregationType,
} from "~/ui-rtk/constants/charts"
import useMetricsCache from "~/ui-rtk/hooks/metrics-cache"
import ChartDataLoader from "../ChartDataLoader"
import {
  GranularityMapping,
  MetricsMapping,
  TMetricItem,
} from "~/ui-rtk/constants/metrics-mapping"
import { TableColumn, TimeDimensionGranularity } from "@cubejs-client/core"
import { getColorForMetric } from "~/ui-rtk/utils/chart-utils"
import useHighlightMetric from "~/ui-rtk/hooks/highlight-metric"
import { isNumeric } from "~/ui-rtk/utils/format"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { PaginationState, SortingState } from "@tanstack/react-table"
import { ITEMS_PER_PAGE } from "../TableVisualization/constants"
import { LocalStorageKeys, useLocalStorage } from "~/ui-rtk/utils/storage"
import { composeMetrics } from "../ComposedTable/utils"
import { TColumnFiltersState } from "../TableVisualization/types"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import { TDrawerConfig } from "~/ui-rtk/constants/drawer"

export type TChartProps = {
  component: ElementType
  chartId: string
  dateRange: [Date, Date]
  widgetType: VISUALIZATION_TYPE
  setDataAggType?: (type: AggregationType) => void
  granularity: AggregationType | BrandMediaAggregationType
  compareRange?: [Date, Date]
  className?: string
  highlightMetric?: string
  highlightMetricPrefix?: string
  manualTotal?: boolean
  enablePagination?: boolean
  cubeFilters?: TCubeFilterOptions
  sortBy?: string
  sortDesc?: boolean
  limit?: number
  disableGranularity?: boolean
  cubeQueryParams?: Record<string, any>
  comparisonEnabled?: boolean
  compareUniqueKey?: string
  description?: string
  columnFilters?: TColumnFiltersState
  setColumnFilters?: Dispatch<SetStateAction<TColumnFiltersState>>
  manualSort?: boolean
  prefixEnabled?: boolean
  itemComponent?: ElementType
  openDrawer?: (config: Partial<TDrawerConfig>) => void
  useLocalStoragePagination?: boolean
  sorting?: SortingState
  setSorting?: Dispatch<SetStateAction<SortingState>>
} & (Omit<TAreaChartProps, "metrics"> | Omit<TComposedChartProps, "metrics">)

export default function Chart($props: TChartProps) {
  const {
    component,
    dateRange,
    compareRange,
    chartId,
    widgetType,
    granularity,
    dataAggTypeOnly,
    highlightMetric,
    highlightMetricPrefix,
    enablePagination = false,
    cubeFilters,
    sortBy,
    sortDesc = true,
    limit,
    disableGranularity = false,
    cubeQueryParams,
    comparisonEnabled = false,
    compareUniqueKey = "id",
    setDataAggType = () => null,
    columnFilters,
    setColumnFilters,
    manualSort = false,
    useLocalStoragePagination = true,
    prefixEnabled = false,
    sorting: sortingFromProps,
    setSorting: setSortingFromProps,
    ...props
  } = $props
  const [, onCacheSet] = useState()

  const [itemsPerPage, setItemsPerPage] = useLocalStorage<number>(
    LocalStorageKeys.TABLE_VISUALIZATION_ITEMS_PER_PAGE,
    ITEMS_PER_PAGE,
  )
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: useLocalStoragePagination ? itemsPerPage : ITEMS_PER_PAGE,
  })

  const propsSorting = sortBy
    ? [
        {
          id: sortBy,
          desc: sortDesc,
        },
      ]
    : []

  const [sortingFromState, setSortingFromState] =
    useState<SortingState>(propsSorting)

  const sorting = sortingFromProps ?? sortingFromState
  const setSorting = setSortingFromProps ?? setSortingFromState

  const paginationStatePerPage = paginationState.pageSize

  const { isInProgress, data, setCacheItem } = useMetricsCache(
    chartId,
    dataAggTypeOnly ?? granularity,
    dateRange,
    onCacheSet,
    paginationState.pageIndex,
    limit ?? paginationState.pageSize,
    cubeFilters,
    manualSort ? propsSorting : sorting,
  )

  const {
    isInProgress: compareIsInProgress,
    data: compareData,
    setCacheItem: compareSetCacheItem,
  } = useMetricsCache(
    chartId,
    dataAggTypeOnly ?? granularity,
    compareRange as unknown as [Date, Date], // TypeCheck fallback.
    onCacheSet,
    paginationState.pageIndex,
    limit ?? paginationState.pageSize,
    cubeFilters,
    manualSort ? propsSorting : sorting,
  )
  const currentCompany = useAppSelector(selectCurrentCompany)

  const highlightKey = useHighlightMetric(
    highlightMetric,
    highlightMetricPrefix,
  )

  const granularityValue = GranularityMapping[
    dataAggTypeOnly ?? granularity
  ] as TimeDimensionGranularity

  if (!MetricsMapping?.[chartId]) {
    console.error(MetricsMapping, chartId)
  }
  const { filter } = MetricsMapping[chartId]

  const inProgressContent = []

  const cacheSet = !isInProgress()

  if (!cacheSet) {
    if (filter) {
      filter([], granularityValue, {
        currentCompany,
      })
    }
    inProgressContent.push(
      <ChartDataLoader
        key="main-chart"
        className={props.className}
        chartId={chartId}
        dateRange={dateRange}
        widgetType={widgetType}
        granularity={dataAggTypeOnly ?? granularity}
        page={paginationState.pageIndex}
        itemsPerPage={limit ?? paginationState.pageSize}
        setCacheItem={setCacheItem}
        cubeFilters={cubeFilters}
        prefixEnabled={prefixEnabled}
        sorting={manualSort ? propsSorting : sorting}
        enablePagination={enablePagination}
        disableGranularity={disableGranularity}
        cubeQueryParams={cubeQueryParams}
      />,
    )
  }

  const compareCacheSet = !compareIsInProgress()

  if (comparisonEnabled && !compareCacheSet && compareRange) {
    if (filter) {
      filter([], granularityValue, {
        currentCompany,
      })
    }
    inProgressContent.push(
      <ChartDataLoader
        key="compare-chart"
        className={props.className}
        chartId={chartId}
        widgetType={widgetType}
        dateRange={compareRange}
        granularity={dataAggTypeOnly ?? granularity}
        page={limit ?? paginationState.pageIndex}
        itemsPerPage={paginationState.pageSize}
        setCacheItem={compareSetCacheItem}
        cubeFilters={cubeFilters}
        sorting={manualSort ? propsSorting : sorting}
        prefixEnabled={prefixEnabled}
        enablePagination={enablePagination}
        disableGranularity={disableGranularity}
        cubeQueryParams={cubeQueryParams}
      />,
    )
  }

  useEffect(() => {
    if (paginationStatePerPage !== itemsPerPage && useLocalStoragePagination) {
      setItemsPerPage(paginationStatePerPage)
    }
  }, [
    paginationStatePerPage,
    itemsPerPage,
    setItemsPerPage,
    useLocalStoragePagination,
  ])

  if (inProgressContent.length > 0) {
    return inProgressContent[0]
  }

  const { columns, dataSource, totalPages } = data
  const { dataSource: compareDataSource } = compareData ?? {}

  const [dateColumn, ...dataColumns] = columns
  let isCompareMetricMain = false

  let metrics
  let color
  switch (widgetType) {
    case VISUALIZATION_TYPE.AreaChart: {
      if (comparisonEnabled && compareRange) {
        isCompareMetricMain = dataSource.length <= compareDataSource.length
        const dataSources = isCompareMetricMain
          ? [compareDataSource, dataSource]
          : [dataSource, compareDataSource]
        const [mainDataSource, secondaryDataSource] = dataSources

        metrics = (mainDataSource ?? []).map(
          (metrics: TMetricItem, idx: number) => ({
            value: metrics[dataColumns[0]?.key],
            comparedValue: secondaryDataSource[idx]
              ? secondaryDataSource[idx][dataColumns[0]?.key]
              : 0,
            date: metrics.date,
            compareDate: secondaryDataSource[idx]?.date ?? null,
            metadata: dataColumns[0],
          }),
        )
      } else {
        metrics = (dataSource ?? []).map((metrics: TMetricItem) => ({
          value: metrics[dataColumns[0]?.key],
          date: metrics.date,
          metadata: dataColumns[0],
        }))
      }
      color = getColorForMetric(dataColumns[0]?.key)
      break
    }
    case VISUALIZATION_TYPE.ComposedChart:
    case VISUALIZATION_TYPE.MultiChart: {
      if (comparisonEnabled && compareRange) {
        isCompareMetricMain = dataSource.length <= compareDataSource.length

        const dataSources = isCompareMetricMain
          ? [compareDataSource, dataSource]
          : [dataSource, compareDataSource]
        const [mainDataSource, secondaryDataSource] = dataSources

        metrics = (mainDataSource ?? []).map(
          (metrics: TMetricItem, idx: number) => {
            const comparedDate = secondaryDataSource[idx]
              ? secondaryDataSource[idx][dateColumn.dataIndex]
              : null
            const metric: Record<string, number | string | boolean> = {
              date: metrics[dateColumn.key],
              [`compared.date`]: comparedDate,
              [`compared.${dateColumn.key}`]: comparedDate,
              ...metrics,
            }
            if (
              secondaryDataSource[idx] &&
              secondaryDataSource[idx][TOTAL_CALCULATED_DATA_KEY]
            ) {
              metric[`compared.${TOTAL_CALCULATED_DATA_KEY}`] =
                secondaryDataSource[idx][TOTAL_CALCULATED_DATA_KEY]
            }
            dataColumns.forEach((dataColumn: TableColumn) => {
              const nullishValue = dataColumn.type === "number" ? 0 : ""
              metric[dataColumn.dataIndex] =
                metrics[dataColumn.dataIndex] ?? nullishValue
              metric[`compared.${dataColumn.dataIndex}`] = secondaryDataSource[
                idx
              ]
                ? secondaryDataSource[idx][dataColumn.dataIndex]
                : nullishValue
            })

            if ($props.manualTotal && !metric[TOTAL_CALCULATED_DATA_KEY]) {
              const total = dataColumns.reduce(
                (acc: number, { key }: { key: string }) => {
                  if (isNumeric(mainDataSource[idx][key])) {
                    return acc + parseFloat(`${mainDataSource[idx][key]}`)
                  }
                  return acc
                },
                [0, 0],
              )
              const compareTotal = dataColumns.reduce(
                (acc: number, { key }: { key: string }) => {
                  if (isNumeric(secondaryDataSource[idx][key])) {
                    return acc + parseFloat(`${secondaryDataSource[idx][key]}`)
                  }
                  return acc
                },
                0,
              )
              metric[TOTAL_CALCULATED_DATA_KEY] = total
              metric[`compared.${TOTAL_CALCULATED_DATA_KEY}`] = compareTotal
            }
            return metric
          },
        )
      } else {
        metrics = (dataSource ?? []).map((metrics: TMetricItem) => {
          const metric: Record<string, number | string | boolean> = {
            date: metrics[dateColumn.key],
            ...metrics,
          }
          if ($props.manualTotal && !metric[TOTAL_CALCULATED_DATA_KEY]) {
            const total = dataColumns.reduce(
              (acc: number, { key }: { key: string }) => {
                if (isNumeric(metric[key])) {
                  return acc + parseFloat(`${metric[key]}`)
                }
                return acc
              },
              0,
            )
            metric[TOTAL_CALCULATED_DATA_KEY] = total
          }
          return metric
        })
      }
      break
    }
    case VISUALIZATION_TYPE.CohortVisualization:
    case VISUALIZATION_TYPE.TableVisualization:
    case VISUALIZATION_TYPE.SortedItems: {
      if (comparisonEnabled && compareRange) {
        metrics = [dataSource, compareDataSource]
      } else {
        metrics = [...dataSource]
      }
      break
    }
    case VISUALIZATION_TYPE.ComposedTable:
      // eslint-disable-next-line no-case-declarations
      const widget = VISUALIZATIONS_MAP[chartId]
      if (comparisonEnabled && compareRange) {
        const [mainDataSource, secondaryDataSource] = [
          dataSource,
          compareDataSource,
        ]
        metrics = (mainDataSource ?? []).map(
          (metrics: TMetricItem, idx: number) => {
            const comparedDate = secondaryDataSource[idx]
              ? secondaryDataSource[idx][dateColumn.dataIndex]
              : null
            const metric: Record<string, number | string | boolean> = {
              date: metrics[dateColumn.key],
              [`compared.date`]: comparedDate,
              [`compared.${dateColumn.key}`]: comparedDate,
              ...metrics,
            }
            dataColumns.forEach((dataColumn: TableColumn) => {
              const nullishValue = dataColumn.type === "number" ? 0 : ""
              metric[dataColumn.dataIndex] =
                metrics[dataColumn.dataIndex] ?? nullishValue
              metric[`compared.${dataColumn.dataIndex}`] = secondaryDataSource[
                idx
              ]
                ? secondaryDataSource[idx][dataColumn.dataIndex]
                : nullishValue
            })
            return metric
          },
        )
        metrics = composeMetrics(metrics || [], widget.props, true)
      } else {
        metrics = composeMetrics(dataSource || [], widget.props)
      }
      break
    default: {
      return (
        <div className="flex items-center justify-center h-full">
          {widgetType} To Be Fixed
        </div>
      )
    }
  }

  if (filter) {
    metrics = filter(metrics, granularityValue, {
      currentCompany,
      compareMode: comparisonEnabled && compareRange,
    })
  }

  const chartProps = {
    ...props,
    metrics,
    color,
    chartId,
    dataAggType: granularity,
    dataAggTypeOnly,
    compareMode: Boolean(comparisonEnabled && compareRange && compareData),
    isCompareMetricMain,
    highlightKey,
    setDataAggType,
    setPaginationState,
    paginationState,
    setColumnFilters,
    setSorting,
    sorting,
    columnFilters,
    totalPages,
    sortBy,
    sortDesc,
    dateRange,
    enablePagination,
    compareUniqueKey,
    cubeFilters,
  }

  return createElement(component, chartProps)
}
