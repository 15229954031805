interface ApiError extends Error {
  status?: number
}

export function isErrorWithStatus(error: unknown): error is ApiError {
  return typeof error === "object" && error !== null && "status" in error
}

export function isUnauthorizedError(error: unknown) {
  return isErrorWithStatus(error) && error.status === 401
}

export function isBadRequestError(error: unknown) {
  return isErrorWithStatus(error) && error.status === 400
}

export function isNotFoundError(error: unknown) {
  return isErrorWithStatus(error) && error.status === 404
}

export function isInternalServerError(error: unknown) {
  return isErrorWithStatus(error) && error.status === 500
}

export function hasErrorMessage(
  error: unknown,
): error is { data: { message: string } } {
  const isObject = typeof error === "object" && error !== null
  if (!isObject) {
    return false
  }

  const hasData = "data" in error
  if (!hasData) {
    return false
  }

  const data = error.data
  const isDataObject = typeof data === "object" && data !== null
  if (!isDataObject) {
    return false
  }

  return "message" in data
}

export async function uploadFileByPreSignedUrl(
  preSignedUrl: string,
  file: File,
  options?: Omit<RequestInit, "method" | "body">,
) {
  const response = await fetch(preSignedUrl, {
    ...options,
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      ...options?.headers,
    },
    body: file,
  })

  return response.ok
}
