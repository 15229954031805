import { useAppSelector } from "../app/hooks"
import { selectCurrentCompany } from "../app/selectors/company.selector"

export default function useHighlightMetric(
  highlightMetric?: string,
  highlightMetricPrefix?: string,
) {
  const currentCompany = useAppSelector(selectCurrentCompany)

  if (!highlightMetric) {
    return null
  }

  switch (highlightMetric) {
    case "__CURRENT_COMPANY_NAME__": {
      return [highlightMetricPrefix, currentCompany?.name]
        .filter(i => !!i)
        .join(".")
    }
    default:
      return [highlightMetricPrefix, highlightMetric].filter(i => !!i).join(".")
  }
}
