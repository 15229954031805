import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import useSources from "~/ui-rtk/hooks/sources"
import { getContentWidgetIds } from "~/ui-rtk/utils/content"
import { useVisualizationsMap } from "~/ui-rtk/hooks/visualizations"

const content = {
  id: "clsaphypc000608jy5jef5svc",
  slug: "/brand-value/overview",
  icon: null,
  title: "Overview",
  parent: {
    slug: "/brand-value",
    title: "Brand Value",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 20.1,
  content: {
    items: [
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "brand_value_stacked_chart",
          },
        ],
      },
      {
        className: "min-h-120",
        grid: 1,
        items: [
          {
            widget: "brand_value_conversions_table",
          },
        ],
      },
    ],
    title: "Overview",
    periodPicker: {
      visible: true,
    },
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const widgetIds = getContentWidgetIds(
    content.content.items as TDashboardItemsSet,
  )
  const { visualizations } = useVisualizationsMap(widgetIds)

  return {
    content,
    sources,
    visualizations,
    isLoading: isSourcesDataLoading,
  }
}
