import React, { ReactNode } from "react"

import { Loader, Tooltip } from "~/ui-rtk/components/ui/common"
import { ExclamationSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { calculateConnectedPercentage, getTextColor } from "../../utils"

import type { NavItem } from "../../types"
import type { TConnectorSetupDetails } from "../../hooks"

type TConnectorsSetupLabelProps = {
  item: NavItem
  isLoading: boolean
  setupDetails: TConnectorSetupDetails
}

const ConnectorsSetupLabel: React.FC<TConnectorsSetupLabelProps> = ({
  item,
  setupDetails,
  isLoading = false,
}) => {
  const connectedPercentage = calculateConnectedPercentage(
    setupDetails.totalConnected,
    setupDetails.totalConnectors,
  )

  let icon: ReactNode | null
  if (setupDetails.requiresReconnect.length > 0) {
    icon = (
      <Tooltip text="Reconnect is required" type="error">
        <button className="cursor-pointer">
          <ExclamationSvg size={18} fill="error" className="-mt-1" />
        </button>
      </Tooltip>
    )
  }

  return (
    <>
      {item.label}
      {isLoading ? <Loader /> : null}
      {!isLoading ? (
        <>
          <span className={getTextColor(connectedPercentage)}>
            {" "}
            {connectedPercentage}%
          </span>
          {icon}
        </>
      ) : null}
    </>
  )
}

export default ConnectorsSetupLabel
