import React from "react"

import { Divider } from "~/ui-rtk/components/ui/common"
import { SourceItem } from "../SourceItem"
import { ConnectionStep, Connector } from "../../connect"

type StepsListProps = {
  currentStep: ConnectionStep | undefined

  onConnect: (service: string) => void
  onSkip: (service: string) => void
  onUndo: (service: string) => void
  onSetDialog: (connector: Connector) => void
}

const StepsList: React.FC<StepsListProps> = ({
  currentStep,
  onConnect,
  onSkip,
  onUndo,
  onSetDialog,
}) => (
  <div className="flex flex-col gap-8 mb-8">
    <Divider className="w-full border-basic-white/15" />
    <ul className="flex flex-col scrollbar w-full gap-5 overflow-visible">
      {currentStep?.connectors.map((connector: Connector) => (
        <SourceItem
          key={connector.id}
          {...connector}
          image={{
            alt: `${connector.name} Logo`,
            src: connector.icon,
          }}
          onConnect={onConnect}
          onSkip={onSkip}
          onUndo={onUndo}
          onOpenConnectionLinkDialog={() => onSetDialog(connector)}
        />
      ))}
    </ul>
    <Divider className="w-full border-basic-white/15" />
  </div>
)

export default StepsList
