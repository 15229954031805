import useDate, { type Dayjs } from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export interface DatePreset {
  id: string
  label: string
  startDate: (startDate?: Dayjs) => Dayjs
  endDate: (startDate?: Dayjs, endDate?: Dayjs) => Dayjs
}

export type DatePresets = Record<
  "rangePresets" | "compareRangePresets",
  Array<DatePreset>
>

export const datePresets: DatePresets = {
  rangePresets: [
    {
      id: "range-present-last-30-days",
      label: "Last 30 Days",
      startDate: () => dayjs().startOf("day").subtract(30, "days"),
      endDate: () => dayjs(),
    },
    {
      id: "range-preset-last-3-months",
      label: "Last 3 Months",
      startDate: () => dayjs().startOf("month").subtract(3, "months"),
      endDate: () => dayjs(),
    },
    {
      id: "range-preset-last-6-months",
      label: "Last 6 Months",
      startDate: () => dayjs().startOf("month").subtract(6, "months"),
      endDate: () => dayjs(),
    },
    {
      id: "range-preset-last-12-months",
      label: "Last 12 Months",
      startDate: () => dayjs().startOf("month").subtract(12, "months"),
      endDate: () => dayjs(),
    },
  ],
  compareRangePresets: [
    {
      id: "compare-range-preset-same-dates-prior-year",
      label: "Same Dates Prior Year",
      startDate: (startDate?: Dayjs) => dayjs(startDate).subtract(1, "year"),
      endDate: (_startDate?: Dayjs, endDate?: Dayjs) =>
        dayjs(endDate).subtract(1, "year"),
    },
    {
      id: "compare-range-preset-same-days-prior-year",
      label: "Same Days Prior Year",
      // Using as reference: https://wmfexcel.com/2018/08/08/same-day-last-year/
      startDate: (startDate?: Dayjs) => dayjs(startDate).subtract(364, "days"),
      endDate: (_startDate?: Dayjs, endDate?: Dayjs) =>
        dayjs(endDate).subtract(364, "days"),
    },
    {
      id: "compare-range-preset-prior-30-days",
      label: "Prior 30 Days",
      startDate: (startDate?: Dayjs) => dayjs(startDate).subtract(30, "days"),
      endDate: (startDate?: Dayjs) => dayjs(startDate).subtract(1, "day"),
    },
    {
      id: "compare-range-preset-prior-3-months",
      label: "Prior 3 Months",
      startDate: (startDate?: Dayjs) => dayjs(startDate).subtract(3, "months"),
      endDate: (startDate?: Dayjs) => dayjs(startDate).subtract(1, "day"),
    },
    {
      id: "compare-range-preset-prior-6-months",
      label: "Prior 6 Months",
      startDate: (startDate?: Dayjs) => dayjs(startDate).subtract(6, "months"),
      endDate: (startDate?: Dayjs) => dayjs(startDate).subtract(1, "day"),
    },
    {
      id: "compare-range-preset-prior-12-months",
      label: "Prior 12 Months",
      startDate: (startDate?: Dayjs) => dayjs(startDate).subtract(12, "months"),
      endDate: (startDate?: Dayjs) => dayjs(startDate).subtract(1, "day"),
    },
  ],
} as const
