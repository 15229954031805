import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import { Loader } from "~/ui-rtk/components/ui/common"
import { SOURCES_MAP } from "~/ui-rtk/constants/sources"

export const renderNumberSuffix = (num: number) => {
  let money = num
  let suffix = ""
  if (num > 1_000_000_000_000) {
    money = Math.floor(num / 1_000_000_000_000)
    suffix = "trillion"
  } else if (num > 1_000_000_000) {
    money = Math.floor(num / 1_000_000_000)
    suffix = "billion"
  } else if (num > 1_000_000) {
    money = Math.floor(num / 1_000_000)
    suffix = "million"
  } else if (num > 1_000) {
    money = Math.floor(num / 1_000)
    suffix = "thousand"
  }
  return (
    <>
      <span className="xl:text-10xl text-6xl">${money}</span>
      <span className="xl:text-5xl text-3xl">{suffix}</span>
    </>
  )
}

export const renderMediaIcon = (mediaChannel: string) => {
  if (!mediaChannel) {
    return <>&nbsp;</>
  }

  if (!SOURCES_MAP[mediaChannel.toUpperCase()]) {
    return <>&nbsp;</>
  }

  const source = SOURCES_MAP[mediaChannel.toUpperCase()]

  return renderSourceIcon({
    icon: source.icon,
    name: source.name,
  })
}

export const renderSpinner = () => (
  <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center">
    <Loader />
  </div>
)
