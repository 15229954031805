import { H5 } from "../../typography"
import { type PropsWithChildren } from "react"

export default function SourceTooltipContent({ children }: PropsWithChildren) {
  return (
    <div>
      <H5 className="font-bold">About this metric:</H5>
      {children}
    </div>
  )
}
