import { UpdateCompanyBrandEventDto } from "~/api/dto/company-brand-event/update-company-brand-event.dto"
import { emptySplitApi as api } from "./emptyApi"
import { CompanyBrandEventDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    companyBrandEventsControllerGetCompanyBrandEvents: build.query<
      CompanyBrandEventsControllerGetCompanyBrandEventsApiResponse,
      CompanyBrandEventsControllerGetCompanyBrandEventsApiArg
    >({
      query: () => ({ url: `/company-brand-event` }),
    }),
    companyBrandEventsControllerUpsertCompanyBrandEvents: build.mutation<
      CompanyBrandEventsControllerUpsertCompanyBrandEventsApiResponse,
      CompanyBrandEventsControllerUpsertCompanyBrandEventsApiArg
    >({
      query: queryArg => ({
        url: `/company-brand-event`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type CompanyBrandEventsControllerGetCompanyBrandEventsApiResponse =
  /** status 200  */ CompanyBrandEventDto[]
export type CompanyBrandEventsControllerGetCompanyBrandEventsApiArg = void
export type CompanyBrandEventsControllerUpsertCompanyBrandEventsApiResponse =
  /** status 200  */ CompanyBrandEventDto[]
export type CompanyBrandEventsControllerUpsertCompanyBrandEventsApiArg = {
  body: UpdateCompanyBrandEventDto[]
}
export const {
  useCompanyBrandEventsControllerGetCompanyBrandEventsQuery,
  useCompanyBrandEventsControllerUpsertCompanyBrandEventsMutation,
} = injectedRtkApi
