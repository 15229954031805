import { SvgType } from "~/ui-rtk/shared/types/icon-types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type TAction = {
  label: string
  Icon?: SvgType
  actionId: string
  disabled?: boolean
}

type TActionsMenuClasses = {
  li: string
  link: string
  list: string
  icon: string
}

export type TActionsMenuProps = {
  actions: TAction[]
  handleAction: (actionId: string) => void
  classes?: Partial<TActionsMenuClasses>
}

export default function ActionsMenu({
  classes,
  actions,
  handleAction,
}: TActionsMenuProps) {
  return (
    <ul
      className={cn(
        "p-0 border rounded-lg w-52 menu bg-basic-dark-blue border-basic-blue",
        classes?.list,
      )}
    >
      {actions.map(action => {
        const cx = `rounded-md ${action.disabled ? "opacity-50" : ""}`
        const Icon = action.Icon
        return (
          <li
            key={action.actionId}
            className={cn(cx, classes?.li)}
            onClick={() => !action.disabled && handleAction(action.actionId)}
          >
            <a className={cn("px-4 py-3", classes?.link)}>
              {Icon ? <Icon size={18} className={cn(classes?.icon)} /> : null}
              {action.label}
            </a>
          </li>
        )
      })}
    </ul>
  )
}
