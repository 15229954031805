import { useRef } from "react"
import { isEqual } from "lodash"

export function useDeepCompareMemo<T>(value: T): T {
  const ref = useRef<T>(value)

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
