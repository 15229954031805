import {
  combineSlices,
  configureStore,
  Action,
  ThunkAction,
} from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { setupListeners } from "@reduxjs/toolkit/query"
import { apiSlice } from "./slices/api.slice"
import { userSlice } from "./slices/user.slice"
import { companySlice } from "./slices/company.slice"
import { emptySplitApi } from "../api/emptyApi"
import { loadingSlice } from "./slices/loading.slice"
import { drawerSlice } from "./slices/drawer.slice"
import { demoSlice } from "./slices/demo.slice"

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // TODO filter out sensitive data
})

const rootReducer = combineSlices(
  userSlice,
  companySlice,
  emptySplitApi,
  apiSlice,
  loadingSlice,
  drawerSlice,
  demoSlice,
)

export type RootState = ReturnType<typeof rootReducer>

export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        emptySplitApi.middleware,
        apiSlice.middleware,
      ),
    preloadedState,
    enhancers: getDefaultEnhancers =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
  })
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

export type AppStore = typeof store

export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
