import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ArrowDownSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99813 14.17C7.87146 14.17 7.7448 14.1233 7.6448 14.0233L3.59812 9.97667C3.40479 9.78333 3.40479 9.46333 3.59812 9.27C3.79146 9.07667 4.11146 9.07667 4.30479 9.27L7.99813 12.9633L11.6915 9.27C11.8848 9.07667 12.2048 9.07667 12.3981 9.27C12.5915 9.46333 12.5915 9.78333 12.3981 9.97667L8.35146 14.0233C8.25146 14.1233 8.1248 14.17 7.99813 14.17Z"
      fill={fill}
    />
    <path
      d="M8 14.0559C7.72667 14.0559 7.5 13.8293 7.5 13.5559V2.33594C7.5 2.0626 7.72667 1.83594 8 1.83594C8.27333 1.83594 8.5 2.0626 8.5 2.33594V13.5559C8.5 13.8293 8.27333 14.0559 8 14.0559Z"
      fill={fill}
    />
  </svg>
))
