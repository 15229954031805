import type { FC, ReactNode } from "react"
import H4 from "../H4"

type Props = {
  children: ReactNode
  className?: string
}

export default function SubTitle({
  children,
  className = "",
}: Props): ReturnType<FC<Props>> {
  return <H4 className={className}>{children}</H4>
}
