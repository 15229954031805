import { NavLink } from "react-router-dom"
import { Fragment } from "react"
import { ChevronRightSvg } from "../../ui/svg/arrow"

export type TBreadcrumb = {
  name: string
  slug: string
}

type TBreadcrumbsProps = {
  items: TBreadcrumb[]
}

export default function Breadcrumbs({ items }: TBreadcrumbsProps) {
  const isLastIdx = (idx: number) => items.length - 1 === idx

  const renderedBreadcrumbs = items.map(
    ({ name, slug }: TBreadcrumb, idx: number) =>
      isLastIdx(idx) ? (
        <span key={idx} className="text-2xl">
          {name}
        </span>
      ) : (
        <Fragment key={slug}>
          <NavLink to={slug} key={slug} className="text-2xl">
            {name}
          </NavLink>
          <span key={`connector-${slug}`} className="text-2xl">
            <ChevronRightSvg size={20} className="inline mx-2" fill="gray" />
          </span>
        </Fragment>
      ),
  )
  return renderedBreadcrumbs
}
