import useSources from "~/ui-rtk/hooks/sources"
import { useStats } from "../hooks/stats"
import {
  AD_MEDIA_CATEGORIES_OPTIONS,
  TAdMediaOption,
  TCampaignAdSetAdDetailsProps,
  VALUE_OVER_TIME_KEY_MAP,
  VALUE_OVER_TIME_LABEL_MAP,
  VALUE_OVER_TIME_MODE,
} from "../constants"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useAdQubeQuery } from "./hooks/cube-query"
import { H5, H6 } from "~/ui-rtk/components/ui/typography"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import { TSource } from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import BrandValueOverSpendProgressBar from "../components/BrandValueOverSpendProgressBar"
import ValueOverTimeInfoBlock from "../components/ValueOverTimeInfoBlock"
import ValueOverTimeChart from "../components/ValueOverTimeChart"
import ValueOverTimeModeSelector from "../components/ValueOverTimeModeSelector"
import { Select } from "~/ui-rtk/components/ui/controls"
import StatsTable from "../components/StatsTable"
import { renderDetailsCaption } from "../utils"
import { DetailsTitle } from "../components/DetailsHeader"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import {
  useMediaCategoryControllerGetMediaCategoryQuery,
  useMediaCategoryControllerPostMediaCategoriesMutation,
} from "~/ui-rtk/api/mediaCategoryApi"
import ListBox from "~/ui-rtk/components/ui/common/ListBox/ListBox"

export enum COMPARE_AD_MODE {
  ALL,
  CURRENT_AD_SET,
  CURRENT_CAMPAIGN,
}
export type TDataKey = "ALL" | "CURRENT_CAMPAIGN" | "CURRENT_AD_SET"
export const COMPARE_AD_KEY_MAP: Record<COMPARE_AD_MODE, TDataKey> = {
  [COMPARE_AD_MODE.ALL]: "ALL",
  [COMPARE_AD_MODE.CURRENT_AD_SET]: "CURRENT_AD_SET",
  [COMPARE_AD_MODE.CURRENT_CAMPAIGN]: "CURRENT_CAMPAIGN",
}
export const COMPARE_AD_LABEL_MAP: Record<COMPARE_AD_MODE, string> = {
  [COMPARE_AD_MODE.ALL]: "All",
  [COMPARE_AD_MODE.CURRENT_AD_SET]: "Current Ad Set",
  [COMPARE_AD_MODE.CURRENT_CAMPAIGN]: "Current Campaign",
}

export default function AdSetDetails(props: TCampaignAdSetAdDetailsProps) {
  const { sources } = useSources()
  const { metric, date, granularity } = props
  const stats = useStats(metric)

  const demo = useAppSelector(selectIsEnabled)

  const [category, setCategory] = useState<string | null | undefined>(undefined)

  const campaignName = metric.campaign_name
  const campaignId = metric.campaign_id
  const adSetName = metric.adset_name
  const adSetId = metric.adset_id
  const adName = metric.ad_name
  const adId = metric.ad_id
  const sourceName = metric.media_channel?.toUpperCase()
  const source = sources.get(sourceName)

  const [compareAdSetMode, setCompareAdSetMode] = useState(
    COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.ALL],
  )

  const [valueOverTimeMode, setValueOverTimeMode] = useState(
    VALUE_OVER_TIME_MODE.TOTAL,
  )

  const [createMediaCategory] =
    useMediaCategoryControllerPostMediaCategoriesMutation()

  const {
    data: mediaCategory,
    isLoading: mediaCategoryLoading,
    isFetching: mediaCategoryFetching,
    refetch,
  } = useMediaCategoryControllerGetMediaCategoryQuery(
    {
      id: adId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (mediaCategory?.category) {
      setCategory(mediaCategory.category)
    } else {
      setCategory(null)
    }
  }, [mediaCategory])

  const categoryUpdate = async (value: TAdMediaOption) => {
    await createMediaCategory({
      body: {
        mediaId: adId,
        type: "ad",
        channel: metric.media_channel,
        category: value,
      },
    })
    setCategory(value)
    await refetch()
  }

  const { allAdsDetails, valueOverTimeDetails, isLoading } = useAdQubeQuery(
    date,
    adId,
    compareAdSetMode === COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.CURRENT_AD_SET]
      ? adSetId
      : undefined,
    compareAdSetMode === COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.CURRENT_CAMPAIGN]
      ? campaignId
      : undefined,
    granularity,
  )

  const compareAdSetModeOptions = useMemo(
    () => [
      {
        value: COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.ALL],
        label: COMPARE_AD_LABEL_MAP[COMPARE_AD_MODE.ALL],
      },
      {
        value: COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.CURRENT_CAMPAIGN],
        label: COMPARE_AD_LABEL_MAP[COMPARE_AD_MODE.CURRENT_CAMPAIGN],
      },
      {
        value: COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.CURRENT_AD_SET],
        label: COMPARE_AD_LABEL_MAP[COMPARE_AD_MODE.CURRENT_AD_SET],
      },
    ],
    [],
  )

  const withinCurrent = useMemo(() => {
    if (
      compareAdSetMode === COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.CURRENT_AD_SET]
    ) {
      return "ad set"
    }
    if (
      compareAdSetMode === COMPARE_AD_KEY_MAP[COMPARE_AD_MODE.CURRENT_CAMPAIGN]
    ) {
      return "campaign"
    }
  }, [compareAdSetMode])

  return (
    <div className="campaign-details w-145 h-full">
      <DetailsTitle
        title={adName ?? ""}
        classes={{ container: "mb-4" }}
        demoMode={demo.isEnabled}
      />
      {isLoading && (
        <div className="h-[50%] w-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="col-span-7">
            {mediaCategoryLoading || mediaCategoryFetching ? (
              <Loader />
            ) : (
              <ListBox
                options={AD_MEDIA_CATEGORIES_OPTIONS}
                onSelect={categoryUpdate}
                value={category}
                label="Category"
                placeholder="Please select a Category"
              />
            )}
          </div>
          <div className="flex justify-between items-center mt-3">
            Compare against:
            <Select
              className="w-[50%] bg-basic-dark-blue"
              options={compareAdSetModeOptions}
              value={compareAdSetMode}
              onChange={($evt: ChangeEvent<HTMLSelectElement>) => {
                setCompareAdSetMode(
                  ($evt.target as HTMLSelectElement)
                    .value as (typeof COMPARE_AD_KEY_MAP)[keyof typeof COMPARE_AD_KEY_MAP],
                )
              }}
            />
          </div>
          <Card
            rounded
            className="mt-3 bg-basic-dark-blue border border-basic-blue"
          >
            <div className="grid grid-cols-8">
              <div className="col-span-7">
                <H5
                  className={cn("font-bold mb-2", demo.isEnabled && "blur-md")}
                >
                  {adName}
                </H5>
                <H6
                  className={cn("font-semibold", demo.isEnabled && "blur-md")}
                >
                  Ad Set: {adSetName}
                </H6>
                <H6
                  className={cn("font-semibold", demo.isEnabled && "blur-md")}
                >
                  Campaign: {campaignName}
                </H6>
              </div>
              <div className="flex items-center justify-end">
                {renderSourceIcon({
                  name: source?.name,
                  icon: source?.icon,
                } as TSource)}
              </div>
            </div>
            <div className="mt-3 text-sm">
              {renderDetailsCaption(
                metric,
                allAdsDetails ?? {},
                "ad",
                sourceName.toLowerCase(),
              )}
            </div>
            <BrandValueOverSpendProgressBar
              metric={metric}
              allDetails={allAdsDetails ?? {}}
              label="ad"
              withinCurrent={withinCurrent}
            />
          </Card>
          <Card
            rounded
            className="mt-3 bg-basic-dark-blue border border-basic-blue"
          >
            <H5 className="font-bold">Stats</H5>
            <StatsTable {...stats} />
          </Card>
          <Card
            rounded
            className="mt-3 bg-basic-dark-blue border border-basic-blue"
          >
            <div className="grid grid-cols-2 gap-2">
              <div className="text-start">
                <H5 className="font-bold">Value over time</H5>
              </div>
              <div className="text-end">
                <ValueOverTimeModeSelector
                  mode={valueOverTimeMode}
                  setMode={(mode: VALUE_OVER_TIME_MODE) =>
                    setValueOverTimeMode(mode)
                  }
                />
              </div>
            </div>

            {valueOverTimeDetails && (
              <>
                <div className="mt-3 grid grid-cols-3 gap-1">
                  {[
                    valueOverTimeDetails.info30days,
                    valueOverTimeDetails.info60days,
                    valueOverTimeDetails.info90days,
                  ].map(info => (
                    <ValueOverTimeInfoBlock
                      key={info.label}
                      info={info}
                      label={info.label}
                      dataLabel={VALUE_OVER_TIME_LABEL_MAP[valueOverTimeMode]}
                      dataKey={VALUE_OVER_TIME_KEY_MAP[valueOverTimeMode]}
                    />
                  ))}
                </div>
                <div className="mt-3">
                  <ValueOverTimeChart
                    metrics={valueOverTimeDetails.chartData}
                    dataKey={VALUE_OVER_TIME_KEY_MAP[valueOverTimeMode]}
                    label="ad"
                  />
                </div>
              </>
            )}
          </Card>
        </>
      )}
    </div>
  )
}
