import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const DataSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33301 3.77893C3.33301 3.50279 3.10915 3.27893 2.83301 3.27893C2.55687 3.27893 2.33301 3.50279 2.33301 3.77893V13.4456C2.33301 14.0899 2.85534 14.6123 3.49967 14.6123H13.1663C13.4425 14.6123 13.6663 14.3884 13.6663 14.1123C13.6663 13.8361 13.4425 13.6123 13.1663 13.6123H3.49967C3.40763 13.6123 3.33301 13.5376 3.33301 13.4456V3.77893ZM9.33301 5.4456C9.33301 5.16945 9.55687 4.9456 9.83301 4.9456H13.1673C13.4435 4.9456 13.6673 5.16945 13.6673 5.44559L13.6674 8.78225C13.6674 9.05839 13.4435 9.28225 13.1674 9.28225C12.8912 9.28226 12.6674 9.0584 12.6674 8.78226L12.6673 6.65171L9.18656 10.1325C8.9913 10.3277 8.67472 10.3277 8.47946 10.1325L7.16634 8.81937L5.01989 10.9658C4.82463 11.1611 4.50805 11.1611 4.31279 10.9658C4.11753 10.7706 4.11753 10.454 4.31279 10.2587L6.81279 7.75871C7.00805 7.56345 7.32463 7.56345 7.51989 7.75871L8.83301 9.07182L11.9592 5.9456H9.83301C9.55687 5.9456 9.33301 5.72174 9.33301 5.4456Z"
      fill={fill}
    />
  </svg>
))
