import { Navigate, useSearchParams } from "react-router-dom"

import { GuestLayout } from "../components/layout"
import { CreatePasswordForm } from "../components/features/forms"
import TokenExpiredView from "./TokenExpiredView"
import { useAuthControllerValidateTokenQuery } from "../api/authApi"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { ContentBox } from "~/ui-rtk/components/ui/common"

const InvitePage = () => {
  const [searchParams] = useSearchParams()
  const companyId = searchParams.get("companyId")
  if (companyId) {
    return <Navigate to={brandRoutes.SIGN_IN} />
  }

  const token = searchParams.get("token")
  if (!token) {
    return <TokenExpiredView />
  }

  const { isError } = useAuthControllerValidateTokenQuery({ token })
  if (isError) {
    return <TokenExpiredView />
  }

  return (
    <GuestLayout>
      <ContentBox title="Choose a password to continue.">
        <CreatePasswordForm token={token} />
      </ContentBox>
    </GuestLayout>
  )
}

export default InvitePage
