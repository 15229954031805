import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { useCodeBlock, useConnect } from "./connect"
import { useCallback, useEffect } from "react"
import { UpdateCompanyBrandEventDto } from "~/api/dto/company-brand-event/update-company-brand-event.dto"
import { H2, H4 } from "~/ui-rtk/components/ui/typography"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { Field, FieldArray, Form, Formik } from "formik"
import { Card, Divider, Loader } from "~/ui-rtk/components/ui/common"
import { Button, Toggle } from "~/ui-rtk/components/ui/controls"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { ClipboardIcon } from "@heroicons/react/20/solid"
import { toast } from "react-toastify"

export function SettingsBrandEventsPage() {
  const { isLoading, setIsLoading } = useLoadingState()
  const currentCompany = useAppSelector(selectCurrentCompany)
  const { formattedCodeBlock, codeBlock } = useCodeBlock(
    currentCompany?.id ?? "",
  )

  const {
    allBrandEvents,
    initialValues,
    isLoading: isDataLoading,
    updateSettings,
  } = useConnect()

  useEffect(() => {
    document.title = "Marathon Data: Settings > Brand Events Settings"
  }, [])

  const handleUpdateSettings = async (values: {
    events: UpdateCompanyBrandEventDto[]
  }) => {
    setIsLoading(true)
    await updateSettings(values.events).catch(() => null)
    setIsLoading(false)
  }

  const handleCopyToClipboard = useCallback(() => {
    void navigator.clipboard
      .writeText(codeBlock)
      .then(() => {
        toast.success("Copied")
      })
      .catch(() =>
        toast.error("Failed to copy id to clipboard", {
          toastId: "clipboard-copy-company-id",
        }),
      )
  }, [codeBlock])

  const renderScriptCode = useCallback(() => {
    if (!currentCompany) {
      return null
    }

    return (
      <Card rounded className="bg-background-dark-shade relative">
        <pre>{formattedCodeBlock} </pre>
        <ClipboardIcon
          width={20}
          color="grey"
          className="absolute top-4 right-4 hover:cursor-pointer"
          onClick={handleCopyToClipboard}
        />
      </Card>
    )
  }, [currentCompany])

  if (isDataLoading) {
    return <Loader />
  }

  return (
    <div className="px-6 pt-6">
      <div className="text-start">
        <H2>
          <Breadcrumbs
            items={[
              {
                slug: "/settings",
                name: "Settings",
              },
              {
                slug: "brand-events",
                name: "Your Brand Conversion Events",
              },
            ]}
          />
        </H2>
      </div>
      <div className="mt-4 text-start">
        <div className="flex justify-between">
          <H4 className="text-start">
            Add custom conversion events to your ad platforms here
          </H4>
          <div>{renderScriptCode()}</div>
        </div>
        <Divider className="mt-4" />
        <Formik
          initialValues={
            initialValues as {
              events: UpdateCompanyBrandEventDto[]
            }
          }
          onSubmit={handleUpdateSettings}
        >
          {({ values }) => (
            <Form>
              <table className="table table-zebra mt-4">
                <thead>
                  <tr>
                    <th>Turn On/Off</th>
                    <th>Conversion Event Name</th>
                    <th>Event Details</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="events"
                    render={arrayHelpers =>
                      allBrandEvents?.map((event, idx) => (
                        <tr key={idx}>
                          <td>
                            <Field
                              type="text"
                              id={`event[${idx}].isActive`}
                              name={`event[${idx}].isActive`}
                              as={Toggle}
                              value={values.events[idx].isActive}
                              onChange={(active: boolean) =>
                                arrayHelpers.replace(idx, {
                                  ...values.events[idx],
                                  isActive: active,
                                })
                              }
                            />
                          </td>
                          <td>{event.label}</td>
                          <td>-</td>
                        </tr>
                      ))
                    }
                  />
                </tbody>
              </table>

              <Divider className="my-3" />
              <div className="items-right">
                <Button
                  variant={{ variant: "solid", color: "pink" }}
                  size="sm"
                  type="submit"
                  isLoading={isLoading || isDataLoading}
                >
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
