import { Option } from "../../common/SearchSelect"

export const ITEMS_PER_PAGE = 10
export const LOADING_DATA = Array(10).fill({ "dummy-key": "" })

export const DEFAULT_CELL_WIDTH = 200

export const PAGE_OPTIONS: Option[] = [
  {
    label: `${ITEMS_PER_PAGE}`,
    value: ITEMS_PER_PAGE,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "25",
    value: 25,
  },
]

export type TManualFilter = {
  value: string
  label: string
  disabled?: boolean
}
