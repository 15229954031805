import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export default function H5({
  children,
  className = "",
}: Props): ReturnType<FC<Props>> {
  const cs = ["text-base", className].join(" ").trim()
  return <h5 className={cs}>{children}</h5>
}
