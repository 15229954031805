import { useCallback } from "react"
import { useCompanyConnectorControllerUpdateCompanyConnectorMutation } from "~/ui-rtk/api/companyConnectorApi"

export const useConnect = () => {
  const [confirmConnectionAsync] =
    useCompanyConnectorControllerUpdateCompanyConnectorMutation()

  const confirmConnection = useCallback(async (id: string) => {
    await confirmConnectionAsync({
      id,
      updateCompanyConnectorDto: {
        confirmedAt: new Date(),
      },
    })
  }, [])

  return { confirmConnection }
}
