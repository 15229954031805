import React, { useCallback, useState } from "react"
// import dayjs from "dayjs"

import { Tooltip } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"
import { UndoSvg } from "~/ui-rtk/components/ui/svg/arrow"
import {
  // CheckMarkSvg,
  CloseSvg,
  ErrorSvg,
} from "~/ui-rtk/components/ui/svg/essentials"

import { isReconnectRequired, isResolved } from "./helpers"
import { adjustConnectorIconForWhiteTheme } from "~/ui-rtk/utils/icon-utils"

import type { Connector } from "../../connect"

export type SourceItemProps = {
  image: {
    alt: string
    src: string
  }
  isSkipped?: boolean
  onSkip: (id: string) => void
  onUndo: (id: string) => void
  onOpenConnectionLinkDialog: () => void
  onConnect: (id: string, callback: () => void) => void
} & Pick<Connector, "id" | "name" | "connectionLink" | "status" | "metadata">

// const getDateLabel = (date: Date) =>
//   `${dayjs(date).format("MMM D, YYYY, h:mm A")}`

const SourceItem: React.FC<SourceItemProps> = ({
  id,
  name,
  image,
  status,
  metadata,
  onSkip,
  onUndo,
  onConnect,
  // connectionLink,
  onOpenConnectionLinkDialog,
  isSkipped = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const whiteThemeIconPath = adjustConnectorIconForWhiteTheme(id, image.src)

  const handleConnect = useCallback(() => {
    void setIsSubmitting(true)
    void onConnect(id, () => setIsSubmitting(false))
  }, [id])

  const handleSkip = useCallback(() => {
    void onSkip(id)
  }, [id])

  const handleReset = useCallback(() => {
    void onUndo(id)
  }, [id])

  const renderIconWithTooltip = useCallback(() => {
    if (status !== "RECONNECT" && status !== "FAILED") {
      return null
    }

    let tooltipText = "Reconnect is required"

    if (Array.isArray(metadata?.errors) && metadata.errors.length > 0) {
      tooltipText = "Synchronization issues occurred during the connection"
    } else if (status === "FAILED") {
      tooltipText = "Unexpected issue occurred"
    }

    return (
      <Tooltip text={tooltipText} type="error">
        <button className="cursor-pointer">
          <ErrorSvg size={24} fill="error" />
        </button>
      </Tooltip>
    )
  }, [status, metadata])

  const renderButtons = useCallback(() => {
    if (isResolved(status)) {
      return (
        <Button className="min-w-32" disabled>
          Connected
        </Button>
      )
    }

    const requiresReconnect = isReconnectRequired(status)

    if (isSkipped) {
      return (
        <React.Fragment>
          <Button className="min-w-32" disabled>
            Skipped
          </Button>
          <button className="p-0 m-0" onClick={handleReset}>
            <UndoSvg fill="black" size={24} />
          </button>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button
          className="min-w-32"
          onClick={handleConnect}
          isLoading={isSubmitting}
          variant={{
            variant: "solid",
            color: requiresReconnect ? "red" : "pink",
          }}
        >
          {requiresReconnect ? "Reconnect" : "Connect"}
        </Button>
        {/* {connectionLink ? (
          <Tooltip
            text={
              <>
                Link was sent successfully to{" "}
                <span className="text-brand text-nowrap">
                  {connectionLink.recipientEmail}
                </span>{" "}
                at {getDateLabel(connectionLink.createdAt)}
              </>
            }
            classes={{
              container: "bg-basic-blue min-w-[400px]",
              triangle: "bg-basic-blue",
              text: "text-white/80",
            }}
          >
            <Button
              className="min-w-32 hover:text-black/70 text-basic-black/[.90] flex items-center"
              variant={{ variant: "text" }}
              onClick={onOpenConnectionLinkDialog}
            >
              <span>Link sent</span>
              <CheckMarkSvg size={22} fill="states.success" />
            </Button>
          </Tooltip>
        ) : (
          <Button
            className="min-w-32 text-black hover:text-black/70"
            variant={{ variant: "text" }}
            onClick={onOpenConnectionLinkDialog}
          >
            Send Link
          </Button>
        )} */}
        <button className="p-0 m-0" onClick={handleSkip}>
          <CloseSvg fill="black" size={24} />
        </button>
      </React.Fragment>
    )
  }, [
    status,
    isSkipped,
    isSubmitting,
    handleSkip,
    handleReset,
    handleConnect,
    onOpenConnectionLinkDialog,
  ])

  return (
    <li className="flex justify-between w-full px-4 py-2 bg-white rounded-xl">
      <div className="flex items-center gap-5">
        <img src={whiteThemeIconPath} alt={image.alt} className="h-9 max-w-9" />
        <p className="font-medium leading-5 text-black">{name}</p>
      </div>

      <div className="flex items-center gap-3">
        {renderButtons()}
        {status === "NOT_CONNECTED" || status === "RECONNECT"
          ? renderIconWithTooltip()
          : null}
      </div>
    </li>
  )
}

export default SourceItem
