import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const WarnSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.925 3.28604C10.925 3.28605 10.9251 3.28602 10.925 3.28604L4.98869 6.71391C4.32278 7.10663 3.91504 7.81955 3.91504 8.58003V15.42C3.91504 16.1948 4.32622 16.9037 4.98503 17.284L10.9212 20.7118C10.9218 20.7122 10.9225 20.7125 10.9231 20.7129C11.5969 21.097 12.4167 21.0941 13.0751 20.714L19.0084 17.2879C19.0094 17.2873 19.0104 17.2867 19.0113 17.2862C19.6773 16.8935 20.0851 16.1805 20.0851 15.42V8.58003C20.0851 7.8113 19.6789 7.10747 19.0114 6.71389C19.0104 6.71331 19.0094 6.71273 19.0084 6.71216L13.0789 3.28824C13.0783 3.2879 13.0777 3.28755 13.0771 3.28721C12.4033 2.90303 11.5835 2.90595 10.925 3.28604ZM9.92507 1.55396C11.206 0.814437 12.7854 0.816989 14.0712 1.55176L14.0751 1.554L20.0217 4.98781C21.2925 5.73456 22.0851 7.0899 22.0851 8.58003V15.42C22.0851 16.8984 21.294 18.2646 20.0217 19.0122L20.0151 19.0161L14.0751 22.446C12.7942 23.1856 11.2148 23.183 9.92891 22.4483L9.92499 22.446L3.98507 19.0161C2.70388 18.2764 1.91504 16.9052 1.91504 15.42V8.58003C1.91504 7.10165 2.70609 5.73548 3.97841 4.98783L3.98497 4.98398L9.92507 1.55396ZM12.0051 6.75C12.5574 6.75 13.0051 7.19772 13.0051 7.75V13C13.0051 13.5523 12.5574 14 12.0051 14C11.4528 14 11.0051 13.5523 11.0051 13V7.75C11.0051 7.19772 11.4528 6.75 12.0051 6.75ZM12.0051 15.2C12.5574 15.2 13.0051 15.6477 13.0051 16.2V16.3C13.0051 16.8523 12.5574 17.3 12.0051 17.3C11.4528 17.3 11.0051 16.8523 11.0051 16.3V16.2C11.0051 15.6477 11.4528 15.2 12.0051 15.2Z"
      fill={fill}
    />
  </svg>
))
