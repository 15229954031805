import React from "react"
import { ErrorMessage, Field } from "formik"

import { Input } from "~/ui-rtk/components/ui/controls"

type ConnectFormFieldProps = {
  name: string
  label: string
  placeholder: string
  type?: string
  isError?: boolean
}

const ConnectFormField: React.FC<ConnectFormFieldProps> = ({
  label,
  name,
  placeholder = "",
  type = "text",
  isError = false,
}) => (
  <div className="flex flex-col justify-start text-left">
    <label className="ml-1">
      <b className="text-3 text-platinum">{label}</b>
    </label>
    <Field
      id={name}
      name={name}
      type={type}
      as={Input}
      fullWidth
      placeholder={placeholder}
      isError={isError}
    />
    <ErrorMessage
      name={name}
      component="span"
      className="input-error-message"
    />
  </div>
)

export default ConnectFormField
