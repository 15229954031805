import { MouseEvent, useMemo, useState, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import type { NavItem } from "../types"

export const useConnect = (
  path?: string,
  onBeforeNavigate?: () => boolean,
  onNavigate?: () => void,
  items?: NavItem[],
  labelVisible?: boolean,
) => {
  const navigate = useStableNavigate()
  const location = useLocation()
  const isActive = useMemo(() => {
    if (!path) {
      return false
    }

    if (location.pathname === path) {
      return true
    }

    const prefix = location.pathname.split("/")[1]
    return path.startsWith(`/${prefix}`)
  }, [path, location])
  const [isExpanded, setIsExpanded] = useState(true)

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (items?.length && onBeforeNavigate && !onBeforeNavigate()) {
        setIsExpanded(true)
        return
      }

      if (items?.length) {
        setIsExpanded(prev => !prev)
        return
      }

      if (path) {
        navigate(path)
      }

      onNavigate?.()
    },
    [path, onBeforeNavigate, onNavigate, labelVisible, items, setIsExpanded],
  )

  const handleNavigate = useCallback(() => {
    onNavigate?.()
  }, [setIsExpanded, onNavigate])

  return {
    isActive,
    isExpanded,
    handleClick,
    handleNavigate,
  }
}
