import { RefObject, useEffect, useMemo, useRef, useState } from "react"

export type ResizeHandler = (size: { width?: number; height?: number }) => void

export interface UseResizeObserverOptions {
  ref: RefObject<Element>
  onResize?: ResizeHandler
}

export const useResizeObserver = ({
  ref,
  onResize,
}: UseResizeObserverOptions) => {
  const resizeObserverCallbackRef =
    useRef<(entries: ResizeObserverEntry[]) => void>()
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  })

  useMemo(() => {
    resizeObserverCallbackRef.current = entries => {
      if (!Array.isArray(entries) || !entries.length) {
        return
      }
      const entry = entries[0]
      const width = Math.round(entry.contentRect.width)
      const height = Math.round(entry.contentRect.height)

      if (size.width === width && size.height === height) {
        return
      }
      setSize({ width, height })
      if (onResize) {
        onResize({ width, height })
      }
    }
  }, [size.width, size.height, onResize])

  useEffect(() => {
    const refEl = ref.current
    if (!refEl) {
      return
    }
    const observer = new ResizeObserver(entries => {
      if (resizeObserverCallbackRef.current) {
        resizeObserverCallbackRef.current(entries)
      }
    })

    observer.observe(refEl)
    return () => {
      observer.unobserve(refEl)
    }
  }, [onResize, ref])
  return size
}
