import * as Sentry from "@sentry/react"
import { type Query, TimeDimensionGranularity } from "@cubejs-client/core"
import {
  AggregationType,
  UNKNOWN_DATA_KEY,
  TOTAL_CALCULATED_DATA_KEY,
} from "./charts"
import { MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING } from "../components/features/dashboards/BrandMediaOptimizationDashboard/constants"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"

import useDate from "~/ui-rtk/hooks/date"
import { safeDivide } from "../utils/number-utils"
const dayjs = useDate()

export type TMetric = Partial<Query>
export type TQuery = {
  q: TMetric | TMetric[] | ((params: Record<string, any>) => TMetric)
  postProcess?: (
    data: any[],
    params: {
      granularity?: TimeDimensionGranularity
    },
  ) => [any[]] | [any[], any[]]
  getCubePrefix?: (params: Record<string, any>) => string
  disabled?: boolean
  filter?: (
    data: any[],
    granularity: TimeDimensionGranularity,
    options: Record<string, any>,
  ) => any[] | { metrics: any[]; metricsConfig: any }
}
export type TMetricsMap = Record<string, TQuery>

export type TMetricItem = Record<string, string | number>
export type TMetricColumn = {
  key: string
  [key: string]: string
}

interface brandValueStackedChartDataPoint {
  [dateKey: string]: {
    // This allows for dynamic keys, like '2024-09'
    "date.month": string
    ga_baseline_revenue?: number // Optional, as it might not be present in every data point
    engagements?: number // Optional
    conversions?: number // Optional
  }
}

export const generalPostProcess = (data: any, columns: any) => {
  const [dateColumn, ...dataColumns] = columns

  return (data ?? []).map((metrics: TMetricItem) => {
    const metric: Record<string, number | string | boolean> = {
      [dateColumn.key]: metrics[dateColumn.key],
    }
    dataColumns.forEach((column: TMetricColumn) => {
      const parsedValue = parseFloat(`${metrics[column.dataIndex]}`) ?? 0
      metric[column.dataIndex] =
        column.type === "number" ? parsedValue : metrics[column.dataIndex]
    })
    return metric
  })
}

export const MetricsMapping: TMetricsMap = {
  google_keywords_share_of_search_total_composed_chart: {
    q: {
      measures: [
        "company_mind_share_metrics_monthly.share_of_search",
        "company_mind_share_metrics_monthly.searches",
      ],
      dimensions: [
        "company_mind_share_metrics_monthly.brand",
        "company_mind_share_metrics_monthly.category",
      ],
      timeDimensions: [
        {
          dimension: "company_mind_share_metrics_monthly.date",
        },
      ],
      filters: [
        {
          dimension: "company_mind_share_metrics_monthly.metric",
          operator: "equals",
          values: ["google_keywords_share_of_search_total"],
        },
      ],
    },
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const valueKey = "company_mind_share_metrics_monthly.share_of_search"
      const absValueKey = "company_mind_share_metrics_monthly.searches"
      const resultMap: Record<string, any> = {}
      const columnsMap: Record<string, Record<string, string>> = {}

      data.forEach((item: any) => {
        const date = dayjs(item[dateKey]).format("YYYY-MM-DD")
        if (!resultMap[date]) {
          resultMap[date] = {
            [dateKey]: date,
            [brandKey]: {},
            [TOTAL_CALCULATED_DATA_KEY]: 0,
          }
        }
        const brandDataKey = `${brandKey}.${item[brandKey]}`
        if (!columnsMap[brandDataKey]) {
          columnsMap[brandDataKey] = {
            dataIndex: brandDataKey,
            key: brandDataKey,
            shortTitle: item[brandKey],
            title: item[brandKey],
            type: "number",
          }
        }
        resultMap[date][brandKey][item[brandKey]] = parseFloat(item[valueKey])
        resultMap[date][brandDataKey] = parseFloat(item[valueKey])
        resultMap[date][TOTAL_CALCULATED_DATA_KEY] += item[absValueKey] ?? 0
      })

      let not100 = false

      const result = Object.values(resultMap)
        .sort((a, b) => dayjs(a[dateKey]).unix() - dayjs(b[dateKey]).unix())
        .map((item: any) => {
          const total = Object.keys(item[brandKey]).reduce(
            (acc, brand) => acc + item[brandKey][brand],
            0,
          )

          // Hack to make total = 100% (by extrapolating existing values)
          if (total < 100) {
            not100 = true

            const coef = 100 / total
            const newItem = {
              [dateKey]: item[dateKey],
              [brandKey]: {} as Record<string, any>,
              [TOTAL_CALCULATED_DATA_KEY]: item[TOTAL_CALCULATED_DATA_KEY],
            }
            Object.keys(item[brandKey]).forEach(brand => {
              const newVal = item[brandKey][brand] * coef
              newItem[brandKey][brand] = newVal
              newItem[`${brandKey}.${brand}`] = newVal
            }, 0)
            return newItem
          }
          return item
        })

      if (not100) {
        Sentry.captureMessage(
          "google_keywords_share_of_search_total_composed_chart - data not end up to 100%",
        )
      }

      return [
        result,
        [
          {
            dataIndex: dateKey,
            key: dateKey,
            shortTitle: "Date",
            title: "Company Mind Share Metrics Monthly Date",
            type: "time",
          },
          ...Object.values(columnsMap),
        ],
      ]
    },
    filter: (
      data: any[],
      granularity: TimeDimensionGranularity,
      { currentCompany, compareMode },
    ) => {
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
      const brands = keywords?.google?.competitors ?? []
      const alias = keywords?.google?.searchAlias
      const aliases = alias ? [alias] : []
      const currentCompanyName = currentCompany?.name

      let skippedKeys = [dateKey, "date", brandKey, TOTAL_CALCULATED_DATA_KEY]
      if (compareMode) {
        skippedKeys = [
          ...skippedKeys,
          `compared.${dateKey}`,
          "compared.date",
          `compared.${brandKey}`,
          `compared.${TOTAL_CALCULATED_DATA_KEY}`,
        ]
      }

      if (!brands || brands.length < 1) {
        return []
      }

      const lowerBrands = brands.map((b: string) => b.toLowerCase())
      const lowerAliases = [currentCompanyName, ...aliases].map((a: string) =>
        a.toLowerCase(),
      )

      return data.map(dataItem => {
        const item: Record<string, any> = {
          [dateKey]: dataItem[dateKey],
          date: dataItem[dateKey],
          [brandKey]: dataItem[brandKey],
          [UNKNOWN_DATA_KEY]: {
            [brandKey]: {},
          },
          [TOTAL_CALCULATED_DATA_KEY]: dataItem[TOTAL_CALCULATED_DATA_KEY],
        }

        if (compareMode) {
          item[`compared.${dateKey}`] = dataItem[`compared.${dateKey}`]
          item["compared.date"] = dataItem["compared.date"]
          item[`compared.${TOTAL_CALCULATED_DATA_KEY}`] =
            dataItem[`compared.${TOTAL_CALCULATED_DATA_KEY}`]
          item[`compared.${UNKNOWN_DATA_KEY}`] = {
            [brandKey]: {},
          }
        }

        const foundBrands: string[] = []
        const subItem: Record<string, any> = {}

        Object.keys(dataItem).forEach((dataKey: string) => {
          if (skippedKeys.includes(dataKey)) {
            return
          }
          const isCompareKey = dataKey.startsWith("compared.")
          const compareOrMainPrefix = isCompareKey ? "compared." : ""

          const brand = dataKey.replace(
            `${compareOrMainPrefix}${brandKey}.`,
            "",
          )
          const currentCompanyKey = `${compareOrMainPrefix}${brandKey}.${currentCompanyName}`
          const unknownKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}`
          const subitemBrandKey = `${compareOrMainPrefix}${brand}`
          const subitemCurrentCompanyKey = `${compareOrMainPrefix}${currentCompanyName}`
          const unknownDataKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}`

          foundBrands.push(brand.toLowerCase())

          if (lowerBrands.includes(brand.toLowerCase())) {
            item[dataKey] = dataItem[dataKey] ?? 0
            if (!dataKey.startsWith("compared.")) {
              subItem[subitemBrandKey] = dataItem[dataKey]
            }
          } else if (lowerAliases.includes(brand.toLowerCase())) {
            if (!item[currentCompanyKey]) {
              item[currentCompanyKey] = dataItem[dataKey]
              subItem[subitemCurrentCompanyKey] = dataItem[dataKey]
            } else {
              item[currentCompanyKey] += dataItem[dataKey]
              subItem[subitemCurrentCompanyKey] += dataItem[dataKey]
            }
          } else {
            item[unknownKey] = dataItem[dataKey] ?? 0
            item[unknownDataKey][brandKey][brand] = dataItem[dataKey] ?? 0
            subItem[brand] = dataItem[dataKey]
          }
        })

        const notFoundBrands = lowerBrands.filter(
          (x: string) => !foundBrands.includes(x),
        )
        notFoundBrands.forEach((brand: string) => {
          item[`${brandKey}.${brand}`] = 0
          subItem[brand] = 0
        })
        item[brandKey] = subItem
        return item
      })
    },
  },
  google_keywords_share_of_search_brands_share_composed_chart: {
    q: {
      measures: [
        "company_mind_share_metrics_monthly.share_of_search",
        "company_mind_share_metrics_monthly.searches",
      ],
      dimensions: ["company_mind_share_metrics_monthly.brand"],
      timeDimensions: [
        {
          dimension: "company_mind_share_metrics_monthly.date",
        },
      ],
      filters: [
        {
          dimension: "company_mind_share_metrics_monthly.metric",
          operator: "equals",
          values: ["google_keywords_share_of_search_per_brand"],
        },
      ],
    },
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const valueKey = "company_mind_share_metrics_monthly.share_of_search"
      const absValueKey = "company_mind_share_metrics_monthly.searches"
      const resultMap: Record<string, any> = {}
      const columnsMap: Record<string, Record<string, string>> = {}

      data.forEach((item: any) => {
        const date = dayjs(item[dateKey]).format("YYYY-MM-DD")
        if (!resultMap[date]) {
          resultMap[date] = {
            [dateKey]: date,
            [brandKey]: {},
            [TOTAL_CALCULATED_DATA_KEY]: 0,
          }
        }
        const brandDataKey = `${brandKey}.${item[brandKey]}`
        if (!columnsMap[brandDataKey]) {
          columnsMap[brandDataKey] = {
            dataIndex: brandDataKey,
            key: brandDataKey,
            shortTitle: item[brandKey],
            title: item[brandKey],
            type: "number",
          }
        }
        resultMap[date][brandKey][item[brandKey]] = parseFloat(item[valueKey])
        resultMap[date][brandDataKey] = parseFloat(item[valueKey])
        resultMap[date][TOTAL_CALCULATED_DATA_KEY] += item[absValueKey]
      })

      let not100 = false

      const result = Object.values(resultMap)
        .sort((a, b) => dayjs(a[dateKey]).unix() - dayjs(b[dateKey]).unix())
        .map((item: any) => {
          const total = Object.keys(item[brandKey]).reduce(
            (acc, brand) => acc + item[brandKey][brand],
            0,
          )

          // Hack to make total = 100% (by extrapolating existing values)
          if (total < 100) {
            not100 = true

            const coef = 100 / total
            const newItem = {
              [dateKey]: item[dateKey],
              [brandKey]: {} as Record<string, any>,
              [TOTAL_CALCULATED_DATA_KEY]: item[TOTAL_CALCULATED_DATA_KEY],
            }
            Object.keys(item[brandKey]).forEach(brand => {
              const newVal = item[brandKey][brand] * coef
              newItem[brandKey][brand] = newVal
              newItem[`${brandKey}.${brand}`] = newVal
            }, 0)
            return newItem
          }
          return item
        })

      if (not100) {
        Sentry.captureMessage(
          "google_keywords_share_of_search_brands_share_composed_chart - data not end up to 100%",
        )
      }

      return [
        result,
        [
          {
            dataIndex: dateKey,
            key: dateKey,
            shortTitle: "Date",
            title: "Company Mind Share Metrics Monthly Date",
            type: "time",
          },
          ...Object.values(columnsMap),
        ],
      ]
    },
    filter: (
      data: any[],
      granularity: TimeDimensionGranularity,
      { currentCompany, compareMode },
    ) => {
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
      const brands = keywords?.google?.competitors ?? []
      const alias = keywords?.google?.searchAlias
      const aliases = alias ? [alias] : []
      const currentCompanyName = currentCompany?.name

      let skippedKeys = [dateKey, "date", brandKey, TOTAL_CALCULATED_DATA_KEY]
      if (compareMode) {
        skippedKeys = [
          ...skippedKeys,
          `compared.${dateKey}`,
          "compared.date",
          `compared.${brandKey}`,
          `compared.${TOTAL_CALCULATED_DATA_KEY}`,
        ]
      }

      if (!brands || brands.length < 1) {
        return []
      }

      const lowerBrands = brands.map((b: string) => b.toLowerCase())
      const lowerAliases = [currentCompanyName, ...aliases].map((a: string) =>
        a.toLowerCase(),
      )

      return data.map(dataItem => {
        const item: Record<string, any> = {
          [dateKey]: dataItem[dateKey],
          date: dataItem[dateKey],
          [brandKey]: dataItem[brandKey],
          [UNKNOWN_DATA_KEY]: {
            [brandKey]: {},
          },
          [TOTAL_CALCULATED_DATA_KEY]: dataItem[TOTAL_CALCULATED_DATA_KEY],
        }

        if (compareMode) {
          item[`compared.${dateKey}`] = dataItem[`compared.${dateKey}`]
          item["compared.date"] = dataItem["compared.date"]
          item[`compared.${TOTAL_CALCULATED_DATA_KEY}`] =
            dataItem[`compared.${TOTAL_CALCULATED_DATA_KEY}`]
          item[`compared.${UNKNOWN_DATA_KEY}`] = {
            [brandKey]: {},
          }
        }

        const foundBrands: string[] = []
        const subItem: Record<string, any> = {}
        Object.keys(dataItem).forEach((dataKey: string) => {
          if (skippedKeys.includes(dataKey)) {
            return
          }
          const isCompareKey = dataKey.startsWith("compared.")
          const compareOrMainPrefix = isCompareKey ? "compared." : ""

          const brand = dataKey.replace(
            `${compareOrMainPrefix}${brandKey}.`,
            "",
          )
          const currentCompanyKey = `${compareOrMainPrefix}${brandKey}.${currentCompanyName}`
          const unknownKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}`
          const subitemBrandKey = `${compareOrMainPrefix}${brand}`
          const subitemCurrentCompanyKey = `${compareOrMainPrefix}${currentCompanyName}`
          const unknownDataKey = `${compareOrMainPrefix}${UNKNOWN_DATA_KEY}`

          foundBrands.push(brand.toLowerCase())
          if (lowerBrands.includes(brand.toLowerCase())) {
            item[dataKey] = dataItem[dataKey] ?? 0
            if (!dataKey.startsWith("compared.")) {
              subItem[subitemBrandKey] = dataItem[dataKey]
            }
          } else if (lowerAliases.includes(brand.toLowerCase())) {
            if (!item[currentCompanyKey]) {
              item[currentCompanyKey] = dataItem[dataKey]
              subItem[subitemCurrentCompanyKey] = dataItem[dataKey]
            } else {
              item[currentCompanyKey] += dataItem[dataKey]
              subItem[subitemCurrentCompanyKey] += dataItem[dataKey]
            }
          } else {
            item[unknownKey] = dataItem[dataKey] ?? 0
            item[unknownDataKey][brandKey][brand] = dataItem[dataKey] ?? 0
            subItem[brand] = dataItem[dataKey]
          }
        })

        const notFoundBrands = lowerBrands.filter(
          (x: string) => !foundBrands.includes(x),
        )
        notFoundBrands.forEach((brand: string) => {
          item[`${brandKey}.${brand}`] = 0
          subItem[brand] = 0
        })
        item[brandKey] = subItem
        return item
      })
    },
  },
  google_keywords_share_of_search_by_category_multi_chart: {
    q: {
      measures: [
        "company_mind_share_metrics_monthly.share_of_search",
        "company_mind_share_metrics_monthly.searches",
      ],
      dimensions: [
        "company_mind_share_metrics_monthly.category",
        "company_mind_share_metrics_monthly.brand",
      ],
      timeDimensions: [
        {
          dimension: "company_mind_share_metrics_monthly.date",
        },
      ],
      filters: [
        {
          dimension: "company_mind_share_metrics_monthly.metric",
          operator: "equals",
          values: ["google_keywords_share_of_search_per_category"],
        },
      ],
    },
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const categoryKey = "company_mind_share_metrics_monthly.category"
      const brandKey = "company_mind_share_metrics_monthly.brand"
      const valueKey = "company_mind_share_metrics_monthly.share_of_search"
      const absValueKey = "company_mind_share_metrics_monthly.searches"
      const resultMap: Record<string, any> = {}
      const columnsMap: Record<string, Record<string, string>> = {}

      data.forEach((item: any) => {
        const date = dayjs(item[dateKey]).format("YYYY-MM-DD")
        if (!resultMap[date]) {
          resultMap[date] = {
            [dateKey]: date,
            [categoryKey]: {},
            [TOTAL_CALCULATED_DATA_KEY]: {},
          }
        }
        if (!resultMap[date][categoryKey][item[categoryKey]]) {
          resultMap[date][categoryKey][item[categoryKey]] = {
            [TOTAL_CALCULATED_DATA_KEY]: 0,
          }
        }
        if (
          typeof resultMap[date][TOTAL_CALCULATED_DATA_KEY][item[brandKey]] ===
          "undefined"
        ) {
          resultMap[date][TOTAL_CALCULATED_DATA_KEY][item[brandKey]] = 0
        }
        const val = parseFloat(item[valueKey])
        resultMap[date][categoryKey][item[categoryKey]][item[brandKey]] = val
        resultMap[date][categoryKey][item[categoryKey]][
          TOTAL_CALCULATED_DATA_KEY
        ] += item[absValueKey] ?? 0
        resultMap[date][TOTAL_CALCULATED_DATA_KEY][item[brandKey]] += val
      })

      let not100 = false

      const result = Object.values(resultMap)
        .sort((a, b) => dayjs(a[dateKey]).unix() - dayjs(b[dateKey]).unix())
        .map((item: any) => {
          const newItem = {
            [dateKey]: item[dateKey],
            [TOTAL_CALCULATED_DATA_KEY]: {},
          }

          Object.keys(item[categoryKey]).forEach((category: any) => {
            const total = Object.keys(item[categoryKey][category]).reduce(
              (acc, brand: string) =>
                brand === TOTAL_CALCULATED_DATA_KEY
                  ? acc
                  : acc + item[categoryKey][category][brand],
              0,
            )

            // Hack to make total = 100% (by extrapolating existing values)
            if (total > 0 && total !== 100) {
              not100 = true

              const coef = 100 / total

              Object.keys(item[categoryKey][category]).forEach(brand => {
                const categoryBrandKey = `${category}.${brand}`

                if (!columnsMap[categoryBrandKey]) {
                  columnsMap[categoryBrandKey] = {
                    dataIndex: categoryBrandKey,
                    key: categoryBrandKey,
                    shortTitle: brand,
                    title: brand,
                    type: "number",
                  }
                }
                const newVal =
                  brand === TOTAL_CALCULATED_DATA_KEY
                    ? item[categoryKey][category][brand]
                    : item[categoryKey][category][brand] * coef
                newItem[categoryBrandKey] = newVal
              }, 0)
            } else {
              Object.keys(item[categoryKey][category]).forEach(brand => {
                const categoryBrandKey = `${category}.${brand}`

                if (!columnsMap[categoryBrandKey]) {
                  columnsMap[categoryBrandKey] = {
                    dataIndex: categoryBrandKey,
                    key: categoryBrandKey,
                    shortTitle: brand,
                    title: brand,
                    type: "number",
                  }
                }

                newItem[categoryBrandKey] = item[categoryKey][category][brand]
              }, 0)
            }
          })
          const totalTotal = Object.keys(
            item[TOTAL_CALCULATED_DATA_KEY],
          ).reduce(
            (acc, brand: string) =>
              acc + item[TOTAL_CALCULATED_DATA_KEY][brand],
            0,
          )
          if (totalTotal !== 100) {
            const coef = 100 / totalTotal
            Object.keys(item[TOTAL_CALCULATED_DATA_KEY]).forEach(brand => {
              const newVal = item[TOTAL_CALCULATED_DATA_KEY][brand] * coef
              newItem[`${TOTAL_CALCULATED_DATA_KEY}.${brand}`] = newVal ?? 0
            }, 0)
          } else {
            Object.keys(item[TOTAL_CALCULATED_DATA_KEY]).forEach(brand => {
              newItem[`${TOTAL_CALCULATED_DATA_KEY}.${brand}`] =
                item[TOTAL_CALCULATED_DATA_KEY][brand] ?? 0
            }, 0)
          }

          return newItem
        })

      if (not100) {
        Sentry.captureMessage(
          "google_keywords_share_of_search_by_category_multi_chart - data not end up to 100%",
        )
      }

      return [
        result,
        [
          {
            dataIndex: dateKey,
            key: dateKey,
            shortTitle: "Date",
            title: "Company Mind Share Metrics Monthly Date",
            type: "time",
          },
          ...Object.values(columnsMap),
        ],
      ]
    },
    filter: (
      data: any[],
      granularity: TimeDimensionGranularity,
      { currentCompany, compareMode },
    ) => {
      const dateKey = `company_mind_share_metrics_monthly.date.${granularity}`
      const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
      const categories = [...(keywords?.google?.categories ?? [])]
      const brands = keywords?.google?.competitors ?? []
      const currentCompanyName = currentCompany?.name
      const alias = keywords?.google?.searchAlias
      const aliases = alias ? [alias] : []

      const lowerBrands = brands.map((b: string) => b.toLowerCase())
      const lowerAliases = [currentCompanyName, ...aliases].map((a: string) =>
        a.toLowerCase(),
      )

      let skippedKeys = [dateKey, "date"]
      if (compareMode) {
        skippedKeys = [...skippedKeys, `compared.${dateKey}`, "compared.date"]
      }

      if (categories.length < 1) {
        return []
      }

      const unknown: {
        brand: string[]
      } = {
        brand: [],
      }

      const metrics = data.map(dataItem => {
        const item: Record<string, any> = {
          [dateKey]: dataItem[dateKey],
          date: dataItem[dateKey],
          [UNKNOWN_DATA_KEY]: {},
        }

        if (compareMode) {
          item[`compared.${dateKey}`] = dataItem[`compared.${dateKey}`]
          item["compared.date"] = dataItem[`compared.${dateKey}`]
          item[`compared.${UNKNOWN_DATA_KEY}`] = {}
        }

        categories.forEach((category: string) => {
          const lowerCategory = category.toLowerCase()

          Object.keys(dataItem).forEach((dataKey: string) => {
            if (skippedKeys.includes(dataKey)) {
              return
            }

            const isCompareKey = dataKey.startsWith("compared.")
            const compareOrMainPrefix = isCompareKey ? "compared." : ""

            if (
              dataKey
                .toLowerCase()
                .startsWith(`${compareOrMainPrefix}${lowerCategory}.`)
            ) {
              const brand = dataKey
                .toLowerCase()
                .replace(`${compareOrMainPrefix}${lowerCategory}.`, "")

              if (
                lowerBrands.includes(brand.toLowerCase()) ||
                brand === TOTAL_CALCULATED_DATA_KEY
              ) {
                item[dataKey] = dataItem[dataKey] ?? 0
              } else if (lowerAliases.includes(brand.toLowerCase())) {
                if (
                  !item[
                    `${compareOrMainPrefix}${category}.${currentCompanyName}`
                  ]
                ) {
                  item[
                    `${compareOrMainPrefix}${category}.${currentCompanyName}`
                  ] = dataItem[dataKey]
                } else {
                  item[
                    `${compareOrMainPrefix}${category}.${currentCompanyName}`
                  ] += dataItem[dataKey]
                }
              } else {
                item[`${compareOrMainPrefix}${UNKNOWN_DATA_KEY}.${dataKey}`] =
                  dataItem[dataKey] ?? 0
                item[`${compareOrMainPrefix}${UNKNOWN_DATA_KEY}`][dataKey] =
                  dataItem[dataKey] ?? 0
                if (!unknown.brand.includes(brand)) {
                  unknown.brand.push(brand)
                }
              }
            }
          })
        })
        return item
      })

      const metricsConfig = {
        category: categories,
        brand: [currentCompanyName, ...brands],
        unknown,
      }

      return {
        metrics,
        metricsConfig,
      }
    },
  },
  instagram_share_of_comments_area_chart: {
    q: {
      measures: ["metrics.instagram_insights_share_of_comments"],
      filters: [
        {
          member: "metrics.metric",
          operator: "equals",
          values: ["instagram_insights_share_of_comments"],
        },
      ],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  instagram_share_of_followers_area_chart: {
    q: {
      measures: ["metrics.instagram_insights_share_of_followers"],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  instagram_share_of_likes_area_chart: {
    q: {
      measures: ["metrics.instagram_insights_share_of_likes"],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_social_profile_views_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_profile_views",
        "metrics.tiktok_profile_views",
        "metrics.facebook_pages_views",
        "metrics.total_social_profile_views",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_shares_stacked_chart: {
    q: {
      measures: [
        "metrics.tiktok_videos_shares",
        "metrics.tiktok_ads_shares",
        "metrics.instagram_business_shares",
        "metrics.instagram_ads_shares",
        "metrics.facebook_pages_shares",
        "metrics.facebook_ads_shares",
        "metrics.youtube_channel_shares",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_saves_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_saves",
        "metrics.instagram_ads_saves",
        "metrics.tiktok_ads_saves",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_new_subscribers_stacked_chart: {
    q: {
      measures: [
        "metrics.attentive_sms_subscribers",
        "metrics.klaviyo_email_subscribers",
        "metrics.total_new_subscribers",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_followers_stacked_chart: {
    q: {
      measures: [
        "metrics.tiktok_profile_follows",
        "metrics.tiktok_ads_follows",
        "metrics.instagram_business_follows",
        "metrics.instagram_insights_follows",
        "metrics.facebook_pages_follows",
        "metrics.youtube_channel_follows",
        "metrics.total_new_followers",
        "metrics.facebook_ads_follows",
        "metrics.instagram_ads_follows",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_likes_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_likes",
        "metrics.instagram_ads_likes",
        "metrics.tiktok_videos_likes",
        "metrics.tiktok_ads_likes",
        "metrics.facebook_pages_likes",
        "metrics.facebook_ads_likes",
        "metrics.youtube_channel_likes",
        "metrics.total_likes",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  total_comments_stacked_chart: {
    q: {
      measures: [
        "metrics.instagram_business_comments",
        "metrics.instagram_ads_comments",
        "metrics.tiktok_videos_comments",
        "metrics.tiktok_ads_comments",
        "metrics.facebook_pages_comments",
        "metrics.facebook_ads_comments",
        "metrics.youtube_channel_comments",
        "metrics.total_comments",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  facebook_mentions_area_chart: {
    q: {
      measures: ["metrics.facebook_pages_mentions"],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  branded_search_totals_stacked_chart: {
    q: {
      measures: [
        "metrics.google_search_organic_branded_search_impressions",
        "metrics.google_ads_paid_branded_search_impressions",
        "metrics.google_ads_paid_branded_search_total_spend",
        "metrics.google_search_organic_branded_search_clicks",
        "metrics.google_ads_paid_branded_search_clicks",
      ],
      dimensions: ["metrics.companyid"],
      filters: [
        {
          member: "metrics.metric",
          operator: "equals",
          values: [
            "google_search_organic_branded_search_impressions",
            "google_ads_paid_branded_search_impressions",
            "google_ads_paid_branded_search_total_spend",
            "google_search_organic_branded_search_clicks",
            "google_ads_paid_branded_search_clicks",
          ],
        },
      ],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  revenue_break_down_stacked_chart: {
    q: {
      measures: [
        "metrics.ga_baseline_revenue",
        "metrics.ga_paid_revenue",
        "metrics.ga_triggered_revenue",
        "metrics.total_ad_spend",
      ],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  session_paid_and_baseline_traffic_data_table: {
    q: [
      {
        measures: [
          "company_revenue_metrics_daily.baseline_sessions",
          "company_revenue_metrics_daily.baseline_engaged_sessions",
          "company_revenue_metrics_daily.baseline_new_sessions",
          "company_revenue_metrics_daily.baseline_conversions",
          "company_revenue_metrics_daily.baseline_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
      {
        measures: [
          "metrics.total_ad_spend",
          "company_revenue_metrics_daily.paid_sessions",
          "company_revenue_metrics_daily.paid_engaged_sessions",
          "company_revenue_metrics_daily.paid_new_sessions",
          "company_revenue_metrics_daily.paid_conversions",
          "company_revenue_metrics_daily.paid_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
        filters: [
          {
            dimension: "metrics.metric",
            operator: "equals",
            values: ["multiple total spent on ads"],
          },
        ],
      },
    ],
    postProcess(data: any[]) {
      return [
        data.map(dataItem => ({
          ...dataItem,
          "company_revenue_metrics_daily.baseline_conversion_rate": safeDivide(
            dataItem["company_revenue_metrics_daily.baseline_conversions"],
            dataItem["company_revenue_metrics_daily.baseline_sessions"],
          ),
          "company_revenue_metrics_daily.baseline_revenue_per_session":
            safeDivide(
              dataItem["company_revenue_metrics_daily.baseline_revenue"],
              dataItem["company_revenue_metrics_daily.baseline_sessions"],
            ),
          "company_revenue_metrics_daily.paid_conversion_rate": safeDivide(
            dataItem["company_revenue_metrics_daily.paid_conversions"],
            dataItem["company_revenue_metrics_daily.paid_sessions"],
          ),
          "company_revenue_metrics_daily.paid_revenue_per_session": safeDivide(
            dataItem["company_revenue_metrics_daily.paid_revenue"],
            dataItem["company_revenue_metrics_daily.paid_sessions"],
          ),
        })),
      ]
    },
  },
  session_paid_triggered_and_baseline_traffic_data_table: {
    q: [
      {
        measures: [
          "company_revenue_metrics_daily.baseline_sessions",
          "company_revenue_metrics_daily.baseline_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
      {
        measures: [
          "company_revenue_metrics_daily.triggered_sessions",
          "company_revenue_metrics_daily.triggered_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
      {
        measures: [
          "company_revenue_metrics_daily.paid_sessions",
          "company_revenue_metrics_daily.paid_revenue",
        ],
        dimensions: ["company_revenue_metrics_daily.companyid"],
        timeDimensions: [
          {
            dimension: "company_revenue_metrics_daily.date",
          },
        ],
      },
    ],
    postProcess(data: any[]) {
      return [
        data.map(dataItem => ({
          ...dataItem,
          "company_revenue_metrics_daily.baseline_revenue_per_session":
            safeDivide(
              dataItem["company_revenue_metrics_daily.baseline_revenue"],
              dataItem["company_revenue_metrics_daily.baseline_sessions"],
            ),
          "company_revenue_metrics_daily.triggered_revenue_per_session":
            safeDivide(
              dataItem["company_revenue_metrics_daily.triggered_revenue"],
              dataItem["company_revenue_metrics_daily.triggered_sessions"],
            ),
          "company_revenue_metrics_daily.paid_revenue_per_session": safeDivide(
            dataItem["company_revenue_metrics_daily.paid_revenue"],
            dataItem["company_revenue_metrics_daily.paid_sessions"],
          ),
        })),
      ]
    },
  },
  baseline_traffic_vs_revenue_stacked_chart: {
    q: {
      measures: ["metrics.ga_baseline_revenue", "metrics.ga_baseline_sessions"],
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
      order: {
        "metrics.date": "asc",
      },
    },
  },
  sessions_from_organic_sessions: {
    q: {
      measures: [
        "metrics.ga_sessions_from_organic_sessions",
        "metrics.ga_revenue_from_organic_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  sessions_from_direct_sessions: {
    q: {
      measures: [
        "metrics.ga_sessions_from_direct_sessions",
        "metrics.ga_revenue_from_direct_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  sessions_from_organic_referal_sessions: {
    q: {
      measures: [
        "metrics.ga_sessions_from_organic_referal_sessions",
        "metrics.ga_revenue_from_organic_referral_sessions",
      ],
      filters: [],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  session_source_and_medium_traffic_data_table: {
    q: {
      measures: [
        "company_revenue_metrics_daily.baseline_sessions",
        "company_revenue_metrics_daily.baseline_engaged_sessions",
        "company_revenue_metrics_daily.baseline_new_sessions",
        "company_revenue_metrics_daily.baseline_revenue",
      ],
      dimensions: [
        "company_revenue_metrics_daily.sessionsource",
        "company_revenue_metrics_daily.sessionmedium",
        "company_revenue_metrics_daily.companyid",
      ],
      timeDimensions: [
        {
          dimension: "company_revenue_metrics_daily.date",
        },
      ],
      filters: [
        {
          member: "company_revenue_metrics_daily.baseline_sessions",
          operator: "gt",
          values: ["0"],
        },
      ],
    },
    postProcess(data: any[]) {
      return [
        data.map(dataItem => ({
          ...dataItem,
          "company_revenue_metrics_daily.baseline_revenue_per_session":
            safeDivide(
              dataItem["company_revenue_metrics_daily.baseline_revenue"],
              dataItem["company_revenue_metrics_daily.baseline_sessions"],
            ),
        })),
      ]
    },
  },
  branded_search_clicks_stacked_chart: {
    q: {
      measures: [
        "metrics.google_search_organic_branded_search_clicks",
        "metrics.google_ads_paid_branded_search_clicks",
        "metrics.google_ads_paid_branded_search_total_spend",
      ],
      filters: [
        // TODO: Delete later if necessary
        {
          member: "metrics.metric",
          operator: "equals",
          values: [
            "google_search_organic_branded_search_clicks",
            "google_ads_paid_branded_search_clicks",
            "google_ads_paid_branded_search_total_spend",
          ],
        },
      ],
      order: {
        "metrics.date": "asc",
      },
      timeDimensions: [
        {
          dimension: "metrics.date",
        },
      ],
    },
  },
  brand_value_cohort_table: {
    q: {
      measures: [
        "company_modeled_metrics_monthly.engagements",
        "company_modeled_metrics_monthly.conversionMonth1",
        "company_modeled_metrics_monthly.conversionMonth2",
        "company_modeled_metrics_monthly.conversionMonth3",
        "company_modeled_metrics_monthly.conversionMonth4",
        "company_modeled_metrics_monthly.conversionMonth5",
        "company_modeled_metrics_monthly.conversionMonth6",
        // "company_modeled_metrics_monthly.conversionMonth7",
        // "company_modeled_metrics_monthly.conversionMonth8",
        // "company_modeled_metrics_monthly.conversionMonth9",
        // "company_modeled_metrics_monthly.conversionMonth10",
        // "company_modeled_metrics_monthly.conversionMonth11",
        // "company_modeled_metrics_monthly.conversionMonth12",
      ],
      filters: [],
      order: {
        "company_modeled_metrics_monthly.engagement_date": "asc",
      },
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.engagement_date",
        },
      ],
    },
  },
  brand_value_conversions_table: {
    q: {
      measures: [
        "company_modeled_metrics_monthly.engagements",
        "company_modeled_metrics_monthly.revenue90Days",
        "company_modeled_metrics_monthly.revenue180Days",
      ],
      dimensions: ["company_modeled_metrics_monthly.metric"],
      filters: [],
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.engagement_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const engagements = "company_modeled_metrics_monthly.engagements"
      const revenue90Days = "company_modeled_metrics_monthly.revenue90Days"
      const revenue180Days = "company_modeled_metrics_monthly.revenue180Days"
      const rpe90Days = "company_modeled_metrics_monthly.rpe90Days"
      const rpe180Days = "company_modeled_metrics_monthly.rpe180Days"

      return [
        data.map((metric: Record<string, number>) => ({
          ...metric,
          [rpe90Days]: safeDivide(metric[revenue90Days], metric[engagements]),
          [rpe180Days]: safeDivide(metric[revenue180Days], metric[engagements]),
        })),
      ]
    },
  },
  brand_value_stacked_chart: {
    q: [
      {
        measures: [
          "company_modeled_metrics_monthly.engagements",
          "company_modeled_metrics_monthly.conversions",
        ],
        order: {
          "company_modeled_metrics_monthly.engagement_date": "asc",
        },
        timeDimensions: [
          {
            dimension: "company_modeled_metrics_monthly.engagement_date",
          },
        ],
      },
      {
        measures: ["metrics.ga_baseline_revenue"],
        order: {
          "metrics.date": "asc",
        },
        timeDimensions: [
          {
            dimension: "metrics.date",
          },
        ],
      },
    ],
    postProcess: (data: any[]) => {
      const dateKey = "date.month"
      const baselineDateKey = "metrics.date.month"
      const baselineValueKey = "metrics.ga_baseline_revenue"
      const modeledDateKey =
        "company_modeled_metrics_monthly.engagement_date.month"
      const modeledConversionsKey =
        "company_modeled_metrics_monthly.conversions"
      const modeledEngagementsKey =
        "company_modeled_metrics_monthly.engagements"

      const newData: brandValueStackedChartDataPoint = {}
      for (const dataPoint of data) {
        const currentKey =
          dataPoint[baselineDateKey] || dataPoint[modeledDateKey]

        if (!newData[currentKey]) {
          newData[currentKey] = { [dateKey]: currentKey }
        }

        if (dataPoint[baselineDateKey]) {
          newData[currentKey].ga_baseline_revenue = dataPoint[baselineValueKey]
        }
        if (dataPoint[modeledDateKey]) {
          newData[currentKey].engagements = dataPoint[modeledEngagementsKey]
          newData[currentKey].conversions = dataPoint[modeledConversionsKey]
        }
      }

      const result = Object.values(newData)

      const columns = [
        {
          key: "date.month",
          dataIndex: "date.month",
        },
        {
          key: "engagements",
          type: "number",
          dataIndex: "engagements",
          title: "Company Modeled Metrics Monthly Engagements",
          shortTitle: "Engagements",
        },
        {
          key: "conversions",
          type: "currency",
          dataIndex: "conversions",
          title: "Company Modeled Metrics Monthly Conversions",
          shortTitle: "Conversions",
        },
        {
          key: "ga_baseline_revenue",
          type: "currency",
          dataIndex: "ga_baseline_revenue",
          title: "Daily Metrics Baseline Revenue",
          shortTitle: "Baseline Revenue",
        },
      ]

      return [result, columns]
    },
  },
  brand_value_line_chart: {
    q: {
      measures: ["company_modeled_metrics_monthly.conversions"],
      order: {
        "company_modeled_metrics_monthly.engagement_date": "asc",
      },
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.engagement_date",
        },
      ],
    },
  },
  brand_media_optimization_campaign_table: {
    q: (params?: { granularity?: TimeDimensionGranularity }) => {
      const prefix = MetricsMapping?.brand_media_optimization_campaign_table
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_campaign_table.getCubePrefix(
            params as Record<string, any>,
          )
        : `company_media_metrics_weekly_campaigns`
      return {
        measures: [
          `${prefix}.spend`,
          `${prefix}.shares`,
          `${prefix}.likes`,
          `${prefix}.comments`,
          `${prefix}.follows`,
          `${prefix}.saves`,
          `${prefix}.purchases`,
          `${prefix}.brand_value_agg`,
          `${prefix}.short_term_revenue_roas`,
          `${prefix}.brand_value_over_spend`,
          `${prefix}.long_term_revenue_roas`,
          `${prefix}.facebook_ads_spend`,
          `${prefix}.facebook_ads_comments`,
          `${prefix}.facebook_ads_shares`,
          `${prefix}.facebook_ads_new_fans`,
          `${prefix}.facebook_ads_reactions`,
          `${prefix}.facebook_ads_purchases`,
          `${prefix}.facebook_ads_saves`,
          `${prefix}.instagram_ads_spend`,
          `${prefix}.instagram_ads_follows`,
          `${prefix}.instagram_ads_comments`,
          `${prefix}.instagram_ads_likes`,
          `${prefix}.instagram_ads_shares`,
          `${prefix}.instagram_ads_purchases`,
          `${prefix}.instagram_ads_saves`,
          `${prefix}.tiktok_ads_spend`,
          `${prefix}.tiktok_ads_follows`,
          `${prefix}.tiktok_ads_comments`,
          `${prefix}.tiktok_ads_shares`,
          `${prefix}.tiktok_ads_likes`,
          `${prefix}.tiktok_ads_purchases`,
          `${prefix}.long_term_revenue`,
        ],
        dimensions: [
          `${prefix}.media_channel`,
          `${prefix}.campaign_name`,
          `${prefix}.campaign_id`,
          `${prefix}.brand_value`,
        ],
      }
    },
    getCubePrefix: (params?: { granularity?: TimeDimensionGranularity }) =>
      `company_media_metrics_${params?.granularity ? `${params?.granularity}ly` : "weekly"}_campaigns`,
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const prefix = MetricsMapping?.brand_media_optimization_campaign_table
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_campaign_table.getCubePrefix({
            granularity,
          } as Record<string, any>)
        : `company_media_metrics_weekly_campaigns`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            media_channel:
              MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
                itemWithSimpleKeys.media_channel
              ] ?? itemWithSimpleKeys.media_channel,
          }
        }),
      ]
    },
  },
  brand_media_optimization_campaign_table_summary: {
    q: (params?: { granularity?: TimeDimensionGranularity }) => {
      const prefix = MetricsMapping
        ?.brand_media_optimization_campaign_table_summary?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_campaign_table_summary.getCubePrefix(
            params as Record<string, any>,
          )
        : `company_media_metrics_weekly_campaigns`
      return {
        measures: [
          `${prefix}.spend`,
          `${prefix}.shares`,
          `${prefix}.likes`,
          `${prefix}.comments`,
          `${prefix}.follows`,
          `${prefix}.saves`,
          `${prefix}.purchases`,
          `${prefix}.brand_value_agg`,
          `${prefix}.facebook_ads_spend`,
          `${prefix}.facebook_ads_comments`,
          `${prefix}.facebook_ads_shares`,
          `${prefix}.facebook_ads_new_fans`,
          `${prefix}.facebook_ads_reactions`,
          `${prefix}.facebook_ads_purchases`,
          `${prefix}.facebook_ads_saves`,
          `${prefix}.instagram_ads_spend`,
          `${prefix}.instagram_ads_follows`,
          `${prefix}.instagram_ads_comments`,
          `${prefix}.instagram_ads_likes`,
          `${prefix}.instagram_ads_shares`,
          `${prefix}.instagram_ads_purchases`,
          `${prefix}.instagram_ads_saves`,
          `${prefix}.tiktok_ads_spend`,
          `${prefix}.tiktok_ads_follows`,
          `${prefix}.tiktok_ads_comments`,
          `${prefix}.tiktok_ads_shares`,
          `${prefix}.tiktok_ads_likes`,
          `${prefix}.tiktok_ads_purchases`,
          `${prefix}.long_term_revenue`,
        ],
      }
    },
    getCubePrefix: (params?: { granularity?: TimeDimensionGranularity }) =>
      `company_media_metrics_${params?.granularity ? `${params?.granularity}ly` : "weekly"}_campaigns`,
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const prefix = MetricsMapping
        ?.brand_media_optimization_campaign_table_summary?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_campaign_table_summary.getCubePrefix(
            {
              granularity,
            } as Record<string, any>,
          )
        : `company_media_metrics_weekly_campaigns`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })

          return {
            ...itemWithSimpleKeys,
            short_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.purchases,
              itemWithSimpleKeys.spend,
            ),
            brand_value_over_spend: safeDivide(
              itemWithSimpleKeys.brand_value_agg,
              itemWithSimpleKeys.spend,
            ),
            long_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.long_term_revenue,
              itemWithSimpleKeys.spend,
            ),
          }
        }),
      ]
    },
  },
  brand_media_optimization_adsets_table: {
    q: (params?: { granularity?: TimeDimensionGranularity }) => {
      const prefix = MetricsMapping?.brand_media_optimization_adsets_table
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_adsets_table.getCubePrefix(
            params as Record<string, any>,
          )
        : `company_media_metrics_weekly_adsets`
      return {
        measures: [
          `${prefix}.spend`,
          `${prefix}.shares`,
          `${prefix}.likes`,
          `${prefix}.comments`,
          `${prefix}.follows`,
          `${prefix}.saves`,
          `${prefix}.purchases`,
          `${prefix}.brand_value_agg`,
          `${prefix}.short_term_revenue_roas`,
          `${prefix}.brand_value_over_spend`,
          `${prefix}.long_term_revenue_roas`,
          `${prefix}.facebook_ads_spend`,
          `${prefix}.facebook_ads_comments`,
          `${prefix}.facebook_ads_shares`,
          `${prefix}.facebook_ads_new_fans`,
          `${prefix}.facebook_ads_reactions`,
          `${prefix}.facebook_ads_purchases`,
          `${prefix}.facebook_ads_saves`,
          `${prefix}.instagram_ads_spend`,
          `${prefix}.instagram_ads_follows`,
          `${prefix}.instagram_ads_comments`,
          `${prefix}.instagram_ads_likes`,
          `${prefix}.instagram_ads_shares`,
          `${prefix}.instagram_ads_purchases`,
          `${prefix}.instagram_ads_saves`,
          `${prefix}.tiktok_ads_spend`,
          `${prefix}.tiktok_ads_follows`,
          `${prefix}.tiktok_ads_comments`,
          `${prefix}.tiktok_ads_shares`,
          `${prefix}.tiktok_ads_likes`,
          `${prefix}.tiktok_ads_purchases`,
          `${prefix}.long_term_revenue`,
        ],
        dimensions: [
          `${prefix}.media_channel`,
          `${prefix}.campaign_name`,
          `${prefix}.adset_name`,
          `${prefix}.adset_id`,
          `${prefix}.campaign_id`,
          `${prefix}.brand_value`,
        ],
      }
    },
    getCubePrefix: (params?: { granularity?: TimeDimensionGranularity }) =>
      `company_media_metrics_${params?.granularity ? `${params?.granularity}ly` : "weekly"}_adsets`,
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const prefix = MetricsMapping?.brand_media_optimization_adsets_table
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_adsets_table.getCubePrefix({
            granularity,
          } as Record<string, any>)
        : `company_media_metrics_weekly_adsets`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            media_channel:
              MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
                itemWithSimpleKeys.media_channel
              ] ?? itemWithSimpleKeys.media_channel,
          }
        }),
      ]
    },
  },
  brand_media_optimization_adsets_table_summary: {
    q: (params?: { granularity?: TimeDimensionGranularity }) => {
      const prefix = MetricsMapping
        ?.brand_media_optimization_adsets_table_summary?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_adsets_table_summary.getCubePrefix(
            params as Record<string, any>,
          )
        : `company_media_metrics_weekly_adsets`
      return {
        measures: [
          `${prefix}.spend`,
          `${prefix}.shares`,
          `${prefix}.likes`,
          `${prefix}.comments`,
          `${prefix}.follows`,
          `${prefix}.saves`,
          `${prefix}.purchases`,
          `${prefix}.brand_value_agg`,
          `${prefix}.facebook_ads_spend`,
          `${prefix}.facebook_ads_comments`,
          `${prefix}.facebook_ads_shares`,
          `${prefix}.facebook_ads_new_fans`,
          `${prefix}.facebook_ads_reactions`,
          `${prefix}.facebook_ads_saves`,
          `${prefix}.facebook_ads_purchases`,
          `${prefix}.instagram_ads_spend`,
          `${prefix}.instagram_ads_follows`,
          `${prefix}.instagram_ads_comments`,
          `${prefix}.instagram_ads_likes`,
          `${prefix}.instagram_ads_shares`,
          `${prefix}.instagram_ads_purchases`,
          `${prefix}.instagram_ads_saves`,
          `${prefix}.tiktok_ads_spend`,
          `${prefix}.tiktok_ads_follows`,
          `${prefix}.tiktok_ads_comments`,
          `${prefix}.tiktok_ads_shares`,
          `${prefix}.tiktok_ads_likes`,
          `${prefix}.tiktok_ads_purchases`,
          `${prefix}.long_term_revenue`,
        ],
      }
    },
    getCubePrefix: (params?: { granularity?: TimeDimensionGranularity }) =>
      `company_media_metrics_${params?.granularity ? `${params?.granularity}ly` : "weekly"}_adsets`,
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const prefix = MetricsMapping
        ?.brand_media_optimization_adsets_table_summary?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_adsets_table_summary.getCubePrefix(
            {
              granularity,
            } as Record<string, any>,
          )
        : `company_media_metrics_weekly_adsets`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            short_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.purchases,
              itemWithSimpleKeys.spend,
            ),
            brand_value_over_spend: safeDivide(
              itemWithSimpleKeys.brand_value_agg,
              itemWithSimpleKeys.spend,
            ),
            long_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.long_term_revenue,
              itemWithSimpleKeys.spend,
            ),
          }
        }),
      ]
    },
  },
  brand_media_optimization_ads_table: {
    q: (params?: { granularity?: TimeDimensionGranularity }) => {
      const prefix = MetricsMapping?.brand_media_optimization_ads_table
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_ads_table.getCubePrefix(
            params as Record<string, any>,
          )
        : `company_media_metrics_weekly_ads`
      return {
        measures: [
          `${prefix}.spend`,
          `${prefix}.shares`,
          `${prefix}.likes`,
          `${prefix}.comments`,
          `${prefix}.follows`,
          `${prefix}.saves`,
          `${prefix}.purchases`,
          `${prefix}.brand_value_agg`,
          `${prefix}.short_term_revenue_roas`,
          `${prefix}.brand_value_over_spend`,
          `${prefix}.long_term_revenue_roas`,
          `${prefix}.facebook_ads_spend`,
          `${prefix}.facebook_ads_comments`,
          `${prefix}.facebook_ads_shares`,
          `${prefix}.facebook_ads_new_fans`,
          `${prefix}.facebook_ads_reactions`,
          `${prefix}.facebook_ads_saves`,
          `${prefix}.facebook_ads_purchases`,
          `${prefix}.instagram_ads_spend`,
          `${prefix}.instagram_ads_follows`,
          `${prefix}.instagram_ads_comments`,
          `${prefix}.instagram_ads_likes`,
          `${prefix}.instagram_ads_shares`,
          `${prefix}.instagram_ads_purchases`,
          `${prefix}.instagram_ads_saves`,
          `${prefix}.tiktok_ads_spend`,
          `${prefix}.tiktok_ads_follows`,
          `${prefix}.tiktok_ads_comments`,
          `${prefix}.tiktok_ads_shares`,
          `${prefix}.tiktok_ads_likes`,
          `${prefix}.tiktok_ads_purchases`,
          `${prefix}.long_term_revenue`,
        ],
        dimensions: [
          `${prefix}.media_channel`,
          `${prefix}.campaign_name`,
          `${prefix}.adset_name`,
          `${prefix}.ad_name`,
          `${prefix}.ad_id`,
          `${prefix}.adset_id`,
          `${prefix}.campaign_id`,
          `${prefix}.brand_value`,
        ],
      }
    },
    getCubePrefix: (params?: { granularity?: TimeDimensionGranularity }) =>
      `company_media_metrics_${params?.granularity ? `${params?.granularity}ly` : "weekly"}_ads`,
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const prefix = MetricsMapping?.brand_media_optimization_ads_table
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_ads_table.getCubePrefix({
            granularity,
          } as Record<string, any>)
        : `company_media_metrics_weekly_ads`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            media_channel:
              MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
                itemWithSimpleKeys.media_channel
              ] ?? itemWithSimpleKeys.media_channel,
          }
        }),
      ]
    },
  },
  brand_media_optimization_ads_table_summary: {
    q: (params?: { granularity?: TimeDimensionGranularity }) => {
      const prefix = MetricsMapping?.brand_media_optimization_ads_table_summary
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_ads_table_summary.getCubePrefix(
            params as Record<string, any>,
          )
        : `company_media_metrics_weekly_ads`
      return {
        measures: [
          `${prefix}.spend`,
          `${prefix}.shares`,
          `${prefix}.likes`,
          `${prefix}.comments`,
          `${prefix}.follows`,
          `${prefix}.saves`,
          `${prefix}.purchases`,
          `${prefix}.brand_value_agg`,
          `${prefix}.facebook_ads_spend`,
          `${prefix}.facebook_ads_comments`,
          `${prefix}.facebook_ads_shares`,
          `${prefix}.facebook_ads_new_fans`,
          `${prefix}.facebook_ads_reactions`,
          `${prefix}.facebook_ads_saves`,
          `${prefix}.facebook_ads_purchases`,
          `${prefix}.instagram_ads_spend`,
          `${prefix}.instagram_ads_follows`,
          `${prefix}.instagram_ads_comments`,
          `${prefix}.instagram_ads_likes`,
          `${prefix}.instagram_ads_shares`,
          `${prefix}.instagram_ads_purchases`,
          `${prefix}.instagram_ads_saves`,
          `${prefix}.tiktok_ads_spend`,
          `${prefix}.tiktok_ads_follows`,
          `${prefix}.tiktok_ads_comments`,
          `${prefix}.tiktok_ads_shares`,
          `${prefix}.tiktok_ads_likes`,
          `${prefix}.tiktok_ads_purchases`,
          `${prefix}.long_term_revenue`,
        ],
      }
    },
    getCubePrefix: (params?: { granularity?: TimeDimensionGranularity }) =>
      `company_media_metrics_${params?.granularity ? `${params?.granularity}ly` : "weekly"}_ads`,
    postProcess: (
      data: any[],
      params: { granularity?: TimeDimensionGranularity },
    ) => {
      const { granularity } = params
      const prefix = MetricsMapping?.brand_media_optimization_ads_table_summary
        ?.getCubePrefix
        ? MetricsMapping.brand_media_optimization_ads_table_summary.getCubePrefix(
            {
              granularity,
            } as Record<string, any>,
          )
        : `company_media_metrics_weekly_ads`
      return [
        data.map(item => {
          const itemWithSimpleKeys: Record<string, any> = {}

          Object.keys(item).forEach(key => {
            const shortKey = key.replace(`${prefix}.`, "")
            itemWithSimpleKeys[shortKey] = item[key]
          })
          return {
            ...itemWithSimpleKeys,
            short_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.purchases,
              itemWithSimpleKeys.spend,
            ),
            brand_value_over_spend: safeDivide(
              itemWithSimpleKeys.brand_value_agg,
              itemWithSimpleKeys.spend,
            ),
            long_term_revenue_roas: safeDivide(
              itemWithSimpleKeys.long_term_revenue,
              itemWithSimpleKeys.spend,
            ),
          }
        }),
      ]
    },
  },
  brand_media_organic_social_posts_table: {
    q: {
      dimensions: [
        "company_media_metrics_posts.post_id",
        "company_media_metrics_posts.media_channel",
        "company_media_metrics_posts.post_preview",
        "company_media_metrics_posts.post_caption",
        "company_media_metrics_posts.post_link",
        "company_media_metrics_posts.post_date",
        "company_media_metrics_posts.brand_value",
      ],
      measures: [
        "company_media_metrics_posts.brand_value_agg",
        "company_media_metrics_posts.comments",
        "company_media_metrics_posts.follows",
        "company_media_metrics_posts.likes",
        "company_media_metrics_posts.reach",
        "company_media_metrics_posts.saves",
        "company_media_metrics_posts.shares",
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_posts.post_date",
        },
      ],
    },
    postProcess: (data: any[]) => {
      const brandValueKey = "company_media_metrics_posts.brand_value_agg"
      return [
        data.map(item => ({
          ...item,
          [brandValueKey]:
            Number.isNaN(item[brandValueKey]) || item[brandValueKey] === null
              ? "- -"
              : item[brandValueKey],
        })),
      ]
    },
  },
  brand_media_organic_social_posts_table_summary: {
    q: {
      measures: [
        "company_media_metrics_posts.brand_value_agg",
        "company_media_metrics_posts.comments",
        "company_media_metrics_posts.follows",
        "company_media_metrics_posts.likes",
        "company_media_metrics_posts.reach",
        "company_media_metrics_posts.saves",
        "company_media_metrics_posts.shares",
      ],
      timeDimensions: [
        {
          dimension: "company_media_metrics_posts.post_date",
        },
      ],
    },
  },
  subscribe_page_paid_media_card: {
    q: {
      measures: ["company_media_metrics_monthly_campaigns.brand_value_agg"],
      dimensions: [
        "company_media_metrics_monthly_campaigns.media_channel",
        "company_media_metrics_monthly_campaigns.campaign_name",
      ],
      limit: 4,
      order: {
        "company_media_metrics_monthly_campaigns.brand_value_agg": "desc",
      },
    },
  },
  subscribe_page_organic_media_card: {
    q: {
      measures: ["company_media_metrics_posts.brand_value_agg"],
      dimensions: [
        "company_media_metrics_posts.media_channel",
        "company_media_metrics_posts.post_caption",
      ],
      limit: 4,
      order: {
        "company_media_metrics_posts.brand_value_agg": "desc",
      },
    },
  },
  subscribe_page_brand_value_card: {
    q: {
      measures: ["company_modeled_metrics_monthly.conversions"],
      timeDimensions: [
        {
          dimension: "company_modeled_metrics_monthly.conversion_start",
          dateRange: "last 12 months",
        },
      ],
      limit: 1,
    },
  },
}

export const GranularityMapping: Record<string, string> = {
  [AggregationType.DAILY]: "day",
  [AggregationType.MONTHLY]: "month",
  [AggregationType.WEEKLY]: "week",
  [BrandMediaAggregationType.QUARTERLY]: "quarter",
  [BrandMediaAggregationType.YEARLY]: "year",
}
