import React, { RefObject, useRef } from "react"

import { DateInput } from "~/ui-rtk/components/ui/controls"
import { ChevronRightSvg } from "~/ui-rtk/components/ui/svg/arrow"

import {
  RangeTags,
  type DateOrNull,
} from "~/ui-rtk/components/ui/controls/PeriodPicker/types"

type TCompareDatesProps = {
  isComparisonValue?: boolean
  startDate?: Date | null
  endDate?: Date | null
  onChange: (
    date: DateOrNull | undefined,
    tag: RangeTags,
    // componentType: PeriodPickerField,
    close: boolean,
  ) => void
  onFocus: (tag: RangeTags) => void
  onTab?: () => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  startDateRef?: RefObject<HTMLInputElement>
}

const CompareDates: React.FC<TCompareDatesProps> = ({
  isComparisonValue = false,
  startDate,
  endDate,
  onChange,
  onFocus,
  startDateRef,
  onBlur,
  onTab = () => null,
}) => {
  const endDateRef = useRef<HTMLInputElement>(null)

  const startTag = isComparisonValue
    ? RangeTags.compareRangeStart
    : RangeTags.rangeStart

  const endTag = isComparisonValue
    ? RangeTags.compareRangeEnd
    : RangeTags.rangeEnd

  const handleTab = (inputIdx: number) => {
    if (inputIdx === 0) {
      endDateRef.current?.focus()
    } else {
      onTab()
    }
  }

  const handleFocusStartTag = () => {
    onFocus(startTag)
  }

  const handleFocusEndTag = () => {
    onFocus(endTag)
  }

  return (
    <div className="flex flex-row gap-3">
      <div className="flex items-center">
        <DateInput
          itemRef={startDateRef as string & RefObject<HTMLInputElement>}
          id="range-from"
          label="From"
          placeholder="Start date"
          value={startDate}
          onFocus={handleFocusStartTag}
          onChange={(date: DateOrNull, close: boolean) => {
            onChange(date, startTag, close)
          }}
          onTab={() => handleTab(0)}
          onBlur={onBlur}
        />
      </div>
      <ChevronRightSvg
        className="self-end mx-auto mb-3"
        size={24}
        fill="background.light"
      />
      <div>
        <DateInput
          itemRef={endDateRef as string & RefObject<HTMLInputElement>}
          id="range-to"
          label="To"
          placeholder="End date"
          value={endDate}
          onFocus={handleFocusEndTag}
          onChange={(date: Date | null, close: boolean) =>
            onChange(date, endTag, close)
          }
          onTab={() => handleTab(1)}
          onBlur={onBlur}
        />
      </div>
    </div>
  )
}

export default CompareDates
