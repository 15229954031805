import React, { memo, useState } from "react"

import ToggleButton from "../ToggleButton"
import { Loader } from "~/ui-rtk/components/ui/common"
import { useConnect } from "./connect"

import { formatDate } from "../ConnectorStatus/utils"
import { renderStatusIcon } from "../../utils"
import { defaultImages } from "~/ui-rtk/shared/constants/image-paths.contants"

type TAccountInfoProps = {
  confirmedAt?: string
  companyConnectorId: string
}

const AccountInfo: React.FC<TAccountInfoProps> = ({
  confirmedAt,
  companyConnectorId,
}) => {
  const { accountInfo, isLoading } = useConnect(companyConnectorId)
  const [showAccountDetails, setShowAccountDetails] = useState(true)

  const toggleAccountDetails = () => {
    setShowAccountDetails(prev => !prev)
  }

  if (isLoading) {
    // TODO: Add lazy loader
    return <Loader />
  }

  const isAccountInfo =
    Boolean(accountInfo?.accountName) && Boolean(accountInfo?.metric)

  return (
    <article className="max-w-89 w-full mb-10">
      {isAccountInfo && accountInfo ? (
        <>
          <div className="flex items-center justify-between mb-5">
            <b className="text-4 leading-7 font-bold text-platinum">
              Account Details:
            </b>
            <p className="text-4 leading-7 text-platinum">
              {accountInfo?.accountName || "Not provided"}
            </p>
            <ToggleButton
              onToggle={toggleAccountDetails}
              initial={showAccountDetails}
            />
          </div>

          <div className="mb-5">
            {showAccountDetails ? (
              <div className="flex justify-center gap-4">
                <img
                  src={accountInfo?.avatarUrl || defaultImages.noImage}
                  alt={`${accountInfo.accountName} Icon`}
                  width={42}
                  height={42}
                />
                <div className="flex flex-col items-start">
                  <b>{accountInfo.accountName}</b>
                  <p>{accountInfo.metric}</p>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      <div className="space-y-3">
        <p className="text-4 leading-6 text-platinum flex items-center justify-center gap-2">
          {confirmedAt ? `Confirmed ${formatDate(confirmedAt)}` : "Unconfirmed"}{" "}
          {renderStatusIcon(confirmedAt ? "healthy" : "unhealthy")}
        </p>
      </div>
    </article>
  )
}

export default memo(AccountInfo)
