import { useEffect, useMemo, useRef } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import Button from "~/ui-rtk/components/ui/controls/Button"

import { brandRoutes } from "~/ui-rtk/constants/routes/brand-routes"
import useConnect from "./connect"
import { Loader } from "~/ui-rtk/components/ui/common"

const SESSION_KEY = "stripe_session_id"

export const Confirm = () => {
  const navigate = useStableNavigate()
  const { confirmed, handleConfirm, error, isLoading } = useConnect()

  const stripeSessionId = useMemo(
    () => new URLSearchParams(window.location.search).get(SESSION_KEY),
    [window.location],
  )

  const initialized = useRef(false)

  useEffect(() => {
    if (stripeSessionId && !initialized.current) {
      initialized.current = true
      handleConfirm(stripeSessionId).catch((e: Error) => {
        console.error(e)
      })
    }
  }, [stripeSessionId, isLoading])

  const Success = () => (
    <>
      {/* Ripple background container */}
      <div className="absolute inset-0 flex items-center justify-center z-0">
        <div className="ripple ripple-1"></div>
        <div className="ripple ripple-2"></div>
        <div className="ripple ripple-3"></div>
      </div>
      <div className="relative z-10 mx-auto max-w-2xl text-center justify-center">
        <h2 className="sm:text-5xl text-5xl font-semibold tracking-tight bg-gradient-to-br from-pink-orange-gradient-start from-25% to-pink-orange-gradient-end to-100% text-transparent bg-clip-text">
          Woohoo!
          <br />
          You’re Officially Subscribed!
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-2xl leading-8 text-gray-600">
          You’ve made a great choice
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            variant={{
              variant: "outlined",
            }}
            isLoading={!confirmed}
            onClick={e => {
              e.preventDefault()
              navigate(brandRoutes.BRAND_VALUE.OVERVIEW)
            }}
          >
            Show How it Works
          </Button>
          <Button
            isLoading={!confirmed}
            onClick={e => {
              e.preventDefault()
              navigate(brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION)
            }}
          >
            Explore my Brand Value
          </Button>
        </div>
      </div>
    </>
  )
  const Error = () => (
    <>
      <div className="relative z-10 mx-auto max-w-2xl text-center justify-center">
        <h2 className="sm:text-5xl text-5xl font-semibold tracking-tight bg-gradient-to-br from-pink-orange-gradient-start from-25% to-pink-orange-gradient-end to-100% text-transparent bg-clip-text">
          Oops!
          <br />
          Subscription failed
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-2xl leading-8 text-gray-600">
          It looks like there was an issue processing your subscription
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            isLoading={!confirmed}
            onClick={e => {
              e.preventDefault()
              navigate(brandRoutes.SETUP.CONNECTORS)
            }}
          >
            Back Home
          </Button>
        </div>
      </div>
    </>
  )

  return (
    <div className="h-screen flex flex-col items-center justify-center relative overflow-hidden">
      {isLoading && <Loader size={200} />}
      {error && !isLoading && Error()}
      {!error && !isLoading && Success()}
    </div>
  )
}
