import { useSearchParams } from "react-router-dom"

export function useURLSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const setParams = (payload: Record<string, string | undefined>) => {
    setSearchParams(prev => {
      Object.keys(payload).forEach(key => {
        if (payload[key]) {
          prev.set(key, payload[key] ?? "")
        } else {
          prev.delete(key)
        }
      })
      return prev
    })
  }

  return {
    params: Object.fromEntries(searchParams.entries()),
    setParams,
  }
}

export function useQueryParams() {
  const [searchParams] = useSearchParams()
  return Object.fromEntries(searchParams.entries())
}
