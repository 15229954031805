import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const SendArrowSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 2.75C4.25 2.33579 4.58579 2 5 2H15.27C15.6842 2 16.02 2.33579 16.02 2.75C16.02 3.16421 15.6842 3.5 15.27 3.5H6.81066L19.5303 16.2197C19.8232 16.5126 19.8232 16.9874 19.5303 17.2803C19.2374 17.5732 18.7626 17.5732 18.4697 17.2803L5.75 4.56066V13.02C5.75 13.4342 5.41421 13.77 5 13.77C4.58579 13.77 4.25 13.4342 4.25 13.02V2.75ZM2.75 21.25C2.75 20.8358 3.08579 20.5 3.5 20.5H20.5C20.9142 20.5 21.25 20.8358 21.25 21.25C21.25 21.6642 20.9142 22 20.5 22H3.5C3.08579 22 2.75 21.6642 2.75 21.25Z"
      fill={fill}
    />
  </svg>
))
