import { MutableRefObject, useEffect } from "react"
import { useLocation } from "react-router-dom"

export const useScrollRestoration = (
  ref?: MutableRefObject<HTMLDivElement | null>,
) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (ref) {
      ref.current?.scrollTo(0, 0)
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname, ref])

  return null
}
