import { useInView } from "react-intersection-observer"
import Chart, { TChartProps } from "../Chart"

export default function ChartLoader(props: TChartProps) {
  const { inView, ref } = useInView()
  const content = inView ? <Chart {...props} /> : null

  return (
    <div className={`${props.className}`} ref={ref}>
      {content}
    </div>
  )
}
