import SvgHOC from "../../common/SvgHOC"

export const UndoSvg = SvgHOC(({ fill, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.96033 5.15967C7.25322 5.45257 7.25322 5.92744 6.96033 6.22033L5.62065 7.56H15.13C18.3042 7.56 20.88 10.1358 20.88 13.31C20.88 16.4842 18.3042 19.06 15.13 19.06H7.13C6.71578 19.06 6.38 18.7242 6.38 18.31C6.38 17.8958 6.71578 17.56 7.13 17.56H15.13C17.4758 17.56 19.38 15.6558 19.38 13.31C19.38 10.9642 17.4758 9.06 15.13 9.06H5.74066L6.96033 10.2797C7.25322 10.5726 7.25322 11.0474 6.96033 11.3403C6.66743 11.6332 6.19256 11.6332 5.89966 11.3403L3.33966 8.78033C3.04677 8.48744 3.04677 8.01257 3.33967 7.71967L5.89966 5.15967C6.19256 4.86678 6.66743 4.86678 6.96033 5.15967Z"
      fill={fill}
    />
  </svg>
))
