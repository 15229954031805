import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ChevronRightSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9209 3.54975C8.2138 3.25685 8.68867 3.25685 8.98156 3.54975L15.5016 10.0697C16.5645 11.1326 16.5645 12.8675 15.5016 13.9304L8.98156 20.4504C8.68867 20.7433 8.2138 20.7433 7.9209 20.4504C7.62801 20.1575 7.62801 19.6826 7.9209 19.3897L14.4409 12.8697C14.918 12.3926 14.918 11.6075 14.4409 11.1304L7.9209 4.61041C7.62801 4.31752 7.62801 3.84264 7.9209 3.54975Z"
      fill={fill}
    />
  </svg>
))
