import React, { useCallback, useMemo, useEffect } from "react"
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { isEmpty } from "lodash"

import { LogoSvg } from "~/ui-rtk/components/ui/svg/business/LogoSvg"
import { ProfileMenu } from "~/ui-rtk/components/layout/Topbar/components"
import { Button } from "~/ui-rtk/components/ui/controls"
import PagePermissionGuard from "~/ui-rtk/components/features/guards/PagePermissionGuard"
import { useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation } from "~/ui-rtk/api/companyConnectorApi"

import ConfirmEmail from "../ConfirmEmail"
import { PricingDetails } from "../PricingDetails"
import { CheckInbox, AccountDetails } from "./BrandInfo"

import { useSessionHistory } from "./hooks/useSessionHistory"
import { guestRoutes, onboardingRoutes } from "~/ui-rtk/constants/routes"
import { ConnectionFlowPage } from "./ConnectionFlow"
import AuthenticateConnector from "../AuthenticateConnector"
import { CompanySetupStatus } from "~/ui-rtk/api/types"

type Route = keyof typeof onboardingRoutes

const routeMap = {
  [onboardingRoutes.KEYWORDS]: onboardingRoutes.SOURCES,
  [onboardingRoutes.DATA_PENDING]: onboardingRoutes.KEYWORDS,
  [onboardingRoutes.PACKAGE]: onboardingRoutes.KEYWORDS,
}

const AdhocConnector = () => {
  const [searchParams] = useSearchParams()
  const fivetranId = searchParams.get("id")
  const navigate = useStableNavigate()
  const [completeAsync] =
    useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation()

  useEffect(() => {
    const completeOnboarding = async (id: string) => {
      try {
        await completeAsync({
          completeOnboardingDto: { fivetranId: id },
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (fivetranId) {
      void completeOnboarding(fivetranId)
    }
    navigate("/somewhere")
  }, [fivetranId])

  return null
}

const Onboarding = () => {
  const navigate = useStableNavigate()
  const { session, redirectRoute } = useSessionHistory()

  const withGuard = (children: React.ReactNode, route: Route) => {
    const isAllowed = useMemo(() => {
      switch (route) {
        case "CHECK_INBOX":
          return !session?.user.isConfirmed

        case "ACCOUNT_DETAILS": {
          const isConfirmed = Boolean(session?.user.isConfirmed)
          const hasCompany = !isEmpty(session?.currentCompany)
          return isConfirmed && !hasCompany
        }

        case "CONNECTION_FLOW": {
          const isCompleted = Boolean(
            session?.currentCompany.setupStatus === CompanySetupStatus.COMPLETE,
          )
          return !isCompleted
        }

        default:
          return true
      }
    }, [session])

    return (
      <PagePermissionGuard isAllowed={isAllowed}>
        {children}
      </PagePermissionGuard>
    )
  }

  const handleNavigate = (route: string) => () => {
    navigate(route)
  }

  const renderBackButton = useCallback(() => {
    const pathname = location.pathname
    const prevRoute = routeMap[pathname]
    if (!prevRoute) {
      return
    }

    return (
      <Button variant={{ variant: "text" }} onClick={handleNavigate(prevRoute)}>
        Back
      </Button>
    )
  }, [location])

  return (
    <div className="relative flex flex-col justify-center flex-1 min-h-full px-6 py-12 overflow-hidden lg:px-8 bg-basic-black text-gray-ligh">
      <div className="absolute top-0 left-0 right-0 z-50">
        <header className="flex items-center justify-between px-4 py-3">
          <LogoSvg width={177} height={26} />
          <ProfileMenu config={{ disabledActions: ["settings"] }} />
        </header>
      </div>

      <div>
        <div>{renderBackButton()}</div>

        <Routes>
          <Route
            path={onboardingRoutes.CHECK_INBOX}
            element={withGuard(<CheckInbox />, "CHECK_INBOX")}
          />
          <Route
            path={onboardingRoutes.ACCOUNT_DETAILS}
            element={withGuard(<AccountDetails />, "ACCOUNT_DETAILS")}
          />
          <Route
            path={guestRoutes.PRICING_DETAILS}
            element={<PricingDetails />}
          />
          <Route
            path={onboardingRoutes.CONNECTION_FLOW}
            element={withGuard(<ConnectionFlowPage />, "CONNECTION_FLOW")}
          />
          <Route
            path={onboardingRoutes.AUTHENTICATE_CONNECTOR}
            element={<AuthenticateConnector />}
          />
          <Route path={"/setup/connectors"} element={<AdhocConnector />} />
          <Route path={guestRoutes.CONFIRM_EMAIL} element={<ConfirmEmail />} />
          <Route path="*" element={<Navigate to={redirectRoute} />} />
        </Routes>
      </div>
    </div>
  )
}

export default Onboarding
