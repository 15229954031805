import { SuccessSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { Button } from "~/ui-rtk/components/ui/controls"
import { ContentBox, Divider } from "~/ui-rtk/components/ui/common"

import { DATA_PENDING_INSTRUCTIONS } from "./constants"

const PendingDataConnection = () => {
  const handleRedirect = () => {
    window.location.reload()
  }

  return (
    <ContentBox
      title="You’re connected!"
      subTitle="You have successfully connected your brand behavior sources, and are almost ready to dive into the world of performance brand building."
      classes={{ content: "max-w-200" }}
    >
      <div className="flex items-center w-full gap-6">
        <Divider className="w-full border-basic-white/15" />
        <p className="leading-5 tracking-[-1%] text-white text-nowrap">
          What's next?
        </p>
        <Divider className="w-full border-basic-white/15" />
      </div>

      <ul className="flex flex-col gap-8">
        {DATA_PENDING_INSTRUCTIONS.map(instruction => (
          <li key={instruction.id} className="flex items-start gap-4">
            <SuccessSvg fill="verse-green" size={22} />
            <p className="leading-5 tracking-[-1%] text-white">
              {instruction.content}
            </p>
          </li>
        ))}
      </ul>

      <Button className="self-center min-w-48" onClick={handleRedirect}>
        Done
      </Button>
    </ContentBox>
  )
}

export default PendingDataConnection
