import { SignInForm } from "~/ui-rtk/components/features/forms"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { Button } from "../components/ui/controls"
import { GuestLayout } from "../components/layout"
import { ContentBox } from "~/ui-rtk/components/ui/common"

export default function SignIn() {
  const navigate = useStableNavigate()

  const redirectToSignUp = () => {
    void navigate("/onboarding/create-account")
  }

  return (
    <GuestLayout>
      <ContentBox
        title="Sign in to your account"
        subTitle="Unlock an entirely new perspective on your brand"
      >
        <SignInForm />
        <div className="flex items-center justify-center gap-2 mt-10">
          <span className="font-bold leading-6 text-white">
            Don't have an account?
          </span>

          <div className="leading-6">
            <Button
              variant={{ variant: "text" }}
              onClick={redirectToSignUp}
              className="p-0 font-bold text-basic-pink hover:text-indigo-500"
            >
              Sign up here
            </Button>
          </div>
        </div>
      </ContentBox>
    </GuestLayout>
  )
}
