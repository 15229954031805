import { useCallback, useMemo } from "react"
import {
  type Query as TCubeQuery,
  Filter,
  ResultSet,
  TimeDimensionGranularity,
} from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP, getCubeQueryBase } from "../../constants"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import useCampaignAdSetAdDetails, {
  getWeekYearMonthQuarterYearFilters,
  prepareAllItemsDetails,
  prepareValueOverTimeData,
  useAllCampaignsAdSetsAdsDetails,
} from "~/ui-rtk/hooks/campaign-adset-ad-details"
import { getPivotData } from "~/ui-rtk/utils/cube"

import {
  GranularityMapping,
  MetricsMapping,
} from "~/ui-rtk/constants/metrics-mapping"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"

const defaultPrefixKey = "company_media_metrics_weekly_campaigns"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

export const useAllCampaignsCubeQuery = (
  date: string,
  granularity: BrandMediaAggregationType,
) => {
  const { postProcess, getCubePrefix } =
    MetricsMapping.brand_media_optimization_campaign_table
  const prefixKey = getCubePrefix
    ? getCubePrefix({
        granularity: GranularityMapping[granularity],
      })
    : defaultPrefixKey
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}.${date}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allCampaignsFilters = useMemo(
    () => getWeekYearMonthQuarterYearFilters(date, prefixKey, granularity),
    [prefixKey, date, granularity],
  )

  const allCampaignsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [...campaignQuery.dimensions, campaignIdKey],
        filters: allCampaignsFilters,
      }
    }
    return requests[queryAllCampaignKey]
  }, [
    queryAllCampaignKey,
    allCampaignsFilters,
    campaignQuery,
    purchaseKey,
    campaignIdKey,
    granularity,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allCampaignsSet, isLoading } =
    useAllCampaignsAdSetsAdsDetails(allCampaignsQuery)

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allCampaignsSet) {
    const pivotData = getPivotData(
      allCampaignsSet as ResultSet<any>,
      allCampaignsQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    results[cacheKey].allCampaignsDetails =
      prepareAllItemsDetails(processedPivotData)
  }

  const { allCampaignsDetails } = results[cacheKey]

  return { allCampaignsDetails, isLoading }
}

export const useCampaignQubeQuery = (
  campaignId: string,
  date: string,
  granularity: BrandMediaAggregationType,
) => {
  const { postProcess, getCubePrefix } =
    MetricsMapping.brand_media_optimization_campaign_table
  const prefixKey = getCubePrefix
    ? getCubePrefix({
        granularity: GranularityMapping[granularity],
      })
    : defaultPrefixKey
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}.${date}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const queryValueOverTimeKey = `${cacheKey}.${campaignId}.value`

  const yearKey = `${prefixKey}.year`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`

  let customKey: string
  switch (granularity) {
    case BrandMediaAggregationType.WEEKLY: {
      customKey = `${prefixKey}.week`
      break
    }
    case BrandMediaAggregationType.MONTHLY: {
      customKey = `${prefixKey}.month`
      break
    }
    case BrandMediaAggregationType.QUARTERLY: {
      customKey = `${prefixKey}.quarter`
      break
    }
    case BrandMediaAggregationType.YEARLY: {
      customKey = ``
      break
    }
    default: {
      throw new Error("Wrong granularity")
    }
  }

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allCampaignsFilters = useMemo(
    () => getWeekYearMonthQuarterYearFilters(date, prefixKey, granularity),
    [prefixKey, date, granularity],
  )

  const allCampaignsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [...campaignQuery.dimensions, campaignIdKey],
        filters: allCampaignsFilters,
      }
    }
    return requests[queryAllCampaignKey]
  }, [
    queryAllCampaignKey,
    allCampaignsFilters,
    purchaseKey,
    campaignIdKey,
    campaignQuery,
    granularity,
  ])

  const valueOverTimeFilters = useMemo(() => {
    const filter: Filter = {
      and: getWeekYearMonthQuarterYearFilters(date, prefixKey, granularity),
    }

    return filter
  }, [date, prefixKey, granularity])

  const valueOverTimeQuery = useMemo(() => {
    if (!requests[queryValueOverTimeKey]) {
      requests[queryValueOverTimeKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [
          ...campaignQuery.dimensions,
          customKey,
          yearKey,
          campaignIdKey,
        ].filter(key => !!key),
        filters: [valueOverTimeFilters as Filter],
      }
    }
    return requests[queryValueOverTimeKey]
  }, [
    queryValueOverTimeKey,
    valueOverTimeFilters,
    customKey,
    campaignQuery,
    campaignIdKey,
    yearKey,
    purchaseKey,
    granularity,
  ])

  const {
    [CUBE_QUERY_KEYS_MAP.ALL]: allCampaignsSet,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeSet,
    isLoading,
  } = useCampaignAdSetAdDetails({
    [CUBE_QUERY_KEYS_MAP.ALL]: allCampaignsQuery,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeQuery,
  })

  const isCurrent = useCallback(
    (metric: any) => metric.campaign_id === campaignId,
    [campaignId],
  )

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allCampaignsSet && !isLoading) {
    const pivotData = getPivotData(
      allCampaignsSet as ResultSet<any>,
      allCampaignsQuery,
    )
    const processedData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    results[cacheKey].allCampaignsDetails =
      prepareAllItemsDetails(processedData)
  }

  if (valueOverTimeSet && !isLoading) {
    const pivotData = getPivotData(
      valueOverTimeSet as ResultSet<any>,
      valueOverTimeQuery,
    )
    const processedData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData

    try {
      results[cacheKey].valueOverTimeDetails = prepareValueOverTimeData(
        processedData,
        isCurrent,
        granularity,
      )
    } catch (e) {
      console.error(e)
    }
  }

  const { allCampaignsDetails, valueOverTimeDetails } = results[cacheKey]

  return { allCampaignsDetails, valueOverTimeDetails, isLoading }
}
