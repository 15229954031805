export function PaginationSkeleton() {
  return (
    <div className="flex items-center justify-between px-4">
      <div className="flex items-center gap-3">
        <div className="w-6 h-6 skeleton bg-basic-dark-blue" />
        <div className="flex items-center gap-2.5">
          <div className="w-12 h-6 skeleton bg-basic-dark-blue" />
          <div className="relative cursor-text inline-block rounded-md px-2.5 py-1.5">
            <div className="w-8 h-6 skeleton bg-basic-dark-blue" />
          </div>
          <div className="w-12 h-6 skeleton bg-basic-dark-blue" />
        </div>
        <div className="w-6 h-6 skeleton bg-basic-dark-blue" />
      </div>
      <div className="flex items-center gap-2.5">
        <div className="w-12 h-6 skeleton bg-basic-dark-blue" />
        <div className="w-12 h-8 skeleton bg-basic-dark-blue" />
      </div>
    </div>
  )
}
