import { useMemo } from "react"

export const useData = (
  isComparing: boolean,
  compareUniqueKey: string | undefined,
  metrics: any[],
) => {
  const data = useMemo(
    () =>
      isComparing && compareUniqueKey
        ? (metrics[0] as Record<string, any>[])
        : (metrics as Record<string, any>[]),
    [isComparing, compareUniqueKey, metrics],
  )

  const noData = useMemo(
    () =>
      !data || data.length === 0 || (isComparing && metrics[0].length === 0),
    [data, isComparing, metrics],
  )

  const compareData = useMemo(
    () =>
      isComparing && compareUniqueKey
        ? (metrics[1] as Record<string, any>[])
        : null,
    [isComparing, compareUniqueKey, metrics],
  )

  return { data, noData, compareData }
}
