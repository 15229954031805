import * as Sentry from "@sentry/react"
import { isEmpty } from "lodash"
import { useEffect, useMemo } from "react"
import { useAuthControllerCurrentUserQuery } from "~/ui-rtk/api/authApi"
import { onboardingRoutes } from "~/ui-rtk/constants/routes"

export const useSessionHistory = () => {
  const { currentData: session, isLoading: isSessionLoading } =
    useAuthControllerCurrentUserQuery()

  const redirectRoute: string = useMemo(() => {
    if (!session?.user.isConfirmed) {
      return onboardingRoutes.CHECK_INBOX
    }

    if (isEmpty(session.currentCompany)) {
      return onboardingRoutes.ACCOUNT_DETAILS
    }

    return onboardingRoutes.CONNECTION_FLOW
  }, [session])

  useEffect(() => {
    const context = { userId: session?.user?.id }
    Sentry.setContext("onboarding-current-user", context)
  }, [session])

  return {
    session,
    redirectRoute,
    isSessionLoading,
  }
}
