import React, { useCallback, useRef, useState } from "react"
import { convertNumber, ConvertNumberType } from "~/ui-rtk/utils/number-utils"

type TCaptionPreviewNumberProps = {
  value: number
  columnType: ConvertNumberType
  top?: boolean
}

export const CaptionPreviewNumber: React.FC<TCaptionPreviewNumberProps> = ({
  value,
  columnType,
  top = false,
}) => {
  const [isFullCaption, setIsFullCaption] = useState(false)
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null)

  const showFullCaption = () => {
    setIsFullCaption(true)
  }

  const hideFullCaption = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current)
      hoverTimeout.current = null
    }
    setIsFullCaption(false)
  }

  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(showFullCaption, 700)
  }

  const handleMouseLeave = () => {
    hideFullCaption()
  }

  const render = useCallback(
    () => (
      <div
        className="relative w-full"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isFullCaption ? (
          <div
            className={`absolute flex flex-col bg-basic-blue rounded-lg z-50 ${top ? "bottom-10" : "-bottom-15"}  py-3 px-4 gap-2`}
          >
            <p className="max-h-75 overflow-y-auto scrollbar">
              {convertNumber(columnType, value, "-", true)}
            </p>
          </div>
        ) : null}
        <p className="w-full overflow-x-hidden text-ellipsis">
          {convertNumber(columnType, value, "-")}
        </p>
      </div>
    ),
    [value, isFullCaption],
  )

  return render()
}
