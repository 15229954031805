import { PaginationState } from "@tanstack/react-table"
import { useState } from "react"
import { ITEMS_PER_PAGE } from "../constants"

export const usePagination = (total: number) => {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  })

  const fullPages = Math.floor(total / paginationState.pageSize)
  const totalPages =
    fullPages * paginationState.pageSize === total ? fullPages : fullPages + 1

  const showPagination = totalPages > 0

  return {
    paginationState,
    setPaginationState,
    totalPages,
    showPagination,
  }
}
