import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const InfoSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 11C0.25 5.08579 5.08579 0.25 11 0.25C16.9142 0.25 21.75 5.08579 21.75 11C21.75 16.9142 16.9142 21.75 11 21.75C5.08579 21.75 0.25 16.9142 0.25 11ZM11 1.75C5.91421 1.75 1.75 5.91421 1.75 11C1.75 16.0858 5.91421 20.25 11 20.25C16.0858 20.25 20.25 16.0858 20.25 11C20.25 5.91421 16.0858 1.75 11 1.75ZM11 6.25C11.4142 6.25 11.75 6.58579 11.75 7V12C11.75 12.4142 11.4142 12.75 11 12.75C10.5858 12.75 10.25 12.4142 10.25 12V7C10.25 6.58579 10.5858 6.25 11 6.25ZM10.2445 15C10.2445 14.5858 10.5803 14.25 10.9945 14.25H11.0035C11.4177 14.25 11.7535 14.5858 11.7535 15C11.7535 15.4142 11.4177 15.75 11.0035 15.75H10.9945C10.5803 15.75 10.2445 15.4142 10.2445 15Z"
      fill={fill}
    />
  </svg>
))
