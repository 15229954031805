import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useConnect from "./connect"
import HomeDashboard from "~/ui-rtk/components/features/dashboards/HomeDashboard/HomeDashboard"
import { type THomeDashboardItem } from "~/ui-rtk/components/features/dashboards/HomeDashboard/types"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const dateRestrictions = { maxDate: dayjs().subtract(7, "day").toDate() }

export const IS_HOME_PAGE_ENABLED = false

export default function HomePage() {
  const { content, visualizations, isLoading, sources } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <HomeDashboard
        slug={content.slug}
        sources={sources}
        visualizations={visualizations}
        items={content.content.items as THomeDashboardItem[]}
        title={content.content.title}
        periodPicker={content.content.periodPicker}
        dateRangeRestrictions={dateRestrictions}
      />
    </WithLoader>
  )
}
