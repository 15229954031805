import { PropsWithChildren } from "react"
import { Navigate } from "react-router-dom"

type TPagePermissionGuardProps = {
  isAllowed: boolean
}

export default function PagePermissionGuard({
  isAllowed,
  children,
}: PropsWithChildren<TPagePermissionGuardProps>) {
  if (!isAllowed) {
    return <Navigate to="/" replace />
  }

  return children
}
