import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import { Button } from "~/ui-rtk/components/ui/controls"
import { ProductCard } from "./components"
import { ContentBox, Loader } from "~/ui-rtk/components/ui/common"
import { useProductControllerGetAllQuery } from "~/ui-rtk/api/productApi"

const disable = true

export const PricingDetails = () => {
  const navigate = useStableNavigate()
  const { data: products, isLoading } = useProductControllerGetAllQuery()

  if (disable) {
    return <div>Soon</div>
  }

  const navigateBack = () => {
    if (window.length > 2) {
      navigate(-1)
    } else {
      navigate("/")
    }
  }

  if (isLoading) {
    return (
      <Loader
        className="fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        size={64}
      />
    )
  }

  return (
    <ContentBox
      title="Our Pricing"
      classes={{
        container: "max-w-300 pb-10",
        content: "max-w-300",
      }}
    >
      {products ? (
        <div className="flex w-full gap-10 mt-10">
          {products.map(product => (
            <ProductCard
              key={product.id}
              name={product.name}
              description={product.description}
              imageSrc={product.metadata.imageSrc || ""}
              tiers={product.tiers}
            />
          ))}
        </div>
      ) : null}

      <div className="flex justify-center mt-8">
        <Button className="w-40" onClick={navigateBack}>
          Back
        </Button>
      </div>
    </ContentBox>
  )
}
