import dayjs from "dayjs"
import en from "dayjs/locale/en"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isoWeek from "dayjs/plugin/isoWeek"
import localizedFormat from "dayjs/plugin/localizedFormat"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import weekOfYear from "dayjs/plugin/weekOfYear"
import customParseFormat from "dayjs/plugin/customParseFormat"
import weekday from "dayjs/plugin/weekday"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(isoWeek)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.locale({
  ...en,
  weekStart: 1,
})

export { type Dayjs } from "dayjs"

export default function useDate() {
  return dayjs
}
