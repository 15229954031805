import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ChevronDownSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.54966 7.92084C3.84255 7.62795 4.31742 7.62795 4.61032 7.92084L11.1303 14.4408C11.6074 14.9179 12.3925 14.9179 12.8697 14.4408L19.3897 7.92084C19.6825 7.62795 20.1574 7.62795 20.4503 7.92084C20.7432 8.21373 20.7432 8.68861 20.4503 8.9815L13.9303 15.5015C12.8674 16.5644 11.1326 16.5644 10.0697 15.5015L3.54966 8.9815C3.25676 8.68861 3.25676 8.21373 3.54966 7.92084Z"
      fill={fill}
    />
  </svg>
))
