import type { FC, ReactNode } from "react"

type Props = React.HTMLAttributes<HTMLParagraphElement> & {
  children: ReactNode
  className?: string
}

export default function P({
  children,
  className,
  style = {},
}: Props): ReturnType<FC<Props>> {
  const cs = ["text-sm", className].join(" ").trim()
  return (
    <p className={cs} style={style}>
      {children}
    </p>
  )
}
