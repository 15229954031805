import { Form, Formik } from "formik"

import { Button, FormField } from "~/ui-rtk/components/ui/controls"

import { useConnect } from "./connect"

const CreateCompanyForm = () => {
  const { initialValues, createCompany, validationSchema, isLoading } =
    useConnect()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createCompany}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="flex flex-col items-center w-full space-y-8">
          <div className="flex flex-col max-w-100 items-center w-full gap-5.5">
            <FormField
              id="companyName"
              type="text"
              label="Company Name"
              placeholder="your-domain-name.com"
              isError={Boolean(touched.domain && errors.domain)}
            />
            <FormField
              id="domain"
              type="text"
              label="Company Domain"
              placeholder="your-domain-name.com"
              isError={Boolean(touched.domain && errors.domain)}
            />
            <Button type="submit" className="w-full mt-3" isLoading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CreateCompanyForm
