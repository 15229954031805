import React, { useMemo } from "react"

import { Button } from "~/ui-rtk/components/ui/controls"
import { H5 } from "~/ui-rtk/components/ui/typography"
import { Card, FlipCard } from "~/ui-rtk/components/ui/common"

import { renderNumberSuffix, renderSpinner } from "../../utils"

type BrandValueCardProps = {
  data: any
  isLoading?: boolean
  onCheckout: CallableFunction
}

const BrandValueCard: React.FC<BrandValueCardProps> = ({
  data: brandValue,
  isLoading = false,
  onCheckout,
}) => {
  const brandValuePerYear = useMemo(
    () => brandValue?.[0]?.["company_modeled_metrics_monthly.conversions"],
    [brandValue],
  )

  const renderBrandValue = () => (
    <div className="bg-gradient-to-br from-pink-orange-gradient-start from-25% to-pink-orange-gradient-end to-100% text-transparent bg-clip-text">
      {renderNumberSuffix(brandValuePerYear || 0)}
    </div>
  )

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onCheckout()
  }

  const cardClassName = `mx-auto w-full flex flex-col justify-between md:basis-1/3 md:grow-0 h-120 px-4 py-4 bg-basic-dark-blue border border-basic-blue text-center`

  return (
    <FlipCard>
      <Card rounded className={cardClassName}>
        <div>
          <H5 className="text-brand text-center uppercase text-base">
            Brand Value
          </H5>
        </div>
        <div>
          <p className="text-2xl leading-8">
            How has your brand value evolved over the past year?
          </p>
          <p className="opacity-30 text-2xl leading-8">
            Explore your brand’s growth journey
          </p>
        </div>
        <div>&nbsp;</div>
      </Card>

      <Card rounded className={cardClassName}>
        <div>
          <H5 className="text-brand text-center uppercase text-base">
            Brand Value
          </H5>
          <H5 className="text-white opacity-30 text-center uppercase text-base">
            Last 12 Month
          </H5>
        </div>
        <div className="text-center relative">
          {isLoading ? renderSpinner() : renderBrandValue()}
        </div>
        <Button onClick={handleClick}>See my Breakdown</Button>
      </Card>
    </FlipCard>
  )
}

export default BrandValueCard
