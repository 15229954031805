import useConnect from "./connect"
import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import BrandMediaOptimizationDashboard from "~/ui-rtk/components/features/dashboards/BrandMediaOptimizationDashboard/BrandMediaOptimizationDashboard"

export default function PageBrandMediaOptimization() {
  const { content, isLoading } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <BrandMediaOptimizationDashboard
        slug={content.slug}
        items={content.content.items}
        title={content.content.title}
        parent={content.parent}
        periodPicker={content.content.periodPicker}
      />
    </WithLoader>
  )
}
