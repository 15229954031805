import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"

type ConfirmationDialogMapType = {
  [key in string]: {
    alt: string
    title: string
    label: string
    imgSrc: string
  }
}

export enum ConnectorTypeEnum {
  CUSTOM = "CUSTOM",
  FIVETRAN = "FIVETRAN",
}

export const sortArrayWithEssentialFirst = (
  array: string[],
  order: "asc" | "desc" = "asc",
) => {
  const direction = order === "desc" ? -1 : 1

  // Custom sorting logic
  return array.sort((a, b) => {
    if (a === "Essential") return -1
    if (b === "Essential") return 1
    return a.localeCompare(b) * direction
  })
}

export const confirmationDialogPropsMap: ConfirmationDialogMapType = {
  attentive: {
    alt: "Attentive Logo",
    imgSrc: connectorIcons.attentive,
    title: "Connection to Attentive",
    label: "Attentive",
  },
  yotpo: {
    alt: "Yotpo Logo",
    imgSrc: connectorIcons.yotpo,
    title: "Connection to Yotpo",
    label: "Yotpo",
  },
  sps: {
    alt: "SPS Logo",
    imgSrc: connectorIcons.sps,
    title: "Connection to SPS",
    label: "SPS",
  },
  target: {
    alt: "Target Logo",
    title: "Connection to Target",
    label: "Target",
    imgSrc: connectorIcons.target,
  },
  tiktok: {
    alt: "TikTok Logo",
    imgSrc: connectorIcons.tiktok,
    title: "Connection to TikTok Business",
    label: "TikTok Business",
  },
  tiktok_ads: {
    alt: "TikTok Ads Logo",
    imgSrc: connectorIcons.tiktok,
    title: "Connection to TikTok Ads",
    label: "TikTok Ads",
  },
  instagram_insights: {
    alt: "Instagram Logo",
    imgSrc: connectorIcons.instagramInsights,
    title: "Connection to Instagram Insights",
    label: "Instagram Insights",
  },
  amazon_ads: {
    alt: "Amazon Ads Logo",
    title: "Connection to Amazon Ads",
    label: "Amazon Ads",
    imgSrc: connectorIcons.amazonAds,
  },
  amazon_selling_partner: {
    alt: "Amazon Selling Partner Logo",
    title: "Connection to Amazon Selling Partner",
    label: "Amazon Selling Partner",
    imgSrc: connectorIcons.amazonSellingPartner,
  },
  facebook_ads: {
    alt: "Facebook Ads Logo",
    title: "Connection to Facebook Ads",
    label: "Facebook Ads",
    imgSrc: connectorIcons.facebookAds,
  },
  facebook_pages: {
    alt: "Facebook Pages Logo",
    title: "Connection to Facebook Pages",
    label: "Facebook Pages",
    imgSrc: connectorIcons.facebookPages,
  },
  google_ads: {
    alt: "Google Ads Logo",
    title: "Connection to Google Ads",
    label: "Google Ads",
    imgSrc: connectorIcons.googleAds,
  },
  google_analytics: {
    alt: "Google Analytics Logo",
    title: "Connection to Google Analytics",
    label: "Google Analytics",
    imgSrc: connectorIcons.googleAnalytics,
  },
  google_analytics_4: {
    alt: "Google Analytics 4 Logo",
    title: "Connection to Google Analytics 4",
    label: "Google Analytics 4",
    imgSrc: connectorIcons.googleAnalytics4,
  },
  instagram_business: {
    alt: "Instagram Business Logo",
    title: "Connection to Instagram Business",
    label: "Instagram Business",
    imgSrc: connectorIcons.instagram,
  },
  klaviyo: {
    alt: "Klavio Logo",
    title: "Connection to Klavio",
    label: "Klavio",
    imgSrc: connectorIcons.klavio,
  },
  shopify: {
    alt: "Shopify Logo",
    title: "Connection to Shopify",
    label: "Shopify",
    imgSrc: connectorIcons.shopify,
  },
  youtube_analytics: {
    alt: "Youtube Analytics Logo",
    title: "Connection to Youtube Analytics",
    label: "Youtube Analytics",
    imgSrc: connectorIcons.youtube,
  },
}
