import React from "react"

import { Dialog } from "~/ui-rtk/components/ui/common"
import { H2 } from "~/ui-rtk/components/ui/typography"
import { CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { CreateCompanyForm } from "~/ui-rtk/components/features/forms/CreateCompanyForm"

type AddCompanyDialogProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateCompanyDialog: React.FC<AddCompanyDialogProps> = ({
  isOpen = false,
  onClose,
}) => (
  <Dialog isOpen={isOpen} handleClose={onClose}>
    <div className="max-w-160 w-full absolute rounded-md py-8 px-10 center-absolute bg-basic-dark-blue border border-basic-blue shadow-deep">
      <div className="flex flex-col items-center gap-6 mb-8">
        <H2 className="font-medium text-white">Create Company</H2>
      </div>
      <div className="space-y-4">
        <CreateCompanyForm />
      </div>
      <button
        className="absolute right-4 top-4 max-w-7 max-h-7 p-2 flex items-center justify-center hover:bg-basic-blue hover:text-white transition-colors rounded-1"
        onClick={onClose}
      >
        <CloseSvg size={20} fill="platinum" />
      </button>
    </div>
  </Dialog>
)

export default CreateCompanyDialog
