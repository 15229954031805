import React, { useMemo } from "react"
import dayjs from "dayjs"

import { Logo } from "~/ui-rtk/components/ui/common"
import { Image } from "~/ui-rtk/components/ui/common/Image"

import xIconUrl from "~/ui-rtk-public/img/x.svg?url"
import fbIconUrl from "~/ui-rtk-public/img/fb.svg?url"
import igIconUrl from "~/ui-rtk-public/img/instagram.svg?url"
import inIconUrl from "~/ui-rtk-public/img/linkedin.svg?url"

type FooterProps = {
  disableSocialLinks?: boolean
}

const Footer: React.FC<FooterProps> = ({ disableSocialLinks }) => {
  const currentYear = useMemo(() => dayjs(new Date()).format("YYYY"), [])

  return (
    <div className="flex justify-between align-start">
      <div>
        <div className="items-center flex justify-start gap-2">
          <Logo size={25} />
          <h4 className="text-lg font-bold">marathon data</h4>
        </div>
      </div>
      <div className="flex flex-col gap-5 text-center">
        <div className="flex flex-col md:flex-row gap-5 items-center justify-center text-base">
          <a href="https://www.marathondataco.com/blank">Privacy Policy</a>
          <a href="https://www.marathondataco.com/blank-1">
            Terms and Conditions
          </a>
        </div>
        <div className="mx-auto">© {currentYear} by Marathon Data LLC</div>
      </div>
      <div className="flex flex-col md:flex-row md:justify-end justify-start gap-5 basis-30">
        {!disableSocialLinks && (
          <>
            <a href="#">
              <Image src={fbIconUrl} alt="Fb" />
            </a>
            <a href="#">
              <Image src={igIconUrl} alt="Ig" />
            </a>
            <a href="#">
              <Image src={inIconUrl} alt="In" />
            </a>
            <a href="#">
              <Image src={xIconUrl} alt="X" />
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default Footer
