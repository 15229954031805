/* eslint-disable prettier/prettier */
import {
  useRef,
  useState,
  useMemo,
  useCallback,
  ElementType,
  createElement,
  CSSProperties,
} from "react"
import get from "lodash/get"
import {
  Area,
  CartesianGrid,
  Line,
  ComposedChart as ReComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { H3, SubTitle } from "../../typography"
import { useDateRanges } from "~/ui-rtk/utils/date-utils"
import {
  AGG_TYPES,
  AggregationType,
  CHARTS_LOADING_DATA,
  TOTAL_CALCULATED_DATA_KEY,
  UNKNOWN_DATA_KEY,
} from "~/ui-rtk/constants/charts"
import { useResizeObserver } from "~/ui-rtk/utils/resize-observer"
import { formatNumber, startCase } from "~/ui-rtk/utils/format"
import useTailwindTheme from "~/ui-rtk/hooks/tailwind-theme"
import ChartTooltipContent, {
  type TTootlipNumberProps,
} from "./ChartTooltipContent"
import { Card, RichTooltip } from "../../common"
import { InfoSvg } from "../../svg/essentials"
import { Button } from "~/ui-rtk/components/ui/controls"
import {
  BRAND_COLORS_MAP,
  getColorForMetric,
  getLightColor,
  stringToColor,
} from "~/ui-rtk/utils/chart-utils"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { propertyNamesToLowercase } from "~/ui-rtk/utils/object-utils"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export type TGraphType = "line" | "area" | "dashed"

export type TGraphProps = {
  type?: TGraphType
  unit?: string
  pluralizeUnit?: boolean
  dataKey: string
  color?: string
  requiredSources?: string[]
  label?: string
  stacked?: boolean
  labelAggType?:
    | "sum"
    | "avg"
    | "percent-total"
    | "percent-change"
    | "percent-average"
  numberType?: "default" | "currency"
  yAxisId?: string
  isTotal?: boolean
}

const aggFunc = (
  data: Array<{ date: number; [key: string]: number }>,
  dataKey: string,
  aggType:
    | "sum"
    | "avg"
    | "percent-total"
    | "percent-change"
    | "percent-average",
  graphs: TGraphProps[],
  prefixDataKey: string,
  excludeDataKeyFromTotal?: string[],
  yAxisId?: string,
) => {
  const length = data.length
  const sum = ["sum", "avg", "percent-total", "percent-average"].includes(
    aggType,
  )
    ? data.reduce((sum: number, dp) => {
        const metricData = get(dp, `${prefixDataKey}${dataKey}`)
        if (isNaN(metricData)) {
          return sum
        }

        return metricData + sum
      }, 0)
    : 0

  const excludeDataKeyFromTotalWithPrefix = (excludeDataKeyFromTotal ?? []).map(
    key => `${prefixDataKey}${key}`,
  )

  switch (aggType) {
    case "avg":
      return sum / length
    case "percent-total": {
      const axisIdKeys = graphs
        .filter(graph => !yAxisId || graph.yAxisId === yAxisId)
        .map(graph => `${prefixDataKey}${graph.dataKey}`)

      const axisIdTotal = data.reduce(
        (total: number, dp) =>
          total +
          axisIdKeys.reduce(
            (sum, key) =>
              isNaN(get(dp, dataKey)) ||
              (excludeDataKeyFromTotalWithPrefix ?? []).includes(key)
                ? sum
                : sum + get(dp, key),
            0,
          ),
        0,
      )
      return (sum / axisIdTotal) * 100
    }
    case "percent-average": {
      return sum / length
    }
    case "percent-change":
      return length > 0 ? (data[length - 1].value / data[0].value - 1) * 100 : 0
    default:
      return sum
  }
}

const getMaxValueByYAxisId = (
  yAxisId: string,
  graphs: TGraphProps[],
  metrics: Record<string, number>[],
) => {
  if (!yAxisId) {
    return 0
  }
  const filteredGraphsDataKeys = graphs
    .filter(({ yAxisId: graphAxis }) => yAxisId === graphAxis)
    .map(({ dataKey }) => dataKey)

  if (!filteredGraphsDataKeys.length) {
    return 0
  }

  const plainNumberArr = metrics.reduce(
    (accumulator: number[], metric: Record<string, number>) => {
      const items = Object.values(filteredGraphsDataKeys).map(dataKey =>
        [metric[dataKey], metric[`compared.${dataKey}`]].filter(num => !!num),
      )
      return accumulator.concat(...items)
    },
    [],
  )

  return Math.max(...plainNumberArr) * 1.01
}

export type TComposedChartProps = {
  title?: string
  subTitle?: string
  compareMode: boolean
  compareUnit?: string
  comparePluralizeUnit?: boolean
  compareTitle?: string
  dataSources?: string[]
  sources?: Map<
    string,
    {
      name: string
      icon: string
    }
  >
  compareNumberType?: "default" | "currency"
  compareLabelAggType?: "sum" | "avg" | "percent-change"
  dataAggType?: AggregationType
  dataAggTypeOnly?: AggregationType
  metrics: any[]
  interactive?: boolean
  graphs: TGraphProps[]
  graphsFromColumn?: {
    column: string
    type: TGraphType
    label: string
    unit?: string
    pluralizeUnit?: boolean
    labelAggType?:
      | "sum"
      | "avg"
      | "percent-total"
      | "percent-change"
      | "percent-average"
    numberType?: "default" | "currency"
  }
  excludeDataKeyFromTotal?: Array<string>
  totalLabelProps?: TGraphProps
  state?: string
  setDataAggType?: (agg: AggregationType) => void
  isCompareMetricMain?: boolean
  className?: string
  highlightKey?: string
  stacked?: boolean
  manualTotal?: boolean
  tooltipTotalKey?: string
  hideAggSelector?: boolean
  hideTotal?: boolean
  totalComponent?: ElementType
  tooltipProps?: TTootlipNumberProps
  graphsManualSort?: boolean
  description?: string
  emptyStateText?: string
  blurTitle?: boolean
  hideTooltipTitle?: boolean
  isInverted?: boolean
}

export default function ComposedChart({
  title,
  subTitle,
  dataSources: dataSourcesProp,
  dataAggType: dataAggTypeProp,
  dataAggTypeOnly,
  compareMode = false,
  metrics,
  sources,
  interactive = true,
  graphs: graphsProps,
  graphsFromColumn,
  totalLabelProps,
  state = "idle",
  setDataAggType: setDataAggTypeProp = () => null,
  isCompareMetricMain = false,
  excludeDataKeyFromTotal,
  className,
  highlightKey,
  stacked = false,
  hideTotal = false,
  manualTotal = false,
  hideAggSelector = false,
  totalComponent,
  tooltipProps,
  graphsManualSort = false,
  description,
  emptyStateText,
  isInverted = false,
  blurTitle = false,
  hideTooltipTitle = false,
}: TComposedChartProps) {
  const { minAggType, maxAggType } = useDateRanges()
  const [focusedCharts, setFocusedCharts] = useState<string[]>([])
  const [dataAggType, setDataAggType] = useState(
    dataAggTypeProp ?? AggregationType.DAILY,
  )
  const theme = useTailwindTheme()
  const mainMetricPrefix = isCompareMetricMain ? "compared." : ""
  const comparedMetricPrefix = isCompareMetricMain ? "" : "compared."

  const normalizedMetrics = metrics.map(propertyNamesToLowercase) as any[]
  const normalizedHighlightKey = highlightKey?.toLowerCase()

  const refEl = useRef<HTMLElement>(document.body)
  const size = useResizeObserver({ ref: refEl })
  const chartWidth = (size.width || refEl.current?.clientWidth) ?? 0
  const dataSources: string[] =
    dataSourcesProp ??
    (normalizedMetrics?.length > 0 && normalizedMetrics?.[0]?.metadata?.source
      ? Array.from(normalizedMetrics?.[0].metadata.source)
      : [])

  let prevTick: any = null
  const formatTick = (value: any, index: number) => {
    const date = dayjs(value)
    const tick = date.format(
      date.month() !== dayjs(prevTick).month() || index === 0 ? `DD MMM` : "DD",
    )
    prevTick = value
    return tick
  }

  const isMetricHighlighted = (dataKey: string) => {
    if (!normalizedHighlightKey) {
      return false
    }
    return normalizedHighlightKey === dataKey.toLowerCase()
  }

  const graphsRaw = useMemo(() => {
    if (graphsProps) {
      return graphsProps
    } else if (!graphsFromColumn) {
      throw new Error("graphs or graphsFromColumn must be defined")
    } else if (!normalizedMetrics?.length) {
      return []
    }

    return Object.keys(normalizedMetrics[0][graphsFromColumn?.column] ?? {})
      .map<TGraphProps>(key => ({
        type: graphsFromColumn?.type ?? "line",
        dataKey: `${graphsFromColumn?.column}.${key}`,
        label: startCase(key),
        numberType: graphsFromColumn?.numberType ?? "default",
        unit: graphsFromColumn?.unit,
        pluralizeUnit: graphsFromColumn?.pluralizeUnit ?? true,
        labelAggType: graphsFromColumn?.labelAggType ?? "sum",
        color: getLightColor(stringToColor(key)),
      }))
      .concat(
        Object.keys(
          normalizedMetrics[0][UNKNOWN_DATA_KEY][graphsFromColumn?.column],
        ).map<TGraphProps>(key => ({
          type: graphsFromColumn?.type ?? "line",
          dataKey: `${UNKNOWN_DATA_KEY}.${graphsFromColumn?.column}.${key}`,
          label: `Unknown: ${startCase(key)}`,
          numberType: graphsFromColumn?.numberType ?? "default",
          unit: graphsFromColumn?.unit,
          pluralizeUnit: graphsFromColumn?.pluralizeUnit ?? true,
          color: BRAND_COLORS_MAP.UNKNOWN,
          labelAggType: graphsFromColumn?.labelAggType ?? "sum",
        })),
      )
  }, [graphsProps, graphsFromColumn, normalizedMetrics])

  const graphs = useMemo(
    () =>
      graphsRaw.map((graph, idx) => ({
        ...graph,
        dataKey: graph.dataKey.toLowerCase(),
        color: graph.color ?? getColorForMetric(graph.dataKey, idx),
      })),
    [graphsRaw],
  )

  const aggData = useMemo(
    () =>
      graphs
        .filter(
          ({ requiredSources }) =>
            !focusedCharts.length ||
            focusedCharts.some(source => requiredSources?.includes(source)),
        )
        .map((graph, idx) => ({
          ...graph,
          type: compareMode ? "line" : graph.type,
          color: graph.color ?? getColorForMetric(graph.dataKey, idx),
          isPercent: graph.labelAggType
            ? ["percent-total", "percent-change", "percent-average"].includes(
                graph.labelAggType,
              )
            : false,
          ["compared.value"]: compareMode
            ? aggFunc(
                normalizedMetrics,
                graph.dataKey,
                graph.labelAggType ?? "sum",
                graphs,
                comparedMetricPrefix,
                excludeDataKeyFromTotal,
                graph.yAxisId,
              ) ?? 0
            : null,
          value:
            aggFunc(
              normalizedMetrics,
              graph.dataKey,
              graph.labelAggType ?? "sum",
              graphs,
              mainMetricPrefix,
              excludeDataKeyFromTotal,
              graph.yAxisId,
            ) ?? 0,
          ["compared.total"]:
            (compareMode &&
              (["percent-total", "percent-change", "percent-average"].includes(
                graph?.labelAggType ?? "",
              )
                ? aggFunc(
                    normalizedMetrics,
                    graph.dataKey,
                    "avg",
                    graphsProps,
                    comparedMetricPrefix,
                    excludeDataKeyFromTotal,
                    graph.yAxisId,
                  )
                : 0)) ||
            null,
          total: [
            "percent-total",
            "percent-change",
            "percent-average",
          ].includes(graph?.labelAggType ?? "")
            ? aggFunc(
                normalizedMetrics,
                graph.dataKey,
                graph.labelAggType ?? "sum",
                graphsProps,
                mainMetricPrefix,
                excludeDataKeyFromTotal,
                graph.yAxisId,
              )
            : 0,
        }))
        .sort((a, b) =>
          graphsManualSort ? 0 : b.dataKey.localeCompare(a.dataKey),
        ),
    [normalizedMetrics, graphs, focusedCharts],
  )

  const aggDataMap = useMemo(
    () => new Map(aggData.map((item, index) => [item.dataKey, index])),
    [aggData],
  )

  const sortFunction = useCallback(
    (a: TGraphProps, b: TGraphProps) => {
      if (graphsManualSort) {
        return 0
      }
      if (isMetricHighlighted(a.dataKey)) {
        return -1
      }
      if (isMetricHighlighted(b.dataKey)) {
        return 1
      }
      if (a.isTotal) {
        return 1
      }
      if (b.isTotal) {
        return -1
      }
      return (
        (aggDataMap.get(a.dataKey) ?? -1) - (aggDataMap.get(b.dataKey) ?? -1)
      )
    },
    [aggDataMap],
  )

  const sortedGraphs = useMemo(() => {
    if (!graphsManualSort) {
      const innerSortedGraphs = graphs.slice().sort(sortFunction)
      return !isInverted ? innerSortedGraphs.reverse() : innerSortedGraphs
    }

    const innerGraphs = graphs.slice()
    return !isInverted ? innerGraphs.reverse() : innerGraphs
  }, [graphs, aggData, isInverted])

  const sortedAggData = useMemo(() => {
    if (!graphsManualSort) {
      return aggData.slice().sort(sortFunction).reverse()
    }
    return aggData.slice()
  }, [aggData, sortedGraphs])

  const dataLabels = useMemo(
    () =>
      new Map(
        [...sortedGraphs].reverse().map((graph, idx) => [
          graph.dataKey,
          {
            label: graph.label ?? graph.dataKey,
            numberType: graph.numberType,
            unit: graph.unit,
            order: idx,
            dataKey: graph.dataKey,
            pluralizeUnit: graph.pluralizeUnit ?? true,
          },
        ]),
      ),
    [sortedGraphs],
  )

  const yAxis = useMemo(() => {
    const axisMap = new Set(
      sortedGraphs.map(({ yAxisId }) => yAxisId).filter(id => id),
    )
    return Array.from(axisMap)
  }, [sortedGraphs])

  const toggleChartSelection = (source: string) => () => {
    // if no chart requires this source then do nothing
    if (
      !sortedGraphs.some(({ requiredSources }) =>
        requiredSources?.includes(source),
      )
    ) {
      return
    }
    setFocusedCharts(prevFocusedCharts => {
      if (prevFocusedCharts && prevFocusedCharts.includes(source)) {
        return prevFocusedCharts.filter(chartId => chartId !== source)
      }
      return prevFocusedCharts ? [...prevFocusedCharts, source] : [source]
    })
  }

  const aggregationGroups = useMemo(
    () =>
      AGG_TYPES.filter(({ type }, index) => {
        // if data agg type is set in config, hide all other options
        if (dataAggTypeOnly) {
          return dataAggTypeOnly === type
        }
        // if min agg type is weekly, hide daily
        if (minAggType === AggregationType.WEEKLY && index === 0) {
          return false
        }
        // if max agg type is daily, hide weekly and monthly
        if (maxAggType === AggregationType.DAILY && index > 0) {
          return false
        }
        // if max agg type is weekly, hide monthly
        if (maxAggType === AggregationType.WEEKLY && index === 2) {
          return false
        }
        return true
      }),
    [minAggType, maxAggType, dataAggTypeOnly],
  )

  const noData =
    normalizedMetrics?.length === 0 ||
    !normalizedMetrics?.some(entry =>
      sortedGraphs.some(({ dataKey }) => get(entry, dataKey) !== undefined),
    ) ||
    !normalizedMetrics?.some(entry =>
      sortedGraphs.some(({ dataKey }) => get(entry, dataKey) !== 0),
    )

  const handleAggregationGroup = (type: AggregationType) => {
    setDataAggType(type)
    setDataAggTypeProp(type)
  }

  const totalDataLabels = useMemo(
    () =>
      new Map(
        [...sortedGraphs].reverse().map((graph, idx) => [
          graph.dataKey,
          {
            label: graph.label ?? graph.dataKey,
            numberType: [
              "percent-total",
              "percent-change",
              "percent-average",
            ].includes(graph.labelAggType ?? "")
              ? "default"
              : graph.numberType,
            unit: [
              "percent-total",
              "percent-change",
              "percent-average",
            ].includes(graph.labelAggType ?? "")
              ? "%"
              : graph.unit,
            order: idx,
            dataKey: graph.dataKey,
            pluralizeUnit: graph.pluralizeUnit ?? true,
          },
        ]),
      ),
    [sortedGraphs],
  )

  const totalPayload = useMemo(() => {
    const keyMap: Record<string, number> = {}
    sortedAggData.forEach(aggData => {
      keyMap[aggData.dataKey] = aggData.value
      keyMap[`compared.${aggData.dataKey}`] = aggData["compared.value"] ?? 0
    })
    return sortedGraphs.map(graph => ({
      color: graph.color,
      dataKey: `${mainMetricPrefix}${graph.dataKey}`,
      value: keyMap[`${mainMetricPrefix}${graph.dataKey}`],
      payload: keyMap,
      isPercent: [
        "percent-total",
        "percent-change",
        "percent-average",
      ].includes(graph.labelAggType ?? ""),
    }))
  }, [sortedAggData])

  const lowDataSourceTooltipContent = title ? (
    <ChartTooltipContent
      payload={totalPayload}
      hideDates={true}
      active={true}
      dataLabels={totalDataLabels}
      isTotalCalculated={manualTotal}
      showTotal={!hideTotal}
      isCompareMetricMain={isCompareMetricMain}
      compareMode={compareMode}
      highlightKey={normalizedHighlightKey}
      totalLabelConfig={totalLabelProps}
      excludeDataKeyFromTotal={excludeDataKeyFromTotal}
      tooltipProps={tooltipProps}
      graphsManualSort={graphsManualSort}
      blurTitle={blurTitle}
      hideTooltipTitle={hideTooltipTitle}
    />
  ) : null

  const totalHeader = useMemo(() => {
    if (!totalComponent) {
      return null
    }
    return (
      <>
        {createElement(totalComponent, {
          metrics: normalizedMetrics,
          totalDataKey: totalLabelProps?.dataKey ?? TOTAL_CALCULATED_DATA_KEY,
          label: totalLabelProps?.label ?? "Total",
          compareMode,
          isCompareMetricMain,
        })}
        {noData && emptyStateText}
      </>
    )
  }, [
    totalComponent,
    normalizedMetrics,
    totalLabelProps,
    compareMode,
    isCompareMetricMain,
    noData,
    emptyStateText,
    TOTAL_CALCULATED_DATA_KEY,
  ])

  const composedChartStyle = useMemo(
    () =>
      state !== "idle" || noData
        ? { opacity: 0.15, filter: "grayscale(1)" }
        : undefined,
    [state, noData],
  )

  const cartesianGridStyles = useMemo(
    () => ({ stroke: theme.colors["gray-dark"] }),
    [theme],
  )

  const yAxisStyle = useMemo(
    () => ({
      fontSize: `${theme.fontSize.sm}`,
      textAnchor: "end" as CSSProperties["textAnchor"],
      transform: "translate(-10px, 10px)",
    }),
    [theme],
  )

  const yAxisStyleLeft = useMemo(
    () => ({
      fontSize: `${theme.fontSize.sm}`,
      textAnchor: "start" as CSSProperties["textAnchor"],
      transform: "translate(10px, 10px)",
    }),
    [theme],
  )

  return (
    <div
      className={cn(
        "relative flex flex-col justify-between composed-chart pb-10",
        className,
      )}
    >
      <div>
        <div>
          <div className="mb-3 col-span-3">
            {title && (
              <div className="inline-flex items-center mb-2">
                <H3>{title}</H3>
                <div className="ms-1 flex gap-1 items-center">
                  {description && (
                    <RichTooltip
                      content={
                        <Card
                          rounded
                          className="min-w-128 max-w-180 whitespace-normal text-start leading-5"
                        >
                          {description}
                        </Card>
                      }
                    >
                      <InfoSvg
                        className="cursor-pointer"
                        size={24}
                        fill="blue"
                      />
                    </RichTooltip>
                  )}
                  <RichTooltip
                    content={<Card>{lowDataSourceTooltipContent}</Card>}
                  >
                    <div className="cursor-pointer ms-1 border-2 border-blue rounded-xl text-blue h-6 w-6 text-center text-sm font-semibold">
                      ∑
                    </div>
                  </RichTooltip>
                </div>
              </div>
            )}
            {subTitle ? (
              <SubTitle className="font-bold max-h-16 text-gray-light inline ms-2">
                {subTitle}
              </SubTitle>
            ) : null}
            {totalHeader}
          </div>
          <div className="relative h-full">
            {dataSources.length > 0 ? (
              <div className="flex">
                {dataSources.map(source =>
                  sources?.has(source) ? (
                    <Button
                      variant={{ variant: "solid", color: "blue" }}
                      key={source}
                      className={`w-8 h-8 ${interactive ? "pointer-events-auto" : "pointer-events-none"}`}
                      onClick={toggleChartSelection(source)}
                    >
                      <img
                        src={sources.get(source)?.icon}
                        alt={sources.get(source)?.name}
                        title={sources.get(source)?.name}
                        width={24}
                        height={24}
                        className={`object-cover ${
                          !focusedCharts.length ||
                          focusedCharts.includes(source)
                            ? "opacity-100"
                            : "opacity-50"
                        }`}
                      />
                    </Button>
                  ) : null,
                )}
              </div>
            ) : null}
            <div className="join opacity-75 hover:opacity-100 absolute -bottom-9 right-0 z-40 h-9">
              {!hideAggSelector && aggregationGroups.length > 1
                ? aggregationGroups.map(({ type, long, short }) => (
                    <Button
                      variant={{ variant: "solid", color: "blue" }}
                      key={type}
                      title={long}
                      className={`join-item border text-3.5 px-4 py-3 rounded-sm ${type === (dataAggTypeOnly ?? dataAggType) ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
                      onClick={() => handleAggregationGroup(type)}
                    >
                      {chartWidth < 550 ? short : long}
                    </Button>
                  ))
                : null}
            </div>
          </div>
          <div>{}</div>
        </div>
      </div>
      <ResponsiveContainer
        key={`chart-${chartWidth}-${size.height}-${noData ? "no-data" : ""}`}
        width="100%"
        minHeight={85}
        className="flex items-end grow shrink-0 basis-auto"
      >
        <ReComposedChart
          data={
            state !== "idle" || noData ? CHARTS_LOADING_DATA : normalizedMetrics
          }
          margin={{
            top: sortedGraphs?.[0]?.labelAggType === "percent-total" ? 20 : 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          style={composedChartStyle}
        >
          {normalizedMetrics
            .filter(({ date }) =>
              dayjs(date).isSame(dayjs(date).startOf("quarter")),
            )
            .map(({ date }, index) => (
              <ReferenceLine
                key={index}
                x={date}
                strokeDasharray="2 2"
                yAxisId={yAxis?.[0]}
                stroke={theme.colors["gray-light"]}
                label={{
                  value: `Q${dayjs(date).quarter()}`,
                  position: "bottom",
                  style: {
                    transform: "translateY(14px)",
                    fontSize: `${theme.fontSize.sm}`,
                  },
                }}
              />
            ))}
          <CartesianGrid
            strokeDasharray="3 0"
            vertical={false}
            style={cartesianGridStyles}
          />
          <XAxis
            xAxisId={0}
            dataKey="date"
            tickFormatter={formatTick}
            interval="preserveStart"
            minTickGap={30}
            className="text-sm"
          />
          <XAxis xAxisId={1} dataKey="comparedDate" tick={false} hide />
          {yAxis.map((id, idx) => (
            <YAxis
              domain={[
                0,
                id
                  ? getMaxValueByYAxisId(id, sortedGraphs, metrics) * 1.01
                  : "dataMax",
              ]}
              orientation={idx ? "left" : "right"}
              interval="preserveEnd"
              style={idx ? yAxisStyleLeft : yAxisStyle}
              key={id}
              yAxisId={id}
              axisLine={false}
              tickLine={false}
              hide={idx > 1}
              width={0.01}
              allowDataOverflow
              tickFormatter={(value, index) =>
                index !== 0 || value > 0
                  ? `${formatNumber(value, { compact: true })}`
                  : ""
              }
            />
          ))}
          {yAxis.length < 1 && (
            <YAxis
              domain={[0, "dataMax"]}
              orientation="right"
              interval="preserveEnd"
              padding={{ top: 0, bottom: 0 }}
              width={0.01}
              axisLine={false}
              tickLine={false}
              style={yAxisStyle}
              allowDataOverflow
              tickFormatter={(value, index) =>
                index !== 0 || value > 0
                  ? `${formatNumber(value, { compact: true })}`
                  : ""
              }
            />
          )}
          {sortedGraphs?.map(
            ({
              type = "line",
              dataKey,
              color,
              requiredSources,
              stacked: graphStacked,
              yAxisId,
            }) => {
              // hide chart if source is not connected
              if (
                requiredSources &&
                !requiredSources.some(source => sources?.has(source))
              ) {
                return null
                // hide chart if source is not focused
              } else if (
                focusedCharts.length &&
                requiredSources &&
                !requiredSources?.some(source => focusedCharts.includes(source))
              ) {
                return null
              }

              const mainDataKey = `${mainMetricPrefix}${dataKey}`

              switch (type) {
                case "area":
                  return (
                    <Area
                      type="monotone"
                      key={mainDataKey}
                      dataKey={mainDataKey}
                      strokeWidth={2}
                      stroke={color}
                      fill={color}
                      yAxisId={yAxisId}
                      stackId={stacked || graphStacked ? 1 : undefined}
                      fillOpacity={
                        isMetricHighlighted(mainDataKey) ? 0.75 : 0.25
                      }
                      isAnimationActive={true}
                      animationDuration={1500}
                      animationBegin={0}
                    />
                  )
                case "line":
                  return (
                    <Line
                      type="monotone"
                      key={mainDataKey}
                      dataKey={mainDataKey}
                      dot={{ r: 1 }}
                      strokeWidth={2}
                      stroke={color}
                      fill={color}
                      yAxisId={yAxisId}
                      fillOpacity={0.25}
                      isAnimationActive={true}
                      animationDuration={1500}
                      animationBegin={0}
                    />
                  )
                case "dashed":
                  return (
                    <Line
                      type="monotone"
                      key={mainDataKey}
                      dataKey={mainDataKey}
                      dot={false}
                      strokeWidth={2}
                      strokeDasharray="6 6"
                      stroke={color}
                      fill={color}
                      yAxisId={yAxisId}
                      fillOpacity={0.25}
                      isAnimationActive={true}
                      animationDuration={1500}
                      animationBegin={0}
                    />
                  )
              }
              return null
            },
          )}
          {compareMode &&
            sortedGraphs?.map(
              ({
                dataKey,
                color,
                requiredSources,
                yAxisId,
                stacked: graphStacked,
              }) => {
                // hide chart if source is not connected
                if (
                  requiredSources &&
                  !requiredSources.some(source => sources?.has(source))
                ) {
                  return null
                  // hide chart if source is not focused
                } else if (
                  focusedCharts.length &&
                  requiredSources &&
                  !requiredSources?.some(source =>
                    focusedCharts.includes(source),
                  )
                ) {
                  return null
                }

                const compareDataKey = `${comparedMetricPrefix}${dataKey}`

                return (
                  <Area
                    type="monotone"
                    key={compareDataKey}
                    dataKey={compareDataKey}
                    dot={false}
                    yAxisId={yAxisId}
                    strokeWidth={2}
                    strokeDasharray="6 6"
                    stroke={color}
                    fill={color}
                    fillOpacity={0}
                    stackId={stacked || graphStacked ? 2 : undefined}
                    strokeOpacity={0.8}
                    isAnimationActive={true}
                    animationDuration={1500}
                    animationBegin={0}
                    z={2}
                  />
                )
              },
            )}
          {state === "idle" && !noData ? (
            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              content={({ active, payload }) => {
                if (!active || !payload || !payload.length) {
                  return null
                }
                return (
                  <ChartTooltipContent
                    title={title}
                    active={active}
                    payload={payload}
                    dataLabels={dataLabels}
                    isTotalCalculated={manualTotal}
                    showTotal={!hideTotal}
                    isCompareMetricMain={isCompareMetricMain}
                    compareMode={compareMode}
                    highlightKey={normalizedHighlightKey}
                    totalLabelConfig={totalLabelProps}
                    excludeDataKeyFromTotal={excludeDataKeyFromTotal}
                    tooltipProps={tooltipProps}
                    graphsManualSort={graphsManualSort}
                    blurTitle={blurTitle}
                    hideTooltipTitle={hideTooltipTitle}
                  />
                )
              }}
            />
          ) : null}
        </ReComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
