import React, { ReactNode, useState } from "react"

import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { getTooltipClasses } from "./utils"

import type { ThemeColors } from "~/ui-rtk/themes/types"

export type TooltipType = "info" | "warn" | "error"

export type TTooltipProps = {
  text: string | ReactNode
  type?: TooltipType
  position?: "top" | "bottom"
  classes?: Partial<{
    text: string
    wrapper: string
    container: string
    triangle: string
    color: ThemeColors
  }>
  children: React.ReactNode
}

const Tooltip: React.FC<TTooltipProps> = ({
  text,
  type = "warn",
  position = "bottom",
  children,
  classes: customClasses,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const baseClasses = getTooltipClasses(type)

  const showTooltip = () => setIsVisible(true)
  const hideTooltip = () => setIsVisible(false)

  return (
    <div
      className="relative h-full w-full inline-flex items-center justify-center"
      onMouseLeave={hideTooltip}
    >
      <div onMouseEnter={showTooltip}>{children}</div>
      {isVisible ? (
        <div
          className={cn(
            "absolute transform -translate-x-1/2 left-1/2 min-w-45 animate-fadeIn z-10",
            position === "top" && "bottom-full mb-2 ",
            position === "bottom" && "top-full mt-2",
            customClasses?.wrapper,
          )}
        >
          <div
            className={cn(
              "relative px-3 py-2 text-white flex items-center justify-center rounded shadow-lg min-h-12",
              baseClasses?.container,
              customClasses?.container,
            )}
          >
            <p className={cn("text-3.5", customClasses?.text)}>{text}</p>
            <div className="absolute w-3 h-3 overflow-hidden -top-[11.8px] bg-transparent">
              <div
                className={cn(
                  "absolute w-full h-full rotate-45 top-2 border-l border-t border-transparent",
                  baseClasses?.triangle,
                  customClasses?.triangle,
                )}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Tooltip
