export const isObjectsEqual = (o1: object, o2: object): boolean => {
  if (
    typeof o1 !== "object" ||
    typeof o2 !== "object" ||
    o1 === null ||
    o2 === null
  ) {
    return o1 === o2
  }

  const keys1 = Object.keys(o1)
  const keys2 = Object.keys(o2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!isObjectsEqual(o1[key as keyof object], o2[key as keyof object])) {
      return false
    }
  }

  return true
}

type AnyObject = Record<string, any>

function isPlainObject(input: any) {
  return input && !Array.isArray(input) && typeof input === "object"
}

export function propertyNamesToLowercase(obj: object) {
  const final: AnyObject = {}

  // Iterate over key-value pairs of the root object 'obj'
  for (const [key, value] of Object.entries(obj)) {
    // Set the lowercased key in the 'final' object and use the original value if it's not an object
    // else use the value returned by this function (recursive call).
    final[key.toLowerCase()] = isPlainObject(value)
      ? propertyNamesToLowercase(value)
      : value
  }
  return final
}
