import React, { useCallback, useState } from "react"
import { ConnectorMetadata } from "~/ui-rtk/api/types"
import { Tooltip } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"
import {
  ErrorSvg,
  InfoSvg,
  SendSvg,
} from "~/ui-rtk/components/ui/svg/essentials"
import { useFeatureFlagEnabled } from "posthog-js/react"

import type { ConnectorStatus } from "~/ui-rtk/shared/types/connectors"

type TSourceItemProps = {
  id: string
  name: string
  image: {
    alt: string
    src: string
  }
  status: ConnectorStatus
  metadata?: ConnectorMetadata
  confirmedAt?: string
  onConnect: (id: string, callback: () => void) => void
  onStatusCheck: (id: string) => void
  onOpenConnectionLinkDialog: () => void
}

const buttonTextMap: { [key in ConnectorStatus]: string } = {
  CONNECTED: "Connected",
  FAILED: "Try again",
  NOT_CONNECTED: "Connect",
  RECONNECT: "Reconnect",
  PENDING: "Downloading",
} as const

const SourceItem: React.FC<TSourceItemProps> = ({
  id,
  name,
  image,
  status,
  metadata,
  confirmedAt,
  onConnect,
  onStatusCheck,
  onOpenConnectionLinkDialog,
}) => {
  const flagEnabled = useFeatureFlagEnabled("connection-link")

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleConnect = useCallback(() => {
    void setIsSubmitting(true)
    void onConnect(id, () => setIsSubmitting(false))
  }, [id])

  const handleStatusCheck = useCallback(() => {
    void onStatusCheck(id)
  }, [id])

  const renderIconWithTooltip = useCallback(() => {
    if (status !== "RECONNECT" && status !== "FAILED") {
      return null
    }

    let tooltipText = "Reconnect is required"

    if (Array.isArray(metadata?.errors) && metadata.errors.length > 0) {
      tooltipText = "Synchronization issues occurred during the connection"
    } else if (status === "FAILED") {
      tooltipText = "Unexpected issue occurred"
    }

    return (
      <Tooltip text={tooltipText} type="error">
        <button className="cursor-pointer">
          <ErrorSvg size={24} fill="error" />
        </button>
      </Tooltip>
    )
  }, [status, metadata])

  return (
    <li className="flex justify-between border border-basic-blue w-full px-3 py-2 rounded-xl">
      <div className="flex items-center md:gap-5">
        <img src={image.src} alt={image.alt} className="h-9 max-w-9" />
        <p className="font-medium leading-5">{name}</p>
      </div>

      <div className="flex md:items-center sm:items-center gap-1">
        <div className="flex max-w-[400px] md:min-w-[175px] self-end items-center justify-end gap-1 lg:gap-2 xl:gap-3 2xl:gap-3 columns-6">
          <Button
            className="w-40 col-span-5"
            disabled={
              isSubmitting || status === "PENDING" || status === "CONNECTED"
            }
            variant={{
              variant: "solid",
              color: ["FAILED", "RECONNECT"].includes(status) ? "red" : "pink",
            }}
            isLoading={isSubmitting || status === "PENDING"}
            onClick={handleConnect}
          >
            {buttonTextMap[status]}
          </Button>

          <div className="col-span-1 w-6">
            {status === "CONNECTED" && Boolean(confirmedAt) ? (
              <button onClick={handleStatusCheck} className="cursor-pointer">
                <InfoSvg size={24} fill="blue" />
              </button>
            ) : null}

            {flagEnabled && status === "NOT_CONNECTED" ? (
              <button
                className="cursor-pointer"
                onClick={onOpenConnectionLinkDialog}
              >
                <SendSvg size={24} fill="white" />
              </button>
            ) : null}

            {status === "FAILED" || status === "RECONNECT"
              ? renderIconWithTooltip()
              : null}
          </div>
        </div>
      </div>
    </li>
  )
}

export default SourceItem
