import { useEffect } from "react"
import { toast } from "react-toastify"
import { Navigate, useSearchParams } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import ExpiryDetails from "./ExpiryDetails"
import ConnectionDetails from "./ConnectionDetails"
import { H3 } from "~/ui-rtk/components/ui/typography"
import { Loader } from "~/ui-rtk/components/ui/common"

import useConnect from "./connect"

import { DEFAULT_GUEST_ROUTE } from "~/ui-rtk/constants/routes"
import { LocalStorageKeys, setLocalStorageValue } from "~/ui-rtk/utils/storage"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const OAuthConnection = () => {
  const navigate = useStableNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get("token")
  const connectionLinkId = searchParams.get("id")
  if (!token || !connectionLinkId) {
    toast.error("Invalid connection link ID.")
    return <Navigate to={DEFAULT_GUEST_ROUTE} />
  }

  const { data, isLoading, isError } = useConnect(connectionLinkId, token)

  useEffect(() => {
    if (isError) {
      toast.error("Failed to load Connection Link information.", {
        toastId: "oauth-connection-link-page-toast",
      })
      navigate(DEFAULT_GUEST_ROUTE)
    }
  }, [isError])

  useEffect(() => {
    if (token) {
      setLocalStorageValue(LocalStorageKeys.GUEST_TOKEN, token)
    }
  }, [token])

  if (isLoading) {
    return <Loader />
  }

  const renderDetails = () => {
    if (!data) {
      return
    }

    const { company, connector, expiresAt, recipientEmail } = data

    const isExpired = dayjs(expiresAt).isBefore(dayjs())
    if (isExpired) {
      return <ExpiryDetails inviter={recipientEmail} />
    }

    return (
      <ConnectionDetails
        token={token}
        company={company.name}
        service={connector.id}
        application={connector.name}
      />
    )
  }

  return (
    <div className="flex items-center justify-center h-screen">
      {isLoading && <Loader />}
      {data && (
        <div className="flex flex-col justify-center py-10 text-center items-center max-w-190 mx-auto border-gray-light/[.2] bg-background-dark-jungle border px-6 rounded-md">
          <img
            src={data.connector.icon}
            alt={`${data.connector.name} Logo`}
            width={32}
            className="self-center mb-6"
          />
          <H3 className="mb-4">
            Connect {data.connector.name} to Marathon Data
          </H3>
          <p className="mb-8">You have been chosen!</p>
          <p className="mb-6 text-center">
            One of your teammates has identified you as the account holder of{" "}
            {data.connector.name} and has asked you to connect this account to
            Marathon Data in order to transform the way you measure and
            understand your brand.
          </p>
          {renderDetails()}
        </div>
      )}
    </div>
  )
}

export default OAuthConnection
