import { useCallback, useState } from "react"
import * as yup from "yup"
import type { FormikHelpers } from "formik"

import { isFetchBaseQueryError } from "~/ui-rtk/utils/guards"
import { isUnauthorizedError } from "~/ui-rtk/utils/http-utils"
import { useForgotPasswordContext } from "~/ui-rtk/pages/ForgotPassword"
import { useAuthControllerRequestResetPasswordMutation } from "~/ui-rtk/api/authApi"

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Oops! That doesn’t look like a valid email")
    .required("Email is required!"),
})

const initialValues = {
  email: "",
}
type FormValues = typeof initialValues

export default function useConnect() {
  const { showEmailSentView } = useForgotPasswordContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [requestResetPassword, result] =
    useAuthControllerRequestResetPasswordMutation()

  const handleRequestResetPassword = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      void setIsSubmitting(true)
      try {
        await requestResetPassword({
          requestResetPasswordDto: { email: values.email },
        }).unwrap()
        void showEmailSentView(values.email)
      } catch (error) {
        if (isFetchBaseQueryError(error) && !isUnauthorizedError(error)) {
          setErrors({ email: "Invalid email" })
        }
      } finally {
        void setIsSubmitting(false)
      }
    },
    [requestResetPassword],
  )

  return {
    initialValues,
    validationSchema,
    handleRequestResetPassword,
    requestResult: result,
    isSubmitting,
  }
}
