import { Select } from "~/ui-rtk/components/ui/controls"
import { GRANULARITIES } from "../../constants"
import { useCallback, useMemo } from "react"
import useDate from "~/ui-rtk/hooks/date"
import WeekPicker from "~/ui-rtk/components/ui/controls/WeekPicker"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"
import MonthPicker from "~/ui-rtk/components/ui/controls/MonthPicker/MonthPicker"
import QuarterPicker from "~/ui-rtk/components/ui/controls/QuarterPicker/QuarterPicker"
import YearPicker from "~/ui-rtk/components/ui/controls/YearPicker/YearPicker"

export type TPeriodPickerProps = {
  dateRange: [Date, Date]
  onDateRangeChange: (dateRange: [Date, Date]) => void
  maxDateRestriction: Date
  granularity: BrandMediaAggregationType
  setGranularity: (agg: BrandMediaAggregationType) => void
}

const dayjs = useDate()

export default function PeriodPicker({
  dateRange,
  granularity,
  setGranularity,
  onDateRangeChange,
  maxDateRestriction,
}: TPeriodPickerProps) {
  const startOfPeriod = useMemo(() => {
    const baseDate = dayjs(dateRange[1])
    switch (granularity) {
      case BrandMediaAggregationType.WEEKLY: {
        return baseDate.weekday(0).toDate()
      }
      case BrandMediaAggregationType.MONTHLY: {
        return baseDate.startOf("month").toDate()
      }
      case BrandMediaAggregationType.QUARTERLY: {
        return baseDate.startOf("quarter").toDate()
      }
      case BrandMediaAggregationType.YEARLY: {
        return baseDate.startOf("year").toDate()
      }
      default: {
        throw new Error("Wrong granularity")
      }
    }
  }, [dateRange, granularity])

  const handleChangeGranularity = (granularity: BrandMediaAggregationType) =>
    setGranularity(granularity)

  const handleChangePeriod = useCallback(
    (startOfPeriod: Date) => {
      const baseDate = dayjs(startOfPeriod)
      let endOfPeriod: Date
      switch (granularity) {
        case BrandMediaAggregationType.WEEKLY: {
          endOfPeriod = baseDate.add(6, "day").toDate()
          break
        }
        case BrandMediaAggregationType.MONTHLY: {
          endOfPeriod = baseDate.endOf("month").toDate()
          break
        }
        case BrandMediaAggregationType.QUARTERLY: {
          endOfPeriod = baseDate.endOf("quarter").toDate()
          break
        }
        case BrandMediaAggregationType.YEARLY: {
          endOfPeriod = baseDate.endOf("year").toDate()
          break
        }
        default: {
          throw new Error("Wrong granularity")
        }
      }
      void onDateRangeChange([startOfPeriod, endOfPeriod])
    },
    [granularity, dateRange],
  )

  const renderPeriodPicker = useCallback(() => {
    switch (granularity) {
      case BrandMediaAggregationType.WEEKLY: {
        return (
          <WeekPicker
            startOfWeek={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
      case BrandMediaAggregationType.MONTHLY: {
        return (
          <MonthPicker
            startOfMonth={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
      case BrandMediaAggregationType.QUARTERLY: {
        return (
          <QuarterPicker
            startOfQuarter={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
      case BrandMediaAggregationType.YEARLY: {
        return (
          <YearPicker
            startOfYear={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
    }
  }, [granularity, dateRange])

  return (
    <div className="flex gap-2">
      <Select
        options={GRANULARITIES}
        value={granularity}
        onChange={ev =>
          handleChangeGranularity(
            (ev.target as HTMLSelectElement).value as BrandMediaAggregationType,
          )
        }
      />
      {renderPeriodPicker()}
    </div>
  )
}
