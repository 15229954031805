import { toast } from "react-toastify"
import { useCallback, useEffect, useMemo, useState } from "react"
import { isFuture } from "date-fns"

import ConnectorLinkDialog from "~/ui-rtk/components/features/connectors/ConnectorLinkDialog"
import {
  StepsList,
  PendingDataConnection,
  SkippedDataConnection,
} from "./components"
import { Button } from "~/ui-rtk/components/ui/controls"
import {
  CamelProgressBar,
  ContentBox,
  Loader,
} from "~/ui-rtk/components/ui/common"

import {
  type Connector,
  UNRESOLVED_CONNECTOR_STATUSES,
  useConnect,
} from "./connect"
import { serviceConnectorMap } from "~/ui-rtk/components/features/connectors/forms/utils"
import { useLazyComponent } from "~/ui-rtk/shared/hooks/useLazyComponent/useLazyComponent"

export const MIN_CONNECTION_COUNT = 2

export const ConnectionFlowPage = () => {
  const {
    skipServiceAsync,
    undoServiceSkipAsync,
    isConnectionFlowCompleting,
    isGoogleAnalyticsCompleted,
    connect,
    connectionFlowDetails,
    isDataLoading,
    completeConnectionFlow,
  } = useConnect()

  const [flowState, setFlowState] = useState<"completed" | "skipped" | null>()
  const [onboardUrl, setOnboardUrl] = useState<string | null>(null)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const currentStep = useMemo(
    () =>
      connectionFlowDetails.steps.find(step => step.order === currentStepIndex),
    [connectionFlowDetails, currentStepIndex],
  )

  const { loadComponent, unloadComponent, renderLazyComponent } =
    useLazyComponent()

  const handleConnect = useCallback(
    async (id: string, callback?: CallableFunction) => {
      try {
        const { uri, companyConnectorId } = await connect(id)
        const connectorService = serviceConnectorMap[id]
        if (connectorService) {
          loadComponent(connectorService.component, {
            isOpen: true,
            uri,
            service: id,
            companyConnectorId,
            onClose: unloadComponent,
          })
          return
        }

        setOnboardUrl(uri)
      } catch (error) {
        toast.error("Connection failed")
      } finally {
        void callback?.()
      }
    },
    [connect],
  )

  const openConnectionLinkDialog = (connector: Connector) => {
    loadComponent(ConnectorLinkDialog, {
      connectorId: connector.id,
      imgSrc: connector.icon,
      service: connector.name,
      onClose: unloadComponent,
    })
  }

  const handleComplete = useCallback(async () => {
    const { totalCompleted } = connectionFlowDetails
    const hasMinimumConnections = totalCompleted >= MIN_CONNECTION_COUNT
    if (!isGoogleAnalyticsCompleted || !hasMinimumConnections) {
      setFlowState("skipped")
      return
    }

    try {
      await completeConnectionFlow()
      setFlowState("completed")
    } catch (error) {
      toast.error("Failed to complete connection flow")
    }
  }, [
    completeConnectionFlow,
    connectionFlowDetails,
    isGoogleAnalyticsCompleted,
  ])

  const handleSkipConnection = async (service: string) => {
    try {
      await skipServiceAsync(service)
    } catch (error) {
      toast.error("Failed to skip service connection.")
      throw error
    }
  }

  const handleUndoSkip = async (service: string) => {
    try {
      await undoServiceSkipAsync(service)
    } catch (error) {
      toast.error("Failed to undo skip operation over service connection.")
      throw error
    }
  }

  const handleNext = () => setCurrentStepIndex(prev => prev + 1)
  const handlePrev = () => setCurrentStepIndex(prev => prev - 1)

  const isLastStep =
    currentStep?.order === connectionFlowDetails.steps.length - 1

  const nextDisabled = useMemo(() => {
    const isAllProcessed = currentStep?.connectors.every(connector => {
      const isResolved = !UNRESOLVED_CONNECTOR_STATUSES.includes(
        connector.status,
      )

      const isDelegated = connector.connectionLink
        ? isFuture(connector.connectionLink.expiresAt)
        : false
      return connector.isSkipped || isResolved || (isDelegated && !isLastStep)
    })
    return !isAllProcessed
  }, [isLastStep, currentStep])

  useEffect(() => {
    if (onboardUrl) {
      // window.open(onboardUrl, "_blank", "noopener,norefferer")
      window.location.href = onboardUrl
    }
  }, [onboardUrl])

  useEffect(() => {
    if (connectionFlowDetails.defaultStep !== currentStepIndex) {
      setCurrentStepIndex(connectionFlowDetails.defaultStep)
    }
  }, [connectionFlowDetails.defaultStep])

  if (flowState === "completed") {
    return <PendingDataConnection />
  } else if (flowState === "skipped") {
    return <SkippedDataConnection />
  }

  return (
    <ContentBox
      title="Onboard your data into Marathon to officially secure your spot on the waitlist for our private beta"
      subTitle={currentStep?.title}
      classes={{
        content: "max-w-full",
        contentContainer: "lg:px-10",
      }}
    >
      {!isDataLoading ? (
        <div>
          <StepsList
            currentStep={currentStep}
            onSetDialog={openConnectionLinkDialog}
            onConnect={handleConnect}
            onSkip={handleSkipConnection}
            onUndo={handleUndoSkip}
          />
          <CamelProgressBar
            total={connectionFlowDetails.totalConnectors}
            completed={connectionFlowDetails.totalCompleted}
          />
          <div className="flex gap-4">
            <Button
              className="mx-auto w-48 mt-8"
              onClick={handlePrev}
              disabled={currentStepIndex < 1}
            >
              Previous
            </Button>
            <Button
              className="mx-auto w-48 mt-8"
              onClick={isLastStep ? handleComplete : handleNext}
              isLoading={isConnectionFlowCompleting}
              disabled={nextDisabled}
            >
              {isLastStep ? "Complete" : "Next"}
            </Button>
          </div>
          {renderLazyComponent()}
        </div>
      ) : (
        <Loader />
      )}
    </ContentBox>
  )
}
