import { useMemo } from "react"
import {
  type Query as TCubeQuery,
  BinaryOperator,
  Filter,
} from "@cubejs-client/core"
import { useCubeQuery, UseCubeQueryOptions } from "@cubejs-client/react"
import { CUBE_QUERY_KEYS_MAP } from "../components/features/details/constants"

import useDate, { Dayjs } from "~/ui-rtk/hooks/date"
import { BrandMediaAggregationType } from "../constants/charts"

const dayjs = useDate()

export type TCampaignAdSetAdDetailsData = {
  brandValue: number
  total: number
  purchases: number
  spend?: number
}
export type TCampaignAdSetAdDetailsItem = {
  current: TCampaignAdSetAdDetailsData
  all: TCampaignAdSetAdDetailsData
  totalItems: number
  date: string
}

function getInfoBlock(metrics: any[], weeksChunk?: number) {
  const { total, spend, brandValue } = (
    weeksChunk ? metrics.slice(0, weeksChunk) : metrics
  ).reduce(
    (acc, item) => ({
      total: acc.total + item.current.brandValue + item.current.purchases,
      brandValue: acc.brandValue + item.current.brandValue,
      spend: acc.spend + item.current.spend,
    }),
    {
      total: 0,
      spend: 0,
      brandValue: 0,
    },
  )
  return {
    total,
    spend,
    brandValue,
  }
}

export function prepareAllItemsDetails(metrics: any[]) {
  return metrics.reduce(
    (acc, metric) => ({
      brand_value_agg: acc.brand_value_agg + metric.brand_value_agg,
      spend: acc.spend + metric.spend,
      purchases: acc.purchases + metric.purchases,
    }),
    {
      brand_value_agg: 0,
      spend: 0,
      purchases: 0,
    },
  )
}

export function prepareValueOverTimeData(
  metrics: any[],
  isCurrent: (metric: any) => boolean,
  granularity: BrandMediaAggregationType,
) {
  const resultSet = metrics.reduce((resultSet, metric) => {
    const year = metric.year
    let date: Dayjs
    switch (granularity) {
      case BrandMediaAggregationType.WEEKLY: {
        date = dayjs(`${year}-01-01`).isoWeek(metric.week)
        break
      }
      case BrandMediaAggregationType.MONTHLY: {
        date = dayjs(`${year}-01-01`).month(metric.month)
        break
      }
      case BrandMediaAggregationType.QUARTERLY: {
        date = dayjs(`${year}-01-01`).quarter(metric.quarter)
        break
      }
      case BrandMediaAggregationType.YEARLY: {
        date = dayjs(`${year}-01-01`)
        break
      }
      default: {
        throw new Error("Wrong granularity")
      }
    }

    const dateStr = date.format("YYYY-MM-DD")

    if (!resultSet[dateStr]) {
      resultSet[dateStr] = {
        current: {
          brandValue: 0,
          total: 0,
          purchases: 0,
          spend: 0,
        },
        all: {
          brandValue: 0,
          purchases: 0,
          total: 0,
        },
        totalItems: 0,
        date: dateStr,
      } as TCampaignAdSetAdDetailsItem
    }

    if (isCurrent(metric)) {
      resultSet[dateStr].current.brandValue = metric.brand_value_agg
      resultSet[dateStr].current.total =
        metric.brand_value_agg + metric.purchases
      resultSet[dateStr].current.spend = metric.spend
      resultSet[dateStr].current.purchases = metric.purchases
    }
    if (metric.brand_value !== null && metric.spend > 0) {
      resultSet[dateStr].all.brandValue += metric.brand_value_agg
      resultSet[dateStr].all.total += metric.brand_value_agg + metric.purchases
      resultSet[dateStr].all.purchases += metric.purchases
      resultSet[dateStr].totalItems += 1
    }
    return resultSet
  }, {})

  const chartData = Object.values(resultSet).sort(
    (a: unknown, b: unknown) =>
      dayjs((a as TCampaignAdSetAdDetailsItem).date)
        .toDate()
        .getTime() -
      dayjs((b as TCampaignAdSetAdDetailsItem).date)
        .toDate()
        .getTime(),
  )

  return {
    chartData: chartData.map((item: unknown) => ({
      ...(item as TCampaignAdSetAdDetailsItem),
      average: {
        brandValue:
          (item as TCampaignAdSetAdDetailsItem).totalItems > 0
            ? (item as TCampaignAdSetAdDetailsItem).all.brandValue /
              (item as TCampaignAdSetAdDetailsItem).totalItems
            : 0,
        total:
          (item as TCampaignAdSetAdDetailsItem).totalItems > 0
            ? (item as TCampaignAdSetAdDetailsItem).all.total /
              (item as TCampaignAdSetAdDetailsItem).totalItems
            : 0,
      },
    })),
    info30days: {
      ...getInfoBlock([...chartData].reverse(), 4),
      label: `4${granularity[0]}`,
    },
    info60days: {
      ...getInfoBlock([...chartData].reverse(), 8),
      label: `8${granularity[0]}`,
    },
    info90days: {
      ...getInfoBlock([...chartData].reverse()),
      label: `12${granularity[0]}`,
    },
  }
}

function getFullYearsBetweenDates(date1: Date, date2: Date) {
  const [startDate, endDate] = dayjs(date1).isBefore(dayjs(date2))
    ? [date1, date2]
    : [date2, date1]
  const firstYear = startDate.getFullYear()
  const lastYear = endDate.getFullYear()

  const years = []

  for (let i = firstYear + 1; i < lastYear; i++) {
    years.push(i)
  }

  return years
}

export function getYearFilters(date: string, keyPrefix: string) {
  const yearKey = `${keyPrefix}.year`
  const endDate = dayjs(date)
  const startDate = endDate.subtract(12, "quarters")

  return [
    {
      and: [
        {
          dimension: yearKey,
          operator: "lte",
          values: [endDate.isoWeekYear().toString()],
        },
        {
          dimension: yearKey,
          operator: "gte",
          values: [startDate.isoWeekYear().toString()],
        },
      ],
    },
  ] as Filter[]
}

export function getQuarterYearFilters(date: string, keyPrefix: string) {
  const quarterKey = `${keyPrefix}.quarter`
  const yearKey = `${keyPrefix}.year`
  const endDate = dayjs(date)
  const startDate = endDate.subtract(12, "quarters")
  const fullYearsBetweenDates = getFullYearsBetweenDates(
    startDate.toDate(),
    endDate.toDate(),
  )

  const filters: Filter[] = []

  filters.push({
    or: [
      {
        and: [
          {
            dimension: yearKey,
            operator: "equals",
            values: [endDate.isoWeekYear().toString()],
          },
          {
            dimension: quarterKey,
            operator: "lte",
            values: [endDate.quarter().toString()],
          },
          {
            dimension: quarterKey,
            operator: "gte",
            values: ["1"],
          },
        ],
      },
      ...fullYearsBetweenDates.map(year => ({
        dimension: yearKey,
        operator: "equals" as BinaryOperator,
        values: [`${year}`],
      })),
      {
        and: [
          {
            dimension: yearKey,
            operator: "equals",
            values: [startDate.isoWeekYear().toString()],
          },
          {
            dimension: quarterKey,
            operator: "lte",
            values: ["4"],
          },
          {
            dimension: quarterKey,
            operator: "gt",
            values: [startDate.quarter().toString()],
          },
        ],
      },
    ],
  })

  return filters
}

export function getMonthYearFilters(date: string, keyPrefix: string) {
  const monthKey = `${keyPrefix}.month`
  const yearKey = `${keyPrefix}.year`
  const valueOverTimeStartDate = dayjs(date).subtract(12, "months")
  const isSameYear =
    dayjs(date).format("YYYY") === valueOverTimeStartDate.format("YYYY")

  const filters: Filter[] = []

  if (isSameYear) {
    filters.push({
      dimension: yearKey,
      operator: "equals",
      values: [dayjs(date).isoWeekYear().toString()],
    })
    filters.push({
      dimension: monthKey,
      operator: "lte",
      values: [(dayjs(date).month() + 1).toString()],
    })
    filters.push({
      dimension: monthKey,
      operator: "gt",
      values: [(valueOverTimeStartDate.month() + 1).toString()],
    })
  } else {
    const firstMonthInPrevYear = valueOverTimeStartDate.month() + 1

    filters.push({
      or: [
        {
          and: [
            {
              dimension: yearKey,
              operator: "equals",
              values: [dayjs(date).isoWeekYear().toString()],
            },
            {
              dimension: monthKey,
              operator: "lte",
              values: [(dayjs(date).month() + 1).toString()],
            },
            {
              dimension: monthKey,
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        {
          and: [
            {
              dimension: yearKey,
              operator: "equals",
              values: [valueOverTimeStartDate.isoWeekYear().toString()],
            },
            {
              dimension: monthKey,
              operator: "lte",
              values: ["12"],
            },
            {
              dimension: monthKey,
              operator: "gt",
              values: [firstMonthInPrevYear.toString()],
            },
          ],
        },
      ],
    })
  }

  return filters
}

export function getWeekYearFilters(date: string, keyPrefix: string) {
  const weekKey = `${keyPrefix}.week`
  const yearKey = `${keyPrefix}.year`
  const valueOverTimeStartDate = dayjs(date).subtract(12, "week")
  const isSameYear =
    dayjs(date).format("YYYY") === valueOverTimeStartDate.format("YYYY")

  const filters: Filter[] = []

  if (isSameYear) {
    filters.push({
      dimension: yearKey,
      operator: "equals",
      values: [dayjs(date).isoWeekYear().toString()],
    })
    filters.push({
      dimension: weekKey,
      operator: "lte",
      values: [dayjs(date).isoWeek().toString()],
    })
    filters.push({
      dimension: weekKey,
      operator: "gt",
      values: [valueOverTimeStartDate.isoWeek().toString()],
    })
  } else {
    const lastWeekInPrevYear = valueOverTimeStartDate.endOf("year").isoWeek()
    const firstWeekInPrevYear = valueOverTimeStartDate.isoWeek()

    filters.push({
      or: [
        {
          and: [
            {
              dimension: yearKey,
              operator: "equals",
              values: [dayjs(date).isoWeekYear().toString()],
            },
            {
              dimension: weekKey,
              operator: "lte",
              values: [dayjs(date).isoWeek().toString()],
            },
            {
              dimension: weekKey,
              operator: "gte",
              values: ["1"],
            },
          ],
        },
        {
          and: [
            {
              dimension: yearKey,
              operator: "equals",
              values: [valueOverTimeStartDate.isoWeekYear().toString()],
            },
            {
              dimension: weekKey,
              operator: "lte",
              values: [lastWeekInPrevYear.toString()],
            },
            {
              dimension: weekKey,
              operator: "gt",
              values: [firstWeekInPrevYear.toString()],
            },
          ],
        },
      ],
    })
  }

  return filters
}

export function getWeekYearMonthQuarterYearFilters(
  date: string,
  keyPrefix: string,
  granularity: BrandMediaAggregationType,
) {
  switch (granularity) {
    case BrandMediaAggregationType.WEEKLY: {
      return getWeekYearFilters(date, keyPrefix)
    }
    case BrandMediaAggregationType.MONTHLY: {
      return getMonthYearFilters(date, keyPrefix)
    }
    case BrandMediaAggregationType.QUARTERLY: {
      return getQuarterYearFilters(date, keyPrefix)
    }
    case BrandMediaAggregationType.YEARLY: {
      return getYearFilters(date, keyPrefix)
    }
    default: {
      throw new Error("Wrong granularity")
    }
  }
}

export function useAllCampaignsAdSetsAdsDetails(allQuery: TCubeQuery) {
  const queryOptions: UseCubeQueryOptions = useMemo(
    () => ({
      castNumerics: true,
    }),
    [],
  )

  const { resultSet: allSet, isLoading } = useCubeQuery(allQuery, queryOptions)

  return {
    isLoading,
    [CUBE_QUERY_KEYS_MAP.ALL]: allSet,
  }
}

export default function useCampaignAdSetAdDetails({
  [CUBE_QUERY_KEYS_MAP.ALL]: allQuery,
  [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeQuery,
}: Record<
  (typeof CUBE_QUERY_KEYS_MAP)[keyof typeof CUBE_QUERY_KEYS_MAP],
  TCubeQuery
>) {
  const queryOptions: UseCubeQueryOptions = useMemo(
    () => ({
      castNumerics: true,
    }),
    [],
  )

  const { resultSet: allSet, isLoading } = useCubeQuery(allQuery, queryOptions)
  const { resultSet: valueOverTimeSet, isLoading: isLoading2 } = useCubeQuery(
    valueOverTimeQuery,
    queryOptions,
  )

  return {
    isLoading: isLoading || isLoading2,
    [CUBE_QUERY_KEYS_MAP.ALL]: allSet,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeSet,
  }
}
