import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CompanyDto } from "~/ui-rtk/api/types"
import { revertAll } from "../revertAll"

export interface CompanyState {
  currentCompany: CompanyDto | null
}

const initialState: CompanyState = {
  currentCompany: null,
}

export const companySlice = createSlice({
  name: "companySlice",
  initialState,
  extraReducers: builder => builder.addCase(revertAll, () => initialState),
  reducers: {
    setCurrentCompany: (
      state: CompanyState,
      { payload }: PayloadAction<CompanyDto>,
    ) => {
      state.currentCompany = payload
    },
  },
})

export const companyActions = companySlice.actions
