import { PropsWithChildren } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type TCardProps = {
  rounded?: boolean
  fullWidth?: boolean
  ["no-border"]?: boolean
  className?: string
} & React.HTMLProps<HTMLDivElement>

export default function Card({
  rounded = false,
  className,
  children,
  ["no-border"]: noBorder = false,
  fullWidth = false,
  ...props
}: PropsWithChildren<TCardProps>) {
  const cx = cn(
    "text-background-light bg-basic-blue py-5 px-4",
    fullWidth && "container",
    rounded ? "rounded-lg" : "rounded-sm",
    noBorder && "border border-channel-blue",
    className,
  )

  return (
    <div className={cx} {...props}>
      {children}
    </div>
  )
}
