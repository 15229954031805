import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const highlightWeek = (date: Date) => {
  const start = dayjs(date).weekday(0)
  return Array.from(new Array(7), (_, i) => start.add(i, "day").toDate())
}

const getWeekRange = (date: Date) => {
  const start = dayjs(date).weekday(0)
  const end = dayjs(date).weekday(6)
  return `${start.format("MMM D")} -> ${end.format("D")}`
}

const getWeekLabel = (date: Date) => {
  const weekNumber = dayjs(date).isoWeek()
  const year = dayjs(date).isoWeekYear()
  return `Week ${weekNumber}, ${year} (${getWeekRange(date)})`
}

export { highlightWeek, getWeekLabel }
