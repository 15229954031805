import { RootState } from "../store"
import { CompanyState } from "../slices/company.slice"
import { createSelector } from "@reduxjs/toolkit"
import { CompanyDto } from "~/ui-rtk/api/types"

const selectCurrentCompanySelector = (state: RootState) =>
  state.companySlice.currentCompany

export const selectCurrentCompany = createSelector(
  [selectCurrentCompanySelector],
  currentCompany => ({ ...currentCompany }) as CompanyDto,
)

// Return types
export type SelectCompanyType = CompanyState
