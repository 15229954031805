import SvgHOC from "../../common/SvgHOC/index"

export const ArrowBackSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6667 7.55566H1.77783M1.77783 7.55566L5.77783 3.55566M1.77783 7.55566L5.77783 11.5557"
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
))
