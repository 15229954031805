import * as zod from "zod"

export class URLValidator {
  private static schema = zod.string().url()

  public static validate(url: string): boolean {
    const normalizedUrl = url.toLowerCase().trim()
    return this.schema.safeParse(normalizedUrl).success
  }
}
