import * as yup from "yup"
import { toast } from "react-toastify"
import {
  useAuthControllerCurrentUserQuery,
  useAuthControllerLogoutMutation,
} from "~/ui-rtk/api/authApi"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import {
  UpdateCompanySettingsDto,
  useCompanyControllerUpdateCompanySettingsMutation,
  useCompanyControllerUpdateCompanyMutation,
} from "~/ui-rtk/api/companyApi"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { isUnauthorizedError } from "~/ui-rtk/utils/http-utils"
import { useCallback, useMemo } from "react"
import { CompanySetupStatus } from "~/ui-rtk/api/types"

export const validationSchema = yup.object().shape({
  isPipelineEnabled: yup.boolean().default(false),
  freeAccessEndsAt: yup.date().nullable().optional(),
  isBillingEnabled: yup.boolean().optional().default(false),
  industry: yup.string(),
})

export type TCompanySettingsFormValues = yup.InferType<typeof validationSchema>

export function useConnect() {
  const currentCompany = useAppSelector(selectCurrentCompany)
  const { refetch } = useAuthControllerCurrentUserQuery()
  const [updateCompanySettings] =
    useCompanyControllerUpdateCompanySettingsMutation()
  const [updateCompany] = useCompanyControllerUpdateCompanyMutation()

  const [logout] = useAuthControllerLogoutMutation()
  const navigate = useStableNavigate()

  const initialValues: TCompanySettingsFormValues = useMemo(() => {
    const metadata = currentCompany?.metadata as any
    return {
      industry: metadata?.industry || "",
      isPipelineEnabled: Boolean(metadata?.isPipelineEnabled),
      freeAccessEndsAt: metadata?.freeAccessEndsAt
        ? new Date(metadata?.freeAccessEndsAt)
        : null,
      isBillingEnabled: Boolean(metadata?.isBillingEnabled),
    }
  }, [currentCompany])

  const updateSettings = useCallback(
    async (values: TCompanySettingsFormValues) => {
      if (!currentCompany?.id) {
        return
      }

      const updateCompanyDto: UpdateCompanySettingsDto = {
        metadata: {
          isPipelineEnabled: values.isPipelineEnabled,
          freeAccessEndsAt: values.freeAccessEndsAt?.toISOString(),
          isBillingEnabled: values.isBillingEnabled,
          industry: values.industry,
        },
      }

      try {
        await updateCompanySettings({
          id: currentCompany.id,
          updateCompanySettingsDto: updateCompanyDto,
        }).unwrap()
        await updateCompany({
          id: currentCompany.id,
          updateCompanyDto: {
            setupStatus: values.isBillingEnabled
              ? CompanySetupStatus.PENDING_PAYMENT
              : CompanySetupStatus.INITIAL_SETUP,
          },
        })
        toast.success("Settings were updated")
        await refetch()
      } catch (error) {
        if (isUnauthorizedError(error)) {
          await logout()
          navigate("/")
          return
        }
        toast.error("Could not update company settings")
      }
    },
    [currentCompany],
  )

  return {
    currentCompany,
    initialValues,
    validationSchema,
    updateSettings,
  }
}
