import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../store"

export const isOpen = (state: RootState) => state.drawerSlice.isOpen
export const drawerContent = (state: RootState) => state.drawerSlice.content

export const selectIsOpen = createSelector([isOpen], isOpen => ({
  isOpen,
}))
export const selectDrawerContent = createSelector(
  [drawerContent],
  drawerContent => ({
    drawerContent,
  }),
)
