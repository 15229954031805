import { useMemo } from "react"
import { AggregationType } from "../constants/charts"
import { LocalStorageKeys, useLocalStorage } from "./storage"
import { usePeriod } from "../hooks/period"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export const dateMax = (date1: Date, date2: Date) => {
  if (date1 > date2) {
    return date1
  }
  return date2
}

export const dateMin = (date1: Date, date2: Date) => {
  if (date1 < date2) {
    return date1
  }
  return date2
}

export const useDateRanges = () => {
  const { period, comparePeriod } = usePeriod()
  const dateRange = [dayjs(period[0]).toDate(), dayjs(period[1]).toDate()]
  const compareRange = comparePeriod
    ? [dayjs(comparePeriod[0]).toDate(), dayjs(comparePeriod[1]).toDate()]
    : null

  const minAggType = useMemo(() => {
    const diff = dayjs(dateRange[1]).diff(dateRange[0], "month")

    if (diff > 12) {
      return AggregationType.WEEKLY
    }

    return AggregationType.DAILY
  }, [dateRange])

  const maxAggType = useMemo(() => {
    const diffMonths = dayjs(dateRange[1]).diff(dateRange[0], "months")
    const diffWeeks = dayjs(dateRange[1]).diff(dateRange[0], "weeks")

    if (diffMonths >= 1) {
      return AggregationType.MONTHLY
    }

    if (diffWeeks >= 1) {
      return AggregationType.WEEKLY
    }

    return AggregationType.DAILY
  }, [dateRange])

  return { dateRange, compareRange, minAggType, maxAggType }
}

export const useDatesRangeStorage = () => {
  const today = new Date()
  const aWeekAgo = dayjs(today).startOf("day").subtract(6, "days")
  const twoWeeksAgo = dayjs(today).startOf("day").subtract(13, "days")

  return useLocalStorage<{
    dateRange: [string, string]
    compareRange: [string, string] | null
  }>(LocalStorageKeys.DASHBOARD_DATES, {
    dateRange: [aWeekAgo.toISOString(), today.toISOString()],
    compareRange: [
      twoWeeksAgo.toISOString(),
      aWeekAgo.subtract(1, "day").toISOString(),
    ],
  })
}

export function isDateMoreRecentThanMonthsAgo(date: Date, monthsAgo: number) {
  const givenDate = dayjs(date)
  const targetDate = dayjs().subtract(monthsAgo, "month")
  return givenDate.isAfter(targetDate)
}
