import { H2, H3 } from "../../../ui/typography"
import {
  ElementType,
  createElement,
  createRef,
  useCallback,
  useMemo,
  useState,
} from "react"
import { type DashboardVisualizationDto } from "~/ui-rtk/api/defaultApi"
import useSources from "~/ui-rtk/hooks/sources"
import {
  AreaChart,
  CohortVisualization,
  ComposedChart,
  DropDownChart,
  TableVisualization,
} from "../../../ui/charts"
import SortedItems from "~/ui-rtk/components/ui/charts/SortedItems/SortedItems"
import MultiChart from "../../../ui/charts/MultiChart"
import ChartLoader from "../../../ui/charts/ChartLoader"
import { AggregationType, VISUALIZATION_TYPE } from "~/ui-rtk/constants/charts"
import {
  TDashboardItem,
  TDashboardItemWidget,
  TDashboardItemsSet,
  WIDGET_TYPE,
} from "~/ui-rtk/constants/dashboard"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { ExclamationSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { PeriodPicker } from "~/ui-rtk/components/ui/controls"
import { flatContentWidgets } from "~/ui-rtk/utils/content"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import { ComposedTable } from "../../../ui/charts/ComposedTable/ComposedTable"
import Card from "../../../ui/common/Card"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { TColumnFiltersState } from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import { DATE_FORMAT, usePeriod } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export interface TDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  items: TDashboardItemsSet
  excludeDataKeyFromTotal?: Array<string>
  parent: {
    slug: string
    title: string
  }
  visualizations: {
    [k: string]: DashboardVisualizationDto
  }
  dateRangeRestrictions?: Partial<{ minDate: Date; maxDate: Date }>
  cubeQueryParams?: Record<string, any>
}

const VISUALIZATIONS: Record<VISUALIZATION_TYPE, ElementType> = {
  TableVisualization,
  AreaChart,
  ComposedChart,
  DropDownChart,
  CohortVisualization,
  MultiChart,
  ComposedTable,
  SortedItems,
}

const WIDGETS: Record<WIDGET_TYPE, ElementType> = {
  H3,
  SPACER: () => null,
}

const colsClassNameMap: Record<number, string> = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
}

const alignMap: Record<string, string> = {
  end: "items-end",
  center: "items-center",
  start: "items-start",
}

const justifyMap: Record<string, string> = {
  end: "justify-end",
  center: "justify-center",
  start: "justify-start",
}

export default function Dashboard({
  title,
  items,
  periodPicker,
  slug,
  parent,
  visualizations,
  excludeDataKeyFromTotal,
  dateRangeRestrictions,
  cubeQueryParams = {},
}: TDashboardProps) {
  const { sources } = useSources()
  const isPeriodPickerVisible = periodPicker?.visible ?? false
  const { dateRange, compareRange } = useMinMaxDateRange(
    dateRangeRestrictions?.minDate,
    dateRangeRestrictions?.maxDate,
  )
  const { granularity, setPeriod, setComparePeriod, setGranularity } =
    usePeriod()

  const plainItems: TDashboardItemWidget[] = flatContentWidgets(items)
  const visibleWidgets: (VISUALIZATION_TYPE | WIDGET_TYPE)[] = useMemo(
    () =>
      plainItems
        .filter(
          ({ widget }) =>
            !visualizations[widget]?.requiredSources?.length ||
            visualizations[widget]?.requiredSources?.some(source =>
              sources?.has(source),
            ),
        )
        .map(({ widget }) => widget),
    [items],
  )
  const refs = useMemo(
    () => Object.fromEntries(visibleWidgets.map(id => [id, createRef<any>()])),
    [visibleWidgets],
  )
  const showComparisonInPicker = plainItems.some(
    ({ widget }) => visualizations[widget]?.props?.comparisonEnabled,
  )

  // ToDo
  const handleChangePeriods = (
    dateRange: [Date, Date],
    compareDateRange: [Date, Date] | null,
  ) => {
    setPeriod(
      dayjs(dateRange[0]).format(DATE_FORMAT),
      dayjs(dateRange[1]).format(DATE_FORMAT),
    )

    if (compareDateRange && compareDateRange.length > 0) {
      setComparePeriod(
        dayjs(compareDateRange[0]).format(DATE_FORMAT),
        dayjs(compareDateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setComparePeriod()
    }
  }

  const isSourceConnected = (widget: string) =>
    !visualizations[widget] ||
    (visualizations[widget]?.requiredSources?.length ?? 0) === 0 ||
    visualizations[widget]?.requiredSources?.some(source => sources.has(source))

  const handleSetDataAggType = (aggType: AggregationType) => {
    setGranularity(aggType)
  }

  const [columnFilters, setColumnFilters] = useState<TColumnFiltersState>([])

  const cubeFilters = useMemo(() => {
    const extraFilters = columnFilters.reduce((acc: any, filterItem) => {
      if (filterItem.variant === "range") {
        const values = filterItem.value as [number, number]
        if (typeof values[0] === "number") {
          acc.push({
            member: filterItem.id,
            operator: "gte",
            values: [values[0].toString()],
          })
        }

        if (typeof values[1] === "number") {
          acc.push({
            member: filterItem.id,
            operator: "lte",
            values: [values[1].toString()],
          })
        }
      } else if (filterItem.variant === "text") {
        const filterValue = filterItem.value
        const isArray = Array.isArray(filterValue)
        const values = isArray ? filterValue : [filterValue]

        acc.push({
          member: filterItem.id,
          operator: "contains",
          values,
        })
      }

      return acc
    }, [])

    return { extraFilters }
  }, [columnFilters])

  const renderChartLoader = useCallback(
    (item: TDashboardItemWidget, itemsSet: TDashboardItem) => {
      const widgetType = visualizations[item.widget].type as VISUALIZATION_TYPE
      const tableTypes = [
        VISUALIZATION_TYPE.TableVisualization,
        VISUALIZATION_TYPE.ComposedTable,
      ]

      const chartLoader = (
        <ChartLoader
          chartId={item.widget}
          className={cn("border-0 rounded-lg", itemsSet.className)}
          widgetType={widgetType}
          component={VISUALIZATIONS[widgetType]}
          dateRange={dateRange}
          compareRange={compareRange ?? undefined}
          excludeDataKeyFromTotal={excludeDataKeyFromTotal}
          key={item.widget}
          granularity={granularity}
          sources={sources}
          requiredSources={visualizations[item.widget]?.requiredSources}
          setDataAggType={handleSetDataAggType}
          cubeQueryParams={cubeQueryParams}
          description={visualizations[item.widget]?.description}
          compareMode={
            visualizations[item.widget].props.comparisonEnabled &&
            (compareRange?.length ?? 0) > 0
          }
          // TEMP SOLUTION
          {...(VISUALIZATION_TYPE.TableVisualization === widgetType
            ? { cubeFilters, columnFilters, setColumnFilters }
            : {})}
          {...visualizations[item.widget].props}
        />
      )

      if (tableTypes.includes(widgetType)) {
        return chartLoader
      }

      return (
        <Card rounded className="bg-basic-dark-blue">
          {chartLoader}
        </Card>
      )
    },
    [
      visualizations,
      cubeQueryParams,
      sources,
      granularity,
      dateRange,
      compareRange,
      excludeDataKeyFromTotal,
    ],
  )

  const info = useMemo(() => {
    const renderInfoWidget = visibleWidgets.find(
      (widget: string) => !!visualizations[widget]?.renderInfo,
    )
    if (renderInfoWidget) {
      const renderInfo = visualizations[renderInfoWidget]?.renderInfo
      return (renderInfo && renderInfo()) ?? undefined
    }
  }, [visibleWidgets, visualizations])

  return (
    <div>
      <div>
        <div className="text-start">
          <H2>
            <Breadcrumbs
              items={[
                {
                  slug: parent.slug,
                  name: parent.title,
                },
                {
                  slug,
                  name: title,
                },
              ]}
            />
          </H2>
        </div>
        <div className="grid grid-cols-3 sticky top-19 z-50 my-1 px-1 justify-between gap-2 align-center">
          {info && <div className="col-span-2">{info}</div>}
          <div
            className={`flex items-center justify-end ${info ? "" : "col-span-3"}`}
          >
            <PeriodPicker
              dateRange={dateRange}
              compareRange={compareRange}
              opacity={isPeriodPickerVisible ? 1 : 0.5}
              onChange={handleChangePeriods}
              restrictions={dateRangeRestrictions}
              comparisonEnabled={showComparisonInPicker}
            />
          </div>
        </div>
        <div className="sticky top-33 z-50 px-1">
          <div className="flex justify-between justify-items-end">
            <div className="flex gap-1">
              <>
                {/* {slug === "/brand-health/mind-share" ? (
                  <Button
                    onClick={() =>
                      navigate(`${slug}/google-keywords`, {
                        preventScrollReset: true,
                      })
                    }
                  >
                    Keywords
                  </Button>
                ) : null} */}
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-stack">
        {items.length > 0 ? (
          items.map((itemsSet: TDashboardItem, idx: number) => {
            const filteredWidgets = itemsSet.items.filter(
              (item: TDashboardItemWidget) =>
                visibleWidgets.includes(item.widget),
            )

            return filteredWidgets.length > 0 ? (
              <div
                className={`grid grid-stack-item ${colsClassNameMap[itemsSet.grid]} gap-4 my-4`}
                key={`grid-stack-item-${idx}`}
              >
                {filteredWidgets.map((item, idy) => (
                  <div
                    ref={refs[item.widget]}
                    key={`${item.widget}-${idx}-${idy}`}
                    className={`grid-stack-item-content ${["SPACER", "H3"].includes(item.widget) ? "overflow-hidden" : ""}`}
                  >
                    <div className="relative">
                      {!isSourceConnected(item.widget) ? (
                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center gap-1 align-center z-1">
                          <ExclamationSvg size={20} />
                        </div>
                      ) : null}
                      {visualizations[item.widget] &&
                      VISUALIZATIONS[
                        visualizations[item.widget].type as VISUALIZATION_TYPE
                      ]
                        ? renderChartLoader(item, itemsSet)
                        : null}
                      {WIDGETS[item.widget as WIDGET_TYPE] ? (
                        <div
                          className={`w-100 h-full flex ${item.verticalAlign && alignMap[item.verticalAlign]} ${item.horizontalAlign && justifyMap[item.horizontalAlign]}`}
                        >
                          {createElement(WIDGETS[item.widget as WIDGET_TYPE], {
                            children: item.text,
                          })}
                        </div>
                      ) : null}
                      {!visualizations[item.widget] &&
                      !WIDGETS[item.widget as WIDGET_TYPE] ? (
                        <div className="flex items-center justify-center h-full bg-background-dark w-100">
                          <ExclamationSvg size={32} fill="error" />
                          <span className="font-bold text-error">
                            Invalid visualization "{item.widget}"
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            ) : null
          })
        ) : (
          <div>This page is under construction. Please come again later</div>
        )}
      </div>
    </div>
  )
}
