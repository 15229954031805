import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const DataSaverSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 2.8501C7.2084 2.8501 3.26795 6.48528 2.79631 11.1555C2.75469 11.5676 2.38686 11.8679 1.97475 11.8263C1.56263 11.7847 1.26228 11.4169 1.3039 11.0047C1.85226 5.57491 6.4318 1.3501 12.0001 1.3501C17.569 1.3501 22.1479 5.58548 22.6963 11.0046C22.738 11.4167 22.4377 11.7846 22.0256 11.8263C21.6135 11.868 21.2456 11.5677 21.2039 11.1556C20.7323 6.49471 16.7912 2.8501 12.0001 2.8501ZM2.22734 14.3539C2.62286 14.2309 3.04323 14.4518 3.16626 14.8473C4.15603 18.0294 6.82135 20.4806 10.13 21.1552C10.5358 21.238 10.7977 21.6341 10.715 22.0399C10.6322 22.4458 10.2361 22.7077 9.83026 22.625C5.97886 21.8396 2.88417 18.9908 1.73394 15.2929C1.61092 14.8973 1.83182 14.477 2.22734 14.3539ZM21.7672 14.4053C22.162 14.5308 22.3803 14.9525 22.2549 15.3472C21.0835 19.0334 17.9995 21.8499 14.1604 22.6349C13.7545 22.7179 13.3583 22.4562 13.2753 22.0503C13.1923 21.6445 13.454 21.2483 13.8599 21.1653C17.1607 20.4903 19.8167 18.0668 20.8253 14.893C20.9508 14.4982 21.3725 14.2799 21.7672 14.4053Z"
      fill={fill}
    />
  </svg>
))
