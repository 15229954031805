import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react"
import CategoryItem from "./CategoryItem"

import "./index.scss"
import { SearchSvg, CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { useClickOutside } from "~/ui-rtk/shared/hooks"

type TTopbarProps = {
  categories: string[]
  value?: string
  onSelect?: (category: string) => void
  onSearch?: (searchTerm: string) => void
}

const Topbar: React.FC<TTopbarProps> = ({
  value,
  categories,
  onSelect,
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  void useClickOutside(inputContainerRef, () => setIsSearchFocus(false))

  const [selectedCategory, setSelectedCategory] = useState<string>(
    value || categories[0],
  )

  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category)
    onSelect?.(category)
  }

  const handleFocus = (event: MouseEvent) => {
    void event.stopPropagation()
    void event.preventDefault()

    void setIsSearchFocus(prev => !prev)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    void setSearchTerm(value)
    void onSearch?.(value)
  }

  useEffect(() => {
    if (isSearchFocus) {
      void inputRef.current?.focus()
    }
  }, [isSearchFocus])

  return (
    <div className="flex items-center w-full gap-4">
      <div className="max-w-full border border-basic-blue rounded-2xl w-full overflow-x-auto custom-scrollbar">
        <ul className="flex items-center flex-1">
          {categories.map(category => (
            <CategoryItem
              id={category}
              key={category}
              text={category}
              isSelected={selectedCategory === category}
              onClick={handleSelectCategory}
            />
          ))}
        </ul>
      </div>

      <div
        ref={inputContainerRef}
        className={cn(
          "w-14 h-14 flex transition-[width] relative cursor-pointer px-4 rounded-2xl border border-basic-blue bg-basic-blue",
          isSearchFocus && "w-89",
        )}
      >
        <input
          ref={inputRef}
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Source"
          className={cn(
            "hidden min-h-0 bg-transparent ring-0 active:ring-0 focus:ring-0 text-white",
            isSearchFocus && "w-full visible block",
          )}
        />
        <div
          className="flex items-center justify-center absolute top-1/2 -translate-y-1/2 h-full w-13 self-end right-0"
          onClick={handleFocus}
        >
          {!isSearchFocus && <SearchSvg />}
          {isSearchFocus && <CloseSvg />}
        </div>
      </div>
    </div>
  )
}

export default Topbar
