import { createContext, useContext, useRef, MutableRefObject } from "react"
import { useNavigate, NavigateFunction } from "react-router-dom"

const StableNavigateContext =
  createContext<MutableRefObject<NavigateFunction> | null>(null)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const StableNavigateContextProvider = ({ children }) => {
  const navigate = useNavigate()
  const navigateRef = useRef(navigate)

  return (
    <StableNavigateContext.Provider value={navigateRef}>
      {children}
    </StableNavigateContext.Provider>
  )
}

// Why are we doing this? Read this thread - https://github.com/remix-run/react-router/issues/7634
const useStableNavigate = (): NavigateFunction => {
  const navigateRef = useContext(StableNavigateContext)
  if (!navigateRef || navigateRef.current === null) {
    throw new Error("StableNavigate context is not initialized")
  }
  return navigateRef.current
}

export {
  StableNavigateContext,
  StableNavigateContextProvider,
  useStableNavigate,
}
