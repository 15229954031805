import React, { useState } from "react"
import { toast } from "react-toastify"
import { useAuthControllerRequestResetPasswordMutation } from "~/ui-rtk/api/authApi"
import { Button } from "~/ui-rtk/components/ui/controls"

type TCheckInboxProps = {
  email: string
}

const CheckInbox: React.FC<TCheckInboxProps> = ({ email }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [resetPassword] = useAuthControllerRequestResetPasswordMutation()

  const openGmail = () => {
    const gmailUrl = `https://mail.google.com/mail`
    window.open(gmailUrl, "_blank")
  }

  const resendLink = async () => {
    void setIsSubmitting(true)
    try {
      await resetPassword({ requestResetPasswordDto: { email } })
    } catch {
      toast.error("Failed to resend link", {
        toastId: "resent-toast-error",
      })
    } finally {
      void setIsSubmitting(false)
    }
  }

  return (
    <div className="flex justify-between gap-6">
      <Button onClick={openGmail} size="lg" className="w-full">
        Open Gmail
      </Button>
      <Button
        isLoading={isSubmitting}
        variant={{ variant: "solid", color: "white" }}
        onClick={resendLink}
        className="w-full"
        size="lg"
      >
        Resend Link
      </Button>
    </div>
  )
}

export default CheckInbox
