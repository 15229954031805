import {
  useMediaCategoryControllerGetMediaCategoryQuery,
  useMediaCategoryControllerPostMediaCategoriesMutation,
} from "~/ui-rtk/api/mediaCategoryApi.ts"
import useSources from "~/ui-rtk/hooks/sources"
import { useStats } from "../hooks/stats"
import {
  ADSET_MEDIA_CATEGORIES_OPTIONS,
  TAdsetMediaOption,
  TCampaignAdSetAdDetailsProps,
  VALUE_OVER_TIME_KEY_MAP,
  VALUE_OVER_TIME_LABEL_MAP,
  VALUE_OVER_TIME_MODE,
} from "../constants"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useAdSetQubeQuery } from "./hooks/cube-query"
import { H5, H6 } from "~/ui-rtk/components/ui/typography"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import { TSource } from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import BrandValueOverSpendProgressBar from "../components/BrandValueOverSpendProgressBar"
import ValueOverTimeInfoBlock from "../components/ValueOverTimeInfoBlock"
import ValueOverTimeChart from "../components/ValueOverTimeChart"
import ValueOverTimeModeSelector from "../components/ValueOverTimeModeSelector"
import { Select } from "~/ui-rtk/components/ui/controls"
import StatsTable from "../components/StatsTable"
import { renderDetailsCaption } from "../utils"
import { DetailsTitle } from "../components/DetailsHeader"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import ListBox from "~/ui-rtk/components/ui/common/ListBox/ListBox"
import {
  URL_ADSET_ID_PARAM_KEY,
  URL_WIDGET_PARAMS,
} from "../../dashboards/BrandMediaOptimizationDashboard/constants"
import { ArrowTopRightOnSquare } from "~/ui-rtk/components/ui/svg/arrow/ArrowTopRightOnSquare"
import NavigationButton from "~/ui-rtk/components/ui/controls/NavigationButton/NavigationButton"

export enum COMPARE_AD_SET_MODE {
  ALL,
  CURRENT_CAMPAIGN,
}
export type TDataKey = "ALL" | "CURRENT_CAMPAIGN"
export const COMPARE_AD_SET_KEY_MAP: Record<COMPARE_AD_SET_MODE, TDataKey> = {
  [COMPARE_AD_SET_MODE.ALL]: "ALL",
  [COMPARE_AD_SET_MODE.CURRENT_CAMPAIGN]: "CURRENT_CAMPAIGN",
}
export const COMPARE_AD_SET_LABEL_MAP: Record<COMPARE_AD_SET_MODE, string> = {
  [COMPARE_AD_SET_MODE.ALL]: "All",
  [COMPARE_AD_SET_MODE.CURRENT_CAMPAIGN]: "Current Campaign",
}

export default function AdSetDetails(props: TCampaignAdSetAdDetailsProps) {
  const { sources } = useSources()
  const { metric, date, granularity } = props
  const stats = useStats(metric)
  const [category, setCategory] = useState<string | null | undefined>(undefined)
  const campaignName = metric.campaign_name
  const campaignId = metric.campaign_id
  const adSetName = metric.adset_name
  const adSetId = metric.adset_id
  const sourceName = metric.media_channel?.toUpperCase()
  const source = sources.get(sourceName)

  const demo = useAppSelector(selectIsEnabled)

  const [createMediaCategory] =
    useMediaCategoryControllerPostMediaCategoriesMutation()

  const {
    data: mediaCategory,
    isLoading: mediaCategoryLoading,
    isFetching: mediaCategoryFetching,
    refetch,
  } = useMediaCategoryControllerGetMediaCategoryQuery(
    {
      id: adSetId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (mediaCategory?.category) {
      setCategory(mediaCategory.category)
    } else {
      setCategory(null)
    }
  }, [mediaCategory])

  const categoryUpdate = async (value: TAdsetMediaOption) => {
    await createMediaCategory({
      body: {
        mediaId: adSetId,
        type: "adset",
        channel: metric.media_channel,
        category: value,
      },
    })
    setCategory(value)
    await refetch()
  }

  const [compareAdSetMode, setCompareAdSetMode] = useState(
    COMPARE_AD_SET_KEY_MAP[COMPARE_AD_SET_MODE.ALL],
  )

  const [valueOverTimeMode, setValueOverTimeMode] = useState(
    VALUE_OVER_TIME_MODE.TOTAL,
  )

  const { allAdSetsDetails, valueOverTimeDetails, isLoading } =
    useAdSetQubeQuery(
      date,
      adSetId,
      compareAdSetMode ===
        COMPARE_AD_SET_KEY_MAP[COMPARE_AD_SET_MODE.CURRENT_CAMPAIGN]
        ? campaignId
        : undefined,
      granularity,
    )

  const compareAdSetModeOptions = useMemo(
    () => [
      {
        value: COMPARE_AD_SET_KEY_MAP[COMPARE_AD_SET_MODE.ALL],
        label: COMPARE_AD_SET_LABEL_MAP[COMPARE_AD_SET_MODE.ALL],
      },
      {
        value: COMPARE_AD_SET_KEY_MAP[COMPARE_AD_SET_MODE.CURRENT_CAMPAIGN],
        label: COMPARE_AD_SET_LABEL_MAP[COMPARE_AD_SET_MODE.CURRENT_CAMPAIGN],
      },
    ],
    [],
  )

  return (
    <div className="campaign-details w-145 h-full">
      <DetailsTitle
        title={adSetName ?? ""}
        classes={{ container: "mb-4" }}
        demoMode={demo.isEnabled}
      />
      {isLoading && (
        <div className="h-[50%] w-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="mt-3">
            {mediaCategoryLoading || mediaCategoryFetching ? (
              <Loader />
            ) : (
              <ListBox
                options={ADSET_MEDIA_CATEGORIES_OPTIONS}
                onSelect={categoryUpdate}
                value={category}
                label="Category:"
                className="border border-basic-blue rounded-lg"
                placeholder="Please select a Category"
              />
            )}
          </div>
          <div className="flex justify-between items-center mt-3">
            Compare against:
            <Select
              className="w-[50%] bg-basic-dark-blue"
              options={compareAdSetModeOptions}
              value={compareAdSetMode}
              onChange={($evt: ChangeEvent<HTMLSelectElement>) => {
                setCompareAdSetMode(
                  ($evt.target as HTMLSelectElement)
                    .value as (typeof COMPARE_AD_SET_KEY_MAP)[keyof typeof COMPARE_AD_SET_KEY_MAP],
                )
              }}
            />
          </div>
          <Card
            rounded
            className="mt-3 bg-basic-dark-blue border border-basic-blue"
          >
            <div className="grid grid-cols-8">
              <div className="col-span-7">
                <H6
                  className={cn("font-bold mb-2", demo.isEnabled && "blur-md")}
                >
                  {adSetName}
                </H6>
                <H6
                  className={cn("font-semibold", demo.isEnabled && "blur-md")}
                >
                  Campaign: {campaignName}
                </H6>
              </div>
              <div className="flex items-center justify-end">
                {renderSourceIcon({
                  name: source?.name,
                  icon: source?.icon,
                } as TSource)}
              </div>
            </div>
            <div className="mt-3 text-sm">
              {renderDetailsCaption(
                metric,
                allAdSetsDetails ?? {},
                "ad set",
                sourceName.toLowerCase(),
              )}
            </div>
            <BrandValueOverSpendProgressBar
              metric={metric}
              allDetails={allAdSetsDetails ?? {}}
              label="ad set"
              withinCurrent={
                compareAdSetMode ===
                COMPARE_AD_SET_KEY_MAP[COMPARE_AD_SET_MODE.CURRENT_CAMPAIGN]
                  ? "campaign"
                  : undefined
              }
            />
          </Card>
          <div className="mt-3">
            <NavigationButton
              to={`?widget=${URL_WIDGET_PARAMS.AD}&${URL_ADSET_ID_PARAM_KEY}=${adSetId}`}
            >
              <ArrowTopRightOnSquare fill="white" size={20} /> View Ads
            </NavigationButton>
          </div>
          <Card
            rounded
            className="mt-3 bg-basic-dark-blue border border-basic-blue"
          >
            <H5 className="font-bold">Stats</H5>
            <StatsTable {...stats} />
          </Card>
          <Card
            rounded
            className="mt-3 bg-basic-dark-blue border border-basic-blue"
          >
            <div className="grid grid-cols-2 gap-2">
              <div className="text-start">
                <H5 className="font-bold">Value over time</H5>
              </div>
              <div className="text-end">
                <ValueOverTimeModeSelector
                  mode={valueOverTimeMode}
                  setMode={(mode: VALUE_OVER_TIME_MODE) =>
                    setValueOverTimeMode(mode)
                  }
                />
              </div>
            </div>

            {valueOverTimeDetails && (
              <>
                <div className="mt-3 grid grid-cols-3 gap-1">
                  {[
                    valueOverTimeDetails.info30days,
                    valueOverTimeDetails.info60days,
                    valueOverTimeDetails.info90days,
                  ].map(info => (
                    <ValueOverTimeInfoBlock
                      key={info.label}
                      info={info}
                      label={info.label}
                      dataLabel={VALUE_OVER_TIME_LABEL_MAP[valueOverTimeMode]}
                      dataKey={VALUE_OVER_TIME_KEY_MAP[valueOverTimeMode]}
                    />
                  ))}
                </div>
                <div className="mt-3">
                  <ValueOverTimeChart
                    metrics={valueOverTimeDetails.chartData}
                    dataKey={VALUE_OVER_TIME_KEY_MAP[valueOverTimeMode]}
                    label="ad set"
                  />
                </div>
              </>
            )}
          </Card>
        </>
      )}
    </div>
  )
}
