import ComposedSimpleChart, {
  type IGraphProps,
} from "~/ui-rtk/components/ui/charts/ComposedSimpleChart/ComposedSimpleChart"
import useTailwindTheme from "~/ui-rtk/hooks/tailwind-theme"

export default function CampaignDetailsValueOverTimeChart({
  metrics,
  dataKey,
  label,
}: {
  metrics: any
  dataKey: "total" | "brandValue"
  label: string
}) {
  const theme = useTailwindTheme()

  const colorClassNamesPerColorMap = {
    [theme.colors.cyan]: "progress-cyan",
    [theme.colors.neutral]: "progress-neutral",
    [theme.colors.pink]: "progress-pink",
  }

  const graphs: IGraphProps[] = [
    {
      type: "line",
      unit: "$",
      label: "Average",
      dataKey: `average.${dataKey}`,
      pluralizeUnit: false,
      color: theme.colors.neutral,
      yAxisId: "roas",
    },
    {
      type: "line",
      unit: "$",
      label: "Spend",
      dataKey: "current.spend",
      pluralizeUnit: false,
      color: theme.colors.pink,
      yAxisId: "spend",
    },
    {
      type: "line",
      unit: "$",
      label: `This ${label}`,
      dataKey: `current.${dataKey}`,
      pluralizeUnit: false,
      color: theme.colors.cyan,
      yAxisId: "roas",
    },
  ]

  return (
    <div className="campaign-details-value-over-time-chart pa-1 grid grid-cols-12 gap-3">
      <div className="col-span-9">
        <ComposedSimpleChart
          title="Total brand value per follower"
          metrics={metrics}
          graphs={graphs}
          hideSources={true}
          hideTooltip={true}
          hideQuarterReferences={true}
        />
      </div>
      <div className="col-span-3">
        {graphs.map((graph: IGraphProps) => (
          <div className="grid grid-cols-5 gap-3" key={graph.label}>
            <div className="h-0.5 flex self-center justify-center">
              <progress
                className={`${colorClassNamesPerColorMap[graph?.color ?? "red"]} progress h-full square-progress`}
                value={100}
                max={100}
              ></progress>
            </div>
            <div className="col-span-4 text-sm flex items-center">
              {graph?.label ?? "--"}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
