import { TooltipType } from "./"

export const getTooltipClasses = (type: TooltipType) => {
  switch (type) {
    case "warn":
      return {
        container: "bg-yellow-info/[.80] text-basic-black font-[600]",
        triangle: "bg-yellow-info/[.80]",
      }
    case "error":
      return {
        container: "bg-states-error/[.80] font-[600]",
        triangle: "bg-states-error/[.80]",
      }
    default:
      return {
        container: "bg-infographics-blue/[.80] font-[600]",
        triangle: "bg-infographics-blue/[.80]",
      }
  }
}
