import { PropsWithChildren } from "react"

import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { brandRoutes, guestRoutes } from "~/ui-rtk/constants/routes"
import useAccess from "~/ui-rtk/hooks/access"

export default function SubscriptionGuard({ children }: PropsWithChildren) {
  const { isBillingEnabled, hasAccess } = useAccess()

  const navigate = useStableNavigate()

  if (hasAccess) {
    return children
  }

  if (isBillingEnabled) {
    navigate(guestRoutes.SUBSCRIBE)
  } else {
    navigate(brandRoutes.SETUP.CONNECTORS)
  }

  return null
}
