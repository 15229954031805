import { useEffect, useMemo, useState } from "react"

import useSources from "~/ui-rtk/hooks/sources"
import { Button, MultiSelect } from "~/ui-rtk/components/ui/controls"
import {
  BrandMediaAggregationType,
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"

import {
  type TBrandMediaOptimizationTableId,
  type TBrandMediaOptimizationTable,
} from "~/ui-rtk/pages/BrandMedia/Optimization/connect"

import { Breadcrumbs } from "./components"
import { Chart, TableVisualization } from "~/ui-rtk/components/ui/charts"

import { BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP } from "~/ui-rtk/constants/brand-media"

import type { TMultiSelectOption } from "~/ui-rtk/components/ui/controls/MultiSelect"
import type {
  TableVisualizationColumn,
  TColumnFiltersState,
} from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import useDrawer from "~/ui-rtk/hooks/drawer"
import { DRAWER_TYPE, TDrawerConfig } from "~/ui-rtk/constants/drawer"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import { getBaseCubeFilters, useLocalStorageWarningSetting } from "./utils"
import {
  MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING,
  SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING,
  TBrandMediaDashboardCharts,
  URL_ADSET_ID_PARAM_KEY,
  URL_CAMPAIGN_ID_PARAM_KEY,
  URL_FILTER_PARAM_KEY,
  URL_PARAM_TO_WIDGET_MAP,
  URL_SORT_PARAM_KEY,
  URL_WIDGET_PARAM_KEY,
  VISUALIZATION_TABLE,
} from "./constants"
import BrandMediaOptimizationSortedSection from "./components/SortedSection/SortedSection"
import { Link } from "react-router-dom"
import { SOURCES_MAP } from "~/ui-rtk/constants/sources"
import { usePeriod, DATE_FORMAT } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"
import {
  GranularityMapping,
  MetricsMapping,
} from "~/ui-rtk/constants/metrics-mapping"
import PeriodPicker from "./components/PeriodPicker/PeriodPicker"
import { useLastUpdateDate, useSummaries } from "~/ui-rtk/hooks/cube"
import { TimeDimensionGranularity } from "@cubejs-client/core"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { useUrlParamsStateArray } from "~/ui-rtk/hooks/urlParamsState"
import { SortingState } from "@tanstack/react-table"
import IncompletePeriodDialog from "./components/IncompletePeriodDialog/IncompletePeriodDialog"

export interface TBrandMediaDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  items: TBrandMediaDashboardCharts
  parent: {
    slug: string
    title: string
  }
}
const dayjs = useDate()

const maxDateRestriction = dayjs().startOf("week").subtract(1, "day").toDate()

const BEST_WORST_ENABLED = false

export default function BrandMediaOptimizationDashboard({
  title,
  items,
  slug,
  parent,
}: TBrandMediaDashboardProps) {
  const { params, setParams } = useURLSearchParams()
  const { sources } = useSources()
  const { granularity, setPeriod, setGranularity } = usePeriod(
    BrandMediaAggregationType.WEEKLY,
    true,
  )
  const { openDrawer, closeDrawer } = useDrawer()
  const { lastDate } = useLastUpdateDate()
  const { dateRange, compareRange } = useMinMaxDateRange(
    undefined,
    new Date(lastDate as string) ?? maxDateRestriction,
    BrandMediaAggregationType.WEEKLY,
    true,
  )
  const [inaccurateDatesMode, setInaccurateDatesMode] = useState(false)
  const { dontShowDialogAgain, setDontShowDialogAgain } =
    useLocalStorageWarningSetting()
  const [uiDontShowDialog, setUiDontShowDialog] = useState(false)
  const [lastShowedPeriod, setLastShowedPeriod] = useState<[Date, Date] | null>(
    null,
  )

  const bestWorstEnabled = BEST_WORST_ENABLED || params.enable_best_worst
  const queryDate = params.date

  const initialDateRange: [Date, Date] = queryDate
    ? [new Date(queryDate), new Date(queryDate)]
    : dateRange

  const [internalDateRange, setInternalDateRange] = useState(initialDateRange)

  const visibleWidgets: TBrandMediaDashboardCharts = useMemo(
    () =>
      Object.keys(items)
        .filter(chartId => {
          const { requiredSources } =
            items[chartId as unknown as TBrandMediaOptimizationTableId]
          return (
            !requiredSources?.length ||
            requiredSources?.some((source: string) => sources?.has(source))
          )
        })
        .reduce(
          (filteredCharts, chartId) => {
            filteredCharts[
              chartId as unknown as TBrandMediaOptimizationTableId
            ] = items[chartId as unknown as TBrandMediaOptimizationTableId]
            return filteredCharts
          },
          {} as Record<
            TBrandMediaOptimizationTableId,
            TBrandMediaOptimizationTable
          >,
        ),
    [items, sources],
  )

  const defaultVisibleChart = useMemo(
    () =>
      visibleWidgets[
        Object.keys(
          visibleWidgets,
        )[0] as unknown as TBrandMediaOptimizationTableId
      ],
    [visibleWidgets],
  )
  const widgetURLSearchParam =
    params[URL_WIDGET_PARAM_KEY] ?? defaultVisibleChart?.id
  const campaignIdFromUrl = params[URL_CAMPAIGN_ID_PARAM_KEY]
  const adsetIdFromUrl = params[URL_ADSET_ID_PARAM_KEY]

  const activeWidget = useMemo(
    () =>
      widgetURLSearchParam && URL_PARAM_TO_WIDGET_MAP[widgetURLSearchParam]
        ? visibleWidgets[
            widgetURLSearchParam.toUpperCase() as TBrandMediaOptimizationTableId
          ]
        : defaultVisibleChart,
    [widgetURLSearchParam],
  )

  const querySelectedSource = params.selected_sources
  const initialSources = useMemo(() => {
    if (querySelectedSource) {
      const brandMediaSources = querySelectedSource
        .split(",")
        .map(
          (sourceId: string) =>
            BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP[
              sourceId
            ],
        )
      return [...new Set(brandMediaSources)].filter(source =>
        activeWidget?.requiredSources?.includes(source),
      )
    }
    return (activeWidget?.requiredSources || []).map(
      (sourceId: string) =>
        BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP[
          sourceId
        ],
    )
  }, [querySelectedSource, activeWidget])

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget?.widget]?.props,
    [activeWidget],
  )

  const queryPrefix = useMemo(() => {
    const widget = activeWidget?.widget ?? ""
    const fn = MetricsMapping?.[widget]?.getCubePrefix
    return fn && typeof fn === "function"
      ? fn({
          granularity: GranularityMapping[granularity],
        })
      : ""
  }, [activeWidget, granularity])

  const { state: rawSorting, setState: setSorting } = useUrlParamsStateArray<
    SortingState | undefined
  >(URL_SORT_PARAM_KEY)
  const { state: rawColumnFilters, setState: setColumnFilters } =
    useUrlParamsStateArray<TColumnFiltersState | undefined>(
      URL_FILTER_PARAM_KEY,
    )

  const columnFilters = useMemo(
    () => rawColumnFilters as TColumnFiltersState,
    [rawColumnFilters],
  )
  const sorting = useMemo(
    () =>
      rawSorting.map(sorting => ({
        ...sorting,
        desc: (sorting.desc as unknown as string) === "true",
      })),
    [rawSorting],
  )

  const [columnKeys, setColumnKeys] = useState<string[]>([])
  const [sourceKeys, setSourceKeys] = useState<string[]>(initialSources)

  const transformedSourceKeys = useMemo(
    () =>
      sourceKeys.map(
        sourceKey =>
          MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
            sourceKey.toLowerCase()
          ]?.toUpperCase() ?? sourceKey,
      ),
    [sourceKeys],
  )

  const columnOptions = useMemo(() => {
    const options = chartProps?.columnConfig.map(
      (column: TableVisualizationColumn) =>
        ({
          id: column.key,
          label: column.header,
        }) as TMultiSelectOption,
    )

    return options || []
  }, [chartProps])

  const sourcesOptions = useMemo(() => {
    if (!activeWidget?.requiredSources) {
      return []
    }

    const options = activeWidget.requiredSources
      .filter(sourceId => sources.has(sourceId))
      .map(sourceId => {
        const source = sources.get(sourceId)

        const fakeSource =
          SOURCES_MAP[
            SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING[source?.id ?? ""].toUpperCase()
          ]
        return {
          id: source?.id ?? "",
          label: fakeSource?.name ?? "",
        }
      })

    return options
  }, [activeWidget, sources])

  const cubeFilters = useMemo(() => {
    const filters: Record<string, string | undefined | null> = {
      campaign_id: [
        VISUALIZATION_TABLE.AD_SET,
        VISUALIZATION_TABLE.AD,
      ].includes(activeWidget?.widget)
        ? params[URL_CAMPAIGN_ID_PARAM_KEY]
        : undefined,
      adset_id:
        activeWidget?.widget === VISUALIZATION_TABLE.AD
          ? params[URL_ADSET_ID_PARAM_KEY]
          : undefined,
    }

    const baseQuery = getBaseCubeFilters(
      queryPrefix,
      dateRange[0],
      granularity as unknown as BrandMediaAggregationType,
      filters,
    )

    return {
      and: [
        ...baseQuery.and,
        {
          or: sourceKeys.map((sourceId: string) => ({
            member: `${queryPrefix}.media_channel`,
            operator: "equals",
            values: [SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING[sourceId] ?? sourceId],
          })),
        },
      ],
      extraFilters: columnFilters.reduce((acc: any, filterItem) => {
        if (filterItem.variant === "range") {
          const { min, max } = filterItem.value as {
            min?: number
            max?: number
          }
          if (min) {
            acc.push({
              member: `${queryPrefix}.${filterItem.id}`,
              operator: "gte",
              values: [min.toString()],
            })
          }

          if (max) {
            acc.push({
              member: `${queryPrefix}.${filterItem.id}`,
              operator: "lte",
              values: [max.toString()],
            })
          }
        } else if (filterItem.variant === "text") {
          const filterValue = filterItem.value
          const isArray = Array.isArray(filterValue)
          const values = isArray ? filterValue : [filterValue]

          acc.push({
            member: `${queryPrefix}.${filterItem.id}`,
            operator: filterItem.operator,
            values,
          })
        }

        return acc
      }, []),
    } as TCubeFilterOptions
  }, [sourceKeys, queryPrefix, dateRange, columnFilters, params, activeWidget])

  const querySortBy = params.sort_by
  const sortBy = useMemo(
    () =>
      querySortBy ? `${queryPrefix}.${params.sort_by}` : chartProps?.sortBy,
    [querySortBy, queryPrefix],
  )

  const cubeQueryParams = useMemo(
    () => ({
      granularity: GranularityMapping[granularity] as TimeDimensionGranularity,
    }),
    [granularity],
  )

  const summary = useSummaries({
    widget: activeWidget?.summaryWidget,
    dateRange,
    compareRange: compareRange ?? undefined,
    cubeFilters,
    cubeQueryParams,
  })

  const setInitialColumnKeys = () => {
    const props = VISUALIZATIONS_MAP[activeWidget?.widget]?.props
    setColumnKeys(
      props?.columnConfig.reduce(
        (
          acc: string[],
          column: TableVisualizationColumn & { disabled: boolean },
        ) => {
          if (!column.disabled) {
            acc.push(column.key)
          }
          return acc
        },
        [],
      ),
    )
  }

  const setInitialSourceKeys = () => {
    setSourceKeys(initialSources)
  }

  useEffect(() => {
    setInitialColumnKeys()
    setInitialSourceKeys()
  }, [widgetURLSearchParam])

  useEffect(() => {
    if (campaignIdFromUrl) {
      const hasCampaignFilter = columnFilters.find(
        filter =>
          filter.id === URL_CAMPAIGN_ID_PARAM_KEY &&
          filter.operator === "contains" &&
          filter.value === campaignIdFromUrl,
      )
      if (hasCampaignFilter) {
        setParams({
          [URL_CAMPAIGN_ID_PARAM_KEY]: undefined,
        })
        closeDrawer()
      } else {
        setColumnFilters((prevState: TColumnFiltersState | undefined) => {
          const newState = (
            prevState ? [...prevState] : []
          ) as TColumnFiltersState
          const filterIdx = newState.findIndex(
            filter =>
              filter.id === URL_CAMPAIGN_ID_PARAM_KEY &&
              filter.operator === "contains",
          )

          if (filterIdx >= 0) {
            newState[filterIdx].value = campaignIdFromUrl
          } else {
            newState.push({
              id: URL_CAMPAIGN_ID_PARAM_KEY,
              variant: "text",
              operator: "contains",
              value: campaignIdFromUrl,
            })
          }
          return newState
        })
      }
    }
  }, [campaignIdFromUrl, columnFilters])

  useEffect(() => {
    if (adsetIdFromUrl) {
      const hasAdsetFilter = columnFilters.find(
        filter =>
          filter.id === URL_ADSET_ID_PARAM_KEY &&
          filter.operator === "contains" &&
          filter.value === adsetIdFromUrl,
      )
      if (hasAdsetFilter) {
        setParams({
          [URL_ADSET_ID_PARAM_KEY]: undefined,
        })
        closeDrawer()
      } else {
        setColumnFilters((prevState: TColumnFiltersState | undefined) => {
          const newState = (
            prevState ? [...prevState] : []
          ) as TColumnFiltersState
          const filterIdx = newState.findIndex(
            filter =>
              filter.id === URL_ADSET_ID_PARAM_KEY &&
              filter.operator === "contains",
          )

          if (filterIdx >= 0) {
            newState[filterIdx].value = adsetIdFromUrl
          } else {
            newState.push({
              id: URL_ADSET_ID_PARAM_KEY,
              variant: "text",
              operator: "contains",
              value: adsetIdFromUrl,
            })
          }
          return newState
        })
      }
    }
  }, [adsetIdFromUrl, columnFilters])

  useEffect(() => {
    if (
      lastDate &&
      internalDateRange?.length > 1 &&
      dayjs(lastDate as string).isBefore(dayjs(internalDateRange[1]))
    ) {
      if (
        !lastShowedPeriod ||
        lastShowedPeriod[0].getTime() !== internalDateRange[0].getTime() ||
        lastShowedPeriod[1].getTime() !== internalDateRange[1].getTime()
      ) {
        setInaccurateDatesMode(true)
        setLastShowedPeriod(internalDateRange)
      }
    }
  }, [lastDate, internalDateRange, lastShowedPeriod])

  const changePeriod = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange.map(date => dayjs(date))

    void setPeriod(start.format(DATE_FORMAT), end.format(DATE_FORMAT))
    void setInternalDateRange([start.toDate(), end.toDate()])
  }

  const handleSetDataAggType = (aggType: BrandMediaAggregationType) => {
    setGranularity(aggType)
  }
  const handleSetWidget = (widgetId: string) => {
    setParams({
      [URL_WIDGET_PARAM_KEY]: widgetId.toLowerCase(),
      [URL_CAMPAIGN_ID_PARAM_KEY]: undefined,
      [URL_ADSET_ID_PARAM_KEY]: undefined,
      [URL_FILTER_PARAM_KEY]: undefined,
      [URL_SORT_PARAM_KEY]: undefined,
    })
    closeDrawer()
  }
  const handleOpenDrawer = (config: Partial<TDrawerConfig>) => {
    let drawerType: DRAWER_TYPE | null = null
    let component: string

    if (Object.values(VISUALIZATION_TABLE).includes(activeWidget?.widget)) {
      drawerType = DRAWER_TYPE.CampaingAdSetAdDetails
    }

    if (activeWidget?.widget === VISUALIZATION_TABLE.CAMPAIGN) {
      component = "CampaignDetails"
    } else if (activeWidget?.widget === VISUALIZATION_TABLE.AD_SET) {
      component = "AdSetDetails"
    } else if (activeWidget?.widget === VISUALIZATION_TABLE.AD) {
      component = "AdDetails"
    } else {
      component = ""
    }

    if (drawerType) {
      openDrawer({
        ...config,
        props: {
          ...config.props,
          granularity,
          component,
        },
        type: drawerType,
      } as TDrawerConfig)
    }
  }

  useEffect(() => {
    changePeriod(dateRange)
  }, [granularity])

  const handleClose = () => {
    setDontShowDialogAgain(true)
    setUiDontShowDialog(true)
  }

  const shouldShowDialog = useMemo(
    () => !uiDontShowDialog && !dontShowDialogAgain,
    [uiDontShowDialog, dontShowDialogAgain],
  )

  const infoDialogContent =
    shouldShowDialog && inaccurateDatesMode ? (
      <IncompletePeriodDialog
        onClose={handleClose}
        startDate={dayjs(dateRange[0]).format("MMMM D, YYYY")}
        endDate={dayjs(dateRange[1]).format("MMMM D, YYYY")}
        lastSyncDate={dayjs(lastDate as string).format("MMMM D, YYYY")}
      />
    ) : null

  return (
    <>
      <Breadcrumbs slug={slug} title={title} parent={parent} />
      {bestWorstEnabled && (
        <div className="mt-3">
          <BrandMediaOptimizationSortedSection
            visibleWidgets={Object.values(visibleWidgets)}
            dateRange={dateRange}
            granularity={granularity as unknown as BrandMediaAggregationType}
          />
        </div>
      )}
      <div className="flex justify-between gap-2 mt-3">
        <div className="join max-h-14">
          {Object.values(visibleWidgets).map(tab => (
            <Button
              variant={{ variant: "solid", color: "blue" }}
              key={tab.id}
              title={tab.label}
              className={`join-item border text-3.5 px-4 py-3 rounded-md ${tab.id === activeWidget?.id ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
              onClick={() => handleSetWidget(tab.id)}
            >
              {tab.label}
            </Button>
          ))}
        </div>
        <div className="flex gap-2">
          <MultiSelect
            label="Columns"
            options={columnOptions as TMultiSelectOption[]}
            value={columnOptions.filter((option: TMultiSelectOption) =>
              columnKeys.includes(option.id),
            )}
            onChange={(options: TMultiSelectOption[]) =>
              setColumnKeys(options.map(({ id }) => id))
            }
          />
          <MultiSelect
            className="max-w-50"
            label="All Platforms"
            options={sourcesOptions as TMultiSelectOption[]}
            value={sourcesOptions.filter((option: TMultiSelectOption) =>
              transformedSourceKeys.includes(option.id),
            )}
            onChange={(options: TMultiSelectOption[]) =>
              setSourceKeys(
                options.map(({ id }) =>
                  SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING[id].toUpperCase(),
                ),
              )
            }
          />
          <PeriodPicker
            granularity={granularity as unknown as BrandMediaAggregationType}
            setGranularity={setGranularity}
            dateRange={dateRange}
            onDateRangeChange={changePeriod}
            maxDateRestriction={maxDateRestriction}
          />
        </div>
      </div>
      <div className="mt-3">
        {activeWidget ? (
          <Chart
            {...chartProps}
            chartId={activeWidget?.widget}
            className="min-h-120"
            widgetType={VISUALIZATION_TYPE.TableVisualization}
            component={TableVisualization}
            dateRange={internalDateRange}
            compareRange={compareRange ?? undefined}
            granularity={granularity}
            sources={sources}
            requiredSources={activeWidget?.requiredSources}
            hideAggSelector={true}
            setDataAggType={handleSetDataAggType}
            cubeFilters={cubeFilters}
            sortBy={sortBy}
            cubeQueryParams={{
              [activeWidget?.widget]: cubeQueryParams,
            }}
            columnFilters={columnFilters}
            summaryWidget={activeWidget?.summaryWidget}
            setColumnFilters={setColumnFilters}
            useLocalStoragePagination={!activeWidget?.summaryWidget}
            columnConfig={chartProps.columnConfig.filter(
              ({ key }: { key: string }) => columnKeys?.includes(key),
            )}
            openDrawer={
              Object.values(VISUALIZATION_TABLE).includes(activeWidget?.widget)
                ? handleOpenDrawer
                : undefined
            }
            summary={summary}
            sorting={sorting}
            setSorting={setSorting}
          />
        ) : (
          <div>
            It seems like you don't have active connection to show anything on
            this page. Please{" "}
            <Link
              className="link"
              to="/setup/connectors"
              title="Connector page"
            >
              review your connections.
            </Link>
          </div>
        )}
        {infoDialogContent}
      </div>
    </>
  )
}
