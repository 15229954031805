import { emptySplitApi as api } from "./emptyApi"
import { ConnectionLinkDto } from "./types"
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    connectionLinkControllerCreate: build.mutation<
      ConnectionLinkControllerCreateApiResponse,
      ConnectionLinkControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/connection-link`,
        method: "POST",
        body: queryArg.createConnectionLinkDto,
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ["ConnectionLinkCreated"],
    }),
    connectionLinkControllerGetConnectionLinksByCompany: build.query<
      ConnectionLinkControllerGetConnectionLinksByCompanyApiResponse,
      ConnectionLinkControllerGetConnectionLinksByCompanyApiArg
    >({
      query: () => ({ url: `/connection-link` }),
      providesTags: ["ConnectionLinkCreated"],
    }),
    connectionLinkControllerGetConnectionLinkById: build.query<
      ConnectionLinkControllerGetConnectionLinkByIdApiResponse,
      ConnectionLinkControllerGetConnectionLinkByIdApiArg
    >({
      query: queryArg => ({ url: `/connection-link/${queryArg.id}` }),
    }),
    connectionLinkControllerRemoveConnectionLink: build.mutation<
      ConnectionLinkControllerRemoveConnectionLinkApiResponse,
      ConnectionLinkControllerRemoveConnectionLinkApiArg
    >({
      query: queryArg => ({
        url: `/connection-link/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    connectionLinkControllerCreateSession: build.mutation<
      ConnectionLinkControllerCreateSessionApiResponse,
      ConnectionLinkControllerCreateSessionApiArg
    >({
      query: queryArg => ({
        url: "/connection-link/create-connection-session",
        method: "POST",
        body: queryArg.createConnectionSessionDto,
      }),
    }),
    guestConnectionLinkControllerGetById: build.query<
      GuestConnectionLinkControllerGetByIdApiResponse,
      GuestConnectionLinkControllerGetByIdApiArg
    >({
      query: queryArg => ({
        url: `/guest/connection-link/${queryArg.connectionLinkId}`,
        params: {
          token: queryArg.token,
        },
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type ConnectionLinkControllerCreateApiResponse =
  /** status 200  */ string
export type ConnectionLinkControllerCreateApiArg = {
  createConnectionLinkDto: CreateConnectionLinkDto
}
export type ConnectionLinkControllerGetConnectionLinksByCompanyApiResponse =
  /** status 200  */ ConnectionLinkDto[]
export type ConnectionLinkControllerGetConnectionLinksByCompanyApiArg = void
export type ConnectionLinkControllerGetConnectionLinkByIdApiResponse =
  /** status 200  */ ConnectionLinkDto
export type ConnectionLinkControllerGetConnectionLinkByIdApiArg = {
  id: string
}
export type ConnectionLinkControllerRemoveConnectionLinkApiResponse =
  /** status 200  */ ConnectionLinkDto
export type ConnectionLinkControllerRemoveConnectionLinkApiArg = {
  id: string
}

export type ConnectionLinkControllerCreateSessionApiResponse = {
  //
}
export type ConnectionLinkControllerCreateSessionApiArg = {
  createConnectionSessionDto: {
    token: string
  }
}

export type GuestConnectionLinkControllerGetByIdApiResponse = ConnectionLinkDto
export type GuestConnectionLinkControllerGetByIdApiArg = {
  connectionLinkId: string
  token: string
}
export type CreateConnectionLinkDto = {
  connectorId: string
  recipientEmail?: string
  expiresAt?: Date
}
export const {
  useGuestConnectionLinkControllerGetByIdQuery,
  useConnectionLinkControllerCreateMutation,
  useConnectionLinkControllerGetConnectionLinksByCompanyQuery,
  useConnectionLinkControllerGetConnectionLinkByIdQuery,
  useConnectionLinkControllerRemoveConnectionLinkMutation,
} = injectedRtkApi
