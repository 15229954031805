import { useCallback } from "react"
import { useGuestCompanyConnectorControllerCreateCompanyConnectorMutation as useGuestCreateCompanyConnectorMutation } from "~/ui-rtk/api/companyConnectorApi"

const useConnect = () => {
  const [
    createConnectionAsync,
    { isLoading: isCompanyConnectorCreationLoading },
  ] = useGuestCreateCompanyConnectorMutation()

  const createConnection = useCallback(async (token: string) => {
    try {
      const { uri, companyConnectorId } = await createConnectionAsync({
        token,
      }).unwrap()

      return { uri, companyConnectorId }
    } catch {
      throw new Error("Failed to connect to the service.")
    }
  }, [])

  return { createConnection, isLoading: isCompanyConnectorCreationLoading }
}

export default useConnect
