import { createElement, ElementType } from "react"

type TSortedItemsProps = {
  metrics: Record<string, any>[]
  itemComponent: ElementType
  props: Record<string, any>
}

export default function SortedItems(props: TSortedItemsProps) {
  const { itemComponent, metrics, ...restProps } = props

  return (
    <div className="flex flex-nowrap gap-2 justify-between max-w-full overflow-x-auto scroll-smooth">
      {(metrics ?? []).map((metric, idx) => (
        <div className="shrink-0 grow-0 basis-75" key={`sorted-item-${idx}`}>
          {createElement(itemComponent, {
            metric,
            ...restProps,
          })}
        </div>
      ))}
    </div>
  )
}
