import { useCallback } from "react"

import getFeaturebaseScript from "~/ui-rtk/utils/getFeaturebaseScript.tsx"

export default function useFeaturebase() {
  const { identify } = getFeaturebaseScript()

  const openFeedback = useCallback(() => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "changePage",
        payload: "PostsView",
        openWidget: true,
      },
    })
  }, [])

  const openRoadMap = useCallback(() => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "changePage",
        payload: "RoadmapView",
        openWidget: true,
      },
    })
  }, [])

  const openChangeLog = useCallback(() => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "changePage",
        payload: "ChangelogView",
        openWidget: true,
      },
    })
  }, [])

  const openHelpCenter = useCallback(() => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "changePage",
        payload: "HelpView",
        openWidget: true,
      },
    })
  }, [])

  return {
    identify,
    openFeedback,
    openChangeLog,
    openRoadMap,
    openHelpCenter,
  }
}
