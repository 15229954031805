import { DataSource } from "@db/maindb/prisma/client"
import { emptySplitApi as api } from "./emptyApi"
import { UserDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    userControllerCreateUser: build.mutation<
      UserControllerCreateUserApiResponse,
      UserControllerCreateUserApiArg
    >({
      query: queryArg => ({
        url: `/user`,
        method: "POST",
        body: queryArg.createUserDto,
      }),
    }),
    userControllerGetAllUsers: build.query<
      UserControllerGetAllUsersApiResponse,
      UserControllerGetAllUsersApiArg
    >({
      query: () => ({ url: `/user` }),
    }),
    userControllerGetUserById: build.query<
      UserControllerGetUserByIdApiResponse,
      UserControllerGetUserByIdApiArg
    >({
      query: queryArg => ({ url: `/user/${queryArg.id}` }),
    }),
    userControllerUpdateUser: build.mutation<
      UserControllerUpdateUserApiResponse,
      UserControllerUpdateUserApiArg
    >({
      query: queryArg => ({
        url: `/user/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateUserDto,
      }),
    }),
    userControllerDeleteUser: build.mutation<
      UserControllerDeleteUserApiResponse,
      UserControllerDeleteUserApiArg
    >({
      query: queryArg => ({ url: `/user/${queryArg.id}`, method: "DELETE" }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type UserControllerCreateUserApiResponse = /** status 200  */ UserDto
export type UserControllerCreateUserApiArg = {
  createUserDto: CreateUserDto
}
export type UserControllerGetAllUsersApiResponse = /** status 200  */ UserDto[]
export type UserControllerGetAllUsersApiArg = void
export type UserControllerGetUserByIdApiResponse = /** status 200  */ UserDto
export type UserControllerGetUserByIdApiArg = {
  id: string
}
export type UserControllerUpdateUserApiResponse = unknown
export type UserControllerUpdateUserApiArg = {
  id: string
  updateUserDto: UpdateUserDto
}
export type UserControllerDeleteUserApiResponse = /** status 200  */ UserDto
export type UserControllerDeleteUserApiArg = {
  id: string
}
export type CreateUserDto = {
  name: string
  email: string
  isSuperAdmin: boolean
}
export type UpdateUserDto = {
  name?: string
  email?: string
  isSuperAdmin?: boolean
  dataSource?: DataSource
}
export const {
  useUserControllerCreateUserMutation,
  useUserControllerGetAllUsersQuery,
  useUserControllerGetUserByIdQuery,
  useUserControllerUpdateUserMutation,
  useUserControllerDeleteUserMutation,
} = injectedRtkApi
