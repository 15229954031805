import * as yup from "yup"
import { useCallback } from "react"
import {
  useCompanyConnectorControllerAuthenticateCompanyConnectorMutation as useCompanyConnectorAuthMutation,
  useGuestCompanyConnectorControllerAuthenticateMutation as useGuestCompanyConnectorAuthMutation,
} from "~/ui-rtk/api/companyConnectorApi"
import { GuestTokenParam } from "../types"

export const validationSchema = yup.object().shape({
  brands: yup.string().required("Brand Keywords are required!"),
  competitors: yup.string().required("Compatitor Keywords are required!"),
  categories: yup.string().required("Category Keywords are required!"),
})

export type FormValues = yup.InferType<typeof validationSchema>

const initialValues: FormValues = {
  brands: "",
  competitors: "",
  categories: "",
}

type Params = {
  extra: string
  authCode: string
  companyConnectorId: string
} & GuestTokenParam

export const useConnect = () => {
  const [authAsync, { isLoading: isAuthLoading }] =
    useCompanyConnectorAuthMutation()
  const [guestAuthAsync, { isLoading: isGuestAuthLoading }] =
    useGuestCompanyConnectorAuthMutation()

  const authenticate = useCallback(async (params: Params) => {
    const { isGuest, companyConnectorId, authCode, extra } = params
    if (isGuest) {
      await guestAuthAsync({
        id: companyConnectorId,
        token: params.token,
        authenticateCompanyConnectorDto: {
          authCode,
          extra,
        },
      })
    } else {
      await authAsync({
        id: companyConnectorId,
        authenticateCompanyConnectorDto: {
          authCode,
          extra,
        },
      }).unwrap()
    }
  }, [])

  const isLoading = isAuthLoading || isGuestAuthLoading

  return {
    isLoading,
    initialValues,
    validationSchema,
    authenticate,
  }
}
