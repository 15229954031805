import React from "react"
import { Formik, Form } from "formik"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"

import {
  ConnectorDialog,
  ConnectFormField,
} from "~/ui-rtk/components/features/connectors"
import { FormValues, useConnect } from "./connect"

import { isInternalServerError } from "~/ui-rtk/utils/http-utils"
import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"

import type { CustomerConnectorDialogFormPropsWithoutUri } from "../types"
import { Button } from "~/ui-rtk/components/ui/controls"

type YotpoForm = CustomerConnectorDialogFormPropsWithoutUri

const YotpoForm: React.FC<YotpoForm> = ({
  companyConnectorId,
  isOpen,
  onClose,
  ...props
}) => {
  const { isLoading, initialValues, validationSchema, authenticate } =
    useConnect()

  const handleConnect = async (values: FormValues) => {
    if (isLoading) {
      return
    }

    if (!companyConnectorId) {
      throw new Error("Missing company connector id")
    }

    const appKey = values.appKey
    const secretKey = values.secretKey
    const authCode = btoa(`${appKey}:${secretKey}`)

    try {
      await authenticate({ companyConnectorId, authCode, ...props })
    } catch (err) {
      if (isInternalServerError(err)) {
        toast.error("Invalid Yotpo Credentials", {
          toastId: "yotpo-form-connect-credentials-error",
        })
        return
      }

      toast.error("Unexpected error. Please, try again later.", {
        toastId: "yotpo-form-connect-unexcepted-error",
      })
    }
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.yotpo}
      alt="Yotpo Logo"
      title="Connection to Yotpo"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className="leading-5 text-left text-base text-platinum">
        In order to connect to Yotpo, you will be required to provide your App
        Key and Secret Key. They can be found in General Settings (
        <Link
          to="https://settings.yotpo.com/#/general_settings"
          target="_blank"
          className="text-blue"
        >
          https://settings.yotpo.com/#/general_settings
        </Link>
        ) under API Credentials, like below.
      </p>
      <img
        src="/assets/img/yotpo_credentials.png"
        alt="Yotpo General Settings"
      />
      <Formik
        onSubmit={handleConnect}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ touched, errors }) => (
          <Form className="flex flex-col w-full gap-4 mt-8">
            <div className="space-y-3">
              <ConnectFormField
                name="appKey"
                label="App Key*"
                placeholder="Enter your App Key here"
                isError={Boolean(touched.appKey && errors.appKey)}
              />
              <ConnectFormField
                name="secretKey"
                label="Secret Key*"
                placeholder="Enter your Secret Key here"
                isError={Boolean(touched.secretKey && errors.secretKey)}
              />
            </div>

            <Button
              variant={{ variant: "solid", color: "white" }}
              type="submit"
              isLoading={isLoading}
            >
              Connect
            </Button>
          </Form>
        )}
      </Formik>
    </ConnectorDialog>
  )
}

export default YotpoForm
