/* eslint-disable @typescript-eslint/no-explicit-any */
import { twMerge } from "tw-merge"
import clsx, { ClassValue } from "clsx"

import { dark as theme } from "../themes/dark"

import type { ThemeColors } from "../themes/types"

export const cn = (...args: ClassValue[]): string => twMerge(clsx(...args))

export const getThemeColor = (keys: ThemeColors): string | undefined => {
  const pathArray = keys.split(".")
  let value: any = theme.colors
  for (const key of pathArray) {
    value = value[key]
    if (value === undefined) {
      return undefined
    }
  }
  return value
}
