import { emptySplitApi as api } from "./emptyApi"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    subscriptionControllerCreateCheckoutSession: build.mutation<
      SubscriptionControllerCreateCheckoutSessionApiResponse,
      SubscriptionControllerCreateCheckoutSessionApiArg
    >({
      query: queryArg => ({
        url: "/subscription/checkout",
        method: "POST",
        body: queryArg.createCheckoutSessionDto,
      }),
    }),
    subscriptionControllerConfirmSubscriptionCheckoutSession: build.mutation<
      SubscriptionControllerConfirmSubscriptionCheckoutSessionApiResponse,
      ConfirmSubscriptionCheckoutSessionDto
    >({
      query: queryArg => ({
        url: `/subscription/checkout/confirm`,
        method: "POST",
        body: queryArg,
      }),
    }),
    subscriptionControllerCreateCustomerPortal: build.query<
      SubscriptionControllerCreateCustomerPortalApiResponse,
      SubscriptionControllerCreateCustomerPortalApiArg
    >({
      query: () => ({ url: `/subscription/customer-portal` }),
    }),
    subscriptionControllerCreateCustomerPortalSession: build.query<
      string,
      void
    >({
      query: () => ({
        url: "subscription/customer-portal",
        responseHandler: response => response.text(),
      }),
    }),
  }),
})

export { injectedRtkApi as generatedApi }

export type ConfirmSubscriptionCheckoutSessionDto = {
  stripeSessionId: string
}
export type SubscriptionControllerConfirmSubscriptionCheckoutSessionApiResponse =
  /** status 200  */ string

export type SubscriptionControllerCreateCustomerPortalApiResponse = unknown
export type SubscriptionControllerCreateCustomerPortalApiArg = void

export type SubscriptionControllerCreateCheckoutSessionApiResponse = {
  checkoutUrl: string
}
export type SubscriptionControllerCreateCheckoutSessionApiArg = {
  createCheckoutSessionDto: CreateCheckoutSessionDto
}

export type CreateCheckoutSessionDto = {
  productIds: string[]
}

export const {
  //
  useSubscriptionControllerCreateCheckoutSessionMutation,
  useSubscriptionControllerConfirmSubscriptionCheckoutSessionMutation,
  useSubscriptionControllerCreateCustomerPortalQuery,
  useLazySubscriptionControllerCreateCustomerPortalSessionQuery,
} = injectedRtkApi
