import { createElement } from "react"
import {
  CampaignAdSetAdStringToComponentMap,
  TCampaignAdSetAdDetailsProps,
} from "~/ui-rtk/components/features/details/constants"

type TDetailsDrawer = {
  component: keyof typeof CampaignAdSetAdStringToComponentMap
} & TCampaignAdSetAdDetailsProps

export default function CampaingAdSetAdDetails(props: TDetailsDrawer) {
  const { component: componentName, ...restProps } = props
  const component = CampaignAdSetAdStringToComponentMap[componentName]
  return createElement(component, restProps)
}
