import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const PulseSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4992 2.94531C13.3276 2.94531 13.9992 3.61689 13.9992 4.44531V13.4468C13.9992 14.2753 13.3276 14.9468 12.4992 14.9468H3.5C2.67157 14.9468 2 14.2753 2 13.4468V4.44531C2 3.61689 2.67157 2.94531 3.5 2.94531H12.4992ZM12.4992 3.94531H3.5C3.22386 3.94531 3 4.16917 3 4.44531V13.4468C3 13.723 3.22386 13.9468 3.5 13.9468H12.4992C12.7754 13.9468 12.9992 13.723 12.9992 13.4468V4.44531C12.9992 4.16917 12.7754 3.94531 12.4992 3.94531ZM5.50339 8.61227L6.54117 6.24487C6.7087 5.86268 7.23169 5.84847 7.43005 6.19128L7.46327 6.25973L9.05079 10.2242L9.71963 8.88841C9.79378 8.74031 9.93555 8.63997 10.0966 8.61719L10.1667 8.61227H11.5C11.7761 8.61227 12 8.83613 12 9.11227C12 9.3654 11.8119 9.5746 11.5678 9.60771L11.5 9.61227H10.4755L9.44709 11.6662C9.26538 12.0291 8.7605 12.0299 8.56809 11.695L8.53583 11.6283L6.97825 7.73856L6.28807 9.31301C6.21833 9.4721 6.07202 9.5819 5.90354 9.60687L5.83013 9.61227H4.5C4.22386 9.61227 4 9.38841 4 9.11227C4 8.85914 4.1881 8.64994 4.43215 8.61683L4.5 8.61227H5.50339L6.54117 6.24487L5.50339 8.61227Z"
      fill={fill}
    />
  </svg>
))
