import { useEffect, useState } from "react"
import * as yup from "yup"
import { useSearchParams } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"

import { DEFAULT_GUEST_ROUTE } from "~/ui-rtk/constants/routes"
import { useGuestCompanyConnectorControllerAuthenticateMutation as useGuestCompanyConnectorAuthMutation } from "../api/companyConnectorApi"
import { isNotFoundError } from "../utils/http-utils"
import {
  getLocalStorageValue,
  LocalStorageKeys,
  unsetLocalStorageValue,
} from "../utils/storage"

const queryValidator = yup.object({
  authCode: yup.string().required(),
  state: yup.string().required(),
})

const AuthenticateConnectorGuest = () => {
  const navigate = useStableNavigate()
  const [searchParams] = useSearchParams()
  const [authenticate] = useGuestCompanyConnectorAuthMutation()

  const [text, setText] = useState("Authenticating...")

  const redirect = () => {
    setTimeout(() => navigate(DEFAULT_GUEST_ROUTE), 1000)
  }

  const showErrorMessage = (error: any) => {
    if (
      error instanceof yup.ValidationError ||
      error.name === "ValidationError"
    ) {
      toast.error("Failed to authenticate the connector.", {
        toastId: "auth-connector-validation-error-id",
      })
    } else if (isNotFoundError(error)) {
      toast.error("Connection was not found.", {
        toastId: "auth-connector-not-found-error-id",
      })
    } else {
      toast.error("Unexcepted error. Try again later.", {
        toastId: "auth-connector-server-error-id",
      })
    }

    setText("Failed to authenticate connector. Redirecting...")
  }

  useEffect(() => {
    const beginAction = async () => {
      try {
        const token = getLocalStorageValue(LocalStorageKeys.GUEST_TOKEN)
        if (typeof token !== "string") {
          void showErrorMessage(null)
          return
        }

        const result = await queryValidator.validate({
          state: searchParams.get("state"),
          authCode: searchParams.get("code") || searchParams.get("auth_code"),
        })

        const { companyConnectorId } = JSON.parse(result.state)
        await authenticate({
          id: companyConnectorId,
          token,
          authenticateCompanyConnectorDto: {
            authCode: result.authCode,
          },
        })

        setText("Success. Redirecting...")
      } catch (error: any) {
        void showErrorMessage(error)
      } finally {
        unsetLocalStorageValue(LocalStorageKeys.GUEST_TOKEN)
        void redirect()
      }
    }

    void beginAction()
  }, [])

  return <div>{text}</div>
}

export default AuthenticateConnectorGuest
