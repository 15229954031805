export type DateOrNull = Date | null
export type PeriodPickerField = "calendar" | "input"
export type TRangeType = [Date, Date]

export enum RangeTags {
  rangeStart = 0,
  rangeEnd = 1,
  compareRangeStart = 2,
  compareRangeEnd = 3,
}

export type TDateRestrictions = Partial<{
  maxDate: Date
  minDate: Date
}>
