import type { DateOrNull } from "~/ui-rtk/shared/types/date"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export const getDateLabel = (date: DateOrNull) => {
  if (!date) {
    return
  }

  return dayjs(date).format("MMM D, YYYY")
}
