export enum ReleaseCycle {
  ALPHA = "ALPHA",
  BETA = "BETA",
}

export type ConnectorMetadata = {
  visibleToCompanyIds?: string[]
  errors?: string[]
  isBeta: boolean
}

export type ConnectorDto = {
  id: string
  name: string
  icon: string
  category: string[]
  authStrategy: string
  releaseCycle: ReleaseCycle
  config: object
  schema: object
  metadata: ConnectorMetadata
  createdAt: string
  updatedAt: string
}
