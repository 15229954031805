import { /* useCallback, */ useMemo } from "react"
import {
  type Query as TCubeQuery,
  ResultSet,
  // TimeDimension,
} from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP } from "../../constants"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import useCampaignAdSetAdDetails from "~/ui-rtk/hooks/campaign-adset-ad-details"
import { getPivotData } from "~/ui-rtk/utils/cube"
import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"

// import useDate from "~/ui-rtk/hooks/date"
// const dayjs = useDate()

const prefixKey = "company_media_metrics_posts"
// const postIdKey = `${prefixKey}.post_id`
const brandValueKey = `${prefixKey}.brand_value`

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

// function getInfoBlock(metrics: any[], weeksChunk?: number) {
//   const { brandValue } = (
//     weeksChunk ? metrics.slice(0, weeksChunk) : metrics
//   ).reduce(
//     (acc, item) => ({
//       brandValue: acc.brandValue + item.current.brandValue,
//     }),
//     {
//       brandValue: 0,
//     },
//   )
//   return {
//     brandValue,
//   }
// }

function prepareAllItemsDetails(metrics: any[]) {
  const brandValue = metrics.reduce(
    (acc, metric) => acc + (metric[brandValueKey] ?? 0),
    0,
  )
  return {
    brandValue,
    total: metrics.length,
  }
}

// function prepareValueOverTimeData(
//   metrics: any[],
//   isCurrent: (metric: any) => boolean,
//   keyPrefix: string,
// ) {
//   const dateKey = `${keyPrefix}.post_date`
//   const brandValueKey = `${keyPrefix}.brand_value`

//   const resultSet = metrics.reduce((resultSet, metric) => {
//     const date = dayjs(metric[dateKey]).format("YYYY-MM-DD")

//     if (!resultSet[date]) {
//       resultSet[date] = {
//         current: {
//           brandValue: 0,
//         },
//         all: {
//           brandValue: 0,
//         },
//         totalItems: 0,
//         date,
//       } as TCampaignAdSetAdDetailsItem
//     }

//     if (isCurrent(metric)) {
//       resultSet[date].current.brandValue = metric[brandValueKey]
//     }
//     if (metric[brandValueKey] !== null) {
//       resultSet[date].all.brandValue += metric[brandValueKey]
//       resultSet[date].totalItems += 1
//     }
//     return resultSet
//   }, {})

//   const chartData = Object.values(resultSet).sort(
//     (a: unknown, b: unknown) =>
//       dayjs((a as TCampaignAdSetAdDetailsItem).date)
//         .toDate()
//         .getTime() -
//       dayjs((b as TCampaignAdSetAdDetailsItem).date)
//         .toDate()
//         .getTime(),
//   )

//   return {
//     chartData: chartData.map((item: unknown) => ({
//       ...(item as TCampaignAdSetAdDetailsItem),
//       average: {
//         brandValue:
//           (item as TCampaignAdSetAdDetailsItem).totalItems > 0
//             ? (item as TCampaignAdSetAdDetailsItem).all.brandValue /
//               (item as TCampaignAdSetAdDetailsItem).totalItems
//             : 0,
//       },
//     })),
//     info30days: {
//       ...getInfoBlock([...chartData].reverse(), 4),
//       label: "30 days",
//     },
//     info60days: {
//       ...getInfoBlock([...chartData].reverse(), 8),
//       label: "60 days",
//     },
//     info90days: {
//       ...getInfoBlock([...chartData].reverse()),
//       label: "90 days",
//     },
//   }
// }

export const usePostQubeQuery = (postId: string) => {
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${currentCompanyId}.${postId}`
  const queryAllPostsKey = `${cacheKey}.all`
  // const queryValueOverTimeKey = `${cacheKey}.value`
  // const today = dayjs().startOf("day").format("YYYY-MM-DD")
  // const aWeekAgo = dayjs()
  //   .startOf("day")
  //   .subtract(6, "days")
  //   .format("YYYY-MM-DD")
  // const twelveWeeksAgo = dayjs()
  //   .startOf("day")
  //   .subtract(83, "days")
  //   .format("YYYY-MM-DD")

  const baseQuery = {
    ...MetricsMapping["brand_media_organic_social_posts_table"].q,
  }

  const postQuery = useMemo(
    () =>
      ({
        ...baseQuery,
        // timeDimensions: ((baseQuery as TCubeQuery)?.timeDimensions ?? []).map(
        //   (timeDimension: TimeDimension) => {
        //     const $timeDimension = {
        //       ...timeDimension,
        //       dateRange: [today, aWeekAgo],
        //     }

        //     return $timeDimension
        //   },
        // ) as unknown as TimeDimension[],
        // filters: [
        //   {
        //     and: [
        //       {
        //         dimension: brandValueKey,
        //         operator: "set",
        //       },
        //     ],
        //   },
        // ],
      }) as TCubeQuery,
    [baseQuery /* , today, aWeekAgo*/],
  )

  const allPostsQuery = useMemo(() => {
    if (!requests[queryAllPostsKey]) {
      requests[queryAllPostsKey] = {
        ...postQuery,
      }
    }
    return requests[queryAllPostsKey]
  }, [queryAllPostsKey])

  // const valueOverTimeQuery = useMemo(() => {
  //   if (!requests[queryValueOverTimeKey]) {
  //     requests[queryValueOverTimeKey] = {
  //       ...baseQuery,
  //       // timeDimensions: ((baseQuery as TCubeQuery)?.timeDimensions ?? []).map(
  //       //   (timeDimension: TimeDimension) => {
  //       //     const $timeDimension = {
  //       //       ...timeDimension,
  //       //       dateRange: [today, twelveWeeksAgo],
  //       //     }

  //       //     return $timeDimension
  //       //   },
  //       // ) as unknown as TimeDimension[],
  //     }
  //   }
  //   return requests[queryValueOverTimeKey]
  // }, [queryValueOverTimeKey])

  const {
    [CUBE_QUERY_KEYS_MAP.ALL]: allPostsSet,
    // [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeSet,
    isLoading,
  } = useCampaignAdSetAdDetails({
    [CUBE_QUERY_KEYS_MAP.ALL]: allPostsQuery,
    // [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeQuery,
  })

  // const isCurrent = useCallback(
  //   (metric: any) => metric[postIdKey] === postId,
  //   [postId],
  // )

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allPostsSet) {
    const pivotData = getPivotData(allPostsSet as ResultSet<any>, allPostsQuery)
    results[cacheKey].allPostsDetails = prepareAllItemsDetails(pivotData)
  }

  // if (valueOverTimeSet) {
  //   const pivotData = getPivotData(
  //     valueOverTimeSet as ResultSet<any>,
  //     valueOverTimeQuery,
  //   )
  //   results[cacheKey].valueOverTimeDetails = prepareValueOverTimeData(
  //     pivotData,
  //     isCurrent,
  //     granularity,
  //   )
  // }

  const { allPostsDetails, valueOverTimeDetails } = results[cacheKey]

  return { allPostsDetails, valueOverTimeDetails, isLoading }
}
