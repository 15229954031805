import React, { useCallback, useMemo, useState } from "react"
import Input, { InputProps } from "~/ui-rtk/components/ui/controls/Input"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

import "./index.scss"
import { formatDate } from "./utils"

export type DateInputState = {
  isFocused: boolean
  setIsFocused: (isFocused: boolean) => void
}

export type DateInputProps = Omit<
  InputProps,
  "onChange" | "value" | "type" | "onBlur"
> & {
  value?: Date | null
  onTab?: () => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onChange?: (date: Date | null, close: boolean) => void
}

export const DateInput = ({
  value,
  onFocus,
  onBlur,
  onChange,
  label,
  name,
  id,
  onTab = () => null,
  ...props
}: DateInputProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [internalValue, setInternalValue] = useState<string>(
    formatDate(value ?? ""),
  )

  const inputName = useMemo(
    () => (name ? name : `date-input-${Math.random()}`),
    [name],
  )

  const isError = useMemo(
    () => !dayjs(internalValue, "MMMM Do YYYY", true).isValid(),
    [internalValue],
  )

  const triggerChangeEvent = (close = false) => {
    const dateValue = dayjs(internalValue, "MMMM Do YYYY", true)
    if (dateValue.isValid()) {
      setIsFocused(false)
      onChange?.(dateValue.toDate(), close)
    } else {
      onChange?.(null, close)
    }
  }

  const handleKeyDown = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.KeyboardEvent<Element>,
    ) => {
      if (event.key === "Enter") {
        event.preventDefault()
        triggerChangeEvent(true)
      }
      if (event.key === "Tab") {
        event.preventDefault()
        onTab()
      }
    },
    [internalValue, triggerChangeEvent, onTab],
  )

  const handleChange = (event: React.ChangeEvent<EventTarget>) => {
    const value = (event.target as HTMLInputElement).value
    void setInternalValue(value)

    const dateValue = dayjs(value, "MMMM Do YYYY", true)
    if (dateValue.isValid()) {
      onChange?.(dateValue.toDate(), false)
    }
  }

  const classNames = [
    "ui-rtk-date-input bg-basic-blue text-basic-white text-base",
    props.className,
    isFocused ? "border-brand" : "",
  ]
    .join(" ")
    .trim()

  return (
    <div className="space-y-3">
      {label ? (
        <label
          className="leading-6 text-basic-grey-inactive"
          htmlFor={id ?? inputName}
        >
          {label}
        </label>
      ) : null}
      <Input
        {...props}
        type="text"
        id={id ?? inputName}
        name={inputName}
        className={classNames}
        classes={{
          input: "bg-basic-blue",
        }}
        value={isFocused ? internalValue : formatDate(value ?? "")}
        onFocus={event => {
          setInternalValue(formatDate(value ?? ""))
          setIsFocused(true)
          onFocus?.(event)
        }}
        onBlur={event => {
          setIsFocused(false)
          onBlur?.(event)
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        isError={isError}
      />
    </div>
  )
}
