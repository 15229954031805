import { createContext, useContext, useState } from "react"
import { ForgotPasswordComp } from "./ForgotPassword"

export const ForgotPasswordContext = createContext({
  email: "",
  isEmailSentView: false,
  showEmailSentView: (_email: string) => {
    //
  },
})

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("")
  const [isEmailSentView, setIsEmailSentView] = useState(false)

  const showEmailSentView = (email: string) => {
    void setEmail(email)
    void setIsEmailSentView(true)
  }

  const value = {
    email,
    isEmailSentView,
    showEmailSentView,
  }

  return (
    <ForgotPasswordContext.Provider value={value}>
      <ForgotPasswordComp />
    </ForgotPasswordContext.Provider>
  )
}

export const useForgotPasswordContext = () => {
  const ctx = useContext(ForgotPasswordContext)
  if (!ctx) {
    throw new Error(
      `Hook cannot be used outside of ${ForgotPasswordContext.displayName}`,
    )
  }
  return ctx
}

export default ForgotPassword
