import { useCallback, useState } from "react"
import * as yup from "yup"
import type { FormikHelpers } from "formik"

import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import { isFetchBaseQueryError } from "~/ui-rtk/utils/guards"
import { onboardingRoutes } from "~/ui-rtk/constants/routes"
import { useAuthControllerCreateAccountMutation } from "~/ui-rtk/api/authApi"

export const validationSchema = yup.object().shape({
  workEmail: yup
    .string()
    .email("Oops! That doesn’t look like a valid email")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
  fullName: yup.string().required("Full name is required!"),
})

type FormValues = {
  fullName: string
  workEmail: string
  password: string
}

export default function useConnect() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [createAccountAsync] = useAuthControllerCreateAccountMutation()

  const navigate = useStableNavigate()

  const initialValues: FormValues = {
    workEmail: "",
    password: "",
    fullName: "",
  }

  const handleRegister = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      const { workEmail, ...createAccountDto } = values
      void setIsSubmitting(true)
      try {
        await createAccountAsync({
          createAccountDto: {
            workEmail: workEmail?.toLowerCase(),
            ...createAccountDto,
          },
        })

        navigate(onboardingRoutes.CHECK_INBOX)
      } catch (error) {
        if (isFetchBaseQueryError(error) && error.status === 401) {
          setErrors({
            workEmail: "Invalid credentials",
            password: "Invalid credentials",
          })
        }
      } finally {
        void setIsSubmitting(false)
      }
    },
    [navigate],
  )

  return {
    handleRegister,
    initialValues,
    validationSchema,
    isSubmitting,
  }
}
