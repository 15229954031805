import { useCallback } from "react"
import { toast } from "react-toastify"
import * as yup from "yup"
import { useCompanyControllerSignUpCompanyMutation } from "~/ui-rtk/api/companyApi"
import { domainRegex } from "~/ui-rtk/constants/regex"
import { hasErrorMessage, isBadRequestError } from "~/ui-rtk/utils/http-utils"

export type AccountDetailsFormValues = {
  domain: string
  companyName: string
}

const initialValues: AccountDetailsFormValues = {
  domain: "",
  companyName: "",
}

export const validationSchema = yup.object().shape({
  domain: yup
    .string()
    .transform(value => value.trim())
    .matches(domainRegex, { message: "Invalid domain format" })
    .required("Domain is required"),
  companyName: yup.string().required("Company name is required!"),
})

export const useConnect = () => {
  const [signUpCompanyAsync, { isLoading }] =
    useCompanyControllerSignUpCompanyMutation()

  const handleSuccess = () => {
    toast.success(`Company was successfully created`, {
      toastId: "onboarding-account-details-success",
    })
    window.location.reload()
  }

  const submit = useCallback(async (values: AccountDetailsFormValues) => {
    try {
      await signUpCompanyAsync({
        signUpCompanyDto: { ...values },
      }).unwrap()

      handleSuccess()
    } catch (error) {
      if (hasErrorMessage(error)) {
        const message = error.data.message
        if (message.includes("setup external integrations")) {
          return handleSuccess()
        }

        if (isBadRequestError(error)) {
          toast.error(error.data.message, {
            toastId: "onboarding-account-details-bad-request-error",
          })
          return
        }
      } else {
        toast.error("Something went wrong", {
          toastId: "onboarding-account-details-unexpected-error",
        })
      }
    }
  }, [])

  return {
    isSubmitting: isLoading,
    initialValues,
    validationSchema,
    updateAccountDetails: submit,
  }
}
