import { ReactNode, useCallback, useMemo } from "react"
import { SessionRole } from "~/api/dto/auth/session-role"
import { CompanySetupStatus } from "~/ui-rtk/api/types"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import PagePermissionGuard from "~/ui-rtk/components/features/guards/PagePermissionGuard"
import useAccess from "~/ui-rtk/hooks/access"

export const usePermissions = () => {
  const currentCompany = useAppSelector(selectCurrentCompany)
  const sessionRole = useAppSelector(selectUserSessionRole)
  const { hasAccess: isAccessGranted, isBillingEnabled } = useAccess()

  const isPermissionGranted = useCallback(
    (roles: SessionRole[]) => roles.includes(sessionRole),
    [sessionRole],
  )

  const isUserManagementGranted = useMemo(
    () =>
      [
        SessionRole.SUPER_ADMIN,
        SessionRole.ADMIN,
        SessionRole.ADMIN_WITH_ACCESS,
      ].includes(sessionRole),
    [sessionRole],
  )

  const isCompanyManagementGranted = [SessionRole.SUPER_ADMIN].includes(
    sessionRole,
  )

  const isCompanyOnboarded = useMemo(
    () =>
      currentCompany?.setupStatus &&
      currentCompany.setupStatus !== CompanySetupStatus.INITIAL_SETUP,
    [currentCompany],
  )

  return {
    sessionRole,
    currentCompany,
    isBillingEnabled,
    isAccessGranted,
    isCompanyOnboarded,
    isPermissionGranted,
    isUserManagementGranted,
    isCompanyManagementGranted,
  }
}

export const withGuard = (
  children: ReactNode,
  allowed: boolean | boolean[],
) => {
  const isAllowed = Array.isArray(allowed)
    ? allowed.every(isAllowed => isAllowed === true)
    : allowed

  return (
    <PagePermissionGuard isAllowed={isAllowed}>{children}</PagePermissionGuard>
  )
}
