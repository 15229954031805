import { Formik, Form, ErrorMessage, Field } from "formik"
import {
  Role,
  DataSourceEnum,
  UserFormValues,
  useValidation,
} from "./validation"
import { Input, FormikSelect, Button } from "~/ui-rtk/components/ui/controls"
import H2 from "~/ui-rtk/components/ui/typography/H2"

type TUserFormProps = {
  user?: UserFormValues | null
  submit: (user: UserFormValues) => void
  isLoading?: boolean
  showDataSourceInfo: boolean
}

export default function UserForm({
  user,
  submit,
  isLoading,
  showDataSourceInfo,
}: TUserFormProps) {
  const [validationSchema] = useValidation()

  const initialValues = {
    name: user?.name ?? "",
    email: user?.email ?? "",
    role: user?.role ?? "USER",
    dataSource: user?.dataSource ?? "SNAPSHOT",
  } as UserFormValues

  const roleOptions = [
    {
      label: "Admin",
      value: Role.ADMIN,
    },
    {
      label: "User",
      value: Role.USER,
    },
  ]

  const sourceOptions = [
    {
      label: "Snapshot",
      value: DataSourceEnum.SNAPSHOT,
    },
    {
      label: "Latest",
      value: DataSourceEnum.LATEST,
    },
  ]

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
      >
        {({ touched, errors }) => (
          <Form className="h-auto p-6">
            <H2 className="font-bold">{user ? "Edit User" : "Add User"}</H2>
            <div className="mt-5 flex items-start justify-between min-h-18 flex-col gap-2">
              <div className="flex-none w-24 mt-3 text-left ">Name</div>
              <div className="relative flex-auto w-full">
                <Field
                  type="text"
                  id="name"
                  name="name"
                  as={Input}
                  disabled={!!user}
                  fullWidth
                  isError={touched.name && errors.name}
                />
                <ErrorMessage
                  component="a"
                  name="name"
                  className="absolute left-0 input-error-message"
                />
              </div>
            </div>
            <div className="mt-5 flex items-start justify-between min-h-18 flex-col gap-2">
              <div className="flex-none w-24 mt-3 text-left">E-mail</div>
              <div className="relative flex-auto w-full">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  as={Input}
                  disabled={!!user}
                  fullWidth
                  isError={touched.email && errors.email}
                />
                <ErrorMessage
                  component="a"
                  name="email"
                  className="absolute left-0 input-error-message"
                />
              </div>
            </div>
            <div className="mt-5 flex items-start justify-between min-h-18 flex-col gap-2">
              <div className="flex-none w-24 mt-3 text-left">Role</div>
              <div className="relative flex-auto w-full">
                <Field
                  type="text"
                  id="role"
                  name="role"
                  component={FormikSelect}
                  options={roleOptions}
                  value={user?.role ?? ""}
                  isError={touched.role && errors.role}
                />
                <ErrorMessage
                  component="a"
                  name="role"
                  className="absolute left-0 top-full input-error-message"
                />
              </div>
            </div>
            {showDataSourceInfo && user && (
              <div className="mt-5 flex items-start justify-between min-h-18 flex-col gap-2">
                <div className="flex-none w-24 mt-3 text-left">DataSource</div>
                <div className="relative flex-auto w-full">
                  <Field
                    type="text"
                    id="dataSource"
                    name="dataSource"
                    component={FormikSelect}
                    options={sourceOptions}
                    value={user?.dataSource ?? "SNAPSHOT"}
                    isError={touched.dataSource && errors.dataSource}
                  />
                  <ErrorMessage
                    component="a"
                    name="dataSource"
                    className="absolute left-0 top-full input-error-message"
                  />
                </div>
              </div>
            )}
            <div className="flex mt-10">
              <Button
                variant={{ variant: "solid", color: "white" }}
                type="submit"
                isLoading={isLoading}
                className="w-full"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
