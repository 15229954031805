import { BinaryFilter, LogicalAndFilter } from "@cubejs-client/core"
import useDate from "~/ui-rtk/hooks/date"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"
import { LocalStorageKeys, useLocalStorage } from "~/ui-rtk/utils/storage"

const dayjs = useDate()

export const useLocalStorageWarningSetting = () => {
  const [dontShowDialogAgain, setDontShowDialogAgain] =
    useLocalStorage<boolean>(
      LocalStorageKeys.DONT_SHOW_INACCURATE_DATES_WARNING,
      false,
    )

  return {
    dontShowDialogAgain,
    setDontShowDialogAgain,
  }
}

export const getBaseCubeYearlyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) =>
  ({
    member: `${queryPrefix}.year`,
    operator: "equals",
    values: [dayjs(startOfPeriod).isoWeekYear().toString()],
  }) as BinaryFilter

export const getBaseCubeQuarterlyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) => ({
  and: [
    getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
    {
      member: `${queryPrefix}.quarter`,
      operator: "equals",
      values: [`${dayjs(startOfPeriod).quarter()}`],
    } as BinaryFilter,
  ],
})

export const getBaseCubeMonthlyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) => ({
  and: [
    getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
    {
      member: `${queryPrefix}.month`,
      operator: "equals",
      values: [`${dayjs(startOfPeriod).month() + 1}`],
    } as BinaryFilter,
  ],
})

export const getBaseCubeWeelklyFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
) => ({
  and: [
    getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
    {
      member: `${queryPrefix}.week`,
      operator: "equals",
      values: [dayjs(startOfPeriod).isoWeek().toString()],
    } as BinaryFilter,
  ],
})

export const getBaseCubeFilters = (
  queryPrefix: string,
  startOfPeriod: Date,
  granularity: BrandMediaAggregationType,
  filters?: Record<string, string | undefined | null>,
): LogicalAndFilter => {
  const formattedFilters = filters
    ? Object.keys(filters)
        .map(key => {
          if (!filters[key]) {
            return undefined
          }
          return {
            member: `${queryPrefix}.${key}`,
            operator: "equals",
            values: [filters[key]],
          }
        })
        .filter(f => !!f)
    : []

  switch (granularity) {
    case BrandMediaAggregationType.WEEKLY: {
      return {
        and: [
          ...formattedFilters,
          ...getBaseCubeWeelklyFilters(queryPrefix, startOfPeriod).and,
        ],
      } as LogicalAndFilter
    }
    case BrandMediaAggregationType.MONTHLY: {
      return {
        and: [
          ...formattedFilters,
          ...getBaseCubeMonthlyFilters(queryPrefix, startOfPeriod).and,
        ],
      } as LogicalAndFilter
    }
    case BrandMediaAggregationType.QUARTERLY: {
      return {
        and: [
          ...formattedFilters,
          ...getBaseCubeQuarterlyFilters(queryPrefix, startOfPeriod).and,
        ],
      } as LogicalAndFilter
    }
    case BrandMediaAggregationType.YEARLY: {
      return {
        and: [
          ...formattedFilters,
          getBaseCubeYearlyFilters(queryPrefix, startOfPeriod),
        ],
      } as LogicalAndFilter
    }
    default: {
      throw new Error("wrong granularity")
    }
  }
}
