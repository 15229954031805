import React from "react"
import { ConnectorDto } from "~/ui-rtk/api/types"
import { ExclamationSvg } from "~/ui-rtk/components/ui/svg/essentials"

export type TSetupMessageProps = {
  connectors: ConnectorDto[]
} & React.PropsWithChildren

export const SetupMessage: React.FC<TSetupMessageProps> = ({ connectors }) => (
  <div className="text-3.5 leading-4 transition-all duration-300 font-medium mt-0.5">
    <b>You need to connect:</b>
    <ul className="mt-1">
      {connectors.map(connector => (
        <li key={connector.id} className="space-x-2 text-nowrap text-3">
          <ExclamationSvg size={14} className="mr-1" fill="states.error" />
          {connector.name}
        </li>
      ))}
    </ul>
  </div>
)
