import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const PersonSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.836 9.33329C12.664 9.33329 13.3353 10.0045 13.3353 10.8325V11.2162C13.3353 11.8123 13.1222 12.3889 12.7345 12.8418C11.6883 14.0641 10.0968 14.6674 7.99992 14.6674C5.90268 14.6674 4.312 14.0639 3.2678 12.8411C2.88129 12.3885 2.66895 11.8128 2.66895 11.2177V10.8325C2.66895 10.0045 3.34018 9.33329 4.1682 9.33329H11.836ZM11.836 10.3333H4.1682C3.89247 10.3333 3.66895 10.5568 3.66895 10.8325V11.2177C3.66895 11.5748 3.79635 11.9202 4.02826 12.1917C4.8638 13.1702 6.17438 13.6674 7.99992 13.6674C9.82546 13.6674 11.1372 13.1702 11.9748 12.1916C12.2074 11.9198 12.3353 11.5739 12.3353 11.2161V10.8325C12.3353 10.5568 12.1118 10.3333 11.836 10.3333ZM7.99992 1.33643C9.84087 1.33643 11.3333 2.82881 11.3333 4.66976C11.3333 6.51071 9.84087 8.00309 7.99992 8.00309C6.15897 8.00309 4.66658 6.51071 4.66658 4.66976C4.66658 2.82881 6.15897 1.33643 7.99992 1.33643ZM7.99992 2.33643C6.71125 2.33643 5.66658 3.38109 5.66658 4.66976C5.66658 5.95842 6.71125 7.00309 7.99992 7.00309C9.28858 7.00309 10.3333 5.95842 10.3333 4.66976C10.3333 3.38109 9.28858 2.33643 7.99992 2.33643Z"
      fill={fill}
    />
  </svg>
))
