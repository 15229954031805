import { useGuestConnectionLinkControllerGetByIdQuery as useGuestConnectionLinkQuery } from "~/ui-rtk/api/connectionLinkApi"

const useConnect = (connectionLinkId: string, token: string) => {
  const {
    data: connectioLink,
    isLoading: isConnectionLinkLoading,
    isError: isConnectionLinkError,
  } = useGuestConnectionLinkQuery({ token, connectionLinkId })

  const isLoading = isConnectionLinkLoading
  const isError = isConnectionLinkError

  return { data: connectioLink, isLoading, isError }
}

export default useConnect
