import {
  DataSvg,
  PulseSvg,
  ValueSvg,
} from "~/ui-rtk/components/ui/svg/business"
import { HomeSvg, SettingsSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { brandRoutes } from "~/ui-rtk/constants/routes"
import { SessionRole } from "~/api/dto/auth/session-role"

import type { TSidebarItemProps } from "./SidebarItem"
import { IS_HOME_PAGE_ENABLED } from "~/ui-rtk/pages/Home/HomePage"

export const sidebarItems: Pick<
  TSidebarItemProps,
  "Icon" | "label" | "path" | "items" | "availableTo"
>[] = [
  {
    path: brandRoutes.HOME,
    label: "Home",
    Icon: HomeSvg,
    availableTo: IS_HOME_PAGE_ENABLED
      ? [
          SessionRole.SUPER_ADMIN,
          // SessionRole.ADMIN_WITH_ACCESS,
          // SessionRole.USER_WITH_ACCESS,
          // SessionRole.ADMIN,
          // SessionRole.USER,
        ]
      : [], // adjust once dashboard will be ready
  },
  {
    path: brandRoutes.BRAND_HEALTH.OVERVIEW,
    label: "Brand Health",
    availableTo: [
      SessionRole.SUPER_ADMIN,
      SessionRole.ADMIN_WITH_ACCESS,
      SessionRole.USER_WITH_ACCESS,
      SessionRole.ADMIN,
      SessionRole.USER,
    ],
    Icon: PulseSvg,
    items: [
      {
        path: brandRoutes.BRAND_HEALTH.OVERVIEW,
        label: "Overview",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      {
        path: brandRoutes.BRAND_HEALTH.MIND_SHARE,
        label: "Mind Share",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      {
        path: brandRoutes.BRAND_HEALTH.OWNED_AND_ORGANIC,
        label: "Owned & Organic",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
    ],
  },
  {
    path: brandRoutes.BRAND_VALUE.OVERVIEW,
    label: "Brand Value",
    availableTo: [
      SessionRole.SUPER_ADMIN,
      SessionRole.ADMIN,
      SessionRole.USER,
      SessionRole.ADMIN_WITH_ACCESS,
      SessionRole.USER_WITH_ACCESS,
    ],
    Icon: ValueSvg,
    items: [
      {
        path: brandRoutes.BRAND_VALUE.OVERVIEW,
        label: "Overview",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      /* https://linear.app/marathon-data/issue/MD-2250/hide-cohortvisualization-for-mvp */
      // {
      //   path: brandRoutes.BRAND_VALUE.COHORT_ANALYSIS,
      //   label: "Cohort Analysis",
      //   availableTo: [
      //     SessionRole.SUPER_ADMIN,
      //     SessionRole.ADMIN_WITH_ACCESS,
      //     SessionRole.USER_WITH_ACCESS,
      //   ],
      // },
    ],
  },
  {
    path: brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION,
    label: "Brand Media",
    availableTo: [
      SessionRole.SUPER_ADMIN,
      SessionRole.ADMIN,
      SessionRole.USER,
      SessionRole.ADMIN_WITH_ACCESS,
      SessionRole.USER_WITH_ACCESS,
    ],
    Icon: DataSvg,
    items: [
      {
        path: brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION,
        label: "Paid Media Optimization",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      {
        path: brandRoutes.BRAND_MEDIA.ORGANIC_SOCIAL_POSTS,
        label: "Organic Social Posts",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
    ],
  },
] as const

export type TSetupSidebarItem = Pick<
  TSidebarItemProps,
  "Icon" | "label" | "path" | "items" | "availableTo"
>

export const setupSidebarItems: TSetupSidebarItem[] = [
  {
    path: brandRoutes.SETUP.ROOT,
    label: "Setup",
    availableTo: [
      SessionRole.SUPER_ADMIN,
      SessionRole.ADMIN,
      SessionRole.USER,
      SessionRole.ADMIN_WITH_ACCESS,
      SessionRole.USER_WITH_ACCESS,
    ],
    Icon: SettingsSvg,
    items: [
      {
        path: brandRoutes.SETUP.KEYWORDS,
        label: "Keywords + Competitors",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
      {
        path: brandRoutes.SETUP.CONNECTORS,
        label: "Connectors",
        availableTo: [
          SessionRole.SUPER_ADMIN,
          SessionRole.ADMIN,
          SessionRole.USER,
          SessionRole.ADMIN_WITH_ACCESS,
          SessionRole.USER_WITH_ACCESS,
        ],
      },
    ],
  },
] as const
