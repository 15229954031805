import { useMemo, useRef } from "react"
import camelCase from "lodash/camelCase"
import startCase from "lodash/startCase"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { toCurrency } from "~/ui-rtk/utils/number-utils"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export type CohortVisualizationColumnType =
  | "auto"
  | "number"
  | "date"
  | "currency"

export type CohortVisualizationColumn = {
  key: string
  header?: string
  type?: CohortVisualizationColumnType
  width?: number
}

export type CohortVisualizationProps = {
  metrics: any[]
  realizedLabelText: string
  forecastLabelText: string
  realizedCellColor: string
  forecastCellColor: string
  compareUniqueKey?: string
  compareMode?: boolean
  columnConfig?: Array<CohortVisualizationColumn>
  className?: string
}

const EMPTY_DATA = { "": "" }
const LOADING_DATA = Array(20).fill(EMPTY_DATA)

const formatValue = (
  value: string | number,
  type?: CohortVisualizationColumnType,
  isTotal = false,
) => {
  if (type === "date") {
    if (isTotal || !value) {
      return value
    }
    return dayjs(value).format(`MMM 'YY`)
  }

  const isNumber = typeof value === "number"
  const isNaN = !isNumber || Number.isNaN(Number(value))

  if (!isNaN && isNumber && type === "currency") {
    return toCurrency(value)
  } else if (!isNaN && (isNumber || type === "number")) {
    return Number(value).toLocaleString("en-US", { maximumFractionDigits: 0 })
  }

  return value
}

const getCellColor = (
  row: { [key: string]: string | number },
  column: string,
  forecastCellColor: string,
  realizedCellColor: string,
) => {
  const monthNumber = parseInt(column.replace("month", ""), 10)
  if (Number.isNaN(Number(monthNumber)) || row === EMPTY_DATA) {
    return "transparent"
  }
  return row[`isForecastMonth${monthNumber}`]
    ? forecastCellColor
    : realizedCellColor
}

export default function CohortVisualization({
  metrics,
  realizedLabelText,
  forecastLabelText,
  realizedCellColor,
  forecastCellColor,
  compareUniqueKey,
  compareMode = false,
  columnConfig,
  className,
}: CohortVisualizationProps) {
  const tableRef = useRef<HTMLTableSectionElement>(null)
  const data = useMemo(
    () =>
      compareMode && compareUniqueKey
        ? (metrics[0] as Record<string, any>[])
        : (metrics as Record<string, any>[]),
    [compareMode, compareUniqueKey, metrics],
  )

  const compareData = useMemo(
    () =>
      compareMode && compareUniqueKey
        ? (metrics[1] as Record<string, any>[])
        : null,
    [compareMode, compareUniqueKey, metrics],
  )

  const cellStyles = useMemo(() => {
    const colors: Record<string, { backgroundColor: string }>[] = data.map(
      row => {
        const rowColors: Record<string, { backgroundColor: string }> = {}
        Object.keys(row).forEach(key => {
          rowColors[key] = {
            backgroundColor: getCellColor(
              row,
              key,
              forecastCellColor,
              realizedCellColor,
            ),
          }
        })
        return rowColors
      },
    )
    return colors
  }, [data, forecastCellColor, realizedCellColor])

  const noData =
    !data || data.length === 0 || (compareMode && metrics[0].length === 0)

  const columns = useMemo<CohortVisualizationColumn[]>(
    () =>
      columnConfig ||
      (noData ? Object.keys(LOADING_DATA[0]) : Object.keys(data[0])).map(
        col => ({
          key: col,
          header: startCase(camelCase(col)),
          type: "auto",
        }),
      ),
    [columnConfig, noData, data],
  )

  const totals = useMemo(
    () =>
      data
        ? data.reduce((acc, item) => {
            for (const key in item) {
              if (
                key !== "company_modeled_metrics_monthly.engagement_date.month"
              ) {
                acc[key] = (acc[key] || 0) + item[key]
              } else {
                acc[key] = "Totals"
              }
            }
            return acc
          }, {})
        : null,
    [data],
  )

  const realizedCellStyles = useMemo(
    () => ({
      background: realizedCellColor,
    }),
    [realizedCellColor],
  )

  const forecastCellStyles = useMemo(
    () => ({
      background: forecastCellColor,
    }),
    [forecastCellColor],
  )

  return (
    <div
      className={cn(
        "relative flex flex-col justify-between overflow-hidden",
        className,
      )}
    >
      <div ref={tableRef}>
        <div className="gap-1 mx-4 my-2">
          <div className="flex gap-2 my-1">
            <div className="w-5.5 h-5.5" style={realizedCellStyles}></div>
            <caption>{realizedLabelText}</caption>
          </div>
          <div className="flex gap-2 my-1">
            <div className="w-5.5 h-5.5" style={forecastCellStyles}></div>
            <caption>{forecastLabelText}</caption>
          </div>
        </div>
        <div>
          <table
            className={`table table-zebra ${noData ? "overflow-hidden" : ""}`}
          >
            <thead>
              <tr>
                {columns.map(column => (
                  <th id={`key-${column.key}`} key={`${column.key}`}>
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(noData ? LOADING_DATA : data).map((row, index: number) => {
                const compareRow =
                  compareMode && compareUniqueKey
                    ? compareData?.find(
                        compareRow =>
                          compareRow[compareUniqueKey] ===
                          row[compareUniqueKey],
                      )
                    : null
                return (
                  <tr key={index}>
                    {columns.map(column => (
                      <td
                        style={cellStyles[index][column.key]}
                        key={`${column.key}-${index}`}
                      >
                        {formatValue(row[column.key], column.type)}
                        {compareMode &&
                        ["currency", "number", "date"].includes(
                          `${column.type}`,
                        ) ? (
                          <span className="text-gray-light-2">
                            &nbsp;(
                            {compareRow
                              ? formatValue(compareRow[column.key], column.type)
                              : "N/A"}
                            )
                          </span>
                        ) : null}
                      </td>
                    ))}
                  </tr>
                )
              })}
              <tr key="row-totals">
                {columns.map(column => (
                  <td key={`${column.key}-row-totals`}>
                    {totals &&
                      formatValue(totals[column.key], column.type, true)}
                    {compareMode &&
                    ["currency", "number", "date"].includes(
                      `${column.type}`,
                    ) ? (
                      <span className="text-gray-light-2">&nbsp;</span>
                    ) : null}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
