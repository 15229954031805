import { SOURCE_IDS } from "./sources"

export const SORT_BY = {
  FOLLOWER: "follows",
  SHARES: "shares",
  COMMENTS: "comments",
  REACTIONS: "likes",
  SAVES: "saves",
}

export const BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP =
  {
    [SOURCE_IDS.INSTAGRAM]: SOURCE_IDS.META,
    [SOURCE_IDS.INSTAGRAM_ADS]: SOURCE_IDS.META,
    [SOURCE_IDS.FACEBOOK]: SOURCE_IDS.META,
    [SOURCE_IDS.TIKTOK]: SOURCE_IDS.TIKTOK,
    [SOURCE_IDS.TIKTOK_ADS]: SOURCE_IDS.TIKTOK,
    [SOURCE_IDS.YOUTUBE]: SOURCE_IDS.YOUTUBE,
    [SOURCE_IDS.FACEBOOK_ADS]: SOURCE_IDS.META,
    [SOURCE_IDS.META]: SOURCE_IDS.META,
  }
