import React, { useEffect, useState } from "react"
import ReactCardFlip from "react-card-flip"

type FlipCardProps = {
  children: [React.ReactNode, React.ReactNode]
}

const FlipCard: React.FC<FlipCardProps> = ({ children }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 550)

  const handleMouseEnter = () => {
    setIsFlipped(true)
  }

  const handleMouseLeave = () => {
    setIsFlipped(false)
  }

  const handleToggleFlip = () => {
    setIsFlipped(prev => !prev)
  }

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 550)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  return (
    <div
      className="w-full"
      onMouseEnter={!isMobile ? handleMouseEnter : undefined}
      onMouseOver={!isMobile ? handleMouseEnter : undefined}
      onMouseLeave={!isMobile ? handleMouseLeave : undefined}
      onClick={isMobile ? handleToggleFlip : undefined}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        {children}
      </ReactCardFlip>
    </div>
  )
}

export default FlipCard
