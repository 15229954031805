import { CreateMediaCategoryDto } from "~/api/dto/media-category/create-media-category.dto"
import { MediaCategoryDto } from "~/api/dto/media-category/media-category.dto"
import { emptySplitApi as api } from "./emptyApi"

export type MediaCategoryControllerGetMediaCategoriesApiResponse =
  /** status 200  */ MediaCategoryDto[]
export type MediaCategoryControllerGetMediaCategoriesApiResponseArg = void
export type MediaCategoryControllerCreateMediaCategoryApiResponse =
  /** status 200  */ MediaCategoryDto
export type MediaCategoryControllerCreateMediaCategoryApiResponseArg = {
  body: CreateMediaCategoryDto
}
export type MediaCategoryControllerGetMediaCategoryApiResponse =
  /** status 200  */ MediaCategoryDto
export type MediaCategoryControllerGetMediaCategoryApiResponseArg = {
  id: string
}

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    mediaCategoryControllerGetMediaCategories: build.query<
      MediaCategoryControllerGetMediaCategoriesApiResponse,
      MediaCategoryControllerGetMediaCategoriesApiResponseArg
    >({
      query: () => ({ url: `/media-category` }),
    }),
    mediaCategoryControllerPostMediaCategories: build.mutation<
      MediaCategoryControllerCreateMediaCategoryApiResponse,
      MediaCategoryControllerCreateMediaCategoryApiResponseArg
    >({
      query: queryArg => ({
        url: `/media-category`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    mediaCategoryControllerGetMediaCategory: build.query<
      MediaCategoryControllerGetMediaCategoryApiResponse,
      MediaCategoryControllerGetMediaCategoryApiResponseArg
    >({
      query: queryArg => ({
        url: `/media-category/${queryArg.id}`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useMediaCategoryControllerGetMediaCategoryQuery,
  useMediaCategoryControllerGetMediaCategoriesQuery,
  useMediaCategoryControllerPostMediaCategoriesMutation,
} = injectedRtkApi

export { injectedRtkApi as generatedApi }
