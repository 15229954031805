import { useCallback } from "react"
import { toast } from "react-toastify"

import {
  ConnectionLinkControllerCreateApiArg,
  useConnectionLinkControllerCreateMutation as useCreateConnectionLink,
} from "~/ui-rtk/api/connectionLinkApi"

export const useConnect = () => {
  const [createLinkAsync, { isLoading }] = useCreateConnectionLink()

  const createConnectionLink = useCallback(
    async (connectorId: string, recipientEmail?: string) => {
      if (!connectorId) {
        throw new Error("Missing connector ID")
      }

      const createLinkArg: ConnectionLinkControllerCreateApiArg = {
        createConnectionLinkDto: {
          connectorId,
          recipientEmail,
        },
      }

      try {
        const connectionLink = await createLinkAsync(createLinkArg).unwrap()
        return connectionLink
      } catch (err) {
        if (err instanceof Error) {
          toast.error(err.message)
          return
        }

        toast.error("Unexpected error occurred. Please, try again.")
      }
    },
    [],
  )

  return {
    isLoading,
    createConnectionLink,
  }
}
