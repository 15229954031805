import ContentLoader from "react-content-loader"
import useRefetchDialog from "~/ui-rtk/hooks/refetch-dialog"
import useTailwindTheme from "~/ui-rtk/hooks/tailwind-theme"

export default function ChartSkeleton({ className }: { className?: string }) {
  const theme = useTailwindTheme()

  const refetchContent = useRefetchDialog()

  return (
    <div className={`${className} relative`}>
      {refetchContent}
      <ContentLoader
        speed={1}
        width="100%"
        height="100%"
        viewBox="0 0 1581 442"
        backgroundColor={theme.colors.background.dark}
        foregroundColor={theme.colors.background["dark-shade"]}
      >
        <path d="M 0 313.12 c 58.555 20.641 117.11 41.282 175.666 41.282 c 58.555 0 117.11 -346.162 175.665 -346.162 c 58.555 0 117.11 242.75 175.666 242.75 c 58.555 0 117.11 -36.75 175.665 -36.75 c 58.555 0 117.111 41.2 175.666 41.2 c 58.555 0 117.11 -20.6 175.665 -20.6 c 58.556 0 117.111 78.28 175.666 78.28 c 58.555 0 117.11 -121.787 175.665 -121.787 c 58.556 0 117.111 60.935 175.666 121.869 V 412 H 0 z" />
        <path d="M 0 313.12 c 58.555 20.641 117.11 41.282 175.666 41.282 c 58.555 0 117.11 -346.162 175.665 -346.162 c 58.555 0 117.11 242.75 175.666 242.75 c 58.555 0 117.11 -36.75 175.665 -36.75 c 58.555 0 117.111 41.2 175.666 41.2 c 58.555 0 117.11 -20.6 175.665 -20.6 c 58.556 0 117.111 78.28 175.666 78.28 c 58.555 0 117.11 -121.787 175.665 -121.787 c 58.556 0 117.111 60.935 175.666 121.869" />
      </ContentLoader>
    </div>
  )
}
