import useSources from "~/ui-rtk/hooks/sources"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { useAuthControllerCurrentUserQuery } from "~/ui-rtk/api/authApi"

export default function useConnect() {
  const {
    currentData: session,
    isLoading,
    refetch,
  } = useAuthControllerCurrentUserQuery()

  const { sources, refetch: refetchSources } = useSources()

  const handleRefetch = async () => {
    await Promise.all([refetch(), refetchSources()])
  }

  useEffect(() => {
    const context = {
      companyId: session?.currentCompany?.id,
      userId: session?.user?.id,
      dataSource: session?.user?.dataSource,
    }
    Sentry.setContext("current-user-and-company", context)
  }, [session])

  return {
    session,
    isLoading,
    sources,
    refetch: handleRefetch,
  }
}
