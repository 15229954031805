import React, { useMemo } from "react"
import ReactDatePicker, { type ReactDatePickerProps } from "react-datepicker"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

import { Input } from "~/ui-rtk/components/ui/controls"
import { Popover } from "~/ui-rtk/components/ui/common"
import { ChevronDownSvg } from "~/ui-rtk/components/ui/svg/arrow"

import { MONTH_AGO_RESTRICTION } from "~/ui-rtk/constants/restrictions"

import { getYearLabel } from "./utils"
import { isDateMoreRecentThanMonthsAgo } from "~/ui-rtk/utils/date-utils"

import "react-datepicker/dist/react-datepicker.css"
import "./index.scss"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TYearPickerClasses = {
  input: string
}

type TYearPickerProps = {
  startOfYear: Date
  classes?: Partial<TYearPickerClasses>
  onChange: (date: Date) => void
} & Omit<ReactDatePickerProps, "onChange">

const YearPicker: React.FC<TYearPickerProps> = ({
  startOfYear,
  minDate,
  onChange,
  classes,
  ...props
}) => {
  const $minDate = useMemo(() => {
    if (
      !minDate ||
      isDateMoreRecentThanMonthsAgo(minDate, MONTH_AGO_RESTRICTION)
    ) {
      return dayjs().subtract(MONTH_AGO_RESTRICTION, "month").toDate()
    }
    return minDate
  }, [minDate])

  const handlePickDate = (callback: () => void) => (date: Date) => {
    void onChange(date)
    void callback()
  }

  return (
    <Popover
      relative
      handler={() => (
        <Input
          readOnly
          value={getYearLabel(startOfYear)}
          appendNode={<ChevronDownSvg className="cursor-pointer" />}
          className={cn("min-w-72 max-h-12.5 rounded-md", classes?.input)}
        />
      )}
    >
      {closePopup => (
        <ReactDatePicker
          calendarClassName="ui-rtk-yearly-picker mt-2"
          selected={startOfYear}
          showYearPicker
          inline
          minDate={$minDate}
          onChange={handlePickDate(closePopup)}
          calendarStartDay={1}
          {...props}
        />
      )}
    </Popover>
  )
}

export default YearPicker
