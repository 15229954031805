import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/16/solid"
import React, { type PropsWithChildren, useState, Fragment } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TListBoxProps = {
  options: any[]
  onSelect?: (value: any) => void
  value: any
  defaultValue?: any
  label?: string
  placeholder?: string
  className?: string
} & PropsWithChildren

const ListBox: React.FC<TListBoxProps> = ({
  options,
  onSelect,
  value: _value,
  defaultValue,
  label,
  placeholder,
  className,
}) => {
  const isControlled = typeof _value !== "undefined"
  const hasDefaultValue = typeof defaultValue !== "undefined"

  const [internalValue, setInternalValue] = useState<string[]>(
    hasDefaultValue ? defaultValue : null,
  )

  const selected = isControlled ? _value : internalValue

  const onChange = (value: any) => {
    if (onSelect) {
      onSelect(value)
    }

    if (!isControlled) {
      setInternalValue(value)
    }
  }

  return (
    <div className="w-full flex items-center space-x-2">
      {label && <p className="whitespace-nowrap">{label}</p>}
      <div className={cn("relative flex-1", className)}>
        <Listbox value={selected} onChange={onChange}>
          <Listbox.Button
            className={cn(
              "relative block w-full rounded-lg bg-basic-dark-blue py-1.5 pr-8 pl-3 text-left text-sm/6 text-white",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
            )}
          >
            {selected || placeholder}
            <ChevronDownIcon
              className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
              aria-hidden="true"
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={cn(
                "absolute w-full z-50 rounded-xl border border-white/15 bg-basic-dark-blue p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              )}
            >
              {options.map(option => (
                <Listbox.Option
                  key={option}
                  value={option}
                  className="group flex items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-basic-dark-blue/10 cursor-pointer"
                >
                  {({ selected }) => (
                    <Fragment>
                      {selected && <CheckIcon className="size-4 fill-white" />}
                      <div className="text-sm/6 text-white">{option}</div>
                    </Fragment>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
    </div>
  )
}

export default ListBox
