import { VISUALIZATION_TYPE } from "../constants/charts"
import {
  TDashboardItem,
  TDashboardItemWidget,
  TDashboardItemsSet,
  WIDGET_TYPE,
} from "../constants/dashboard"

export const flatContentWidgets = (
  items: TDashboardItemsSet,
): TDashboardItemWidget[] =>
  items.reduce(
    (accumulator: TDashboardItemWidget[], value: TDashboardItem) =>
      accumulator.concat(value.items),
    [],
  )

export const getContentWidgetIds = (
  items: TDashboardItemsSet,
): (VISUALIZATION_TYPE | WIDGET_TYPE)[] =>
  flatContentWidgets(items).map(({ widget }) => widget)
