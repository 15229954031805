import { useEffect, useState } from "react"
import { Tab } from "@headlessui/react"
import { Link, useLocation } from "react-router-dom"
import { P } from "~/ui-rtk/components/ui/typography"

import "./index.scss"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { SessionRole } from "~/api/dto/auth/session-role"
import { brandRoutes } from "~/ui-rtk/constants/routes"

export default function SettingsTabs() {
  const sessionRole = useAppSelector(selectUserSessionRole)
  const isUserManagementGranted = [
    SessionRole.SUPER_ADMIN,
    SessionRole.ADMIN,
    SessionRole.ADMIN_WITH_ACCESS,
  ].includes(sessionRole)
  const isCompanyManagementGranted = [SessionRole.SUPER_ADMIN].includes(
    sessionRole,
  )

  const location = useLocation()
  const allSettingsLinks = [
    {
      label: "Profile",
      disabled: false,
      to: brandRoutes.SETTINGS.PROFILE,
    },
    {
      label: "Password",
      disabled: false,
      to: brandRoutes.SETTINGS.PASSWORD,
    },
    {
      label: "Users",
      disabled: false,
      userManagementPermissionRequired: true,
      to: brandRoutes.SETTINGS.USERS,
    },
    {
      label: "Company Settings",
      disabled: false,
      companyManagementPermissionRequired: true,
      to: brandRoutes.SETTINGS.COMPANY,
    },
    {
      label: "Brand Conversion Events",
      disabled: false,
      companyManagementPermissionRequired: true,
      to: brandRoutes.SETTINGS.BRAND_EVENTS,
    },
  ]
  const SettingsLinks = allSettingsLinks
    .filter(
      ({ userManagementPermissionRequired }) =>
        !userManagementPermissionRequired || isUserManagementGranted,
    )
    .filter(
      ({ companyManagementPermissionRequired }) =>
        !companyManagementPermissionRequired || isCompanyManagementGranted,
    )
  const findActiveIndex = () =>
    SettingsLinks.findIndex(({ to }) => location.pathname === to)
  const [index, setIndex] = useState(findActiveIndex())

  useEffect(() => {
    setIndex(findActiveIndex())
  }, [location])

  return (
    <div className="border-b ui-rtk-settings-tabs border-b-basic-blue">
      <Tab.Group selectedIndex={index} onChange={setIndex}>
        <Tab.List>
          {SettingsLinks.map(link => (
            <Tab disabled={link.disabled} key={link.to}>
              {link.disabled ? (
                <P className="p-3">{link.label}</P>
              ) : (
                <Link aria-disabled={link.disabled} to={link.to}>
                  <P className="pt-3 pb-2.5 px-4 rounded-t-md border-b hover:bg-basic-dark-blue hover:border-b-basic-blue border-b-transparent">
                    {link.label}
                  </P>
                </Link>
              )}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  )
}
