import { useCallback } from "react"
import { useLazyCompanyConnectorControllerGetPresignedUrlQuery } from "~/ui-rtk/api/companyConnectorApi"
import { Operation } from "~/ui-rtk/shared/types"
import { uploadFileByPreSignedUrl } from "~/ui-rtk/utils/http-utils"

export const useS3Upload = () => {
  const [getPreSignedUrlAsync, { isLoading }] =
    useLazyCompanyConnectorControllerGetPresignedUrlQuery()

  const createPreSignedUrl = useCallback(async (companyConnectorId: string) => {
    try {
      const response = await getPreSignedUrlAsync({
        id: companyConnectorId,
      }).unwrap()

      return response
    } catch (error) {
      const message = `Error creating pre-signed URL for companyConnectorId(${companyConnectorId})`
      console.error(`${message}: ${error}`)
      throw new Error(message)
    }
  }, [])

  const uploadFileByUrl = useCallback(
    async (
      file: File,
      connectorId: string,
    ): Promise<Operation<string>> | never => {
      try {
        const { key, presignedUrl } = await createPreSignedUrl(connectorId)
        const isUploaded = await uploadFileByPreSignedUrl(presignedUrl, file)

        if (!isUploaded) {
          return {
            success: false,
            message: `Failed to upload ${file.name} file.`,
          }
        }

        return {
          success: true,
          data: key,
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}: ${error}`)
        throw new Error(`Error uploading file ${file.name}`)
      }
    },
    [],
  )

  const uploadFilesToS3 = useCallback(
    async (files: File[], connectorId: string) => {
      const promises = files.map(file => uploadFileByUrl(file, connectorId))

      try {
        const keys: string[] = []
        const operations = await Promise.allSettled(promises)
        for (const operation of operations) {
          if (operation.status === "rejected") {
            throw new Error(`Upload failed for a file: ${operation.reason}`)
          }

          if (!operation.value.success) {
            throw new Error("Oops.. Failed to upload files")
          }

          keys.push(operation.value.data)
        }

        return keys
      } catch (error) {
        console.error(`Error uploading files: ${error}`)
        throw new Error("Failed to upload files to S3")
      }
    },
    [],
  )

  return {
    isLoading,
    uploadFilesToS3,
  }
}
