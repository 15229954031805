import { safeJSONParse } from "./safeJsonParse"

export enum LocalStorageKeys {
  CONNECTION_FLOW_SKIPPED_SERVICES = "connection-flow-skipped-services",
  GUEST_TOKEN = "guest-token",
  SIDEBAR_STATE = "md-sidebar",
  REMEMBER_ME = "md-remember",
  DASHBOARD_DATES = "md-dashboard-dates",
  DASHBOARD_FILTERS = "md-dashboard-filter",
  MONEY_WARN_DIALOG_SHOWN = "MONEY_WARN_DIALOG_SHOWN",
  LOCAL_STORAGE_DONT_SHOW_AGAIN_SAVE_KEYWORD_DIALOG_NOTIFICATON_KEY = "LOCAL_STORAGE_DONT_SHOW_AGAIN_SAVE_KEYWORD_DIALOG_NOTIFICATON_KEY",
  DONT_SHOW_INACCURATE_DATES_WARNING = "LOCAL_STORAGE_DONT_SHOW_INACCURATE_DATES_WARNING",
  TABLE_VISUALIZATION_ITEMS_PER_PAGE = "LOCAL_STORAGE_TABLE_VISUALIZATION_ITEMS_PER_PAGE",
}

export function setLocalStorageValue<T>(key: LocalStorageKeys, value: T): void {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(value))
  } else {
    console.warn("localStorage is not available")
  }
}

export function unsetLocalStorageValue(key: LocalStorageKeys): void {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key)
  } else {
    console.warn("localStorage is not available")
  }
}

export function getLocalStorageValue<T>(key: LocalStorageKeys): T | null {
  if (typeof window !== "undefined") {
    const value = window.localStorage.getItem(key)
    if (value === null) {
      return null
    }
    return safeJSONParse<T>(value)
  }
  return null
}

export function useLocalStorage<T>(
  key: LocalStorageKeys,
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>]
export function useLocalStorage<T>(
  key: LocalStorageKeys,
  defaultValue?: null,
): [T | null, React.Dispatch<React.SetStateAction<T | null>>]
export function useLocalStorage<T>(
  key: LocalStorageKeys,
  defaultValue: T | null = null,
): [T | null, (value: T) => void] {
  return [
    getLocalStorageValue<T>(key) ?? defaultValue,
    (val: T) => setLocalStorageValue(key, val),
  ]
}
