import React, { useState, memo } from "react"
import { uniqueId } from "lodash"

import ToggleButton from "../ToggleButton"
import { WarnSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { Loader } from "~/ui-rtk/components/ui/common"
import { useConnect } from "./connect"

import { formatDate } from "./utils"
import { renderHealthIndicator, renderStatusIcon } from "../../utils"

type TConnectorStatusProps = {
  companyConnectorId: string
}

const ConnectorStatus: React.FC<TConnectorStatusProps> = ({
  companyConnectorId,
}) => {
  const { connectorInfo, isLoading } = useConnect(companyConnectorId)
  const [showStatusInfo, setShowStatusInfo] = useState(true)

  const toggleStatusInfo = () => {
    setShowStatusInfo(prev => !prev)
  }

  if (isLoading) {
    // TODO: Add lazy loader
    return <Loader />
  }

  const renderStatusIndicator = () => {
    if (!connectorInfo?.status) {
      return <p className="text-platinum">Not provided</p>
    }
    return renderHealthIndicator(connectorInfo.status)
  }

  return (
    <article className="max-w-89 w-full mb-7 space-y-5">
      <div className="flex items-center justify-between">
        <b className="text-4 leading-7 font-bold text-platinum">
          Connector Status:
        </b>
        {renderStatusIndicator()}
        <ToggleButton onToggle={toggleStatusInfo} initial={showStatusInfo} />
      </div>

      {showStatusInfo &&
        (connectorInfo ? (
          <section className="w-64 mx-auto space-y-4">
            <p className="flex justify-between">
              Last Update:{" "}
              {connectorInfo.lastSuccessfulSyncDate
                ? formatDate(connectorInfo.lastSuccessfulSyncDate)
                : "Pending"}{" "}
              {connectorInfo.isLastSyncOutdated
                ? renderStatusIcon("unhealthy")
                : renderStatusIcon("healthy")}
            </p>
            {connectorInfo.warnings && connectorInfo.warnings.length > 0 ? (
              <div className="flex flex-col gap-2 text-left">
                <p>List of warnings:</p>
                <ul className="ml-4">
                  {connectorInfo.warnings.map(warning => (
                    <li
                      key={uniqueId()}
                      className="text-3.5 text-platinum flex items-center gap-2"
                    >
                      <WarnSvg fill="yellow-info" />
                      <p>{warning.message}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </section>
        ) : (
          <p>Failed to load connection details</p>
        ))}
    </article>
  )
}

export default memo(ConnectorStatus)
