import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ValueSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4992 2.94531C13.3276 2.94531 13.9992 3.61689 13.9992 4.44531V13.4468C13.9992 14.2753 13.3276 14.9468 12.4992 14.9468H3.5C2.67157 14.9468 2 14.2753 2 13.4468V4.44531C2 3.61689 2.67157 2.94531 3.5 2.94531H12.4992ZM12.4992 3.94531H3.5C3.22386 3.94531 3 4.16917 3 4.44531V13.4468C3 13.723 3.22386 13.9468 3.5 13.9468H12.4992C12.7754 13.9468 12.9992 13.723 12.9992 13.4468V4.44531C12.9992 4.16917 12.7754 3.94531 12.4992 3.94531Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66646 5.27893C7.66646 5.09484 7.8157 4.9456 7.9998 4.9456C8.18389 4.9456 8.33313 5.09484 8.33313 5.27893V5.44407C9.23407 5.5431 9.93499 6.30671 9.93499 7.23403L9.93499 7.7234C9.93499 7.9075 9.78576 8.05673 9.60166 8.05674C9.41757 8.05674 9.26833 7.9075 9.26833 7.7234L9.26832 7.23403C9.26832 6.67553 8.86463 6.21132 8.33313 6.11727V8.66988L8.83586 8.89524C9.50462 9.19503 9.93499 9.85958 9.93499 10.5925V10.6513C9.93499 11.5781 9.23462 12.3421 8.33313 12.4412V12.6146C8.33313 12.7987 8.18389 12.948 7.9998 12.948C7.8157 12.948 7.66646 12.7987 7.66646 12.6146V12.3908C6.89901 12.1849 6.33333 11.4845 6.33333 10.6513V10.1697C6.33333 9.98561 6.48257 9.83637 6.66667 9.83637C6.85076 9.83637 7 9.98561 7 10.1697V10.6513C7 11.1111 7.27356 11.5067 7.66646 11.6848V9.10161L7.43473 8.99773C6.76459 8.69732 6.33333 8.03139 6.33333 7.297V7.23404C6.33333 6.40123 6.89864 5.70047 7.66646 5.49454V5.27893ZM8.33313 11.768V9.40047L8.56316 9.50358C8.99221 9.69592 9.26832 10.1223 9.26832 10.5925V10.6513C9.26832 11.2095 8.86493 11.6739 8.33313 11.768ZM7.66646 6.20049C7.27341 6.37864 7 6.77441 7 7.23404V7.297C7 7.75357 7.2595 8.16893 7.66646 8.37009V6.20049Z"
      fill={fill}
    />
  </svg>
))
