import { SOURCES_MAP } from "~/ui-rtk/constants/sources"
import { safeDivide, toCoef, toCurrency } from "~/ui-rtk/utils/number-utils"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import { TSource } from "~/ui-rtk/hooks/sources"
import { type TSource as TTableVSource } from "~/ui-rtk/components/ui/charts/TableVisualization/types"

export const createStatsItem = (
  source: TSource,
  spend: number,
  brandValue: number,
  purchases: number,
  brandValueOverSpend: number,
) => {
  const totalValue = brandValue + purchases
  return {
    Source: renderSourceIcon({
      name: source.name,
      icon: source.icon,
    } as TTableVSource),
    Spend: toCurrency(spend),
    "Brand Value": toCurrency(brandValue),
    "Brand ROAS": toCoef(brandValueOverSpend),
    "DR Revenue": toCurrency(purchases),
    "DR ROAS": toCoef(safeDivide(purchases, spend)),
    "Total Value": toCurrency(totalValue),
    "Total ROAS": toCoef(safeDivide(totalValue, spend)),
  }
}

export const getSource = (key: keyof typeof SOURCES_MAP) =>
  SOURCES_MAP[key] as unknown as TSource
