import Card from "~/ui-rtk/components/ui/common/Card"
import Dialog from "~/ui-rtk/components/ui/common/Dialog"

import { Button } from "~/ui-rtk/components/ui/controls"
import { PropsWithChildren } from "react"
import { H3 } from "../../typography"
import { ErrorSvg, InfoSvg, SuccessSvg, WarnSvg } from "../../svg/essentials"

export enum DIALOG {
  WARNING,
  INFO,
  SUCCESS,
  ERROR,
}

export type TDialogProps = {
  isOpen: boolean
  handleClose: () => void
  type?: DIALOG
  width?: number
  hideTitle?: boolean
}

const classNameMap = {
  [DIALOG.WARNING]: "border-states-warn",
  [DIALOG.INFO]: "border-states-info",
  [DIALOG.SUCCESS]: "border-states-success",
  [DIALOG.ERROR]: "border-states-error",
}

const titleMap = {
  [DIALOG.WARNING]: "Warning!",
  [DIALOG.INFO]: "Info!",
  [DIALOG.SUCCESS]: "Success!",
  [DIALOG.ERROR]: "Error!",
}

const iconSize = 20

const iconsMap = {
  [DIALOG.WARNING]: <WarnSvg size={iconSize} fill="states.warn" />,
  [DIALOG.INFO]: <InfoSvg size={iconSize} fill="states.info" />,
  [DIALOG.SUCCESS]: <SuccessSvg size={iconSize} fill="states.success" />,
  [DIALOG.ERROR]: <ErrorSvg size={iconSize} fill="states.error" />,
}

export default function InfoDialog({
  isOpen,
  handleClose,
  children,
  width,
  hideTitle = false,
  type = DIALOG.INFO,
}: PropsWithChildren<TDialogProps>) {
  return (
    <Dialog width={width} isOpen={isOpen} handleClose={handleClose}>
      <Card rounded className={`${classNameMap[type]} p-5`}>
        {!hideTitle && (
          <div className="grid grid-cols-8 gap-4">
            <div>{iconsMap[type]}</div>
            <div className="col-span-7">
              <H3 className="text-start">{titleMap[type]}</H3>
            </div>
          </div>
        )}
        <div className="mt-2">{children}</div>
        <div className="mt-4 text-center flex justify-center">
          <Button
            variant={{ variant: "solid", color: "blue" }}
            className={`btn btn-wide ${classNameMap[type]} flex align-center w-full`}
            size="lg"
            onClick={handleClose}
          >
            Ok
          </Button>
        </div>
      </Card>
    </Dialog>
  )
}
