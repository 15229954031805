/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createPortal } from "react-dom"
import { ToastContainer } from "react-toastify"

const ToastProvider = () =>
  createPortal(
    <ToastContainer position="top-center" autoClose={5000} closeButton />,
    document.getElementById("toast-container")!,
  )

export default ToastProvider
