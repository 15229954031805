import React, { useState } from "react"

import { useConnect } from "./connect"
import { ConnectorDialog } from "~/ui-rtk/components/features/connectors"
import { FileUpload, Button } from "~/ui-rtk/components/ui/controls"

import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"

import type { CustomerConnectorDialogFormPropsWithoutUri } from "../types"

type SephoraUploadForm = CustomerConnectorDialogFormPropsWithoutUri

const SephoraUploadForm: React.FC<SephoraUploadForm> = ({
  isOpen,
  companyConnectorId,
  onClose,
}) => {
  const { authenticate, uploadFilesToS3, handleError, isLoading } = useConnect()
  const [error, setError] = useState("")
  const [uploadFiles, setUploadFiles] = useState<File[]>([])

  const handleConnect = async () => {
    setError("")

    if (!companyConnectorId) {
      throw new Error("Missing company connector id")
    }

    if (!uploadFiles.length) {
      setError("You haven't uploaded any files yet")
      return
    }

    try {
      const keys = await uploadFilesToS3(uploadFiles, companyConnectorId)
      await authenticate(companyConnectorId, keys)
      onClose()
    } catch (err) {
      handleError(err)
    }
  }

  const handleUpload = (files: FileList | null) => {
    setError("")
    setUploadFiles(files ? Array.from(files) : [])
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.sephora}
      alt="Sephora Logo"
      title="Sephora Sales Volume"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p className="leading-5 text-left text-base text-platinum">
        Upload your latest Sephora SPS Commerce Sales volumn CSV file:
      </p>

      <div className="space-y-9 mb-2">
        <FileUpload
          onUpload={handleUpload}
          classes={{
            button:
              "bg-platinum/[.8] text-black hover:bg-platinum hover:text-black",
          }}
        />

        <Button
          variant={{ variant: "solid", color: "white" }}
          onClick={handleConnect}
          isLoading={isLoading}
          className="w-full"
        >
          Connect
        </Button>
      </div>
      {error ? (
        <span className="text-sm text-center text-error">{error}</span>
      ) : null}
    </ConnectorDialog>
  )
}

export default SephoraUploadForm
