import { emptySplitApi as api } from "./emptyApi"
import { SessionRole as Role } from "~/api/dto/auth/session-role.ts"
import { CompanyUserNotification } from "~/api/dto/company-user/company-user-notification"
import { UserDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    companyUserControllerInviteUser: build.mutation<
      CompanyUserControllerInviteUserApiResponse,
      CompanyUserControllerInviteUserApiArg
    >({
      query: queryArg => ({
        url: `/company-user/invite`,
        method: "POST",
        body: queryArg.inviteUserDto,
      }),
    }),
    companyUserControllerGetAllCompanyUsers: build.query<
      CompanyUserControllerGetAllCompanyUsersApiResponse,
      CompanyUserControllerGetAllCompanyUsersApiArg
    >({
      query: () => ({ url: `/company-user` }),
      providesTags: ["UserCompany"],
    }),
    companyUserControllerGetCompanyUserById: build.query<
      CompanyUserControllerGetCompanyUserByIdApiResponse,
      CompanyUserControllerGetCompanyUserByIdApiArg
    >({
      query: queryArg => ({ url: `/company-user/${queryArg.id}` }),
    }),
    companyUserControllerUpdateCompanyUserRole: build.mutation<
      CompanyUserControllerUpdateCompanyUserRoleApiResponse,
      CompanyUserControllerUpdateCompanyUserRoleApiArg
    >({
      query: queryArg => ({
        url: `/company-user/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyUserDto,
      }),
    }),
    companyUserControllerRemoveUserFromCompany: build.mutation<
      CompanyUserControllerRemoveUserFromCompanyApiResponse,
      CompanyUserControllerRemoveUserFromCompanyApiArg
    >({
      query: queryArg => ({
        url: `/company-user/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    companyUserControllerNotify: build.mutation<
      CompanyUserControllerNotifyApiResponse,
      CompanyUserControllerNotifyApiArg
    >({
      query: queryArg => ({
        url: `/company-user/${queryArg.id}/notify`,
        method: "POST",
        body: queryArg.companyUserNotifyDto,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }

export type CompanyUserControllerNotifyApiResponse = void
export type CompanyUserControllerNotifyApiArg = {
  id: string
  companyUserNotifyDto: CompanyUserNotifyDto
}

export type CompanyUserNotifyDto = {
  notificationType: CompanyUserNotification
}

export type CompanyUserControllerInviteUserApiResponse =
  /** status 200  */ UserDto
export type CompanyUserControllerInviteUserApiArg = {
  inviteUserDto: InviteUserDto
}
export type CompanyUserControllerGetAllCompanyUsersApiResponse =
  /** status 200  */ UserDto[]
export type CompanyUserControllerGetAllCompanyUsersApiArg = void
export type CompanyUserControllerGetCompanyUserByIdApiResponse =
  /** status 200  */ UserDto
export type CompanyUserControllerGetCompanyUserByIdApiArg = {
  id: string
}
export type CompanyUserControllerUpdateCompanyUserRoleApiResponse =
  /** status 200  */ UserDto
export type CompanyUserControllerUpdateCompanyUserRoleApiArg = {
  id: string
  updateCompanyUserDto: UpdateCompanyUserDto
}
export type CompanyUserControllerRemoveUserFromCompanyApiResponse =
  /** status 200  */ UserDto
export type CompanyUserControllerRemoveUserFromCompanyApiArg = {
  id: string
}
export type InviteUserDto = {
  name: string
  email: string
  role: Role
}
export type UpdateCompanyUserDto = {
  role: Role
}
export const {
  useCompanyUserControllerInviteUserMutation,
  useCompanyUserControllerGetAllCompanyUsersQuery,
  useCompanyUserControllerGetCompanyUserByIdQuery,
  useCompanyUserControllerUpdateCompanyUserRoleMutation,
  useCompanyUserControllerRemoveUserFromCompanyMutation,
  useCompanyUserControllerNotifyMutation,
} = injectedRtkApi
