import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const SwitchSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.03982 3.45938C9.33301 3.75198 9.33348 4.22685 9.04088 4.52004L5.30813 8.26023H20.5C20.9142 8.26023 21.25 8.59602 21.25 9.01023C21.25 9.42445 20.9142 9.76023 20.5 9.76023H3.50002C3.19681 9.76023 2.92343 9.57766 2.80726 9.29759C2.69108 9.01752 2.75497 8.69505 2.96916 8.48043L7.97916 3.46043C8.27177 3.16725 8.74664 3.16677 9.03982 3.45938ZM2.75002 14.9902C2.75002 14.576 3.08581 14.2402 3.50002 14.2402H20.5C20.8032 14.2402 21.0766 14.4228 21.1928 14.7029C21.309 14.9829 21.2451 15.3054 21.0309 15.52L16.0209 20.54C15.7283 20.8332 15.2534 20.8337 14.9602 20.5411C14.667 20.2485 14.6666 19.7736 14.9592 19.4804L18.6919 15.7402H3.50002C3.08581 15.7402 2.75002 15.4044 2.75002 14.9902Z"
      fill={fill}
    />
  </svg>
))
