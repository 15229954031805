import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export default function H3({
  children,
  className = "",
}: Props): ReturnType<FC<Props>> {
  const cs = ["text-xl font-bold", className].join(" ").trim()
  return <h3 className={cs}>{children}</h3>
}
