import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

import TokenExpiredView from "./TokenExpiredView"

import { onboardingRoutes } from "~/ui-rtk/constants/routes"
import { useAuthControllerConfirmEmailMutation } from "~/ui-rtk/api/authApi"

const ConfirmEmail = () => {
  const [confirmEmail] = useAuthControllerConfirmEmailMutation()

  const navigate = useStableNavigate()
  const [searchParams] = useSearchParams()
  const [message, setMessage] = useState("Confirming...")

  const token = searchParams.get("token")
  if (!token) {
    return <TokenExpiredView />
  }

  const redirect = (url: string) => {
    setTimeout(() => navigate(url), 2000)
  }

  useEffect(() => {
    const beginAction = async (token: string) => {
      try {
        await confirmEmail({
          confirmEmailDto: {
            token,
          },
        }).unwrap()
        void setMessage("Success! Redirecting...")
        void redirect(onboardingRoutes.PACKAGE)
      } catch (error) {
        toast.error("Email confirmation failed", {
          toastId: "email-confirmation-failed",
        })
        void setMessage("Failure! Redirecting...")
        void redirect(onboardingRoutes.CHECK_INBOX)
      }
    }

    if (token) {
      void beginAction(token)
    }
  }, [])

  return <div className="fixed top-20 left-4">{message}</div>
}

export default ConfirmEmail
