import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const SendSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5727 4.42474C19.0308 3.88209 17.9186 3.60487 16.1293 4.20132L7.64005 7.03107C7.63989 7.03112 7.6402 7.03101 7.64005 7.03107C6.2607 7.4925 5.28432 8.00847 4.66802 8.51778C4.04905 9.0293 3.86963 9.46459 3.86963 9.78355C3.86963 10.1023 4.04881 10.5368 4.66742 11.047C5.28354 11.555 6.25939 12.0691 7.63893 12.5282L9.54195 13.1625C9.55435 13.148 9.56738 13.1339 9.58106 13.1202L13.1611 9.53021C13.4535 9.23691 13.9284 9.23625 14.2217 9.52873C14.515 9.82122 14.5157 10.2961 14.2232 10.5894L10.7202 14.1022L11.4736 16.3626C11.9326 17.742 12.4474 18.7181 12.9561 19.3341C13.4668 19.9527 13.9024 20.1323 14.2221 20.1323C14.5419 20.1323 14.9774 19.9527 15.4882 19.3341C15.9969 18.7181 16.5115 17.7423 16.9705 16.363L19.8001 7.87431C19.8 7.87457 19.8001 7.87406 19.8001 7.87431C20.3917 6.08478 20.1154 4.96827 19.5727 4.42474ZM9.3292 14.6727L7.16533 13.9514C7.16521 13.9514 7.16546 13.9515 7.16533 13.9514C5.69005 13.4605 4.52439 12.8733 3.71309 12.2042C2.90419 11.5372 2.36963 10.7186 2.36963 9.78355C2.36963 8.84877 2.90396 8.02968 3.71248 7.36152C4.52361 6.6912 5.68881 6.10206 7.16421 5.60854L15.655 2.77829C17.6756 2.10474 19.5034 2.23252 20.6341 3.36487C21.7638 4.49626 21.8922 6.32528 21.2242 8.34529L21.2236 8.34697L18.3938 16.8366C18.3937 16.8367 18.3938 16.8365 18.3938 16.8366C17.9028 18.3121 17.3148 19.4779 16.6448 20.2892C15.9768 21.0982 15.1574 21.6323 14.2221 21.6323C13.2869 21.6323 12.4674 21.0982 11.7994 20.2892C11.1295 19.4779 10.5416 18.3125 10.0506 16.837L9.3292 14.6727Z"
      fill={fill}
    />
  </svg>
))
