import React, { useMemo } from "react"
import ReactDatePicker, {
  CalendarContainer,
  type CalendarContainerProps,
  type ReactDatePickerProps,
} from "react-datepicker"

import { MONTH_AGO_RESTRICTION } from "~/ui-rtk/constants/restrictions"

import { isDateMoreRecentThanMonthsAgo } from "~/ui-rtk/utils/date-utils"

import "react-datepicker/dist/react-datepicker.css"
import "./index.scss"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export type TDatePickerProps = Omit<ReactDatePickerProps, "selectsRange"> & {
  selectsRange?: boolean
}

const DatePicker: React.FC<TDatePickerProps> = ({
  monthsShown = 1,
  minDate,
  ...props
}) => {
  const $minDate = useMemo(() => {
    if (
      !minDate ||
      isDateMoreRecentThanMonthsAgo(minDate, MONTH_AGO_RESTRICTION)
    ) {
      return dayjs().subtract(MONTH_AGO_RESTRICTION, "month").toDate()
    }
    return minDate
  }, [minDate])

  return (
    <ReactDatePicker
      calendarClassName="ui-rtk-date-picker"
      monthsShown={monthsShown}
      minDate={$minDate}
      calendarContainer={(passProps: CalendarContainerProps) => (
        <CalendarContainer {...passProps} />
      )}
      {...props}
    />
  )
}

export default DatePicker
