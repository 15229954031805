import { DataSource } from "@db/maindb/prisma/client"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"

import {
  InviteUserDto,
  UpdateCompanyUserDto,
  useCompanyUserControllerInviteUserMutation,
  useCompanyUserControllerGetAllCompanyUsersQuery,
  useCompanyUserControllerUpdateCompanyUserRoleMutation,
  useCompanyUserControllerRemoveUserFromCompanyMutation,
} from "~/ui-rtk/api/companyUserApi"
import { useAuthControllerCurrentUserQuery } from "~/ui-rtk/api/authApi"
import { useUserControllerUpdateUserMutation } from "~/ui-rtk/api/userApi"

export default function useConnect() {
  const { data: currentUser } = useAuthControllerCurrentUserQuery()

  const {
    data: users,
    isLoading,
    refetch,
  } = useCompanyUserControllerGetAllCompanyUsersQuery()
  const currentCompany = useAppSelector(selectCurrentCompany)
  const [inviteUser] = useCompanyUserControllerInviteUserMutation()
  const [updateUserRole] =
    useCompanyUserControllerUpdateCompanyUserRoleMutation()
  const [updateUser] = useUserControllerUpdateUserMutation()
  const [deleteUser] = useCompanyUserControllerRemoveUserFromCompanyMutation()

  const handleInviteUser = async (user: InviteUserDto) => {
    await inviteUser({
      inviteUserDto: {
        name: user.name,
        email: user.email,
        role: user.role,
      },
    })
    await refetch()
  }

  const handleUpdateUserRole = async (
    { role, dataSource }: UpdateCompanyUserDto & { dataSource?: DataSource },
    id: string,
  ) => {
    await updateUserRole({
      updateCompanyUserDto: {
        role,
      },
      id,
    })
    if (currentUser?.user.isSuperAdmin && dataSource) {
      await updateUser({
        id,
        updateUserDto: {
          dataSource,
        },
      })
    }
    await refetch()
  }

  const handleDeleteUser = async (id: string) => {
    await deleteUser({
      id,
    })
    await refetch()
  }

  return {
    isLoading,
    users,
    currentCompany,
    showDataSourceInfo: !!currentUser?.user.isSuperAdmin,
    handleInviteUser,
    handleUpdateUserRole,
    handleDeleteUser,
  }
}
