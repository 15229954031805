import React from "react"
import H2 from "~/ui-rtk/components/ui/typography/H2"
import { ConnectorsTable } from "~/ui-rtk/components/features/connectors"
import Breadcrumbs from "../../components/layout/Breadcrumbs"

const Connectors: React.FC = () => (
  <div className="md:px-6 md:pt-6">
    <div className="text-start">
      <H2>
        <Breadcrumbs
          items={[
            {
              slug: "/setup",
              name: "Setup",
            },
            {
              slug: "connectors",
              name: "Connectors",
            },
          ]}
        />
      </H2>
    </div>
    <div className="max-w-350 mx-auto md:space-y-8 md:mt-4 text-center md:pr-8">
      <ConnectorsTable />
    </div>
  </div>
)

export default Connectors
