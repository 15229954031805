import { useCallback } from "react"
import {
  useCompanyConnectorControllerDeleteCompanyConnectorMutation,
  useCompanyConnectorControllerGetCompanyConnectorAccountInfoQuery,
  useCompanyConnectorControllerUpdateCompanyConnectorMutation,
} from "~/ui-rtk/api/companyConnectorApi"

export const useConnect = (companyConnectorId: string) => {
  const {
    data: accountInfo,
    isLoading,
    refetch: refetchAccountInfo,
  } = useCompanyConnectorControllerGetCompanyConnectorAccountInfoQuery({
    id: companyConnectorId,
  })

  const [confirmConnectionAsync] =
    useCompanyConnectorControllerUpdateCompanyConnectorMutation()
  const [deleteConnectionAsync] =
    useCompanyConnectorControllerDeleteCompanyConnectorMutation()

  const confirmConnection = useCallback(async () => {
    await confirmConnectionAsync({
      id: companyConnectorId,
      updateCompanyConnectorDto: {
        confirmedAt: new Date(),
      },
    })
  }, [])

  const deleteConnection = useCallback(async () => {
    await deleteConnectionAsync({ id: companyConnectorId })
  }, [])

  return {
    accountInfo,
    isLoading,
    confirmConnection,
    deleteConnection,
    refetchAccountInfo,
  }
}
