import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const TrendingUpSVG = SvgHOC(({ ...props }) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.72839 5.29166L12.8758 5.29187L12.9176 5.29767L12.9588 5.30918L12.9839 5.31982C13.0169 5.33435 13.0478 5.35547 13.075 5.38264L13.092 5.40099L13.1101 5.42448L13.1327 5.46203L13.1488 5.49967L13.1568 5.52636L13.1624 5.55303L13.1663 5.59228L13.1665 8.73078C13.1665 8.90337 13.0266 9.04328 12.854 9.04328C12.6958 9.04328 12.5651 8.92571 12.5444 8.77318L12.5415 8.73078L12.5413 6.35791L9.32516 9.57468C9.21422 9.68563 9.0406 9.69572 8.91826 9.60495L8.88321 9.57469L7.64551 8.33704L5.36648 10.6161C5.24444 10.7381 5.04658 10.7381 4.92454 10.6161C4.81359 10.5051 4.80351 10.3315 4.89428 10.2092L4.92454 10.1741L7.42454 7.67413C7.53548 7.56319 7.70909 7.5531 7.83142 7.64387L7.86647 7.67413L9.10417 8.91177L12.0988 5.91666H9.72839C9.57018 5.91666 9.43944 5.79909 9.41874 5.64656L9.41589 5.60416C9.41589 5.44595 9.53346 5.3152 9.68599 5.29451L9.72839 5.29166Z"
      fill="currentColor"
    />
  </svg>
))
