import { OrganicPostCard } from "~/ui-rtk/components/features/metricCards/OrganicPostCard/OrganicPostCart"
import SortedItemsLoader from "~/ui-rtk/components/ui/charts/SortedItems/SortedItemsLoader"
import { H3 } from "~/ui-rtk/components/ui/typography"
import { DRAWER_TYPE, TDrawerConfig } from "~/ui-rtk/constants/drawer"
import useDrawer from "~/ui-rtk/hooks/drawer"
import { usePeriod } from "~/ui-rtk/hooks/period"
import { TBrandMediaSocialPostsTable } from "~/ui-rtk/pages/BrandMedia/OrganicSocialPosts/connect"

type TBrandMediaSocialPostsSortedSectionProps = {
  dateRange: [Date, Date]
  activeWidget: TBrandMediaSocialPostsTable
}

export default function BrandMediaSocialPostsSortedSection({
  dateRange,
  activeWidget,
}: TBrandMediaSocialPostsSortedSectionProps) {
  const { openDrawer } = useDrawer()
  const { granularity } = usePeriod()

  const handleOpenDrawer = (config: Partial<TDrawerConfig>) => {
    const drawerType = DRAWER_TYPE.CampaingAdSetAdDetails
    const component = "PostDetails"

    openDrawer({
      ...config,
      props: {
        ...config.props,
        component,
      },
      type: drawerType,
    } as TDrawerConfig)
  }

  return (
    <div className="sorted-section">
      <div className="mt-3">
        <H3>High Impact Organic Posts</H3>
      </div>
      <div className="mt-3 overflow-x-hidden">
        <SortedItemsLoader
          widget={activeWidget}
          isDesc={true}
          dateRange={dateRange}
          granularity={granularity}
          sortByMetric="company_media_metrics_posts.brand_value_agg"
          itemComponent={OrganicPostCard}
          onOpenDrawer={handleOpenDrawer}
          disableGranularity={true}
        />
      </div>
    </div>
  )
}
