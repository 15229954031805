const requiredKeywords = [
  { platform: "google", type: "brands" },
  { platform: "google", type: "competitors" },
  { platform: "google", type: "categories" },
  { platform: "instagram", type: "competitors" },
]

export const checkForMissingKeywords = (keywords: any) =>
  requiredKeywords.some(
    ({ platform, type }) => !keywords[platform]?.[type]?.length,
  )
