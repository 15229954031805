import React, { useState } from "react"
import { toast } from "react-toastify"

import { ConnectionLinkForm } from "~/ui-rtk/components/features/forms"
import { Dialog } from "~/ui-rtk/components/ui/common"
import { H4, P } from "~/ui-rtk/components/ui/typography"
import { SuccessSvg, CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"

import { useConnect } from "./connect"

type ConnectionLinkDialogProps = {
  imgSrc: string
  service: string
  connectorId: string
  onClose: () => void
}

const ConnectorLinkDialog: React.FC<ConnectionLinkDialogProps> = ({
  imgSrc,
  service,
  connectorId,
  onClose,
}) => {
  const { createConnectionLink, isLoading } = useConnect()
  const [showClipboardSuccess, setShowClipboardSuccess] = useState(false)

  const handleSubmit = (recipientEmail: string) => {
    if (!isLoading) {
      void createConnectionLink(connectorId, recipientEmail)
        .then(onClose)
        .catch(() =>
          toast.error("We ran into a problem sending the connection link.", {
            toastId: "send-connection-link-to-email-id",
          }),
        )
    }
  }

  const createAndCopyToClipboard = async () => {
    const connectionLink = await createConnectionLink(connectorId)
    if (connectionLink) {
      void navigator.clipboard
        .writeText(connectionLink)
        .then(() => setShowClipboardSuccess(true))
        .catch(() =>
          toast.error("Failed to copy link to clipboard", {
            toastId: "clipboard-copy-connection-link-id",
          }),
        )
    }
  }

  return (
    <Dialog isOpen={true} handleClose={onClose}>
      <div className="max-w-160 w-full absolute rounded-md py-6 px-10 center-absolute bg-basic-dark-blue shadow-deep border border-basic-blue">
        <div className="flex flex-col items-center gap-6 mb-8">
          <img src={imgSrc} alt={service} className="w-9 h-9" />
          <H4 className="font-medium text-white">Send a Connection Link</H4>
        </div>
        <div className="space-y-4">
          <P className="leading-5 text-left text-base text-platinum">
            This link will super charge your connection experience. Enter the
            email address of the account holder for this connector below, and
            we'll authorize them to connect this connector on your company's
            behalf.
          </P>
          <ConnectionLinkForm
            service={service}
            onSubmit={handleSubmit}
            isSubmitting={isLoading}
          />
          <div className="space-y-2">
            <button
              type="button"
              className="self-center"
              onClick={createAndCopyToClipboard}
            >
              <span className="text-sm text-vibrant-blue">
                Or click here to copy a one-time connector login link to
                clipboard
              </span>
            </button>

            {showClipboardSuccess ? (
              <div className="flex items-center justify-center gap-2 text-sm text-american-green">
                <SuccessSvg fill="green" size={20} />
                <p>Link successfully copied to the clipboard!</p>
              </div>
            ) : null}
          </div>
        </div>

        <button
          className="absolute right-4 top-4 max-w-7 max-h-7 p-2 flex items-center justify-center hover:bg-[#2a2c33] hover:text-white transition-colors rounded-1"
          onClick={onClose}
        >
          <CloseSvg size={20} fill="platinum" />
        </button>
      </div>
    </Dialog>
  )
}

export default ConnectorLinkDialog
