import { TSource } from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import { Image, TImageProps } from "~/ui-rtk/components/ui/common/Image"

export const renderImage = (
  src: string,
  alt: string,
  props?: Omit<TImageProps, "src" | "alt">,
) => (
  <Image
    src={src}
    alt={alt}
    size={42}
    classes={{ image: "border border-basic-blue rounded-1" }}
    {...props}
  />
)

export const renderSourceIcon = (
  source: Pick<TSource, "icon" | "name">,
  props?: TImageProps,
) => (
  <Image
    src={source.icon}
    alt={source.name}
    title={source.name}
    size={24}
    {...props}
  />
)
