import { useMemo } from "react"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery } from "../api/companyConnectorApi"
import { useConnectorControllerGetAllConnectorsQuery } from "../api/connectorApi"
import { CONNECTORS_TO_SOURCES_MAP, SOURCES_MAP } from "../constants/sources"

export type TConnector = {
  id: string
  isConnected: boolean
}

export type TSource = {
  id: string
  name: string
  icon: string
}

export type TSourceMap = Map<string, TSource>

export default function useSources() {
  const {
    data: connectedServices,
    isLoading: isServicesLoading,
    isSuccess: isServicesSuccess,
    refetch,
  } = useCompanyConnectorControllerGetCompanyConnectorsQuery()
  const {
    data: connectorsLib,
    isLoading: isConnectorsLoading,
    isSuccess: isServicesConnectors,
  } = useConnectorControllerGetAllConnectorsQuery()

  // map existing company connector status to the connector sources
  const connectedConnectors: TConnector[] = useMemo(
    () =>
      (connectorsLib ?? [])
        ?.map(({ id }) => ({
          id,
          isConnected:
            connectedServices?.some(
              connector =>
                connector.serviceName === id && connector.isCompleted,
            ) ?? false,
        }))
        .filter(({ isConnected }) => !!isConnected),
    [connectorsLib, connectedServices],
  )

  const sources: TSource[] = useMemo(() => {
    const connectedSourceIds: string[] = connectedConnectors.reduce(
      (acc: string[], { id }: TConnector) => {
        const sourceConnectors = CONNECTORS_TO_SOURCES_MAP[id] || []
        return [...acc, ...sourceConnectors]
      },
      [],
    )
    return [...new Set(connectedSourceIds)].map(
      sourceId => SOURCES_MAP[sourceId],
    )
  }, [connectedConnectors])

  const sourcesMap: TSourceMap = useMemo(
    () =>
      new Map(sources.map(({ id, name, icon }) => [id, { name, icon, id }])),
    [sources],
  )

  return {
    sources: sourcesMap,
    refetch,
    isLoading:
      isServicesLoading ||
      isConnectorsLoading ||
      // if source query was not successful. we just trick it to be in loading state
      // Otherwise, we will have a flickering effect where components are rendered
      // and resubscribed to the query thus resulting in re-requesting failed request
      // and we end up in an infinite loop
      !(isServicesSuccess && isServicesConnectors),
  }
}
