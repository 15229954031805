import { Formik, Form } from "formik"

import useConnect from "./connect"
import { Button, FormField } from "~/ui-rtk/components/ui/controls"

export default function ForgotPasswordForm() {
  const {
    initialValues,
    validationSchema,
    handleRequestResetPassword,
    isSubmitting,
  } = useConnect()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleRequestResetPassword}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="flex flex-col items-center w-full space-y-8">
          <FormField
            id="email"
            type="email"
            placeholder="Email"
            isError={Boolean(touched.email && errors.email)}
          />

          <Button
            type="submit"
            size="lg"
            className="sm:max-w-48 w-full"
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </Form>
      )}
    </Formik>
  )
}
