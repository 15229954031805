import useDate from "~/ui-rtk/hooks/date"

const dayjs = useDate()

export const isSyncOutdated = (lastSync: string) => {
  const oneWeek = 7
  const now = dayjs()
  const syncDate = dayjs(lastSync)
  const diffInDays = now.diff(syncDate, "day")

  return diffInDays > oneWeek
}

export const formatDate = (dateString: string) =>
  dayjs(dateString).format("MM/DD/YYYY")
