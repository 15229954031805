export const onboardingRoutes = {
  CHECK_INBOX: "/onboarding/check-inbox",
  ACCOUNT_DETAILS: "/onboarding/account-details",
  PACKAGE: "/onboarding/package",
  SOURCES: "/onboarding/connect-sources",
  KEYWORDS: "/onboarding/keywords",
  DATA_PENDING: "/onboarding/data-pending",

  CONNECTION_FLOW: "/onboarding/connection-flow",
  AUTHENTICATE_CONNECTOR: "/authenticate-connector",
}
