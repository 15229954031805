import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const ExclamationSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9898 2.58301C12.6802 2.58301 13.2398 3.14265 13.2398 3.83301V14.0413C13.2398 14.7317 12.6802 15.2913 11.9898 15.2913C11.2995 15.2913 10.7398 14.7317 10.7398 14.0413V3.83301C10.7398 3.14265 11.2995 2.58301 11.9898 2.58301ZM10.7397 20.1663C10.7397 19.476 11.2994 18.9163 11.9897 18.9163H12.0103C12.7007 18.9163 13.2603 19.476 13.2603 20.1663C13.2603 20.8567 12.7007 21.4163 12.0103 21.4163H11.9897C11.2994 21.4163 10.7397 20.8567 10.7397 20.1663Z"
      fill={fill}
    />
  </svg>
))
