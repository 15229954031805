import React, { useState } from "react"
import { toast } from "react-toastify"

import { useConnect } from "./connect"
import { Loader } from "~/ui-rtk/components/ui/common"
import ConnectorDialog, { ConnectorDialogProps } from "../ConnectorDialog"

import { defaultImages } from "~/ui-rtk/shared/constants/image-paths.contants"
import { Button } from "~/ui-rtk/components/ui/controls"

type ConfirmConnectionDialogProps = {
  label: string
  companyConnectorId: string
} & ConnectorDialogProps

const ConfirmConnectionDialog: React.FC<ConfirmConnectionDialogProps> = ({
  label,
  companyConnectorId,
  onClose,
  ...props
}) => {
  const { accountInfo, confirmConnection, deleteConnection, isLoading } =
    useConnect(companyConnectorId)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleConfirm = async () => {
    setIsSubmitting(true)
    try {
      await confirmConnection()
      void onClose()
    } catch {
      toast.error(`Failed to confirm connection with ${label}`, {
        toastId: `confirm-connection-failed-${companyConnectorId}`,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDelete = async () => {
    setIsSubmitting(true)
    try {
      await deleteConnection()
      void onClose()
    } catch {
      toast.error(`Failed to delete connection with ${label}`, {
        toastId: `confirm-connection-failed-${companyConnectorId}`,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const dialogClasses = {
    container: "flex flex-col items-center gap-5",
  }

  const avatarAlt = accountInfo
    ? `${accountInfo.accountName} Logo`
    : "Unknown Logo"
  const accountName = accountInfo?.accountName || "Not Provided"
  const accountMetrics = accountInfo?.metric || "Not Provided"

  const isAccountInfo =
    Boolean(accountInfo?.accountName) && Boolean(accountInfo?.metric)

  return (
    <ConnectorDialog onClose={onClose} classes={dialogClasses} {...props}>
      <p className="leading-5 text-base text-platinum text-center">
        Please take a quick moment to verify that the account you connected to{" "}
        {label} is the company's official account.
      </p>
      <div className="flex items-center gap-2">
        <div className="flex gap-4">
          {!isLoading ? (
            <>
              {isAccountInfo ? (
                <>
                  <img
                    src={accountInfo?.avatarUrl || defaultImages.noImage}
                    alt={avatarAlt}
                    width={42}
                    height={42}
                  />
                  <div className="flex flex-col items-start">
                    <b>{accountName}</b>
                    <p>{accountMetrics}</p>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <div className="w-10.5">
              <Loader className="mb-10" />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center w-full gap-5">
        <Button
          variant={{ variant: "solid", color: "white" }}
          isLoading={isSubmitting}
          onClick={handleConfirm}
          className="max-w-50 w-full"
        >
          Confirm
        </Button>
        <Button
          variant={{ variant: "solid", color: "red" }}
          isLoading={isSubmitting}
          onClick={handleDelete}
          className="max-w-50 w-ful"
        >
          Delete Connection
        </Button>
      </div>
    </ConnectorDialog>
  )
}

export default ConfirmConnectionDialog
