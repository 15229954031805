import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import {
  ConfirmSubscriptionCheckoutSessionDto,
  useSubscriptionControllerConfirmSubscriptionCheckoutSessionMutation,
} from "~/ui-rtk/api/subscriptionApi"

export default function useConnect() {
  const [confirmSubscription] =
    useSubscriptionControllerConfirmSubscriptionCheckoutSessionMutation()

  const [isLoading, setIsLoading] = useState(true)
  const [confirmed, setConfirmed] = useState(false)
  const [error, setError] = useState(false)

  const handleConfirm = useCallback(
    async (sessionId: string) => {
      await confirmSubscription({
        stripeSessionId: sessionId,
      } as ConfirmSubscriptionCheckoutSessionDto)
        .unwrap()
        .catch((e: { data: { message: string } }) => {
          console.error(e.data.message)
          toast.error(`Failed to confirm code: ${e.data.message}`)
          setError(true)
        })
      setIsLoading(false)
      setConfirmed(true)
    },
    [setIsLoading, confirmSubscription],
  )

  return {
    isLoading,
    confirmed,
    error,
    handleConfirm,
  }
}
