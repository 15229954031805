import { useCallback, useState } from "react"
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Oops! That doesn’t look like a valid email")
    .required("Email is required!"),
})

const initialValues = {
  email: "",
}

type FormValues = typeof initialValues

type ResultMock = {
  isSuccess: boolean
}

export default function useConnect() {
  const [result, setResult] = useState<ResultMock>({ isSuccess: false })

  const handleSignUp = useCallback(
    (values: FormValues) => {
      // eslint-disable-next-line no-console
      console.log("handleSignUp", values.email)
      setResult({ isSuccess: true })
    },
    [setResult],
  )

  return {
    handleSignUp,
    requestResult: result,
    initialValues,
    validationSchema,
  }
}
