import {
  AggregationType,
  TLabelConfig,
  TOTAL_CALCULATED_DATA_KEY,
} from "~/ui-rtk/constants/charts"
import Card from "~/ui-rtk/components/ui/common/Card"
import { H3, P } from "~/ui-rtk/components/ui/typography"
import pluralize from "pluralize"
import { TooltipProps } from "recharts"
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import { formatNumber } from "~/ui-rtk/utils/format"
import { calcDelta, getLightColor } from "~/ui-rtk/utils/chart-utils"
import { sortBy } from "lodash"
import numeral from "numeral"
import { useMemo } from "react"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export type TTootlipNumberProps = {
  numeralFormat: string
  renderCell?: (
    val: number,
    payload: Payload<ValueType, NameType>,
    labelConfig: TLabelConfig,
    ignoreDecimals?: boolean,
    keyPrefix?: string,
  ) => string
}

type TChartTooltipContentProps = TooltipProps<ValueType, NameType> & {
  title?: string
  dataAggType?: AggregationType
  dataLabels: Map<string, TLabelConfig>
  totalLabelConfig?: TLabelConfig
  excludeDataKeyFromTotal?: Array<string>
  compareMode?: boolean
  isCompareMetricMain?: boolean
  highlightKey?: string
  showTotal?: boolean
  ignoreDecimals?: boolean
  isTotalCalculated?: boolean
  tooltipProps?: TTootlipNumberProps
  onOpenPostsClick?: (date: string) => void
  onOpenAdsClick?: (date: string) => void
  graphsManualSort?: boolean
  hideDates?: boolean
  blurTitle?: boolean
  hideTooltipTitle?: boolean
}

const AGG_TYPE_DATE_FORMAT = {
  [AggregationType.DAILY]: "LL",
  [AggregationType.WEEKLY]: "LL ([Week] W)",
  [AggregationType.MONTHLY]: "MMM YYYY",
}

export default function ChartTooltipContent({
  title,
  dataAggType = AggregationType.DAILY,
  label,
  payload,
  dataLabels,
  compareMode,
  highlightKey,
  excludeDataKeyFromTotal: excludeFromTotal,
  isCompareMetricMain = false,
  showTotal = true,
  ignoreDecimals = false,
  isTotalCalculated = false,
  totalLabelConfig: totalLabelConfigFromProps,
  tooltipProps,
  graphsManualSort = false,
  hideDates = false,
  blurTitle = false,
  hideTooltipTitle = false,
}: TChartTooltipContentProps) {
  const [mainPrefix, comparePrefix] = isCompareMetricMain
    ? ["compared.", ""]
    : ["", "compared."]

  const date = payload?.[0].payload[`${mainPrefix}date`]
  const compareDate = payload?.[0].payload[`${comparePrefix}date`]

  const orderedDataLabels = graphsManualSort
    ? Array.from(dataLabels.values())
    : sortBy(Array.from(dataLabels.values()), "order")

  const totalValue = isTotalCalculated
    ? payload?.[0].payload[
        totalLabelConfigFromProps?.dataKey
          ? totalLabelConfigFromProps?.dataKey.toLowerCase()
          : TOTAL_CALCULATED_DATA_KEY
      ]
    : orderedDataLabels.reduce(
        (acc: number, { dataKey }: { dataKey: string }) => {
          if (
            excludeFromTotal?.includes(dataKey) ||
            dataKey === TOTAL_CALCULATED_DATA_KEY
          ) {
            return acc
          }

          const value = Number(payload?.[0].payload[dataKey]) || 0
          return acc + value
        },
        0,
      )

  const compareTotalValue: number = isTotalCalculated
    ? payload?.[0].payload[
        totalLabelConfigFromProps?.dataKey
          ? `compared.${totalLabelConfigFromProps?.dataKey.toLowerCase()}`
          : `compared.${TOTAL_CALCULATED_DATA_KEY}`
      ]
    : orderedDataLabels.reduce(
        (acc: number, { dataKey }: { dataKey: string }) => {
          const value = Number(payload?.[0].payload[`compared.${dataKey}`]) || 0
          return acc + value
        },
        0,
      )

  const [total, compareTotal] = isCompareMetricMain
    ? [compareTotalValue, totalValue]
    : [totalValue, compareTotalValue]

  const totalLabelConfig = useMemo(
    () =>
      totalLabelConfigFromProps ?? dataLabels.get(`${payload?.[0].dataKey}`),
    [totalLabelConfigFromProps, dataLabels, payload],
  )

  const totalDelta = calcDelta(total, compareTotal)

  const totalColorClassName = useMemo(() => {
    if (totalDelta === null) {
      return ""
    }
    return totalDelta < 0 ? "text-error" : "text-green-bright"
  }, [totalDelta])

  const renderCell = (value: ValueType, labelConfig: TLabelConfig) =>
    tooltipProps?.numeralFormat
      ? numeral(value).format(tooltipProps?.numeralFormat)
      : formatNumber(Number(value), {
          compact: labelConfig.numberType !== "currency",
          currency: labelConfig.numberType === "currency",
          decimals: ignoreDecimals ? 0 : 2,
        })

  const demo = useAppSelector(selectIsEnabled)

  const content = useMemo(
    () =>
      compareMode ? (
        <>
          {!hideDates && (
            <>
              <P>
                {dayjs(date).format(AGG_TYPE_DATE_FORMAT[dataAggType])}
                &nbsp;&nbsp;&nbsp;
              </P>
              <P>
                Compare to:{" "}
                {dayjs(compareDate).format(AGG_TYPE_DATE_FORMAT[dataAggType])}
                &nbsp;&nbsp;&nbsp;
              </P>
            </>
          )}
          <table className="table table-zebra">
            <thead>
              <tr>
                <th className="p-0.5">Metric</th>
                <th className="p-0.5 text-center">Actual</th>
                <th className="p-0.5 text-center">Compare</th>
                <th className="p-0.5 text-end">Delta</th>
              </tr>
            </thead>
            <tbody>
              {orderedDataLabels.map(({ dataKey }) => {
                const payloadItem = payload?.find(
                  ({ dataKey: itemDataKey }) => dataKey === itemDataKey,
                )
                if (!payloadItem) {
                  return null
                }
                const { value: payloadValue, color } =
                  payloadItem as unknown as {
                    value: string | number
                    color: string
                  }
                const labelConfig = dataLabels.get(`${dataKey}`)
                if (!labelConfig) {
                  return null
                }

                const [value, compareValue] = isCompareMetricMain
                  ? [
                      payload?.[0].payload[`compared.${dataKey}`] ?? 0,
                      payloadValue,
                    ]
                  : [
                      payloadValue,
                      payload?.[0].payload[`compared.${dataKey}`] ?? 0,
                    ]

                const delta = calcDelta(value, compareValue)
                let colorClassName = ""
                if (delta !== null) {
                  colorClassName =
                    delta < 0 ? "text-error" : "text-green-bright"
                }

                return (
                  <tr
                    key={dataKey}
                    style={{
                      color: color ? getLightColor(color) : color,
                    }}
                    className="text-sm"
                  >
                    <td
                      className={cn(
                        "p-0.5",
                        demo.isEnabled && blurTitle && "blur-md",
                      )}
                    >
                      {labelConfig.label}
                    </td>
                    <td className="p-0.5 text-start">
                      {tooltipProps?.renderCell && payload
                        ? tooltipProps.renderCell(
                            value as number,
                            payload[0].payload,
                            labelConfig,
                            ignoreDecimals,
                            mainPrefix,
                          )
                        : renderCell(value, labelConfig)}
                      {labelConfig.unit
                        ? ` ${labelConfig.pluralizeUnit ? pluralize(labelConfig.unit, Number(value)) : labelConfig.unit}`
                        : null}
                    </td>
                    <td className="p-0.5 text-start">
                      {tooltipProps?.renderCell && payload
                        ? tooltipProps.renderCell(
                            compareValue as number,
                            payload[0].payload,
                            labelConfig,
                            ignoreDecimals,
                            comparePrefix,
                          )
                        : renderCell(compareValue, labelConfig)}
                      {labelConfig.unit
                        ? ` ${labelConfig.pluralizeUnit ? pluralize(labelConfig.unit, Number(value)) : labelConfig.unit}`
                        : null}
                    </td>
                    <td
                      className={[colorClassName, "p-0.5 text-end"]
                        .filter(c => c)
                        .join(" ")}
                    >
                      {delta !== null
                        ? `${delta > 0 ? "+" : ""}${formatNumber(Number(delta), { decimals: 1 })}%`
                        : "-"}
                    </td>
                  </tr>
                )
              })}
              {showTotal && (
                <tr className="text-sm border-t-2 border-t-background-dark pt-0.5 mt-0.5">
                  <td className="p-0.5">
                    {totalLabelConfig?.label ?? "Total"}:
                  </td>
                  <td className="p-0.5 text-start">
                    {tooltipProps?.numeralFormat
                      ? numeral(total).format(tooltipProps?.numeralFormat)
                      : formatNumber(Number(total), {
                          compact: totalLabelConfig?.numberType !== "currency",
                          currency: totalLabelConfig?.numberType === "currency",
                          decimals: ignoreDecimals ? 0 : 1,
                        })}
                    {totalLabelConfig?.unit
                      ? ` ${totalLabelConfig?.pluralizeUnit ? pluralize(totalLabelConfig?.unit, Number(total)) : totalLabelConfig?.unit}`
                      : null}
                  </td>
                  <td className="p-0.5 text-start">
                    {tooltipProps?.numeralFormat
                      ? numeral(compareTotal).format(
                          tooltipProps?.numeralFormat,
                        )
                      : formatNumber(Number(compareTotal), {
                          compact: totalLabelConfig?.numberType !== "currency",
                          currency: totalLabelConfig?.numberType === "currency",
                          decimals: ignoreDecimals ? 0 : 1,
                        })}
                    {totalLabelConfig?.unit
                      ? ` ${totalLabelConfig?.pluralizeUnit ? pluralize(totalLabelConfig?.unit, Number(total)) : totalLabelConfig?.unit}`
                      : null}
                  </td>
                  <td
                    className={[totalColorClassName, "p-0.5 text-end"]
                      .filter(c => c)
                      .join(" ")}
                  >
                    {totalDelta !== null
                      ? `${totalDelta > 0 ? "+" : ""}${formatNumber(
                          Number(totalDelta),
                          {
                            decimals: ignoreDecimals ? 0 : 1,
                          },
                        )}`
                      : null}
                    %
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <>
          {!hideDates && (
            <P>
              {dayjs(date).format(AGG_TYPE_DATE_FORMAT[dataAggType])}
              &nbsp;&nbsp;&nbsp;
            </P>
          )}
          <div>
            {orderedDataLabels.map(({ dataKey }) => {
              const payloadItem = payload?.find(
                ({ dataKey: itemDataKey }) => dataKey === itemDataKey,
              )
              if (!payloadItem) {
                return null
              }
              const { value, color } = payloadItem as unknown as {
                value: string | number
                color: string
              }
              const labelConfig = dataLabels.get(`${dataKey}`)
              if (!labelConfig) {
                return null
              }

              return (
                <div
                  key={dataKey}
                  style={{
                    color: color ? getLightColor(color) : color,
                  }}
                  className={cn(
                    `columns-2 text-sm w-auto`,
                    highlightKey === dataKey && "font-bold",
                  )}
                >
                  <div className={cn(demo.isEnabled && blurTitle && "blur-sm")}>
                    {labelConfig.label}&nbsp;&nbsp;&nbsp;
                  </div>
                  <div className="text-sm font-bold">
                    {tooltipProps?.renderCell && payload
                      ? tooltipProps.renderCell(
                          value as number,
                          payload[0].payload,
                          labelConfig,
                          ignoreDecimals,
                        )
                      : renderCell(value, labelConfig)}
                    {labelConfig.unit
                      ? ` ${labelConfig.pluralizeUnit ? pluralize(labelConfig.unit, Number(value)) : labelConfig.unit}`
                      : null}
                  </div>
                </div>
              )
            })}
            {showTotal && (
              <div className="columns-2 text-sm border-t-2 pt-0.5 mt-0.5 border-t-background-dark">
                <div>
                  {totalLabelConfig?.label ?? "Total"}&nbsp;&nbsp;&nbsp;
                </div>
                <div className="text-sm font-bold text-primary">
                  {tooltipProps?.numeralFormat
                    ? numeral(total).format(tooltipProps?.numeralFormat)
                    : formatNumber(Number(total), {
                        compact: totalLabelConfig?.numberType !== "currency",
                        currency: totalLabelConfig?.numberType === "currency",
                        decimals: ignoreDecimals ? 0 : 2,
                      })}
                  {totalLabelConfig?.unit
                    ? ` ${totalLabelConfig?.pluralizeUnit ? pluralize(totalLabelConfig?.unit, Number(total)) : totalLabelConfig?.unit}`
                    : null}
                </div>
              </div>
            )}
          </div>
        </>
      ),
    [
      compareMode,
      hideDates,
      date,
      dataAggType,
      orderedDataLabels,
      payload,
      dataLabels,
      isCompareMetricMain,
      tooltipProps,
      showTotal,
      totalLabelConfig,
      totalColorClassName,
      totalDelta,
      ignoreDecimals,
      label,
    ],
  )

  return (
    <Card rounded className="bg-basic-black border-2 border-basic-blue">
      {!hideTooltipTitle && <H3>{title}</H3>}
      {content}
    </Card>
  )
}
