import React, { useState } from "react"
import { toast } from "react-toastify"

import ConnectorDialog, { ConnectorDialogProps } from "../ConnectorDialog"
import { useConnect } from "./connect"
import { Button } from "~/ui-rtk/components/ui/controls"

type TSuccessConnectionDialogProps = {
  label: string
  companyConnectorId: string
} & ConnectorDialogProps

const SuccessConnectionDialog: React.FC<TSuccessConnectionDialogProps> = ({
  label,
  companyConnectorId,
  onClose,
  ...props
}) => {
  const { confirmConnection } = useConnect()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleConfirm = async () => {
    setIsSubmitting(true)
    try {
      await confirmConnection(companyConnectorId)
    } catch {
      toast.error(`Failed to process the connection with ${label}`, {
        toastId: `confirm-connection-failed-${companyConnectorId}`,
      })
    } finally {
      setIsSubmitting(false)
      void onClose()
    }
  }

  const dialogClasses = {
    container: "flex flex-col items-center gap-5",
  }

  return (
    <ConnectorDialog onClose={handleConfirm} classes={dialogClasses} {...props}>
      <p className="leading-5 text-base text-platinum text-center">
        Please check back in 24 hours to verify and confirm your connection.
      </p>

      <div className="flex justify-center w-full gap-5">
        <Button
          variant={{ variant: "solid", color: "white" }}
          isLoading={isSubmitting}
          onClick={handleConfirm}
          className="w-full"
        >
          OK
        </Button>
      </div>
    </ConnectorDialog>
  )
}

export default SuccessConnectionDialog
