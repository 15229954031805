import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const TrashSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.75 4.48535C13.2525 4.23785 10.74 4.11035 8.235 4.11035C6.75 4.11035 5.265 4.18535 3.78 4.33535L2.25 4.48535"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4083 16.5005H6.59328C4.50078 16.5005 4.43328 15.5855 4.35078 14.408L3.86328 6.85547"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1369 6.85547L13.6494 14.408C13.5996 15.1053 13.125 16.5 11.625 16.5"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 12.1875H11.1941"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.125 9.65625H11.2645"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))
