import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const SearchSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.66634 14C11.1641 14 13.9997 11.1645 13.9997 7.66668C13.9997 4.16887 11.1641 1.33334 7.66634 1.33334C4.16854 1.33334 1.33301 4.16887 1.33301 7.66668C1.33301 11.1645 4.16854 14 7.66634 14Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6663 14.6667L13.333 13.3333"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))
