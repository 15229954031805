import { useEffect } from "react"
import { CubeProvider } from "@cubejs-client/react"
import { usePostHog } from "posthog-js/react"
import { useIdleTimer } from "react-idle-timer"

import useFeaturebase from "~/ui-rtk/hooks/featurebase.tsx"

import Guest from "../Guest"
import Main from "../Main"
import { Onboarding } from "../Onboarding"
import StaticSpinner from "~/ui-rtk/components/layout/StaticSpinner"

import { useAppSession } from "./useCubeApiToken"
import useAccess from "~/ui-rtk/hooks/access"

const MARATHONDATA_CO_NAME = "MARATHONDATA_CO"

const AuthPage = () => {
  let content = null
  const { session, cubeApi, isLoading, invalidateTokenIfNeeded } =
    useAppSession()
  const { isOnboarded, isSuperAdmin, isAccessGranted } = useAccess()
  const posthog = usePostHog()
  const { identify } = useFeaturebase()

  const { user, currentCompany } = session || {}

  const onActive = () => {
    invalidateTokenIfNeeded()?.catch(() => null)
  }

  useIdleTimer({
    onActive,
    timeout: 30_000, // 30 seconds
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
    name: MARATHONDATA_CO_NAME,
  })

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        email: user.email,
        name: user.name,
      })
      if (currentCompany) {
        posthog?.group("company", currentCompany.id, {
          name: currentCompany.name,
          setupStatus: currentCompany.setupStatus,
          subscription: currentCompany.subscription,
        })
        identify()
      }
    }
  }, [posthog, user?.id, user?.email, currentCompany?.id])

  const renderAuthSessionContent = () => {
    if (isOnboarded || isAccessGranted || isSuperAdmin) {
      return (
        <CubeProvider cubeApi={cubeApi}>
          <Main />
        </CubeProvider>
      )
    }

    return <Onboarding />
  }

  if (session) {
    content = renderAuthSessionContent()
  } else if (!session && !isLoading) {
    content = <Guest />
  }

  return (
    <>
      <StaticSpinner />
      {content}
    </>
  )
}

export default AuthPage
