import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import Button from "../Button"

export type TNavigationButtonProps = {
  to: string
}

export default function NavigationButton({
  to,
  children,
}: PropsWithChildren<TNavigationButtonProps>) {
  return (
    <Link to={to}>
      <Button
        className="w-full"
        variant={{
          variant: "solid",
          color: "blue",
        }}
      >
        {children}
      </Button>
    </Link>
  )
}
