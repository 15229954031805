import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { Button } from "~/ui-rtk/components/ui/controls"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { GuestLayout } from "../components/layout"
import { ContentBox } from "~/ui-rtk/components/ui/common"

const TokenExpiredView = () => {
  const navigate = useStableNavigate()

  const handleNavigate = () => {
    void navigate(brandRoutes.SIGN_IN)
  }

  return (
    <GuestLayout>
      <ContentBox title="This link has expired!">
        <Button onClick={handleNavigate} className="w-full mx-auto mt-6">
          Back to sign in
        </Button>
      </ContentBox>
    </GuestLayout>
  )
}

export default TokenExpiredView
