import * as yup from "yup"
import YupPassword from "yup-password"
import { useCallback } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"
import {
  useAuthControllerChangePasswordMutation,
  useAuthControllerLogoutMutation,
} from "~/ui-rtk/api/authApi"

YupPassword(yup)

export type ChangePasswordFormValues = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required!"),
  newPassword: yup
    .string()
    .required("New password is required!")
    .min(8, "Must be at least 8 characters long")
    .minLowercase(1, "Must contain a lowercase letter (a-z)")
    .minUppercase(1, "Must contain an uppercase letter (A-Z)")
    .minNumbers(1, "Must contain a number")
    .minSymbols(1, "Must contain a symbol (e.g. @, $, !, %, *, ?)"),
  confirmNewPassword: yup
    .string()
    .required("Must match")
    .oneOf([yup.ref("newPassword"), ""], "Must match"),
})

export default function useConnect() {
  const [changePassword] = useAuthControllerChangePasswordMutation()
  const [logout] = useAuthControllerLogoutMutation()
  const navigate = useStableNavigate()

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }

  const handleChangePassword = useCallback(
    async (values: ChangePasswordFormValues) => {
      try {
        await changePassword({
          resetPasswordLoggedDto: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
        }).unwrap()
        toast.success("Password was changed successfully")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error(error)
        if (error.status === 401) {
          await logout()
          navigate("/")
          return
        }
        toast.error("Could not update password")
      }
    },
    [],
  )

  return {
    initialValues,
    validationSchema,
    changePassword: handleChangePassword,
  }
}
