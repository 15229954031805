import React, { useState } from "react"
import * as yup from "yup"
import { Loader } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

type ConnectionLinkFormProps = {
  service: string
  isSubmitting: boolean
  onSubmit: (email: string) => void
}

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Oops! That doesn’t look like a valid email")
    .required("Email is required"),
})

const ConnectionLinkForm: React.FC<ConnectionLinkFormProps> = ({
  service,
  onSubmit,
  isSubmitting,
}) => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) return

    event.preventDefault()
    setError("")

    await emailSchema
      .validate({ email })
      .then(() => onSubmit(email))
      .catch(err => setError(err.message))
  }

  return (
    <form className="flex flex-col w-full gap-4 mt-8" onSubmit={handleSubmit}>
      <div className="flex flex-col justify-start text-left">
        <label className="ml-1">
          <b className="text-3 text-platinum">
            Enter the e-mail of the {service} Partner account holder here*
          </b>
        </label>
        <div
          className={cn(
            "w-full px-4 py-2 border rounded-md border-onyx",
            error && "border-states-error",
          )}
        >
          <input
            name="email"
            className="w-full text-platinum placeholder:text-4 focus:ring-0 p-0 m-0 bg-transparent"
            placeholder="Enter the account holder's e-mail here..."
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </div>
        {error ? <span className="ml-1 text-3 text-error">{error}</span> : null}
      </div>
      <Button
        variant={{ variant: "solid", color: "white" }}
        type="submit"
        isLoading={isSubmitting}
      >
        Send Connection Email
        {isSubmitting ? <Loader /> : null}
      </Button>
    </form>
  )
}

export default ConnectionLinkForm
