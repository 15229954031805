import { useEffect, useState } from "react"
import * as yup from "yup"
import qs from "qs"
import { useSearchParams } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"

import { brandRoutes } from "~/ui-rtk/constants/routes"
import { useCompanyConnectorControllerAuthenticateCompanyConnectorMutation } from "../api/companyConnectorApi"
import { isNotFoundError } from "../utils/http-utils"
import { ConnectorTypeEnum } from "../components/features/connectors/ConnectorsTable/utils"

const queryValidator = yup.object({
  authCode: yup.string().required(),
  state: yup.string().required(),
})

const AuthenticateConnector = () => {
  const navigate = useStableNavigate()
  const [searchParams] = useSearchParams()
  const [authenticate] =
    useCompanyConnectorControllerAuthenticateCompanyConnectorMutation()

  const [text, setText] = useState("Authenticating...")

  const redirect = (id?: string) => {
    const redirectUrl = id
      ? `${brandRoutes.SETUP.CONNECTORS}?${qs.stringify({ state: { companyConnectorId: id, type: ConnectorTypeEnum.CUSTOM } })}`
      : brandRoutes.SETUP.CONNECTORS

    setTimeout(() => navigate(redirectUrl), 1000)
  }

  const showErrorMessage = (error: any) => {
    if (
      error instanceof yup.ValidationError ||
      error.name === "ValidationError"
    ) {
      toast.error("Failed to authenticate the connector.", {
        toastId: "auth-connector-validation-error-id",
      })
    } else if (isNotFoundError(error)) {
      toast.error("Connection was not found.", {
        toastId: "auth-connector-not-found-error-id",
      })
    } else {
      toast.error("Unexcepted error. Try again later.", {
        toastId: "auth-connector-server-error-id",
      })
    }

    setText("Failed to authenticate connector. Redirecting...")
  }

  useEffect(() => {
    const beginAction = async () => {
      try {
        const result = await queryValidator.validate({
          state: searchParams.get("state"),
          authCode: searchParams.get("code") || searchParams.get("auth_code"),
        })

        const { companyConnectorId } = JSON.parse(result.state)
        await authenticate({
          id: companyConnectorId,
          authenticateCompanyConnectorDto: {
            authCode: result.authCode,
          },
        })

        setText("Success. Redirecting...")
        void redirect(companyConnectorId)
      } catch (error: any) {
        void showErrorMessage(error)
        void redirect()
      }
    }

    void beginAction()
  }, [])

  return <div>{text}</div>
}

export default AuthenticateConnector
