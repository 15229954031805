import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import qs from "qs"
import { toast } from "react-toastify"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"

import ConnectionStatusDialog from "../ConnectionStatusDialog"
import ConfirmConnectionDialog from "../ConfirmConnectionDialog"
import SuccessConnectionDialog from "../SuccessConnectionDialog"
import { H4 } from "~/ui-rtk/components/ui/typography"
import { Loader } from "~/ui-rtk/components/ui/common"
import { Topbar } from "~/ui-rtk/pages/Onboarding/ConnectSources/components"
import { SourceItem } from "./components"

import { useConnect } from "./connect"
import { serviceConnectorMap } from "../forms/utils"
import { confirmationDialogPropsMap, ConnectorTypeEnum } from "./utils"
import ConnectorLinkDialog from "../ConnectorLinkDialog"
import { useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation } from "~/ui-rtk/api/companyConnectorApi"
import {
  isBadRequestError,
  isInternalServerError,
  isUnauthorizedError,
} from "~/ui-rtk/utils/http-utils"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import SubscribeDialog from "../SubscribeDialog/SubscribeDialog"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { SessionRole } from "~/api/dto/auth/session-role"
import { CompanySetupStatus, ConnectionStatus } from "~/ui-rtk/api/types"

const ConnectorsTable: React.FC = () => {
  const [Dialog, setDialog] = useState<ReactNode | null>(null)
  const [onboardUrl, setOnboardUrl] = useState<string | null>(null)

  const currentCompany = useAppSelector(selectCurrentCompany)
  const role = useAppSelector(selectUserSessionRole)
  const isSuperAdmin = role === SessionRole.SUPER_ADMIN

  const [completeAsync, { isError: isCompleteAsyncError }] =
    useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation()

  const [searchParams] = useSearchParams()
  const fivetranId = searchParams.get("id")
  const { setIsLoading } = useLoadingState()

  const location = useLocation()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
    state?: { companyConnectorId?: string; type?: ConnectorTypeEnum }
  }

  const [stateParam, setStateParam] = useState(params?.state ?? null)
  const [searchTerm, setSearchTerm] = useState("")
  const [activeCategory, setActiveCategory] = useState<string>("Essential")
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false)

  const {
    categories,
    connectorsGroup,
    connectors,
    isLoading,
    isError,
    connect,
    getConnectorById,
    getCompanyConnectorByService,
    refetchConnectors,
  } = useConnect()

  const allEssentialConnectorsEnabled = useMemo(() => {
    if (!connectorsGroup["Essential"]) {
      return false
    }
    return connectorsGroup["Essential"].every(
      essentialConnector =>
        essentialConnector.status === ConnectionStatus.CONNECTED,
    )
  }, [connectorsGroup])

  const canShowSubscribeDialog = useMemo(
    () =>
      currentCompany?.metadata?.isBillingEnabled &&
      allEssentialConnectorsEnabled &&
      !isSuperAdmin &&
      !currentCompany.subscription &&
      currentCompany.setupStatus === CompanySetupStatus.INITIAL_SETUP,
    [currentCompany, allEssentialConnectorsEnabled],
  )

  const closeDialog = () => {
    setDialog(null)
  }

  const showSuccessMessage = () => {
    toast.success("Connection was successfully established!", {
      toastId: `onboarding-success-${fivetranId}`,
    })
  }

  const showErrorMessage = () => {
    toast.error("Failed to complete the onboarding", {
      toastId: `onboarding-failed-${fivetranId}`,
    })
  }

  useEffect(() => {
    if (isCompleteAsyncError) {
      void showErrorMessage()
      void refetchConnectors()
    }
  }, [isCompleteAsyncError])

  useEffect(() => {
    const completeOnboarding = async (id: string) => {
      setIsLoading(true)
      try {
        const result = await completeAsync({
          completeOnboardingDto: { fivetranId: id },
        })

        if ("data" in result) {
          void showSuccessMessage()
          void refetchConnectors()
        }
      } catch (error) {
        if (
          (isBadRequestError(error) ||
            isUnauthorizedError(error) ||
            isInternalServerError(error)) &&
          isCompleteAsyncError
        ) {
          void showErrorMessage()
          void refetchConnectors()
        }

        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (fivetranId) {
      void completeOnboarding(fivetranId)
    }
  }, [fivetranId])

  const connectorsByCategory = useMemo(() => {
    const connectors = connectorsGroup[activeCategory] || []
    if (!searchTerm) {
      return connectors
    }

    return connectors.filter(c => c.name.toLowerCase().includes(searchTerm))
  }, [activeCategory, searchTerm, connectorsGroup])

  const connectToService = useCallback(
    async (service: string, callback?: CallableFunction) => {
      try {
        const { companyConnectorId, uri } = await connect(service)

        const connectorService = serviceConnectorMap[service]
        if (connectorService) {
          const { component: ConnectorDialog } = connectorService
          setDialog(() => (
            <ConnectorDialog
              isOpen
              uri={uri}
              service={service}
              companyConnectorId={companyConnectorId}
              onClose={closeDialog}
            />
          ))
          return
        }

        setOnboardUrl(uri)
      } catch {
        toast.error("Failed to connect to the service. Try again later", {
          toastId: `fail-connect-to-service-${service}`,
        })
      } finally {
        void callback?.()
      }
    },
    [],
  )

  const openConfirmDialog = (id: string, service: string) => {
    const props = confirmationDialogPropsMap[service]
    if (props) {
      setDialog(() => (
        <ConfirmConnectionDialog
          isOpen
          companyConnectorId={id}
          onClose={closeDialog}
          {...props}
        />
      ))
    }
  }

  const openConnectionStatusDialog = (service: string) => {
    const companyConnector = getCompanyConnectorByService(service)
    if (!companyConnector) {
      toast.error(`Oops... Seems like this service is not ready yet.`)
      return
    }

    const connector = getConnectorById(service)
    const props = {
      label: connector?.name ?? "",
      imgSrc: connector?.icon ?? "",
    }

    const reconnect = () => connectToService(service)

    setDialog(() => (
      <ConnectionStatusDialog
        isOpen
        onClose={closeDialog}
        onReconnect={reconnect}
        confirmedAt={companyConnector.confirmedAt}
        companyConnectorId={companyConnector.id}
        {...props}
      />
    ))
  }

  const openSuccessDialog = (companyConnectorId: string, service: string) => {
    const companyConnector = getCompanyConnectorByService(service)
    if (!companyConnector) {
      toast.error(`Oops... Seems like this service is not ready yet.`)
      return
    }

    const props = confirmationDialogPropsMap[service]
    if (props) {
      const details: Omit<typeof props, "title"> & { title?: string } = {
        ...props,
      }
      delete details.title
      setDialog(() => (
        <SuccessConnectionDialog
          title={`Your connection to ${details.label} is pending!`}
          isOpen
          onClose={closeDialog}
          companyConnectorId={companyConnectorId}
          {...details}
        />
      ))
    }
  }

  const handleSearch = (value: string) => {
    const normalizedSearchTerm = value.toLocaleLowerCase().trim()
    setSearchTerm(normalizedSearchTerm)
  }

  useEffect(() => {
    if (onboardUrl) {
      window.location.href = onboardUrl
    }
  }, [onboardUrl])

  useEffect(() => {
    if (isError) {
      toast.error("Failed to load Data Connectors. Please, try again later", {
        toastId: "connectors-table-toast",
      })
    }
  }, [isError])

  useEffect(() => {
    if (canShowSubscribeDialog) {
      setShowSubscriptionDialog(true)
    }
  }, [canShowSubscribeDialog])

  useEffect(() => {
    const beginAction = (
      id: string,
      type: ConnectorTypeEnum = ConnectorTypeEnum.FIVETRAN,
    ) => {
      const companyConnector = connectors.find(c => c.id === id)
      if (companyConnector && type === ConnectorTypeEnum.CUSTOM) {
        void openConfirmDialog(companyConnector.id, companyConnector.service)
      } else if (companyConnector && type === ConnectorTypeEnum.FIVETRAN) {
        void openSuccessDialog(companyConnector.id, companyConnector.service)
      }

      void setStateParam(null)
      window.history.replaceState({}, "", `${location.pathname}`)
    }

    const connectorType = stateParam?.type
    const companyConnectorId = stateParam?.companyConnectorId
    if (companyConnectorId && connectors?.length) {
      void beginAction(companyConnectorId, connectorType)
    }
  }, [connectors])

  const subscriptionDialog = showSubscriptionDialog ? (
    <SubscribeDialog onClose={() => setShowSubscriptionDialog(false)} />
  ) : null

  if (isError) {
    return <Navigate to="/" />
  }

  return (
    <div className="md:px-6 md:py-8 space-y-6">
      <div className="flex items-center justify-between">
        <H4 className="font-bold text-marketing-warroom-tw-light">
          Select a connection below
        </H4>
      </div>

      <Topbar
        value={activeCategory}
        categories={categories}
        onSearch={handleSearch}
        onSelect={setActiveCategory}
      />

      {!isLoading ? (
        <ul className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2">
          {connectorsByCategory.map(connector => (
            <SourceItem
              {...connector}
              key={connector.id}
              image={{ src: connector.icon, alt: `${name} Logo` }}
              onConnect={connectToService}
              onStatusCheck={openConnectionStatusDialog}
              onOpenConnectionLinkDialog={() => {
                setDialog(
                  <ConnectorLinkDialog
                    connectorId={connector.id}
                    imgSrc={connector.icon}
                    service={connector.name}
                    onClose={closeDialog}
                  />,
                )
              }}
            />
          ))}
        </ul>
      ) : (
        <Loader size="large" />
      )}
      {Dialog}
      {subscriptionDialog}
      <div className="inline-block text-base text-left text-gray-700 leading-relaxed mt-6 mb-4 border border-yellow-info rounded-lg p-3">
        <p>
          <span className="font-bold">Just a heads up: </span>
          We're working hard to process all your data as quickly as possible.
          However, each platform we connect to has limits on how much data we
          can access and how fast we can download it. This means the process can
          vary, typically taking a day or two, but sometimes up to a week for
          larger datasets.
        </p>
        <br />
        <p>
          Once you've connected your essential connectors, we'll get started on
          building your customer brand model! This process involves analyzing
          your data and may take a few days to complete. In the meantime, you
          can continue adding more connections and keywords to enhance your
          model. We'll be sure to reach out and let you know when it's ready. We
          appreciate your patience and look forward to helping you build more
          resilient baseline revenue!
        </p>
      </div>
    </div>
  )
}

export default ConnectorsTable
