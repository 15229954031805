import { InfoDialog } from "~/ui-rtk/components/ui/common"
import { DIALOG } from "~/ui-rtk/components/ui/common/InfoDialog"

type TIncompletePeriodDialogProps = {
  onClose: () => void
  startDate: string
  endDate: string
  lastSyncDate: string
}

export default function IncompletePeriodDialog({
  onClose,
  startDate,
  endDate,
  lastSyncDate,
}: TIncompletePeriodDialogProps) {
  return (
    <InfoDialog
      isOpen={true}
      handleClose={onClose}
      type={DIALOG.WARNING}
      width={560}
    >
      <>
        <div className="grid grid-cols-8 gap-4">
          <div>&nbsp;</div>
          <div className="col-span-7 text-start">
            Please be aware that your are looking at incomplete period.
            <br />
            Your selected period:{" "}
            <strong>
              {startDate} - {endDate}
            </strong>
            <br />
            Last sync date: <strong>{lastSyncDate}</strong>
          </div>
        </div>
      </>
    </InfoDialog>
  )
}
