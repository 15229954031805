import { useEffect, useRef, useState } from "react"
import useConnect, { type TKeywordsPageFormValues } from "./connect"
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik"
import { H2 } from "~/ui-rtk/components/ui/typography"
import { Divider, InfoDialog } from "~/ui-rtk/components/ui/common"
import {
  ChipsInput,
  Button,
  Checkbox,
  Input,
} from "~/ui-rtk/components/ui/controls"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { DIALOG } from "~/ui-rtk/components/ui/common/InfoDialog"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export default function KeywordsPage() {
  const {
    companyName,
    initialValues,
    validationSchema,
    updateKeywords,
    dontShowDialogAgain,
    setDontShowDialogAgain,
  } = useConnect()
  const { isLoading, setIsLoading } = useLoadingState()
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  const [uiDontShowDialog, setUiDontShowDialog] = useState(false)
  const formRef = useRef<FormikProps<TKeywordsPageFormValues> | null>(null)

  useEffect(() => {
    document.title = "Marathon Data: Settings > Keywords"
    setUiDontShowDialog(dontShowDialogAgain)
  }, [])

  const handleUpdateKeywords = async (values: TKeywordsPageFormValues) => {
    setIsLoading(true)
    await updateKeywords(values)
    setIsLoading(false)
    setShowInfoDialog(true)
    setUiDontShowDialog(dontShowDialogAgain)
  }

  const handleCloseInfoDialog = () => {
    setShowInfoDialog(false)
  }

  const handleSubmit = () => {
    if (formRef.current?.isValid) {
      handleUpdateKeywords(formRef.current?.values).catch(() => null)
    }
  }

  const demo = useAppSelector(selectIsEnabled)

  const infoDialogContent =
    !uiDontShowDialog && showInfoDialog ? (
      <InfoDialog
        isOpen={true}
        handleClose={handleCloseInfoDialog}
        type={DIALOG.SUCCESS}
      >
        <>
          <div className="grid grid-cols-8 gap-4">
            <div>&nbsp;</div>
            <div className="col-span-7 text-start">
              Your changes have been saved.
              <br />
              Please note, that data will be updated in 24 hours
            </div>
          </div>
          <hr className="mt-3 mb-2 border-states-success" />
          <div className="grid grid-cols-8 gap-4">
            <div>
              <Checkbox
                checked={dontShowDialogAgain}
                onChange={evt => setDontShowDialogAgain(evt.target.checked)}
                className="border-states-success bg-background-dark-shade text-states-success"
              />
            </div>
            <div className="col-span-7 text-start">Don't show this again</div>
          </div>
        </>
      </InfoDialog>
    ) : null

  return (
    <div className="px-6 pt-6">
      <div className="text-start grid grid-cols-2 align-center">
        <H2>
          <Breadcrumbs
            items={[
              {
                slug: "/setup",
                name: "Setup",
              },
              {
                slug: "keywords-and-competitors",
                name: "Keywords + Competitors",
              },
            ]}
          />
        </H2>
        <div className="flex aling-center justify-end gap-4">
          <Button
            variant={{ variant: "solid", color: "pink" }}
            size="sm"
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </div>
      <div
        className={cn(
          "space-y-8 mt-4 pr-8 text-start",
          demo.isEnabled && "blur-md",
        )}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpdateKeywords}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
        >
          <Form className="py-6">
            <div className="grid grid-cols-3 grid-rows-4 gap-8 py-6">
              <div className="row-span-4">Google</div>
              <div className="relative col-span-2">
                <label className="leading-8" htmlFor="googleCategories">
                  Primary Brand Keyword:
                </label>
                <br />
                <Field
                  id="googleSearchAlias"
                  name="googleSearchAlias"
                  as={Input}
                  type="text"
                  fullWidth
                  placeholder={companyName}
                />
                <ErrorMessage
                  component="a"
                  name="googleSearchAlias"
                  className="absolute input-error-message"
                />
              </div>
              <div className="relative col-span-2">
                <label className="leading-8" htmlFor="googleCategories">
                  Brand Keywords *:
                </label>
                <br />
                <Field id="googleBrands" name="googleBrands">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    // meta,
                  }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue("googleBrands", value)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  component="a"
                  name="googleBrands"
                  className="absolute input-error-message"
                />
              </div>
              <div className="relative col-span-2">
                <label className="leading-8" htmlFor="googleCategories">
                  Competitor Keywords *:
                </label>
                <br />
                <Field id="googleCompetitors" name="googleCompetitors">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    // meta,
                  }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue("googleCompetitors", value)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  component="a"
                  name="googleCompetitors"
                  className="absolute input-error-message"
                />
              </div>
              <div className="relative col-span-2">
                <label className="leading-8" htmlFor="googleCategories">
                  Category Keywords *:
                </label>
                <br />
                <Field id="googleCategories" name="googleCategories">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    // meta,
                  }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue("googleCategories", value)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  component="a"
                  name="googleCategories"
                  className="absolute input-error-message"
                />
              </div>
            </div>
            <Divider className="my-3" />
            <div className="grid grid-cols-3 grid-rows-1 py-6">
              <div>Instagram</div>
              <div className="relative col-span-2">
                <label className="leading-8" htmlFor="instagramCompetitors">
                  Competitor Ids *:
                </label>
                <br />
                <Field id="instagramCompetitors" name="instagramCompetitors">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    // meta,
                  }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue("instagramCompetitors", value)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  component="a"
                  name="instagramCompetitors"
                  className="absolute input-error-message"
                />
              </div>
            </div>
          </Form>
        </Formik>
        {infoDialogContent}
      </div>
    </div>
  )
}
