import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const CheckMarkSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2178 5.80377C21.6023 6.20021 21.5927 6.83331 21.1962 7.21782L9.85507 18.2178C9.46687 18.5943 8.84968 18.594 8.46187 18.2171L2.80303 12.7171C2.40699 12.3322 2.39797 11.6991 2.7829 11.303C3.16783 10.907 3.80093 10.898 4.19697 11.2829L9.15956 16.1062L19.8038 5.78218C20.2002 5.39766 20.8333 5.40733 21.2178 5.80377Z"
      fill={fill}
    />
  </svg>
))
