import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const CalendarSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8333 2C13.03 2 14 2.97005 14 4.16667V11.8333C14 13.03 13.03 14 11.8333 14H4.16667C2.97005 14 2 13.03 2 11.8333V4.16667C2 2.97005 2.97005 2 4.16667 2H11.8333ZM13 5.66667H3V11.8333C3 12.4777 3.52233 13 4.16667 13H11.8333C12.4777 13 13 12.4777 13 11.8333V5.66667ZM5.16667 9.66667C5.6269 9.66667 6 10.0398 6 10.5C6 10.9602 5.6269 11.3333 5.16667 11.3333C4.70643 11.3333 4.33333 10.9602 4.33333 10.5C4.33333 10.0398 4.70643 9.66667 5.16667 9.66667ZM8 9.66667C8.46024 9.66667 8.83333 10.0398 8.83333 10.5C8.83333 10.9602 8.46024 11.3333 8 11.3333C7.53976 11.3333 7.16667 10.9602 7.16667 10.5C7.16667 10.0398 7.53976 9.66667 8 9.66667ZM5.16667 7C5.6269 7 6 7.3731 6 7.83333C6 8.29357 5.6269 8.66667 5.16667 8.66667C4.70643 8.66667 4.33333 8.29357 4.33333 7.83333C4.33333 7.3731 4.70643 7 5.16667 7ZM8 7C8.46024 7 8.83333 7.3731 8.83333 7.83333C8.83333 8.29357 8.46024 8.66667 8 8.66667C7.53976 8.66667 7.16667 8.29357 7.16667 7.83333C7.16667 7.3731 7.53976 7 8 7ZM10.8333 7C11.2936 7 11.6667 7.3731 11.6667 7.83333C11.6667 8.29357 11.2936 8.66667 10.8333 8.66667C10.3731 8.66667 10 8.29357 10 7.83333C10 7.3731 10.3731 7 10.8333 7ZM11.8333 3H4.16667C3.52233 3 3 3.52233 3 4.16667V4.66667H13V4.16667C13 3.52233 12.4777 3 11.8333 3Z"
      fill={fill}
    />
  </svg>
))
