import React, { useCallback } from "react"
import { Option } from "."
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type Props = {
  option: Option
  className?: string
  disabled?: boolean
  onSelect: (option: Option) => void
}

const SelectItem: React.FC<Props> = ({
  option,
  onSelect,
  className,
  disabled = false,
}) => {
  const handleSelect = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        return
      }

      event.stopPropagation()
      onSelect(option)
    },
    [onSelect, option, disabled],
  )

  return (
    <li
      className={cn(
        "px-4 py-2 font-medium cursor-pointer hover:bg-background-dark-shade text-cadet-blue",
        className,
        disabled && "text-white cursor-not-allowed bg-neutral hover:bg-neutral",
      )}
      onClick={handleSelect}
    >
      {option.label}
    </li>
  )
}

export default SelectItem
