import React, { useState } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { Button } from "~/ui-rtk/components/ui/controls"

type FileUploadProps = {
  onUpload?: (files: FileList | null) => void

  classes?: Partial<{
    button: string
    container: string
  }>
  maxSize?: number
  maxFiles?: number
  isMultiple?: boolean
  fullWidth?: boolean
  text?: string
  label?: string
  accept?: string
}

const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  accept = "text/csv",
  maxSize = 50,
  maxFiles = 10,
  isMultiple = false,
  text = "Select File",
  classes,
  label,
}) => {
  const [error, setError] = useState<string | null>(null)
  const [uploadFiles, setUploadFiles] = useState<FileList | null>(null)

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    const files = event.currentTarget.files

    if (!files) {
      return
    }

    if (files.length > maxFiles) {
      setError(`Too many files! Please upload up to ${maxFiles} files`)
      return
    }

    let isMaxSizeError = false
    const maxSizeBytes = maxSize * 1024 * 1024
    for (let i = 0; i < files.length; i++) {
      const fileSize = files[i].size
      if (fileSize > maxSizeBytes) {
        isMaxSizeError = true
        break
      }
    }

    if (isMaxSizeError) {
      setError(`File size must be less than ${maxSize}MB`)
      return
    }

    setUploadFiles(files)
    onUpload?.(files)
  }

  return (
    <div className={cn("flex flex-col space-y-0.5", classes?.container)}>
      {label ? (
        <label className="ml-1 text-3.5 text-platinum font-bold">{label}</label>
      ) : null}
      <Button
        variant={{ variant: "solid", color: "white" }}
        className={cn("relative", classes?.button)}
      >
        <span>{text}</span>
        <input
          type="file"
          accept={accept}
          maxLength={maxFiles}
          multiple={isMultiple}
          onChange={handleUpload}
          className="absolute inset-0 z-10 opacity-0 cursor-pointer focus:ring-0"
        />
      </Button>
      <div className="flex items-center justify-between">
        {error ? (
          <span className="ml-1 text-sm text-error">{error}</span>
        ) : (
          <span className="text-sm text-platinum/[.8] ml-1">
            {uploadFiles?.length
              ? `${uploadFiles.length} ${uploadFiles.length > 1 ? "files" : "file"} selected`
              : ""}
          </span>
        )}

        <span className="text-sm text-platinum">
          Max {maxFiles} files ({maxSize}MB each)
        </span>
      </div>
    </div>
  )
}

export default FileUpload
