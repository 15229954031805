import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useConnect from "./connect"
import BrandHealthOverviewDashboard from "~/ui-rtk/components/features/dashboards/BrandHealthOverviewDashboard/BrandHealthOverviewDashboard"
import WithSources from "~/ui-rtk/components/layout/WithSources/WithSources"

export default function BrandsHealthOverview() {
  const { content, isLoading } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <WithSources>
        <BrandHealthOverviewDashboard
          slug={content.slug}
          charts={content.content.charts}
          title={content.content.title}
          parent={content.parent}
          periodPicker={content.content.periodPicker}
        />
      </WithSources>
    </WithLoader>
  )
}
