import { useCallback, useEffect, useState } from "react"

import { dateMax, dateMin } from "~/ui-rtk/utils/date-utils"
import { weekAgo, today, twoWeeksAgo, isDateAfter } from "../utils"
import { useMinMaxDates } from "./useMinMaxDates"

import {
  RangeTags,
  type DateOrNull,
  type TRangeType,
  type TDateRestrictions,
} from "../types"
import { DatePreset } from "../presets"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const usePeriodPicker = (
  dateRange?: TRangeType,
  compareRange?: TRangeType | null,
  restrictions?: TDateRestrictions,
  comparisonEnabled = false,
) => {
  const [defaultRangeStart, defaultRangeEnd] = dateRange ?? [weekAgo, today]
  const [defaultCompareRangeStart, defaultCompareRangeEnd] = compareRange ?? [
    twoWeeksAgo,
    weekAgo,
  ]

  // Hook state
  const [preset, setPreset] = useState<string | null>(null)
  const [comparePreset, setComparePreset] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isCompare, setIsCompare] = useState(false)
  const [focusedInputTag, setFocusedInput] = useState(RangeTags.rangeStart)
  const [rangeStartDate, setRangeStartDate] = useState(defaultRangeStart)
  const [rangeEndDate, setRangeEndDate] = useState(defaultRangeEnd)
  const [compareRangeStartDate, setCompareRangeStartDate] =
    useState<DateOrNull>(defaultCompareRangeStart)
  const [compareRangeEndDate, setCompareRangeEndDate] = useState<DateOrNull>(
    defaultCompareRangeEnd,
  )

  const [isManuallyChanged, setIsManuallyChanged] = useState(false)
  const [manuallyChangingTag, setManuallyChangingTag] =
    useState<RangeTags | null>(null)

  const clearManualChanging = () => {
    setFocusedInput(prevTag => {
      const isStartTag = prevTag % 2 === 0
      return isStartTag ? prevTag : prevTag - 1
    })
    setIsManuallyChanged(false)
  }

  const { minDate, maxDate } = useMinMaxDates(
    isCompare,
    focusedInputTag,
    rangeStartDate,
    restrictions,
  )

  // Handlers
  const toggleCompare = useCallback(() => {
    void setIsCompare(prev => {
      const newState = !prev
      setFocusedInput(
        newState ? RangeTags.compareRangeStart : RangeTags.rangeStart,
      )
      return newState
    })

    const diff = dayjs(rangeEndDate).diff(rangeStartDate, "days")
    setCompareRangeStartDate(
      dayjs(rangeStartDate)
        .subtract(diff + 1, "days")
        .toDate(),
    )
    setCompareRangeEndDate(dayjs(rangeStartDate).subtract(1, "days").toDate())
  }, [rangeEndDate, rangeStartDate, isCompare])

  const resetPreset = () => {
    void setPreset(null)
  }

  const resetComparePreset = () => {
    void setComparePreset(null)
  }

  const reset = useCallback(() => {
    resetPreset()
    resetComparePreset()
    setIsCompare(comparisonEnabled && (compareRange?.length ?? 0) > 0)
    setFocusedInput(RangeTags.rangeStart)
    setRangeStartDate(defaultRangeStart)
    setRangeEndDate(defaultRangeEnd)
    setCompareRangeStartDate(defaultCompareRangeStart)
    setCompareRangeEndDate(defaultCompareRangeEnd)
    setIsManuallyChanged(false)
  }, [])

  const open = () => {
    void setIsOpen(true)
  }

  const close = () => {
    void setIsOpen(false)
  }

  const focusInput = (tag: RangeTags) => {
    setFocusedInput(tag)
    setIsManuallyChanged(true)
  }

  const applyPreset = useCallback(
    (preset: DatePreset, isComparePreset: boolean) => () => {
      if (isComparePreset) {
        setComparePreset(preset.id)
        setCompareRangeStartDate(
          preset.startDate(dayjs(rangeStartDate)).toDate(),
        )
        setCompareRangeEndDate(
          preset.endDate(dayjs(rangeStartDate), dayjs(rangeEndDate)).toDate(),
        )
        return
      }
      setPreset(preset.id)
      setRangeStartDate(preset.startDate().toDate())
      setRangeEndDate(preset.endDate().toDate())
    },
    [rangeStartDate, rangeEndDate],
  )

  useEffect(() => {
    setRangeEndDate(defaultRangeEnd)
    setRangeStartDate(defaultRangeStart)
    setCompareRangeStartDate(defaultCompareRangeStart)
    setCompareRangeEndDate(defaultCompareRangeEnd)
    // NOTE: disabled for MVP
    // setIsCompare(Boolean(compareRange))
    setIsCompare(comparisonEnabled && (compareRange?.length ?? 0) > 0)
  }, [
    isOpen,
    defaultRangeEnd,
    defaultRangeStart,
    defaultCompareRangeStart,
    defaultCompareRangeEnd,
    compareRange,
    comparisonEnabled,
  ])

  const handleRangeStartDateUpdate = useCallback(
    (date: Date) => {
      const selectedDate = dateMax(dateMin(date, maxDate), minDate)
      if (isDateAfter(selectedDate, rangeEndDate)) {
        setRangeEndDate(selectedDate)
        setRangeStartDate(rangeEndDate)
        return
      }
      setRangeStartDate(selectedDate)
    },
    [minDate, maxDate, rangeEndDate],
  )

  const handleRangeEndDateUpdate = useCallback(
    (date: Date) => {
      const selectedDate = dateMax(dateMin(date, maxDate), minDate)
      if (rangeStartDate && isDateAfter(rangeStartDate, selectedDate)) {
        setRangeStartDate(selectedDate)
        setRangeEndDate(rangeStartDate)
        return
      }
      setRangeEndDate(selectedDate)
    },
    [minDate, maxDate, rangeStartDate],
  )

  const handleCompareRangeStartDateUpdate = useCallback(
    (date: Date) => {
      const selectedDate = dateMax(dateMin(date, maxDate), minDate)
      setCompareRangeStartDate(selectedDate)
      const shouldUpdateEndDate =
        !compareRangeEndDate || isDateAfter(selectedDate, compareRangeEndDate)

      if (shouldUpdateEndDate) {
        setCompareRangeEndDate(selectedDate)
      }
    },
    [minDate, maxDate, compareRangeEndDate, rangeEndDate],
  )

  const handleCompareRangeEndDateUpdate = useCallback(
    (date: Date) => {
      const selectedDate = dateMax(dateMin(date, maxDate), minDate)
      if (
        compareRangeStartDate &&
        isDateAfter(compareRangeStartDate, selectedDate)
      ) {
        setCompareRangeStartDate(selectedDate)
        setCompareRangeEndDate(compareRangeStartDate)
        return
      }
      setCompareRangeEndDate(selectedDate)
    },
    [minDate, maxDate, compareRangeStartDate],
  )

  // action triggered when a calendar date is selected
  const updateRange = useCallback(
    (date: DateOrNull | undefined, tag: RangeTags) => {
      if (!date) {
        return
      }

      switch (tag) {
        case RangeTags.rangeStart:
          resetPreset()
          handleRangeStartDateUpdate(date)
          break
        case RangeTags.rangeEnd:
          resetPreset()
          handleRangeEndDateUpdate(date)
          break
        case RangeTags.compareRangeStart:
          resetComparePreset()
          handleCompareRangeStartDateUpdate(date)
          break
        case RangeTags.compareRangeEnd:
          resetComparePreset()
          handleCompareRangeEndDateUpdate(date)
          break
      }

      if (!isManuallyChanged) {
        setFocusedInput(prevTag => {
          const isStartTag = prevTag % 2 === 0
          return isStartTag ? prevTag + 1 : prevTag - 1
        })
      } else if (isManuallyChanged) {
        setIsManuallyChanged(false)
      }
    },
    [
      focusedInputTag,
      isManuallyChanged,
      clearManualChanging,
      handleRangeStartDateUpdate,
      handleRangeEndDateUpdate,
      handleCompareRangeStartDateUpdate,
      handleCompareRangeEndDateUpdate,
    ],
  )

  return {
    // open/close handlers
    isOpen,

    // hanlders
    actions: {
      open,
      close,
      reset,
      applyPreset,
      toggleCompare,
      updateRange,
      focusInput,
      clearManualChanging,
      setManuallyChangingTag,
    },

    // state
    state: {
      isCompare,
      focusedInputTag,
      rangeStartDate,
      rangeEndDate,
      compareRangeStartDate,
      compareRangeEndDate,
      minDate,
      maxDate,
      preset,
      comparePreset,
      manuallyChangingTag,
      default: {
        rangeStartDate: defaultRangeStart,
        rangeEndDate: defaultRangeEnd,
        compareStartDate: defaultCompareRangeStart,
        compareEndDate: defaultCompareRangeEnd,
      },
    },
  }
}

export default usePeriodPicker
