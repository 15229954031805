import React, { type PropsWithChildren, type ReactNode } from "react"
import { H1 } from "~/ui-rtk/components/ui/typography"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TClasses = Partial<{
  container: string
  content: string
  contentContainer: string
}>

type TContentBoxProps = {
  title: string
  subTitle?: string | ReactNode
  classes?: TClasses
  disableGradient?: boolean
} & PropsWithChildren

const ContentBox: React.FC<TContentBoxProps> = ({
  title,
  subTitle,
  children,
  classes,
  disableGradient,
}) => (
  <div
    className={cn(
      "relative z-10 max-w-180 w-full sm:min-h-100 flex flex-col items-center justify-center gap-10 mx-auto bg-basic-white/[.07] border border-basic-white/20 shadow-drop-shadow-main py-16 rounded-2xl",
      classes?.container,
    )}
  >
    {!disableGradient && (
      <React.Fragment>
        <div className="absolute z-0 top-[10%] -left-[35%] bg-custom-radial-purple w-320 rotate-[35deg] h-128" />
        <div className="absolute z-0 top-[30%] right-[5%] bg-custom-radial-yellow w-95 h-160 rotate-[54deg]" />
      </React.Fragment>
    )}

    <div
      className={cn(
        "relative w-full z-10 mx-auto lg:px-25 px-6 order-1 flex flex-col items-center",
        classes?.contentContainer,
      )}
    >
      <div
        className={cn("space-y-6 text-center text-white", subTitle && "mb-8")}
      >
        <H1 className="font-bold leading-13 text-10.5">{title}</H1>
        <p className="leading-5 tracking-[-1%]">{subTitle}</p>
      </div>
      <div className={cn("max-w-100 w-full", classes?.content)}>{children}</div>
    </div>
  </div>
)

export default ContentBox
