import AdDetails from "~/ui-rtk/components/features/details/AdDetails/AdDetails"
import AdSetDetails from "~/ui-rtk/components/features/details/AdSetDetails/AdSetDetails"
import CampaignDetails from "~/ui-rtk/components/features/details/CampaignDetails/CampaignDetails"
import { ElementType } from "react"
import { TMetric } from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import PostDetails from "./PostDetails/PostDetails"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"

export type TCampaignAdSetAdDetailsProps = {
  metric: TMetric
  date: string
  granularity: BrandMediaAggregationType
}

export const CampaignAdSetAdStringToComponentMap: Record<string, ElementType> =
  {
    CampaignDetails,
    AdSetDetails,
    AdDetails,
    PostDetails,
  }

export enum VALUE_OVER_TIME_MODE {
  TOTAL,
  BRAND_VALUE,
}
export type TDataKey = "total" | "brandValue"
export const VALUE_OVER_TIME_KEY_MAP: Record<VALUE_OVER_TIME_MODE, TDataKey> = {
  [VALUE_OVER_TIME_MODE.TOTAL]: "total",
  [VALUE_OVER_TIME_MODE.BRAND_VALUE]: "brandValue",
}
export const VALUE_OVER_TIME_LABEL_MAP: Record<VALUE_OVER_TIME_MODE, string> = {
  [VALUE_OVER_TIME_MODE.TOTAL]: "T. Value",
  [VALUE_OVER_TIME_MODE.BRAND_VALUE]: "B. Value",
}
export const ignoreTableHeads = ["Source"]

export const getCubeQueryBase = (tablePrefix: string) => ({
  measures: [`${tablePrefix}.brand_value_agg`, `${tablePrefix}.spend`],
  dimensions: [`${tablePrefix}.brand_value`],
})

export const CUBE_QUERY_KEYS_MAP = {
  ALL: "ALL",
  VALUE_OVER_TIME: "VALUE_OVER_TIME",
}

export type TAdsetMediaOption =
  | "Exclude"
  | "Demand Capture - Acquisition"
  | "Demand Capture - Retargeting"
  | "Demand Capture - Broad"
  | "Demand Generation"
  | "Retention"

export type TCampaignMediaOption =
  | "Exclude"
  | "Demand Capture - Acquisition"
  | "Demand Capture - Retargeting"
  | "Demand Capture - Broad"
  | "Demand Generation"
  | "Retention"

export type TAdMediaOption =
  | "Exclude"
  | "Prompted Engagements"
  | "Meme Content"
  | "Branded Content"

export type TPostMediaOption =
  | "Exclude"
  | "Prompted Engagements"
  | "Meme Content"
  | "Branded Content"

export const CAMPAIGN_MEDIA_CATEGORIES_OPTIONS: TCampaignMediaOption[] = [
  "Exclude",
  "Demand Capture - Acquisition",
  "Demand Capture - Retargeting",
  "Demand Capture - Broad",
  "Demand Generation",
  "Retention",
]

export const ADSET_MEDIA_CATEGORIES_OPTIONS: TAdsetMediaOption[] = [
  "Exclude",
  "Demand Capture - Acquisition",
  "Demand Capture - Retargeting",
  "Demand Capture - Broad",
  "Demand Generation",
  "Retention",
]

export const AD_MEDIA_CATEGORIES_OPTIONS: TAdMediaOption[] = [
  "Exclude",
  "Prompted Engagements",
  "Meme Content",
  "Branded Content",
]

export const POST_MEDIA_CATEGORIES_OPTIONS: TPostMediaOption[] = [
  "Exclude",
  "Prompted Engagements",
  "Meme Content",
  "Branded Content",
]
