import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const TrendingDownSVG = SvgHOC(({ ...props }) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.72839 10.7083L12.8758 10.7081L12.9176 10.7023L12.9588 10.6908L12.9839 10.6802C13.0169 10.6657 13.0478 10.6445 13.075 10.6174L13.092 10.599L13.1101 10.5755L13.1327 10.538L13.1488 10.5003L13.1568 10.4736L13.1624 10.447L13.1663 10.4077L13.1665 7.26922C13.1665 7.09663 13.0266 6.95672 12.854 6.95672C12.6958 6.95672 12.5651 7.07429 12.5444 7.22682L12.5415 7.26922L12.5413 9.64209L9.32516 6.42532C9.21422 6.31437 9.0406 6.30428 8.91826 6.39505L8.88321 6.42531L7.64551 7.66296L5.36648 5.38393C5.24444 5.26189 5.04658 5.26189 4.92454 5.38393C4.81359 5.49487 4.80351 5.66848 4.89428 5.79082L4.92454 5.82587L7.42454 8.32587C7.53548 8.43681 7.70909 8.4469 7.83142 8.35613L7.86647 8.32587L9.10417 7.08823L12.0988 10.0833H9.72839C9.57018 10.0833 9.43944 10.2009 9.41874 10.3534L9.41589 10.3958C9.41589 10.5541 9.53346 10.6848 9.68599 10.7055L9.72839 10.7083Z"
      fill="currentColor"
    />
  </svg>
))
