import { TierDto } from "~/ui-rtk/api/types"

/**
 * Determines the appropriate pricing tier based on a given threshold.
 * @param thresholdValue - The threshold value to compare.
 * @param priceTiers - Array of price tiers.
 * @returns {PriceTier | undefined} - The matched pricing tier, or undefined if no match.
 */
export function getPricingTier(
  revenueValue: number,
  priceTiers: TierDto[],
): TierDto | undefined {
  const revenue = Math.floor(Number(revenueValue))
  const sortedTiers = [...priceTiers].sort((a, b) => {
    if (a.threshold === null) return 1
    if (b.threshold === null) return -1
    return Number(a.threshold) - Number(b.threshold)
  })

  for (const tier of sortedTiers) {
    if (tier.threshold === null || revenue < tier.threshold) {
      return tier
    }
  }

  return sortedTiers[sortedTiers.length - 1]
}
