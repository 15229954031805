import { DateOrNull } from "./types"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export const generateDateRange = (
  startDate: DateOrNull,
  endDate: DateOrNull,
): Date[] =>
  endDate && startDate && dayjs(endDate).isValid() && dayjs(startDate).isValid()
    ? Array(Math.abs(dayjs(endDate).add(1, "day").diff(startDate, "days")))
        .fill(null)
        .map((_, index) => dayjs(startDate).add(index, "day").toDate())
    : []

export const today = new Date()
export const weekAgo = dayjs(today).startOf("day").subtract(6, "days").toDate()
export const twoWeeksAgo = dayjs(today)
  .startOf("day")
  .subtract(13, "days")
  .toDate()

export const minDate = dayjs().subtract(5, "years").startOf("year").toDate()

export const isDateAfter = (date: Date, compareDate: Date) =>
  date.getTime() > compareDate.getTime()
