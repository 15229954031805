import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const CloseSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 4L14 14M4 14L14 4"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))
