import { TOTAL_CALCULATED_DATA_KEY } from "~/ui-rtk/constants/charts"
import type { ConfigItem, TComposeProps } from "./types"

export const composeMetrics = (
  data: any,
  props: TComposeProps,
  isComparing?: boolean,
) => {
  const result: Record<string, any> = {}
  const comparedTempResults: Record<string, any> = {}

  const { columnConfig: config, calculateTotal } = props

  config.forEach((columnConfig: ConfigItem) => {
    if (
      (columnConfig.computeConfig && !columnConfig.required) ||
      columnConfig.isBreakdown
    ) {
      return
    }

    const keys = columnConfig.required?.keys ?? columnConfig.keys

    keys.forEach(key => {
      if (!result[key]) {
        result[key] = data.reduce(
          (acc: number, row: any) =>
            acc + (Number.isNaN(row[key]) ? 0 : (row[key] ?? 0)),
          0,
        )
      }
      if (isComparing) {
        const compareKey = `compared.${key}`
        if (!result[compareKey]) {
          result[compareKey] = data.reduce(
            (acc: number, row: any) =>
              acc +
              (Number.isNaN(row[compareKey]) ? 0 : (row[compareKey] ?? 0)),
            0,
          )
        }
      }
    })
  })

  if (calculateTotal) {
    config.forEach((columnConfig: ConfigItem) => {
      if (
        (columnConfig.computeConfig && !columnConfig.required) ||
        columnConfig.isBreakdown
      ) {
        return
      }

      result[
        `${TOTAL_CALCULATED_DATA_KEY}.${columnConfig.required?.dataKey ?? columnConfig.dataKey}`
      ] = (columnConfig.required?.keys ?? columnConfig.keys ?? []).reduce(
        (acc, key) => acc + result[key],
        0,
      )

      if (isComparing) {
        result[
          `compared.${TOTAL_CALCULATED_DATA_KEY}.${columnConfig.required?.dataKey ?? columnConfig.dataKey}`
        ] = (columnConfig.required?.keys ?? columnConfig.keys ?? []).reduce(
          (acc, key) => acc + result[`compared.${key}`],
          0,
        )
      }
    })
  }

  if (isComparing) {
    config.forEach((columnConfig: ConfigItem) => {
      if (
        !columnConfig ||
        (columnConfig.computeConfig && !columnConfig.required) ||
        columnConfig.isBreakdown
      ) {
        return
      }

      const keys = columnConfig.required?.keys ?? columnConfig.keys

      keys.forEach(key => {
        comparedTempResults[key] = result[`compared.${key}`]
      })
    })

    if (calculateTotal) {
      config.forEach((columnConfig: ConfigItem) => {
        if (
          (columnConfig.computeConfig && !columnConfig.required) ||
          columnConfig.isBreakdown
        ) {
          return
        }
        comparedTempResults[
          `${TOTAL_CALCULATED_DATA_KEY}.${columnConfig.required?.dataKey ?? columnConfig.dataKey}`
        ] =
          result[
            `compared.${TOTAL_CALCULATED_DATA_KEY}.${columnConfig.required?.dataKey ?? columnConfig.dataKey}`
          ]
      })
    }
  }

  config.forEach((columnConfig: ConfigItem) => {
    if (!columnConfig || !columnConfig.computeConfig) {
      return
    }

    const { compute, keySuffix } = columnConfig.computeConfig

    columnConfig.keys.forEach(key => {
      const keyPrefix = key.replace(keySuffix, "")
      result[key] = compute(result, keyPrefix)

      if (isComparing) {
        result[`compared.${key}`] = compute(comparedTempResults, keyPrefix)
      }
    })

    if (calculateTotal) {
      result[`${TOTAL_CALCULATED_DATA_KEY}.${keySuffix}`] = compute(
        result,
        `${TOTAL_CALCULATED_DATA_KEY}.`,
      )

      if (isComparing) {
        result[`compared.${TOTAL_CALCULATED_DATA_KEY}.${keySuffix}`] = compute(
          comparedTempResults,
          `${TOTAL_CALCULATED_DATA_KEY}.`,
        )
      }
    }
  })

  return result
}
