import React from "react"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { H2 } from "~/ui-rtk/components/ui/typography"

type TDashboardBreadcrumbsProps = {
  slug: string
  title: string
  parent: {
    slug: string
    title: string
  }
}

const DashboardBreadcrumbs: React.FC<TDashboardBreadcrumbsProps> = ({
  slug,
  title,
  parent,
}) => (
  <div className="text-start">
    <H2>
      <Breadcrumbs
        items={[
          {
            slug: parent.slug,
            name: parent.title,
          },
          {
            slug,
            name: title,
          },
        ]}
      />
    </H2>
  </div>
)

export default DashboardBreadcrumbs
