import { ignoreTableHeads } from "./constants"
import {
  type TStatsMetaHeader,
  type TStatsMetaItem,
  type TStatsTotalHeader,
  type TStatsTotalItem,
} from "./hooks/stats"

export const renderStatsTotalItem = (
  row: TStatsTotalItem,
  key: TStatsTotalHeader,
) => (
  <td className="px-1" key={key}>
    {!ignoreTableHeads.includes(key) && (
      <>
        <span className="text-xs">{key}</span>
        <br />
      </>
    )}
    {row[key]}
  </td>
)

export const renderStatsMetaItem = (
  row: TStatsMetaItem,
  key: TStatsMetaHeader,
) => (
  <td className="px-1" key={key}>
    {!ignoreTableHeads.includes(key) && (
      <>
        <span className="text-xs">{key}</span>
        <br />
      </>
    )}
    {row[key]}
  </td>
)

export const renderProgressBarsCaption = (
  label: "campaign" | "ad set" | "ad",
  withinCurrent?: "campaign" | "ad set",
) => (
  <>
    180 day Brand ROAS for{" "}
    <span className="text-cyan font-semibold">this {label}</span> vs the{" "}
    <span className="text-gray font-semibold">
      average of your other {label}s
    </span>
    {withinCurrent && <> within current {withinCurrent}</>}
  </>
)

const getStringByRoas = (roas: number) => {
  if (roas <= -0.5) {
    return "This %label% is performing significantly worse than other campaigns on %platform%, consider adjusting budget downward."
  }
  if (roas <= -0.1) {
    return "This %label% is performing worse than other %label%s on %platform%, consider adjusting budget downward."
  }
  if (roas <= 0.1) {
    return "This %label% is performing in-line with other %label%s on %platform%."
  }
  if (roas < 0.5) {
    return "This %label% is performing better than other %label%s on %platform%, consider adjusting budget upward."
  }
  return "This %label% is showing significantly outsized performance vs other %label%s on %platform%, consider adjusting budget upward."
}

export const renderTitle = (title: string) => {
  let $title = title
  if (title.length > 40) {
    $title = `${title.slice(0, 40)}...`
  }

  return $title
}

export const getRoasDiff = (metric: any, allDetails: any) => {
  const currentBrandValueOverSpend = metric.brand_value_over_spend
  const otherBrandValue = allDetails.brand_value_agg - metric.brand_value_agg
  const otherCampaignsSpend = allDetails.spend - metric.spend
  const otherBrandValueOverSpend =
    otherCampaignsSpend > 0 ? otherBrandValue / otherCampaignsSpend : 0

  const roasDiff =
    otherBrandValueOverSpend === 0
      ? 1
      : -(1 - currentBrandValueOverSpend / otherBrandValueOverSpend)

  return {
    roasDiff,
    currentBrandValueOverSpend,
    otherBrandValueOverSpend,
  }
}

export const renderDetailsCaption = (
  metric: any,
  allDetails: any,
  label: "campaign" | "ad set" | "ad",
  platform: "meta" | "tiktok",
) => {
  const { roasDiff } = getRoasDiff(metric, allDetails)

  return getStringByRoas(roasDiff)
    .replaceAll("%label%", label)
    .replaceAll("%platform%", platform)
}
