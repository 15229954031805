// import "./wdyr"
import React from "react"
import { Provider } from "react-redux"
import { createRoot } from "react-dom/client"
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"
import { StableNavigateContextProvider } from "./utils/StableNavigateContext"
import * as Sentry from "@sentry/react"

import Auth from "./pages/Auth"
import { ToastProvider } from "./components/ui/common"

import { store } from "./app/store"

import "react-toastify/dist/ReactToastify.css"
import "./assets/styles/index.css"

import { HubSpotChat } from "./components/layout/HubSpotChat"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  enable_recording_console_log: true,
})

Sentry.init({
  normalizeDepth: 10,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: !!import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    posthog.sentryIntegration({
      organization: "marathon-data",
      projectId: 4505948588605440,
      severityAllowList: ["error", "info"], // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: import.meta.env
    .VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: import.meta.env
    .VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
})

const hubspotEnabled = import.meta.env.VITE_HUBSPOT_CHAT_ENABLED === "true"

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PostHogProvider client={posthog}>
          <BrowserRouter>
            <StableNavigateContextProvider>
              <ToastProvider />
              <Auth />
            </StableNavigateContextProvider>
          </BrowserRouter>
          {hubspotEnabled ? <HubSpotChat /> : null}
        </PostHogProvider>
      </Provider>
    </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
