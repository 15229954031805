import { useState, useMemo, useCallback } from "react"
import Fuse, { IFuseOptions } from "fuse.js"

import type { Option } from "."

const fuseOptions: IFuseOptions<Option> = {
  keys: ["label"],
  includeScore: true,
  threshold: 0.4,
  ignoreLocation: true,
}

export function useSearch(options: Option[], initialQuery = "") {
  const [searchQuery, setSearchQuery] = useState<string>(initialQuery)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const handleSearch = useCallback((text: string) => {
    setIsSearching(true)
    setSearchQuery(text)
  }, [])

  const filteredItems = useMemo(() => {
    const searchTerm = searchQuery.trim().toLowerCase()
    if (searchTerm === "" || !isSearching) return options

    const fuse = new Fuse(options, fuseOptions)
    return fuse.search(searchTerm).map(result => result.item)
  }, [searchQuery, options])

  return {
    searchQuery,
    setSearchQuery: handleSearch,
    filteredItems,
    setIsSearching,
  }
}
