import React from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type THandleAction = (actionId: string, item: TCellItem) => void

export type TTableHeaderProp = {
  label?: string
  value?: string
  customRender?: (
    item: TCellItem,
    handleAction?: THandleAction,
  ) => React.ReactElement
  width?: number
  cellClassName?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TCellItem = Record<string, any>

export type TTableClasses = {
  table: string
  th: string
  thead: string
  td: string
  tbody: string
  row: string
}

export type TTableProps = {
  headers: TTableHeaderProp[]
  items: TCellItem[]
  handleAction?: THandleAction
  classes?: Partial<TTableClasses>
}

const renderCell = (
  header: TTableHeaderProp,
  item: TCellItem,
  handleAction: THandleAction,
) => {
  if (header.customRender) {
    return header.customRender(item, handleAction)
  }
  if (header.value) {
    return item[header.value]
  }
  return ""
}

export default function Table({
  headers,
  items,
  handleAction = () => null,
  classes,
}: TTableProps) {
  return (
    <div className="overflow-hidden border rounded-lg border-basic-blue">
      <table
        className={cn("table rounded-lg bg-basic-dark-blue", classes?.table)}
      >
        {/* head */}
        <thead className={cn(classes?.thead)}>
          <tr className={cn(classes?.row)}>
            {headers.map((header, idx) => {
              const props = {} as React.TdHTMLAttributes<HTMLTableCellElement>
              if (header.width && typeof header.width === "number") {
                props.style = {
                  width: `${header.width}px`,
                }
              }
              return (
                <th
                  key={`table-header-${idx}`}
                  className={cn(
                    "py-4 text-4 leading-5 text-white",
                    classes?.th,
                  )}
                  {...props}
                >
                  {header.label ?? ""}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className={cn(classes?.tbody)}>
          {items.map((item, ydx) => (
            <tr
              className={cn(
                classes?.row,
                `${ydx % 2 === 0 ? "bg-basic-dark-blue" : "bg-basic-black"}`,
              )}
              key={`table-row-${ydx}`}
            >
              {headers.map((header, idx) => (
                <td
                  key={`table-cell-${ydx}-${idx}`}
                  className={cn(header.cellClassName, "", classes?.td)}
                >
                  {renderCell(header, item, handleAction)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
