import React from "react"
import type { TableColumnType } from "~/ui-rtk/components/ui/charts/TableVisualization/types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type THeaderProps = {
  value?: string
  type: TableColumnType
  isComparing: boolean
  className?: string
}

export const Header: React.FC<THeaderProps> = ({
  value,
  type,
  isComparing,
  className,
}) => (
  <div className={cn(["w-full", className])}>
    <p className="w-full overflow-x-hidden text-wrap">{value ?? ""}</p>
    {isComparing && ["currency", "number"].includes(`${type}`) ? (
      <span className="text-gray-light">&nbsp;(Compared)</span>
    ) : null}
  </div>
)
