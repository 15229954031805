import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type TSelectOption = {
  label: string
  value: string | number
  disabled?: boolean
}
export type TSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  options: TSelectOption[]
  placeholder?: string
  isError?: boolean
  classes?: Partial<{
    select: string
  }>
}

export default function Select({
  className,
  options,
  placeholder,
  isError = false,
  classes,
  ...props
}: TSelectProps) {
  const cx = cn(
    "select w-full bg-basic-dark-blue border border-basic-blue disabled:opacity-50",
    isError && "border-states-error",
    className,
  )
  const placeholderOption = placeholder ? (
    <option disabled selected>
      {placeholder}
    </option>
  ) : null
  return (
    <select className={cn(cx, classes?.select)} {...props}>
      {placeholderOption}
      {options.map(option => (
        <option
          key={option.value}
          disabled={option.disabled}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  )
}
