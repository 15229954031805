import { Link } from "react-router-dom"
import { CreateAccountForm } from "~/ui-rtk/components/features/forms/Onboarding"
import { GuestLayout } from "~/ui-rtk/components/layout"
import { ContentBox } from "~/ui-rtk/components/ui/common"
import { guestRoutes } from "~/ui-rtk/constants/routes"

export const CreateAccount = () => (
  <GuestLayout>
    <ContentBox
      title="Create your account"
      subTitle="Unlock an entirely new perspective on your brand"
    >
      <CreateAccountForm />
      <div className="w-full mt-8 space-y-10 text-center text-white">
        <p className="leading-5 font-medium">
          By clicking “Register” above I acknowledge I have read and agree to
          the{" "}
          <a
            target="_blank"
            href="https://www.marathondataco.com/license-and-services-agreement"
            className="underline font-bold"
          >
            License & Services Agreement
          </a>
        </p>

        <p className="leading-5">
          Already have an account?{" "}
          <Link to={guestRoutes.SIGN_IN} className="font-bold text-brand">
            Sign in here
          </Link>
        </p>
      </div>
    </ContentBox>
  </GuestLayout>
)
