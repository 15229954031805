import SvgHOC from "~/ui-rtk/components/ui/common/SvgHOC"

export const HomeSvg = SvgHOC(({ fill, ...props }) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.03277 2.63475C7.59146 2.16342 8.40854 2.16342 8.96723 2.63475L13.4672 6.43111C13.8051 6.71612 14 7.13561 14 7.57761V14.1129C14 14.7572 13.4777 15.2796 12.8333 15.2796H10.8333C10.189 15.2796 9.66667 14.7572 9.66667 14.1129V10.7796C9.66667 10.5034 9.44281 10.2796 9.16667 10.2796H6.83333C6.55719 10.2796 6.33333 10.5034 6.33333 10.7796V14.1129C6.33333 14.7572 5.811 15.2796 5.16667 15.2796H3.16667C2.52233 15.2796 2 14.7572 2 14.1129V7.57761C2 7.13561 2.19494 6.71612 2.53277 6.43111L7.03277 2.63475Z"
      stroke={fill}
    />
  </svg>
))
