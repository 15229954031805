import AccountDetailsForm from "~/ui-rtk/components/features/forms/Onboarding/AccountDetailsForm"
import { ContentBox } from "~/ui-rtk/components/ui/common"

export const AccountDetails = () => (
  <ContentBox
    title="Tell us about yourself"
    subTitle="Please enter your full name and company domain below"
  >
    <AccountDetailsForm />
  </ContentBox>
)
