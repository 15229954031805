import qs from "qs"
import { useURLSearchParams } from "./url"
import { Dispatch, SetStateAction, useMemo } from "react"

function isFunction<T>(
  maybeFunction: T | SetStateAction<T>,
): maybeFunction is (prevState: T) => T {
  return typeof maybeFunction === "function"
}

export default function useUrlParamsState<T>(urlKey: string) {
  const { params, setParams } = useURLSearchParams()

  const filtersURLSearchParam = useMemo(() => params[urlKey], [params])

  const state = useMemo(() => {
    const emptyRes = null
    return filtersURLSearchParam
      ? (qs.parse(filtersURLSearchParam, {
          arrayLimit: 50,
        }) as T)
      : emptyRes
  }, [filtersURLSearchParam])

  const setState: Dispatch<SetStateAction<T>> = (action: SetStateAction<T>) => {
    const newState = isFunction<T>(action) ? action(state as T) : action
    const encodedFilters = newState
      ? qs.stringify(newState, {
          arrayFormat: "indices",
          allowEmptyArrays: true,
        })
      : undefined

    setParams({
      [urlKey]: encodedFilters,
    })
  }

  return { state, setState }
}

export function useUrlParamsStateArray<T>(urlKey: string) {
  const { state: rawState, setState: setRawState } =
    useUrlParamsState<T>(urlKey)

  const state = useMemo(() => {
    if (!rawState) {
      return []
    }

    const parsedState = Array.isArray(rawState)
      ? rawState
      : Object.keys(rawState).reduce((acc, idx) => {
          acc[parseInt(idx, 10)] = (rawState as Record<string, never>)[idx]
          return acc
        }, [])

    return parsedState
  }, [rawState])

  const setState: Dispatch<SetStateAction<T>> = (action: SetStateAction<T>) => {
    const newState = isFunction(action) ? action(state as T) : action

    setRawState(((newState as Array<unknown>).length > 0 ? newState : []) as T)
  }

  return {
    state,
    setState,
  }
}
