import React, { MouseEvent, PropsWithChildren, useMemo } from "react"

import { Loader } from "~/ui-rtk/components/ui/common/Loader"

import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { buttonSizeMap, getButtonVariant } from "./utils"

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
type HTMLButtonPropsWithRef = HTMLButtonProps & {
  ref?: React.Ref<HTMLButtonElement>
}

export type TButtonSize = "sm" | "md" | "lg"
export type TButtonColor = "red" | "white" | "pink" | "blue"

export type TButtonSolid = { variant: "solid"; color: TButtonColor }
export type TButtonOutlined = { variant: "outlined" }
export type TButtonUnderline = { variant: "underlined" }
export type TButtonText = { variant: "text" }

export type TButtonVariant =
  | TButtonSolid
  | TButtonOutlined
  | TButtonUnderline
  | TButtonText

export type TButtonProps = Omit<HTMLButtonProps, "color"> & {
  variant?: TButtonVariant
  size?: TButtonSize
  innerRef?: React.RefObject<HTMLButtonElement>
  isLoading?: boolean
} & PropsWithChildren

const Button: React.FC<TButtonProps> = ({
  children,
  type = "button",
  size = "lg",
  variant = { variant: "solid", color: "pink" },
  isLoading = false,
  innerRef,
  className,
  disabled,
  onClick,
  ...props
}) => {
  const buttonVariant = getButtonVariant(variant)
  const buttonSize = buttonSizeMap[size]
  const cx = useMemo(
    () =>
      cn(
        `gap-2 justify-center items-center`,
        buttonVariant,
        buttonSize,
        className,
      ),
    [buttonVariant, buttonSize, className],
  )

  const otherProps: HTMLButtonPropsWithRef = { ...props }
  if (innerRef) {
    otherProps.ref = innerRef
  }

  const handleClick = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    if (!disabled || !isLoading) {
      void onClick?.(event)
    }
  }

  return (
    <button
      type={type}
      className={cx}
      onClick={handleClick}
      disabled={disabled || isLoading}
      {...otherProps}
    >
      {children}
      {isLoading ? <Loader /> : null}
    </button>
  )
}

Button.displayName = "Button"

export default Button
