import { VALUE_OVER_TIME_MODE } from "../constants"
import { btnColorMap } from "~/ui-rtk/components/ui/controls/Button/utils"

export default function ValueOverTimeModeSelector({
  mode,
  setMode,
}: {
  mode: VALUE_OVER_TIME_MODE
  setMode: (mode: VALUE_OVER_TIME_MODE) => void
}) {
  const btnClassName =
    "flex items-center justify-center font-medium text-3.5 min-w-30"
  return (
    <div className="join value-over-time-mode-selector">
      <button
        className={`${btnClassName} ${mode === VALUE_OVER_TIME_MODE.TOTAL ? btnColorMap.pink : "text-white"}`}
        onClick={() => setMode(VALUE_OVER_TIME_MODE.TOTAL)}
      >
        Total Value
      </button>
      <button
        className={`${btnClassName} ${mode === VALUE_OVER_TIME_MODE.BRAND_VALUE ? btnColorMap.pink : "text-white"}`}
        onClick={() => setMode(VALUE_OVER_TIME_MODE.BRAND_VALUE)}
      >
        Brand Value
      </button>
    </div>
  )
}
