import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectIsLoading } from "../app/selectors/loading.selector"
import { setIsLoading } from "../app/slices/loading.slice"

export default function useLoadingState() {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector(selectIsLoading)

  const handleSetIsLoading = useCallback(
    (isLoadingProp: boolean) => {
      dispatch(setIsLoading(isLoadingProp))
    },
    [isLoading],
  )

  return {
    isLoading,
    setIsLoading: handleSetIsLoading,
  }
}
