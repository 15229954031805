import React, { ChangeEvent } from "react"
import { Column } from "@tanstack/react-table"

import { Input } from "~/ui-rtk/components/ui/controls"

import type {
  TMetric,
  TableVisualizationColumnDef,
  TableVisualizationColumnFilter,
} from "~/ui-rtk/components/ui/charts/TableVisualization/types"

type RangeFilterProps = {
  column: Column<TMetric, unknown>
  tableFilter?: TableVisualizationColumnFilter | null
  onChange: (key: string, value: { min?: number; max?: number }) => void
}

const RangeFilter: React.FC<RangeFilterProps> = ({
  tableFilter,
  column,
  onChange,
}) => {
  const columnDef = column.columnDef as TableVisualizationColumnDef

  const defaultValue = tableFilter?.value as { min?: number; max?: number }

  const handleMinMax =
    (type: "min" | "max") => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value ? Number(event.target.value) : undefined
      if (Number.isNaN(value)) {
        return
      }

      const internalFitlerValue = tableFilter?.value as
        | { min?: number; max?: number }
        | undefined

      const min =
        type === "min" ? value : internalFitlerValue?.min || defaultValue?.min
      const max =
        type === "max" ? value : internalFitlerValue?.max || defaultValue?.max

      onChange(columnDef.key, { min, max })
    }

  return (
    <div className="space-y-3">
      <label className="ml-1 font-semibold">Amount</label>
      <div className="flex items-center gap-2">
        <Input
          className="bg-basic-blue"
          placeholder="Min value"
          defaultValue={defaultValue?.min}
          onChange={handleMinMax("min")}
        />
        <Input
          className="bg-basic-blue"
          placeholder="Max value"
          defaultValue={defaultValue?.max}
          onChange={handleMinMax("max")}
        />
      </div>
    </div>
  )
}

export default RangeFilter
