import { useCallback } from "react"
import { toast } from "react-toastify"
import { useCompanyConnectorControllerAuthenticateUploadMutation } from "~/ui-rtk/api/companyConnectorApi"
import { useS3Upload } from "~/ui-rtk/shared/hooks"
import { isBadRequestError, isNotFoundError } from "~/ui-rtk/utils/http-utils"

export const useConnect = () => {
  const [authenticateUploadAsync, { isLoading: isAuthenticating }] =
    useCompanyConnectorControllerAuthenticateUploadMutation()
  const { uploadFilesToS3, isLoading: isS3Uploading } = useS3Upload()

  const authenticate = useCallback(async (id: string, keys: string[]) => {
    await authenticateUploadAsync({
      id,
      authenticateUploadDto: { keys },
    }).unwrap()
  }, [])

  const handleError = useCallback((err: unknown) => {
    if (isBadRequestError(err) || isNotFoundError(err)) {
      toast.error((err as { message: string }).message)
    } else {
      toast.error("Unexpected error occurred!")
    }
  }, [])

  return {
    handleError,
    authenticate,
    uploadFilesToS3,
    isLoading: isAuthenticating || isS3Uploading,
  }
}
