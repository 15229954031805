import React, { createElement, useCallback, useState } from "react"

interface ComponentInfo<T extends React.JSXElementConstructor<T>> {
  component: T
  props: React.ComponentProps<T>
}

type LoadComponent = <T extends React.JSXElementConstructor<any>>(
  component: T,
  props: React.ComponentProps<T>,
) => void

export const useLazyComponent = () => {
  const [lazyComponent, setLazyComponent] = useState<ComponentInfo<any> | null>(
    null,
  )

  const unloadComponent = useCallback(() => {
    setLazyComponent(null)
  }, [])

  const loadComponent: LoadComponent = useCallback((component, props) => {
    setLazyComponent({
      component,
      props: {
        ...props,
        onClose: props.onClose ?? (() => setLazyComponent(null)),
      },
    })
  }, [])

  const renderLazyComponent = useCallback(() => {
    if (!lazyComponent) {
      return
    }

    return createElement(lazyComponent.component, lazyComponent.props)
  }, [lazyComponent])

  return {
    lazyComponent,
    loadComponent,
    unloadComponent,
    renderLazyComponent,
  }
}
