import { Outlet } from "react-router-dom"
import H1 from "~/ui-rtk/components/ui/typography/H1"
import SettingsTabs from "~/ui-rtk/components/features/settings/SettingsTabs"

export default function Main() {
  return (
    <div className="h-full p-6 bg-basic-black text-basic-white">
      <H1>Settings</H1>
      <SettingsTabs />
      <Outlet />
    </div>
  )
}
