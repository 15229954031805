import { createSlice } from "@reduxjs/toolkit"
import { revertAll } from "../revertAll"

type TDemoState = {
  enabled: boolean
}

const initialState: TDemoState = {
  enabled: false,
}

export const demoSlice = createSlice({
  name: "demoSlice",
  initialState,
  extraReducers: builder => builder.addCase(revertAll, () => initialState),
  reducers: {
    updateDemo(state, { payload }) {
      state.enabled = payload
    },
  },
})

export const { updateDemo } = demoSlice.actions
