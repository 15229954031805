/* eslint-disable no-console */
import { FC, useMemo } from "react"

import H4 from "~/ui-rtk/components/ui/typography/H4"
import SearchSelect, {
  Option,
} from "~/ui-rtk/components/ui/common/SearchSelect"
import { CompanyDto } from "~/ui-rtk/api/types"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUser } from "~/ui-rtk/app/selectors/user.selector"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"

const mapToOptions = (companies: Pick<CompanyDto, "name" | "id">[]) =>
  companies.map(company => ({
    value: company.id,
    label: company.name,
  }))

type TTitleProps = {
  onCompanyChange: (companyId: string) => void
  currentCompany: {
    id: string
    name: string
  }
}

export const Title: FC<TTitleProps> = ({ onCompanyChange, currentCompany }) => {
  const { companies } = useAppSelector(selectUser)
  const demo = useAppSelector(selectIsEnabled)

  const companiesForMap = companies.map(
    ({ company }: { company: CompanyDto }) => company,
  )

  const handleSwitch = (option: Option) => {
    onCompanyChange(`${option.value}`)
  }

  const options = useMemo(() => mapToOptions(companiesForMap), [companies])
  const defaultValue = useMemo(
    () => mapToOptions([currentCompany])[0],
    [companiesForMap, currentCompany.id],
  )

  const renderTitle = () => {
    if (options.length === 1) {
      const option = options[0]
      return <p className="font-bold mt-0.5">{option.label}</p>
    }

    return (
      <SearchSelect
        demoMode={demo.isEnabled}
        options={options}
        value={defaultValue}
        onSelect={handleSwitch}
      />
    )
  }

  return (
    <div className="flex items-center gap-3">
      <H4 className="font-bold">marathon data</H4>
      <span className="font-bold text-neutral text-4.5 leading-4.5">x</span>
      {renderTitle()}
    </div>
  )
}
