import { type Query as TCubeQuery } from "@cubejs-client/core"
import { useCallback, useMemo } from "react"
import { toast } from "react-toastify"

import {
  type CreateCheckoutSessionDto,
  useSubscriptionControllerCreateCheckoutSessionMutation as useCheckoutSessionMutation,
} from "~/ui-rtk/api/subscriptionApi"
import { useProductControllerGetAllQuery as useProductsQuery } from "~/ui-rtk/api/productApi"

import { hasErrorMessage } from "~/ui-rtk/utils/http-utils"
import { ProductFeature } from "~/api/services/product/enums"
import { useCubeQuery } from "@cubejs-client/react"
import { generalPostProcess } from "~/ui-rtk/constants/metrics-mapping"
import { getPivotData } from "~/ui-rtk/utils/cube"
import { SubscriptionProduct } from "./components/SubscriptionProduct/SubscriptionProduct"
import { getPricingTier } from "./utils"
import { isNumeric } from "~/ui-rtk/utils/format"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export const useCompanyRevenueQuery = () => {
  const query = useMemo(() => {
    const now = dayjs().format("YYYY-MM-DD")
    const prevYear = dayjs().subtract(1, "year").format("YYYY-MM-DD")
    const query: TCubeQuery = {
      dimensions: ["metrics.companyid"],
      measures: ["metrics.ga_revenue"],
      filters: [
        {
          member: "metrics.date",
          operator: "inDateRange",
          values: [prevYear, now],
        },
      ],
    }
    return query
  }, [])

  const { resultSet, isLoading } = useCubeQuery(query)

  const dataSource = useMemo(() => {
    if (!resultSet) {
      return null
    }

    const columns = resultSet.tableColumns()
    const pivotData = getPivotData(resultSet, query)
    return generalPostProcess(pivotData, columns)
  }, [resultSet])

  const revenue = useMemo(() => {
    if (!dataSource || !dataSource?.length) {
      return null
    }

    return dataSource.reduce(
      (acc: number, item: any) => acc + item["metrics.ga_revenue"],
      0,
    )
  }, [dataSource])

  return { dataSource, revenue, isLoading }
}

export const useConnect = () => {
  const { data = [], isLoading: isProductsLoading } = useProductsQuery()
  const [
    checkoutAsync,
    { isLoading: isCheckoutLoading, isError: isCheckoutError },
  ] = useCheckoutSessionMutation()

  const {
    dataSource,
    revenue,
    isLoading: isRevenueLoading,
  } = useCompanyRevenueQuery()

  const product: SubscriptionProduct | null = useMemo(() => {
    if (!data || !dataSource) {
      return null
    }

    const product = data.find(p => p.metadata.featureId === ProductFeature.BASE)
    if (!product) {
      return null
    }

    let price: number | null = null
    if (typeof revenue === "number" && isNumeric(revenue)) {
      const tier = getPricingTier(revenue, product?.tiers)
      price = tier?.amount || null
    }

    return { ...product, price }
  }, [data, revenue, dataSource])

  const checkout = useCallback(
    async (dto: CreateCheckoutSessionDto) => {
      try {
        const args = { createCheckoutSessionDto: dto }
        const { checkoutUrl } = await checkoutAsync(args).unwrap()

        window.location.href = checkoutUrl
      } catch (error) {
        if (hasErrorMessage(error) && isCheckoutError) {
          toast.error(error.data.message, {
            toastId: "create-checkout-session-error",
          })
        }
      }
    },
    [isCheckoutError],
  )

  return {
    isFetching: isProductsLoading || isRevenueLoading,
    isCheckoutLoading,
    product,
    checkout,
  }
}
