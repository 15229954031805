import React, { ComponentType, SVGProps } from "react"

import { getThemeColor } from "~/ui-rtk/utils/tailwind-utils"

import type { ThemeColors } from "~/ui-rtk/themes/types"

export type TSvgHOCProps = {
  size?: number
  fill?: ThemeColors
}

const SvgHOC = <P extends SVGProps<SVGSVGElement>>(
  Component: ComponentType<P>,
) => {
  const SvgComponent: React.FC<TSvgHOCProps & P> = ({
    fill,
    size = 16,
    ...props
  }) => (
    <Component
      fill={getThemeColor(fill || "background.light")}
      width={size}
      height={size}
      style={{ minWidth: size, minHeight: size, display: "inline" }}
      {...(props as unknown as P)}
    />
  )

  return SvgComponent
}

export default SvgHOC
